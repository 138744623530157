<template>
  <div class="view-utenti">
    <Loading v-model="loading" />

    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-account-group" />

    <Users @ready="loading = false" />

    <v-btn bottom dark fab large fixed right
      color="success"
      @click.stop="dialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <NewUserDialog v-model="dialog" />
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import PageHeader from '@/components/PageHeader';
import Users from '@/components/Users';
import NewUserDialog from '@/components/NewUserDialog';

export default {
  name: 'Utenti',

  components: {
    Loading,
    PageHeader,
    Users,
    NewUserDialog,
  },

  data: () => ({
    loading: true,

    breadcrumbs: [
      {
        text: 'Utenti',
        exact: true,
        to: { name: 'Utenti' },
      },
    ],

    dialog: false,
  }),
};
</script>
