<template>
  <div class="component-selectedphases">
    <v-card outlined class="flex-grow-1">
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>Lavorazioni richieste</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-chip
          v-if="selectedPhases.length > 0"
          class="ma-2"
          color="green"
          text-color="white"
        >
          {{ selectedPhases.length }}
        </v-chip>
      </v-toolbar>

      <v-card-text>
        <v-alert
          text dense
          type="info"
          border="left">
          Le fasi di lavorazione richieste variano in base alle risposte date.
          Completato il test, sarà possibile selezionare i prodotti e rivedere le
          procedure per ognuna delle fasi.
        </v-alert>

        <v-list v-if="selectedPhases.length > 0"
          subheader>
          <template v-for="phase in selectedPhases">
            <v-list-item :key="phase.id">
              <v-list-item-content>
                <v-list-item-title v-html="phase.name"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action class="list-actions">
                <v-tooltip top v-if="phase.withProcedure">
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="info"
                      v-on="on"
                      @click="onShowProcedure(phase)">
                      <v-icon>mdi-script-text-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Include una procedura</span>
                </v-tooltip>

                <v-tooltip top v-if="hasProducts(phase)">
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="info"
                      v-on="on">
                      <v-icon>mdi-format-list-text</v-icon>
                    </v-btn>
                  </template>
                  <span>Include prodotti</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="`${phase.id}-divider`"></v-divider>
          </template>
        </v-list>

        <v-alert v-else
          text type="warning" icon="mdi-format-list-text" border="left">
          Nessuna lavorazione richiesta
        </v-alert>
      </v-card-text>
    </v-card>

    <PhaseProcedureDialog v-model="procedureDialog"
      :phase="selected" />
  </div>
</template>

<script>
import PhaseProcedureDialog from '@/components/PhaseProcedureDialog';

export default {
  name: 'SelectedPhases',

  props: ['status'],

  components: {
    PhaseProcedureDialog,
  },

  data: () => ({
    loading: false,

    selected: null,
    procedureDialog: false,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  computed: {
    phases() {
      return this.$store.getters['phases/docs'];
    },

    selectedPhases() {
      return this.status
        ? this.phases.filter((phase) => {
          // Verifica se la fase va visualizzata

          const conditions = 'conditions' in phase
            && phase.conditions.constructor === Object
            ? Object.values(phase.conditions)
            : null;

          // Se non sono impostate condizioni, la fase è visibile
          if (!conditions
            || !Array.isArray(conditions)
            || conditions.length === 0) return true;

          // Sono definite delle condizioni, verifico se una di queste è soddisfatta
          let visible = false;
          // eslint-disable-next-line no-restricted-syntax
          for (const condition of conditions) {
            const includeTags = Array.isArray(condition)
              ? condition
              : condition.include;
            const excludeTags = Array.isArray(condition)
              ? []
              : condition.exclude;

            const excludeCommonTags = excludeTags.filter((tag) => this.status.tags.includes(tag));

            if (excludeCommonTags.length > 0) {
              visible = false;
              break;
            }

            const includeCommonTags = includeTags.filter((tag) => this.status.tags.includes(tag));

            if (includeCommonTags.length === includeTags.length) {
              visible = true;
              break;
            }
          }

          return visible;
        })
        : [];
    },

    products() {
      return this.$store.getters['products/docs'];
    },
  },

  methods: {
    hasProducts(phase) {
      // eslint-disable-next-line no-prototype-builtins
      return phase && phase.hasOwnProperty('choices')
        && Object.values(phase.choices).length > 0;
    },

    onShowProcedure(phase) {
      this.selected = phase;
      this.procedureDialog = true;
    },
  },
};
</script>

<style scoped>
.list-actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.v-list-item__title, .v-list-item__subtitle {
  white-space: normal;
}
</style>
