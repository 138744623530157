/* eslint-disable no-shadow */

import Vue from 'vue';
import firebase from 'firebase';
import {
  INIT_TAGS, UPDATE_TAGS, SET_TAGS, CLEAR_TAGS, DESTROY_TAGS,
  SET_TAGS_LISTENER,
} from '@/store/actions/tags';

const state = {
  data: [],
  listener: null,
};

const getters = {
  docs: (state) => Object.values(state.data),
};

const actions = {
  [INIT_TAGS]: ({ dispatch }) => {
    console.log('actions:INIT_TAGS'); // eslint-disable-line no-console

    return dispatch(UPDATE_TAGS);
  },

  [UPDATE_TAGS]: async ({ commit, state }) => {
    console.log('actions:UPDATE_TAGS'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Recupera la collection con l'elenco
    const collectionRef = db.collection('tags').orderBy('name');
    const listener = collectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (tags)'); // eslint-disable-line no-console

      commit(SET_TAGS, {
        snapshot,
      });
    });
    commit(SET_TAGS_LISTENER, listener);
  },

  [DESTROY_TAGS]: ({ state, commit }) => {
    console.log('actions:DESTROY_TAGS'); // eslint-disable-line no-console

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Pulisce lo state
    commit(CLEAR_TAGS);
  },

  add: (context, payload) => {
    console.log('actions:add'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const collectionRef = db.collection('tags');

    return collectionRef.add({
      ...payload,
    });
  },

  update: (context, payload) => {
    console.log('actions:update'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Separa la proprietà 'id' dal resto
    const { id, ...data } = payload;

    const docRef = db.doc(`/tags/${id}`);

    return docRef.update({
      ...data,
    });
  },

  delete: (context, payload) => {
    console.log('actions:delete'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const docRef = db.doc(`/tags/${payload}`);

    return docRef.delete();
  },
};

const mutations = {
  [SET_TAGS_LISTENER]: (state, payload) => {
    console.log('mutations:SET_TAGS_LISTENER'); // eslint-disable-line no-console

    Vue.set(state, 'listener', payload);
  },

  [SET_TAGS]: (state, payload) => {
    console.log('mutations:SET_TAGS'); // eslint-disable-line no-console

    const data = [];

    if (payload.snapshot) {
      payload.snapshot.forEach((doc) => {
        const docData = doc.data();

        data.push({
          id: doc.id,
          ...docData,
        });
      });
    } else if (payload.list) {
      payload.list.forEach((doc) => {
        data.push({
          ...doc,
        });
      });
    }

    Vue.set(state, 'data', data);

    // eslint-disable-next-line no-console
    console.log(`state.data.length = ${state.data.length}`);
    // eslint-disable-next-line no-console
    // console.log('state.data =', state.data);
  },

  [CLEAR_TAGS]: (state) => {
    console.log('mutations:CLEAR_TAGS'); // eslint-disable-line no-console

    Vue.set(state, 'data', []);
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
