<template>
  <div class="view-profilo">
    <Loading v-model="loading" />

    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-account-box" />

    <!-- Mostra un alert se l'email non è verificata -->
    <v-alert text prominent type="warning" border="left"
      v-if="!emailVerified">
      <v-row>
        <v-col
          class="d-flex flex-column flex-sm-row justify-space-between align-start align-sm-center">
          <span>
            <strong>L'indirizzo email {{ currentUser.email }} non è stato verificato.</strong><br>
            Alcune funzionalità potrebbero non essere disponibili finché non verifichi l'indirizzo email.
          </span>
          <v-btn color="warning" class="mt-4 mt-sm-0"
            v-if="!verificationEmailSent"
            @click.stop="onSendVerificationEmail">Invia email di verifica</v-btn>
          <v-btn color="warning" class="mt-4 mt-sm-0"
            light
            v-else
            disabled>Email di verifica inviata</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      v-if="!currentUser.pricelist"
      type="warning" class="mb-4"
      transition="scale-transition">
      <strong>Non ti è stato assegnato un listino.</strong><br>
      Potrai consultare i prodotti e creare preventivi ma non potrai visualizzare i prezzi.
    </v-alert>

    <!-- Alert utilizzato in caso di errore -->
    <v-alert
      type="error" dismissible v-model="alert" class="mb-4"
      transition="scale-transition">
      {{ error }}
    </v-alert>

    <!-- Alert utilizzato in caso di successo -->
    <v-alert
      type="success" dismissible v-model="success" class="mb-4"
      transition="scale-transition">
      Il profilo è stato aggiornato con successo.
    </v-alert>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                  <v-row>
                    <v-col cols="12">
                      <UserImage v-model="updatedUser" />
                    </v-col>

                    <v-col cols="12">
                      <v-switch color="info" class="mt-0"
                        v-model="updatedUser.showLogo"
                        :disabled="loading || !hasImage"
                        :label="updatedUser.showLogo
                          ? 'Usa l\'immagine nei preventivi'
                          : 'Non usare l\'immagine nei preventivi'"
                        :hint="updatedUser.showLogo
                          ? 'L\'immagine verrà mostrata nei preventivi assieme ai dati completi.'
                          : 'L\'immagine NON verrà mostrata nei preventivi.'"
                        persistent-hint></v-switch>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                  <v-row>
                    <v-col cols="6">
                      <v-autocomplete
                        :value="currentUser.type"
                        disabled
                        :items="userTypes"
                        label="Tipologia"
                        hide-details
                        filled
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="6">
                      <v-autocomplete
                        :value="currentUser.pricelist"
                        disabled
                        :items="pricelists"
                        item-text="name"
                        item-value="id"
                        label="Listino"
                        placeholder="Nessun listino selezionato"
                        hide-details
                        filled
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" v-if="hasCompany">
                      <v-text-field type="text"
                        v-model="updatedUser.company"
                        :disabled="loading"
                        label="Ragione sociale"
                        :rules="[v => !!v || 'La ragione sociale è richiesta']"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field type="text"
                        v-model="updatedUser.firstName"
                        :disabled="loading"
                        label="Nome"
                        :rules="[v => !!v || 'Il nome dell\'utente è richiesto']"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field type="text"
                        v-model="updatedUser.lastName"
                        :disabled="loading"
                        label="Cognome"
                        :rules="[v => !!v || 'Il cognome dell\'utente è richiesto']"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" v-if="hasCompany">
                      <v-textarea
                        v-model="updatedUser.address"
                        :disabled="loading"
                        label="Indirizzo"
                        dense
                        rows="1"
                        no-resize auto-grow
                      ></v-textarea>
                    </v-col>

                    <v-col cols="2">
                      <v-text-field type="text" dense
                        v-model="updatedUser.postalCode"
                        :disabled="loading"
                        label="CAP"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="5">
                      <v-text-field type="text" dense
                        v-model="updatedUser.city"
                        :disabled="loading"
                        label="Città"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="5">
                      <v-autocomplete dense
                        v-model="updatedUser.province"
                        :disabled="loading"
                        :items="provinces"
                        clearable
                        label="Provincia"
                        :rules="[v => !!v || 'La provincia è richiesta']"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field type="text"
                        v-model="updatedUser.phone"
                        :disabled="loading"
                        label="Telefono"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field type="text"
                        v-model="updatedUser.phone1"
                        :disabled="loading"
                        label="Telefono"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-checkbox
                        v-model="currentUser.checkbox1"
                        color="success"
                        disabled
                      >
                        <template v-slot:label>
                          <div>
                            Ho letto e accettato le condizioni dell'<router-link :to="{ name: 'Privacy' }">Informativa
                            sul Trattamento dei Dati Personali</router-link>
                            (obbligatorio)
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>

                    <v-col cols="12">
                      <v-checkbox
                        v-model="updatedUser.checkbox2"
                        label="L'utente acconsente all'invio di comunicazioni commerciali riguardanti nuovi prodotti o novità sui prodotti già esistenti (facoltativo)"
                        color="success"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>

          <v-card-actions>
            <!-- v-btn text color="primary"
              v-if="emailVerified"
              :disabled="updating"
              :loading="updating"
              @click="onCancel()">
              Torna ai preventivi
            </v-btn -->

            <v-spacer></v-spacer>

            <v-btn text color="info"
              :disabled="updating"
              :loading="updating"
              @click="onEditCredentials()">
              Modifica le credenziali
            </v-btn>

            <v-btn color="success"
              :disabled="updating || !updated"
              :loading="updating"
              @click="onUpdate()">
              Aggiorna
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="leaveDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Vuoi abbandonare le modifiche?</v-card-title>

        <v-card-text>
          Non hai salvato le modifiche al profilo. Sei certo di voler uscire senza prima salvarle?
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text
            @click="leaveDialog = false">
            Rimani su questa pagina
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="warning" text
            @click="next()">
            Lascia questa pagina senza salvare
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <EditCredentialsDialog v-model="editCredentialsDialog" :user="currentUser" self />
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import PageHeader from '@/components/PageHeader';
import EditCredentialsDialog from '@/components/EditCredentialsDialog';
import { getProvince } from '@/utils/italy';
import UserImage from '@/components/UserImage';
import { UPDATE_USER } from '@/store/actions/users';
import { REFRESH_CURRENT_USER } from '@/store/actions/auth';

export default {
  name: 'Profilo',

  components: {
    Loading,
    PageHeader,
    EditCredentialsDialog,
    UserImage,
  },

  data: () => ({
    loading: true,
    updating: false,
    updated: false,
    valid: true,
    alert: false,
    success: false,
    error: '',

    verificationEmailSent: false,

    editCredentialsDialog: false,

    breadcrumbs: [
      {
        text: 'Profilo',
        exact: true,
        to: { name: 'Profilo' },
      },
    ],

    leaveDialog: false,
    next: null,

    userTypes: [
      { value: 'privato', text: 'Privato' },
      { value: 'rivenditore', text: 'Rivenditore' },
      { value: 'venditore-esterno', text: 'Venditore esterno della rivendita' },
      { value: 'agente', text: 'Agente' },
      { value: 'imbianchino', text: 'Imbianchino' },
      { value: 'progettista', text: 'Progettista' },
      { value: 'keim', text: 'Funzionario KEIM' },
    ],

    updatedUser: {
      firstName: '',
      lastName: '',
      company: '',
      address: '',
      postalCode: '',
      city: '',
      province: '',
      phone: '',
      phone1: '',
      imageUrl: null,
      imageName: null,
      showLogo: false,
      checkbox2: false,
    },
  }),

  async created() {
    // Inizializza i dati dell'utente
    this.updatedUser.firstName = this.currentUser.firstName;
    this.updatedUser.lastName = this.currentUser.lastName;
    this.updatedUser.company = this.currentUser.company || '';
    this.updatedUser.address = this.currentUser.address || '';
    this.updatedUser.postalCode = this.currentUser.postalCode || '';
    this.updatedUser.city = this.currentUser.city || '';
    this.updatedUser.province = this.currentUser.province || '';
    this.updatedUser.phone = this.currentUser.phone || '';
    this.updatedUser.phone1 = this.currentUser.phone1 || '';
    this.updatedUser.imageUrl = this.currentUser.imageUrl || null;
    this.updatedUser.imageName = this.currentUser.imageName || null;
    this.updatedUser.showLogo = this.currentUser.showLogo || false;
    this.updatedUser.checkbox2 = this.currentUser.checkbox2 === true;

    this.$nextTick(() => { this.loading = false; });
  },

  watch: {
    // Imposta `updated` a `true` se i dati dell'utente sono stati modificati rispetto a quelli originali.
    // Attenzione che `updatedUser` contiene un sottoinsieme dei dati dell'utente quindi non potrà essere
    // mai uguale a `currentUser`.
    // Per questo motivo si confrontano i singoli campi.
    updatedUser: {
      handler() {
        this.updated = this.updatedUser.firstName !== this.currentUser.firstName
          || this.updatedUser.lastName !== this.currentUser.lastName
          || this.updatedUser.company !== this.currentUser.company
          || this.updatedUser.address !== this.currentUser.address
          || this.updatedUser.postalCode !== this.currentUser.postalCode
          || this.updatedUser.city !== this.currentUser.city
          || this.updatedUser.province !== this.currentUser.province
          || this.updatedUser.phone !== this.currentUser.phone
          || this.updatedUser.phone1 !== this.currentUser.phone1
          || this.updatedUser.imageUrl !== this.currentUser.imageUrl
          || this.updatedUser.imageName !== this.currentUser.imageName
          || this.updatedUser.showLogo !== this.currentUser.showLogo
          || this.updatedUser.checkbox2 !== (this.currentUser.checkbox2 === true);

        // Se `success` è `true` e i dati dell'utente sono stati modificati, azzera `success`
        if (this.success && this.updated) this.success = false;
      },
      deep: true,
    },
  },

  computed: {
    currentUser() {
      return this.$store.getters.user;
    },

    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    provinces: () => getProvince(),

    // L'email dell'utente è verificata?
    emailVerified() {
      return this.$store.getters.isEmailVerified;
    },

    hasCompany() {
      return ['rivenditore', 'venditore-esterno', 'agente', 'imbianchino', 'progettista', 'keim']
        .includes(this.currentUser.type);
    },

    hasImage() {
      return this.updatedUser && 'imageUrl' in this.updatedUser && this.updatedUser.imageUrl !== null;
    },
  },

  methods: {
    onCancel() {
      // Torna all'elenco dei prodotti
      this.$router.replace({ name: 'Preventivi' }, () => {});
    },

    onEditCredentials(item) {
      this.selected = item;
      this.editCredentialsDialog = true;
    },

    async onSendVerificationEmail() {
      if (this.currentUser && this.currentUser.id) {
        this.loading = true;
        await this.$store.dispatch('users/sendVerificationEmail', this.currentUser.id);
        this.verificationEmailSent = true;
        this.loading = false;
      }
    },

    async onUpdate() {
      const formRef = this.$refs.form;

      if (!formRef.validate()) return;

      this.loading = true;

      const user = {
        id: this.currentUser.id,
        ...this.updatedUser,
      };
      console.log(`${this.constructor.name} - onUpdate() - user:`, user); // eslint-disable-line no-console

      await this.$store.dispatch(`users/${UPDATE_USER}`, {
        user,
      });

      // Aggiorna l'utente corrente
      await this.$store.dispatch(REFRESH_CURRENT_USER);

      this.success = true;
      this.updated = false;

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
};
</script>
