<template>
  <div class="component-users">
    <v-row no-gutters
      v-if="users && users.length > 0">
      <v-col>
        <v-card outlined>
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex flex-wrap mb-2 align-end justify-start">
                <div class="filters-container no-title">
                  <div class="filters-container-content">
                    <v-text-field class="mr-1 mb-1"
                      v-model="filters.search"
                      outlined
                      placeholder="Cerca..."
                      append-icon="mdi-magnify"
                      :disabled="loading"
                      hide-details
                      clearable
                      dense
                    ></v-text-field>
                  </div>
                </div>

                <div class="filters-container">
                  <span class="filters-container-title">Listini</span>

                  <div class="filters-container-content">
                    <v-btn-toggle class="mr-1 mb-1"
                      v-if="filters.pricelist"
                      v-model="filters.pricelist"
                      dense tile
                      multiple
                      @change="filtersNeedsAnUpdate"
                    >
                      <v-tooltip
                        bottom
                        max-width="400">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on"
                            value="no-pricelist"
                            :class="filters.pricelist.includes('no-pricelist')
                              ? 'orange--text' : ''"
                            small
                            min-width="30"
                            height="40">
                            <v-icon small :color="filters.pricelist.includes('no-pricelist')
                              ? 'orange' : ''">
                              mdi-file-hidden
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Listino assente</span>
                      </v-tooltip>

                      <v-tooltip
                        bottom
                        max-width="400">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on"
                            value="suggested"
                            :class="filters.pricelist.includes('suggested')
                              ? 'orange--text' : ''"
                            small
                            min-width="30"
                            height="40">
                            <v-icon small :color="filters.pricelist.includes('suggested')
                              ? 'orange' : ''">
                              mdi-file-document-edit-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Listino da confermare</span>
                      </v-tooltip>

                      <v-tooltip
                        bottom
                        max-width="400">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on"
                            value="pcu"
                            :class="filters.pricelist.includes('pcu')
                              ? 'success--text' : ''"
                            small
                            min-width="30"
                            height="40">
                            <small>PCU</small>
                          </v-btn>
                        </template>
                        <span>Listino PCU confermato</span>
                      </v-tooltip>

                      <v-tooltip
                        bottom
                        max-width="400">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on"
                            value="pcss"
                            :class="filters.pricelist.includes('pcss')
                              ? 'success--text' : ''"
                            small
                            min-width="30"
                            height="40">
                            <small>PCCS</small>
                          </v-btn>
                        </template>
                        <span>Listino PCCS confermato</span>
                      </v-tooltip>
                    </v-btn-toggle>
                  </div>
                </div>

                <v-spacer></v-spacer>

                <div class="px-1 d-flex">
                  <v-switch color="orange" class="mt-0"
                    v-model="filters.archived"
                    :disabled="loading"
                    hide-details
                    label="Mostra archiviati"></v-switch>

                  <v-tooltip bottom >
                    <template v-slot:activator="{ on }">
                      <v-btn outlined small color="primary"
                        class="ml-8"
                        :loading="downloading"
                        @click="onExport"
                        v-on="on">
                        <v-icon small>
                          mdi-download
                        </v-icon>
                        Esporta Elenco
                      </v-btn>
                    </template>
                    <span>Esporta l'elenco degli utenti</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table class="elevation-0"
            :headers="headers"
            :items="orderedUsers"
            item-key="id"
            :items-per-page="20"
            :loading="loading"
            sort-by="name"
            :sort-desc="false"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 30, -1],
              disableItemsPerPage: loading,
              disablePagination: loading,
            }">

            <template v-slot:[`item.name`]="{ item }">
              {{ fullName(item) }}
              <template v-if="hasCompany(item)">
                <br>
                <span class="grey--text">{{ company(item) }}</span>
              </template>
            </template>

            <template v-slot:[`item.email`]="{ item }">
              {{ item.email }}
              <v-tooltip bottom
                :color="emailVerified(item) ? 'success' : 'orange'"
              >
                <template v-slot:activator="{ on }">
                  <v-icon small
                    :color="emailVerified(item) ? 'success' : 'orange'"
                    v-on="on">
                    {{ emailVerified(item) ? 'mdi-check-circle' : 'mdi-alert-circle' }}
                  </v-icon>
                </template>

                <span v-if="emailVerified(item)">Email verificata</span>
                <span v-else>Email non verificata</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.type`]="{ item }">
              {{ type(item) }}
            </template>

            <template v-slot:[`item.pricelist`]="{ item }">
              <UserPricelist
                :user="item"
                @edit="onEdit(item)" />
            </template>

            <!-- Provincia -->
            <template v-slot:[`item.province`]="{ item }">
              <UserProvince
                :user="item"
                @edit="onEdit(item)" />
            </template>

            <!-- Consenso -->
            <template v-slot:[`item.consent`]="{ item }">
              <UserConsent :user="item" />
            </template>

            <template v-slot:[`item.isSuper`]="{ item }">
              <v-btn class="mr-1 my-1"
                v-if="item.isSuper"
                color="error" outlined x-small>Super Utente</v-btn>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-row class="d-flex justify-end flex-nowrap">
                <v-icon small color="orange" class="mr-2"
                  v-if="item.archived">
                  mdi-archive
                </v-icon>

                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on"
                      :disabled="loading">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Se l'email dell'utente non è verificata, mostra l'opzione per inviare un'email di verifica -->
                    <v-list-item
                      v-if="!emailVerified(item)"
                      @click="onSendVerificationEmail(item)">
                      <v-list-item-title>Invia email di verifica</v-list-item-title>
                    </v-list-item>
                    <!-- Se l'email dell'utente non è verificata, dà la possibilità di forzare la verifica -->
                    <v-list-item
                      v-if="!emailVerified(item)"
                      @click="onForceEmailVerification(item)">
                      <v-list-item-title>Forza verifica email</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="onEditCredentials(item)">
                      <v-list-item-title>Modifica le credenziali</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="onDelete(item)">
                      <v-list-item-title>Elimina</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn class="ml-2" fab x-small depressed color="secondary"
                  :disabled="loading"
                  @click="onEdit(item)">
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-alert text prominent type="success" border="left"
      v-else-if="users">
      <v-row align="center">
        <v-col class="grow">
          Al momento non è presente nessun utente.
        </v-col>
        <v-col class="shrink">
          <v-btn color="success"
            @click="newDialog = true">Aggiungi un utente</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <NewUserDialog v-model="newDialog" />
    <DeleteUserDialog v-model="deleteDialog" :user="selected" />
    <EditUserDialog v-model="editDialog" :user="selected" />
    <EditCredentialsDialog v-model="editCredentialsDialog" :user="selected" />
  </div>
</template>

<script>
import NewUserDialog from '@/components/NewUserDialog';
import DeleteUserDialog from '@/components/DeleteUserDialog';
import EditUserDialog from '@/components/EditUserDialog';
import EditCredentialsDialog from '@/components/EditCredentialsDialog';
import UserPricelist from '@/components/UserPricelist';
import UserConsent from '@/components/UserConsent';
import UserProvince from '@/components/UserProvince';

export default {
  name: 'Users',

  components: {
    NewUserDialog,
    DeleteUserDialog,
    EditUserDialog,
    EditCredentialsDialog,
    UserPricelist,
    UserConsent,
    UserProvince,
  },

  data: () => ({
    newDialog: false,
    deleteDialog: false,
    editDialog: false,
    editCredentialsDialog: false,

    selected: null,

    headers: [
      { text: 'Nome / Ragione sociale', value: 'name', sortable: false },
      { text: 'Email', value: 'email', sortable: false },
      { text: 'Tipologia', value: 'type', sortable: false },
      { text: 'Provincia', value: 'province', sortable: false },
      { text: 'Listino', value: 'pricelist', sortable: false },
      { text: 'Consenso', value: 'consent', sortable: false },
      { text: '', value: 'isSuper', sortable: false },
      {
        text: '',
        value: 'action',
        sortable: false,
        align: 'end',
      },
    ],

    filters: {
      search: '',
      pricelist: [],
      archived: false,
    },

    loading: false,
    downloading: false,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  computed: {
    users() {
      return this.$store.getters['users/docs'];
    },

    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    filteredUsers() {
      return this.users
        ? this.users.filter((u) => {
          // Filtro sugli archiviati:
          // Se l'utente è archiviato e il filtro non è attivo, lo escludo
          if (!this.filters.archived && u.archived) return false;

          // Ricerca testuale nel nome
          if (this.filters.search && this.filters.search.trim()
            && !`${u.firstName} ${u.lastName}`
              .toLowerCase().includes(this.filters.search.toLowerCase())) return false;

          return true;
        }).filter((u) => {
          // Filtro sui listini

          // Se non è stato selezionato nessun filtro, mostro tutti gli utenti
          if (!this.filters.pricelist.length) return true;

          const pricelist = this.pricelists.find((pl) => pl.id === u.pricelist);
          const pricelistName = pricelist ? pricelist.name : '';

          if (this.filters.pricelist.includes('no-pricelist')) {
            // Gli utenti senza listino, vengono mostrati solo se non hanno una provincia
            // o se sono di tipo privato
            if (!pricelist && (!u.province || u.type === 'privato')) return true;
          }
          if (this.filters.pricelist.includes('suggested')) {
            // Gli utenti con listino da confermare sono quelli senza listino ma con provincia
            // associata e non di tipo privato
            if (!pricelist && u.province && u.type !== 'privato') return true;
          }
          if (this.filters.pricelist.includes('pcu')) {
            // Filtro per gli utenti con listino PCU confermato
            if (pricelistName === 'PCU') return true;
          }
          if (this.filters.pricelist.includes('pcss')) {
            // Filtro per gli utenti con listino PCCS confermato
            if (pricelistName === 'PCCS') return true;
          }

          return false;
        })
        : [];
    },

    orderedUsers() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.filteredUsers.sort((a, b) => {
        const aSeconds = 'createdAt' in a ? a.createdAt.seconds || 0 : 0;
        const bSeconds = 'createdAt' in b ? b.createdAt.seconds || 0 : 0;

        if (aSeconds > bSeconds) return -1;

        if (aSeconds < bSeconds) return 1;

        return 0;
      });
    },
  },

  watch: {
    users(val) {
      if (Array.isArray(val)) {
        this.$emit('ready');
      }
    },
  },

  created() {
    if (Array.isArray(this.users)) {
      this.$emit('ready');
    }
  },

  methods: {
    filtersNeedsAnUpdate() {
    },

    fullName(user) {
      return `${user.firstName} ${user.lastName}`;
    },

    type(user) {
      if ('type' in user === false) return 'Privato';

      switch (user.type) {
        case 'rivenditore':
          return 'Rivenditore';
        case 'venditore-esterno':
          return 'Venditore esterno della rivendita';
        case 'agente':
          return 'Agente';
        case 'imbianchino':
          return 'Imbianchino';
        case 'progettista':
          return 'Progettista';
        case 'keim':
          return 'Funzionario KEIM';

        default: // 'privato'
          return 'Privato';
      }
    },

    hasCompany(user) {
      return ['rivenditore', 'venditore-esterno', 'agente', 'imbianchino', 'progettista', 'keim']
        .includes(user.type || 'privato');
    },

    company(user) {
      return this.hasCompany(user) ? user.company : '';
    },

    emailVerified(user) {
      return 'emailVerified' in user && user.emailVerified
        ? user.emailVerified
        : false;
    },

    onDelete(item) {
      this.selected = item;
      this.deleteDialog = true;
    },

    onEdit(item) {
      this.selected = item;
      this.editDialog = true;
    },

    onEditCredentials(item) {
      this.selected = item;
      this.editCredentialsDialog = true;
    },

    async onSendVerificationEmail(item) {
      if (item && item.id) {
        this.loading = true;
        await this.$store.dispatch('users/sendVerificationEmail', item.id);
        this.loading = false;
      }
    },

    async onForceEmailVerification(item) {
      if (item && item.id) {
        this.loading = true;
        await this.$store.dispatch('users/forceEmailVerification', item.id);
        this.loading = false;
      }
    },

    async onExport() {
      const filename = 'KEIM Easy - Elenco Utenti.xlsx';

      this.downloading = true;
      const result = await this.$store.dispatch('users/exportUsers');

      const byteCharacters = atob(result.data);
      const byteNumbers = new Array(byteCharacters.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      }
      this.downloading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
