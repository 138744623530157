<template>
  <div class="component-colorlist">
    <v-card outlined v-if="colorlist && colors && colors.length > 0">
      <v-card-title>{{ colorlist.name }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-for="color of colors" :key="color.id"
            cols="12" sm="6" md="4" lg="3" >
            <Color
              :color="color"
              @edit="onEdit(color)" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-alert text prominent type="success" border="left"
      v-else>
      <v-row>
        <v-col
          class="d-flex flex-column flex-sm-row justify-space-between align-start align-sm-center">
          <span>Al momento non è presente nessuna colore.</span>
          <v-btn color="success" class="mt-4 mt-sm-0"
            @click.stop="newDialog = true">Aggiungi un colore</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <NewColorDialog v-model="newDialog" :colorlist="colorlist" />
    <EditColorDialog v-model="editDialog" :colorlist="colorlist" :color="selected" />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unreachable */
/* eslint-disable max-len */

import NewColorDialog from '@/components/NewColorDialog';
import EditColorDialog from '@/components/EditColorDialog';
import Color from '@/components/Color';

export default {
  name: 'Colorlist',

  components: {
    NewColorDialog,
    EditColorDialog,
    Color,
  },

  props: {
    value: {
      type: Object,
    },
    updating: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    id: null,
    colorlist: null,

    newDialog: false,
    editDialog: false,

    selected: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
    userLocale: 'it-IT', // TODO: Dovrà essere impostato nel profilo utente
  }),

  // created() {
  //   console.log('Colorlist: created()'); // eslint-disable-line no-console
  //   this.colorlist = this.value;
  // },

  watch: {
    value(newValue) {
      console.log('Colorlist: watch.value()'); // eslint-disable-line no-console
      if (!newValue) {
        console.log('Colorlist: watch.value(null)'); // eslint-disable-line no-console
        return;
      }
      if (this.colorlist) {
        console.log('Colorlist: this.colorlist !== null'); // eslint-disable-line no-console
        return;
      }

      console.log('Colorlist: newValue =', newValue); // eslint-disable-line no-console

      this.colorlist = newValue;
    },

    colorlist(newValue) {
      console.log('Colorlist: watch.colorlist()'); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
    colors() {
      return this.$store.getters['colorlists/currentColors'];
    },

    // tags() {
    //   return this.$store.getters['tags/docs'];
    // },

    // isAddLineDisabled() {
    //   if (!this.colorlist || !Array.isArray(this.colorlist.conditions)) return true;
    //   return this.colorlist.conditions[this.colorlist.conditions.length - 1].length === 0;
    // },
  },

  methods: {
    onEdit(color) {
      this.selected = color;
      this.editDialog = true;
    },

    updated() {
      console.log('Colorlist: updated()'); // eslint-disable-line no-console
      this.$emit('updated');
    },

    // onAddLine() {
    //   this.colorlist.conditions.push([]);
    // },
  },
};
</script>

<style scoped>
.switch-continer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
</style>
