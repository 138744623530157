<template>
  <div class="component-phasepriorities mt-8">
    <span class="subtitle-2">Elenco priorità:</span>

    <v-card flat>
      <v-card-text class="pb-0">
        <v-alert
          v-if="!Array.isArray(priorities) || priorities.length === 0"
          text dense
          type="info"
          border="left">
          Questa scelta utilizzerà la priorità di default (0).
        </v-alert>
        <v-alert
          v-else
          text dense
          type="info"
          border="left">
          I valori di probabilità attivati (in base ai tag selezionati) verranno sommati.
        </v-alert>

        <v-row class="mt-2"
          v-for="(priority, index) of priorities"
          :key="`priority-${index}`">
          <v-col col="3" class="py-0">
            <v-text-field
              v-model="priorities[index].value"
              label="Valore"
              outlined
              dense
              required
              hint="Sono consentiti sia numeri positivi che negativi"
              @focus="$event.target.select()"
              @change="updated()"></v-text-field>
          </v-col>
          <v-col cols="9" class="py-0">
            <v-autocomplete
              v-model="priorities[index].tags"
              :items="tags"
              item-text="name"
              item-value="slug"
              outlined
              dense
              chips
              small-chips
              deletable-chips
              placeholder="Linea di tag richiesti"
              :hint="'Tutti i tag specificati dovranno essere presenti (AND). '
                + 'Lasciare vuoto per assegnare sempre.'"
              persistent-hint
              multiple
              @change="updated()"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <!-- v-simple-table
          v-if="Array.isArray(selectedProducts) && selectedProducts.length > 0">
          <template v-slot:default>
            <draggable tag="tbody"
              v-model="selectedProducts"
              handle=".sortHandle"
              draggable=".sortableRow"
              @change="updated()">
              <tr class="sortableRow"
                v-for="product in selectedProducts" :key="product.id">
                <td class="px-1" style="width: 0.1%">
                  <v-btn style="cursor: move" icon class="sortHandle">
                    <v-icon>mdi-drag</v-icon>
                  </v-btn>
                </td>
                <td>{{ product.name[userLanguage] }}</td>
                <td class="text-right text-no-wrap">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" class="ml-2">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="onDelete(product)">
                        <v-list-item-title>Elimina</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </draggable>
          </template>
        </v-simple-table -->
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text color="secondary" small
          :disabled="updating || isAddLineDisabled"
          :loading="updating"
          @click="onAddLine()">
          Aggiungi priorità
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable max-len */

export default {
  name: 'PhasePriorities',

  components: {
  },

  props: ['value'],

  data: () => ({
    updating: false,

    priorities: null,

    dialog: false,
    valid: false,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
    userLocale: 'it-IT', // TODO: Dovrà essere impostato nel profilo utente
  }),

  created() {
    console.log('PhasePriorities: created()'); // eslint-disable-line no-console
    this.updatePriorities(this.value);
  },

  watch: {
    value(newValue) {
      if (!newValue) {
        console.log('PhasePriorities: watch.value(null)'); // eslint-disable-line no-console
        this.priorities = null;
        return;
      }
      console.log('PhasePriorities: watch.value() newValue =', newValue); // eslint-disable-line no-console
      this.updatePriorities(newValue);
    },

    priorities(newValue) {
      console.log('PhasePriorities: watch.priorities() newValue =', newValue); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
    tags() {
      return this.$store.getters['tags/docs'];
    },

    isAddLineDisabled() {
      return false;
      // if (!this.priorities || !Array.isArray(this.priorities)) return true;
      // return this.priorities[this.priorities.length - 1].value === 0;
    },
  },

  methods: {
    updated() {
      console.log('PhasePriorities: updated()'); // eslint-disable-line no-console
      this.$emit('change');
    },

    updatePriorities(newValue) {
      console.log('PhasePriorities: updatePriorities() newValue =', newValue); // eslint-disable-line no-console

      let priorities = [];

      if (newValue && newValue.constructor === Object) {
        // Se l'elenco delle priorità è un oggetto,
        // lo converte in un elenco.
        priorities = Object.values(newValue);
      } else if (newValue && Array.isArray(newValue)) {
        priorities = newValue;
      }

      this.priorities = priorities;
    },

    onAddLine() {
      this.priorities.push({
        value: 0,
        tags: [],
      });
    },

    // onDelete(product) {
    //   this.selectedProducts = this.selectedProducts.filter((sp) => sp.id !== product.id);
    //   this.updated();
    // },

    // onAddProductDialog() {
    //   const formRef = this.$refs.form;

    //   if (formRef) {
    //     formRef.reset();
    //     formRef.resetValidation();
    //   }

    //   this.dialog = true;
    // },

    // onAddProduct() {
    //   const formRef = this.$refs.form;

    //   if (formRef.validate()) {
    //     this.loading = true;

    //     this.selectedProducts.push(this.newProduct);
    //     this.updated();

    //     this.dialog = false;
    //     this.loading = false;
    //   }
    // },

    // onCancel() {
    //   this.dialog = false;
    // },
  },
};
</script>

<style scoped>
</style>
