<template>
  <div class="component-phaseproceduredialog">
    <v-dialog v-model="dialog" max-width="800">
      <v-card v-if="phase">
        <v-card-title>{{ phase.name }}</v-card-title>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" v-html="phase.procedure">
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey" text
            :disabled="loading"
            @click="onCancel">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'PhaseProcedureDialog',

  props: ['value', 'phase'],

  components: {
  },

  data: () => ({
    dialog: false,
    loading: false,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  watch: {
    value(newValue) {
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
  },

  methods: {
    onCancel() {
      this.dialog = false;
    },
  },
};
</script>
