<template>
  <div class="component-editcredentialsdialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>
        <v-card>
          <v-card-title class="headline">
            {{ self
              ? 'Modifica le credenziali'
              : 'Modifica le credenziali dell\'utente' }}</v-card-title>

          <v-card-text>
            <v-alert
              type="error" dismissible v-model="alert" class="mb-4"
              transition="scale-transition">
              {{ error }}
            </v-alert>

            <v-row>
              <v-col cols="12">
                <v-text-field type="text"
                  v-model="updatedUser.email"
                  :disabled="loading"
                  label="Indirizzo email"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="password"
                  v-model="updatedUser.password"
                  :disabled="loading"
                  label="Nuova password"
                  :rules="passwordRules"
                  autofocus
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="password"
                  v-model="passwordConfirmation"
                  :disabled="loading"
                  label="Ripeti la password"
                  :rules="passwordConfirmationRules"
                ></v-text-field>
              </v-col>
            </v-row>

            <p>Premendo il pulsante <strong>Aggiorna</strong>, le credenziali dell'utente
              verranno modificate.</p>

            <v-alert v-if="isSelf "
              text dense
              type="warning"
              border="left">
              <span v-if="!self">Stai modificando le tue credenziali.</span>
              Dopo l'aggiornamento sarà necessario
              autenticarsi di nuovo.
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="onCancel">
              Annulla
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn type="submit"
              color="success"
              :loading="loading"
              :disabled="!valid || loading"
              @click="onUpdate">
              Aggiorna
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { UPDATE_USER_CREDENTIALS } from '@/store/actions/users';

export default {
  name: 'EditCredentialsDialog',

  props: {
    value: {
      type: Boolean,
    },
    user: {
      type: Object,
    },
    self: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dialog: false,
    valid: false,
    loading: false,

    alert: false,
    error: '',

    updatedUser: {
      email: '',
      password: '',
    },
    passwordConfirmation: '',

    emailRules: [
      (v) => !!v || "L'indirizzo email è richiesto",
      (v) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        .test(v) || "L'indirizzo email non è un indirizzo corretto",
    ],

    passwordRules: [
      (v) => !!v || 'La password è richiesta',
      (v) => v.length > 5 || 'La password deve essere lunga almeno 6 caratteri',
    ],
  }),

  watch: {
    value(newValue) {
      if (newValue === true) {
        this.updatedUser.email = this.user.email;
        this.updatedUser.password = '';
        this.passwordConfirmation = '';
      }
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.getters.user;
    },

    isSelf() {
      return this.currentUser && this.user && this.currentUser.id === this.user.id;
    },

    passwordConfirmationRules() {
      const rules = [];
      rules.push((v) => !this.updatedUser.password || !!v || 'La password va ripetuta');
      rules.push((v) => (this.updatedUser.password && v === this.updatedUser.password) || 'La password non corrisponde');
      return rules;
    },
  },

  methods: {
    onCancel() {
      this.dialog = false;
    },

    async onUpdate() {
      const formRef = this.$refs.form;

      if (!formRef.validate()) return;

      this.loading = true;
      this.alert = false;
      this.error = '';

      try {
        await this.$store.dispatch(`users/${UPDATE_USER_CREDENTIALS}`, {
          user: {
            uid: this.user.uid,
            ...this.updatedUser,
          },
        });

        // Se l'utente sta modificando se stesso, lo reindirizzo al login
        if (this.isSelf) this.$router.replace({ name: 'Login' }, () => {});

        this.dialog = false;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      } catch (error) {
        console.error(`${this.$options.name}: onUpdate() error =`, error); // eslint-disable-line no-console

        this.loading = false;

        switch (error) {
          case 'auth/email-already-exists':
            this.error = "L'indirizzo email è già in uso";
            break;

          default:
            this.error = `Errore sconosciuto: ${error}`;
            break;
        }

        this.alert = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
