export const INIT_USERS = 'INIT_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const SET_USERS = 'SET_USERS';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_CREDENTIALS = 'UPDATE_USER_CREDENTIALS';
export const SIGNUP_USER = 'SIGNUP_USER';
export const DESTROY_USERS = 'DESTROY_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const SET_USERS_LISTENER = 'SET_USERS_LISTENER';
