import firebase from 'firebase';


export function compareOrder(item, otherItem) {
  if (item.order < otherItem.order) {
    return -1;
  }
  if (item.order > otherItem.order) {
    return 1;
  }
  return 0;
}

export function getOrder(order, id, defaultValue) {
  // eslint-disable-next-line no-prototype-builtins
  return !order || !order.hasOwnProperty(id) ? defaultValue : order[id];
}

export async function getOrderMap(orderMapName) {
  const db = firebase.firestore();

  // Recupera il documento che contiene le mappe con gli ordinamenti
  const orderMapsDocRef = db.doc('/helpers/orderMaps');
  const orderMapsDoc = await orderMapsDocRef.get();

  const orderMaps = orderMapsDoc.exists ? orderMapsDoc.data() : null;

  // eslint-disable-next-line no-prototype-builtins
  return orderMaps.hasOwnProperty(orderMapName) ? orderMaps[orderMapName] : null;
}

export async function setOrderMap(orderMapName, order) {
  const db = firebase.firestore();

  // Recupera il documento che contiene le mappe con gli ordinamenti
  const orderMapsDocRef = db.doc('/helpers/orderMaps');

  const update = {};
  update[orderMapName] = order;

  return orderMapsDocRef.update(update);
}
