<template>
  <div class="view-nuovopreventivo">
    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-file-document-box-outline" />

    <Test
      v-if="type === 'test'"
      v-model="quotation"
      @complete="onTestComplete"
      @updated="updated = true" />
    <ManualSelection
      v-if="type === 'manual'"
      v-model="quotation"
      @updated="updated = true" />

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-actions>
            <v-btn text color="primary"
              :disabled="updating"
              :loading="updating"
              @click="onCancel()">
              Torna all'elenco
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="success"
              :disabled="updating || !nextEnabled"
              :loading="updating"
              @click="onNext()">
              {{ nextText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="leaveDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Vuoi abbandonare le modifiche?</v-card-title>

        <v-card-text>
          Non hai ancora creato il preventivo. Sei certo di voler uscire senza prima memorizzarlo?
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text
            @click="leaveDialog = false">
            Rimani su questa pagina
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="warning" text
            @click="next()">
            Lascia questa pagina
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import Test from '@/components/Test';
import ManualSelection from '@/components/ManualSelection';

export default {
  name: 'NuovoPreventivo',

  components: {
    PageHeader,
    Test,
    ManualSelection,
  },

  data: () => ({
    updating: false,

    quotation: null, // È un nuovo preventivo
    status: null,

    breadcrumbs: [
      {
        text: 'Preventivi',
        exact: true,
        to: { name: 'Preventivi' },
      },
      {
        text: 'Nuovo Preventivo',
        disabled: true,
      },
    ],

    updated: false,
    leaveDialog: false,
    next: null,
  }),

  created() {
    console.log('this.$route.params.type = ', this.$route.params.type); // eslint-disable-line no-console
    this.type = this.$route.params.type;
  },

  computed: {
    nextText() {
      return 'Vai al preventivo';
    },

    nextEnabled() {
      if (this.type === 'test') {
        return this.status && this.status.complete;
      }

      return this.quotation
        && Array.isArray(this.quotation.selectedProducts)
        && this.quotation.selectedProducts.length > 0;
    },
  },

  methods: {
    onCancel() {
      // Torna all'elenco dei preventivi
      this.$router.replace({ name: 'Preventivi' }, () => {});
    },

    onTestComplete(status) {
      console.log('NuovoPreventivo: onTestComplete()'); // eslint-disable-line no-console
      console.log('NuovoPreventivo: onTestComplete() - status =', status); // eslint-disable-line no-console
      this.status = status;
    },

    async onNext() {
      console.log('NuovoPreventivo: onNext()'); // eslint-disable-line no-console

      this.updating = true;
      this.updated = false;

      // Crea il preventivo
      const newQuotation = {
        name: this.$route.query.name,
        location: this.$route.query.location,
        province: this.$route.query.province,
        ...this.quotation,
      };

      if (this.type === 'test') {
        newQuotation.color = this.status.color;
        newQuotation.colorlist = this.status.colorlist;
        newQuotation.tags = this.status.tags;
      }

      console.log('NuovoPreventivo: onNext() newQuotation =', newQuotation); // eslint-disable-line no-console

      try {
        const { id } = await this.$store.dispatch('quotations/add', newQuotation);

        console.log('id = ', id); // eslint-disable-line no-console

        // Mostra il preventivo creato
        this.$router.replace({ name: 'Preventivo', params: { id } }, () => {});
      } catch (error) {
        console.log('error = ', error); // eslint-disable-line no-console
        this.updating = false;
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
};
</script>
