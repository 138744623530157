<template>
  <div class="component-delete-notification-dialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline">Elimina la notifica</v-card-title>

          <v-card-text>
            <p>Confermi di voler eliminare la notifica selezionata?</p>
            <p>La notifica <strong>verrà eliminata anche dalle App
              degli utenti che l'hanno ricevuta</strong>.
              Se vuoi eliminare la notifica soltanto da questo elenco, puoi archiviarla.</p>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="cancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="error" text
              :loading="loading"
              @click="deleteItem()">
              Elimina
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { DELETE_NOTIFICATION } from '@/store/actions/notifications';

export default {
  props: ['value', 'selected'],

  name: 'DeleteNotificationDialog',

  data: () => ({
    dialog: false,
    loading: false,
  }),

  watch: {
    value(newValue) {
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    async deleteItem() {
      if (this.selected && 'id' in this.selected && this.selected.id) {
        this.loading = true;

        try {
          await this.$store.dispatch(`notifications/${DELETE_NOTIFICATION}`, this.selected.id);
          this.dialog = false;
        } catch (error) {
          console.error(error); // eslint-disable-line no-console
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
