<template>
  <div class="component-editquotationdetailsdialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>
        <v-card>
          <v-card-title class="headline">Modifica i dettagli del preventivo</v-card-title>

          <v-card-text>
            <v-row v-if="updatedQuotation">
              <v-col cols="12">
                <v-text-field type="text"
                  v-model="updatedQuotation.name"
                  :disabled="loading"
                  label="Nome preventivo"
                  autofocus
                  :rules="[v => !!v || 'Il nome del preventivo è richiesto']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field type="text"
                  v-model="updatedQuotation.location"
                  :disabled="loading"
                  label="Località cantiere"
                  :rules="[v => !!v || 'La località è richiesta']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="updatedQuotation.province"
                  :disabled="loading"
                  :items="provinces"
                  clearable
                  label="Provincia cantiere"
                  :rules="[v => !!v || 'La provincia è richiesta']"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="cancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn type="submit"
              color="success" text
              :disabled="!valid || loading"
              :loading="loading"
              @click="update()">
              Modifica
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { getProvince } from '@/utils/italy';

export default {
  name: 'EditQuotationDetailsDialog',

  props: ['value', 'quotation'],

  data: () => ({
    dialog: false,
    valid: false,
    loading: false,

    updatedQuotation: null,
  }),

  watch: {
    value(newValue) {
      const formRef = this.$refs.form;

      if (newValue === true) {
        this.dialog = true;

        this.updatedQuotation = {
          id: this.quotation.id,
          name: this.quotation.name,
          location: this.quotation.location,
          province: this.quotation.province,
        };

        if (formRef) {
          // formRef.reset();
          formRef.resetValidation();
        }
      } else {
        this.dialog = false;
      }
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    provinces: () => getProvince(),
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    async update() {
      const formRef = this.$refs.form;

      if (formRef.validate()) {
        this.loading = true;

        await this.$store.dispatch('quotations/update', this.updatedQuotation);

        this.loading = false;
        this.dialog = false;
      }
    },
  },
};
</script>
