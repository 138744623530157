<template>
  <div class="component-user-province">
    <!--
      Mostra il nome della provincia associata all'utente memorizzato in `user`
      nella propria `province`.

      Se l'utente non ha una provincia associata, mostra un testo rosso
      con scritto "Assente".
    -->
    <span v-if="user.province">
      {{ provinceName(user.province) }}
    </span>
    <v-btn v-else
      class="mr-1 my-1"
      x-small depressed
      color="error"
      @click="$emit('edit')">
      Assente
    </v-btn>
  </div>
</template>

<script>
import { getProvince } from '@/utils/italy';

export default {
  name: 'UserProvince',

  props: ['user'],

  data: () => ({
  }),

  computed: {
    provinces: () => getProvince(),
  },

  methods: {
    provinceName(provinceId) {
      const province = this.provinces.find((p) => p.value === provinceId);

      return province && province.text && province.value
        ? `${province.text} (${province.value.toUpperCase()})`
        : 'Sconosciuta';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
