<template>
  <div class="component-discountdialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="headline">
          <span v-if="!product">Applica uno sconto percentuale</span>
          <span v-else>Modifica lo sconto extra percentuale</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation
            @submit.prevent="onConfirm">
            <v-row v-if="product">
              <v-col cols="12">
                <v-text-field class="discount-input"
                  v-model="discountExtra"
                  label="Sconto extra"
                  filled
                  outlined
                  dense
                  reverse
                  :rules="discountRules"
                  required
                  :autofocus="!!product"
                  @focus="$event.target.select()"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" v-if="true || !product">
                <v-text-field class="discount-input"
                  v-model="discount"
                  label="Sconto di codifica"
                  filled
                  outlined
                  dense
                  reverse
                  :autofocus="!product"
                  :rules="discountRules"
                  required
                  @focus="$event.target.select()" />
              </v-col>

              <v-col cols="12" v-if="!product">
                <v-text-field class="discount-input"
                  v-model="discountAccounting"
                  label="Sconto finanziario"
                  filled
                  outlined
                  dense
                  reverse
                  :rules="discountRules"
                  required />
              </v-col>
            </v-row>
          </v-form>

          <v-alert
            v-if="!product"
            type="info" class="mb-4">
            Gli sconti di codifica e finanziario verranno applicati a tutti i prodotti elencati.
            Sarà possibile aggiungere un ulteriore sconto extra
            ad ogni singolo prodotto.
          </v-alert>
          <v-alert
            v-else
            type="info" class="mb-4">
            Le modifiche allo sconto extra e allo sconto di codifica,
            verranno applicate soltanto al prodotto {{ this.productName }}.
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-btn color="grey" text
            @click="onCancel">
            Annulla
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn type="submit"
            color="success" text
            :disabled="!valid"
            @click="onConfirm">
            Applica
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'DiscountDialog',

  props: {
    value: {
      type: Boolean,
    },
    product: {
      type: Object,
    },
    quotation: {
      type: Object,
    },
  },

  data: () => ({
    dialog: false,

    discount: 0,
    discountExtra: 0,
    discountAccounting: 0,

    valid: false,
    discountRules: [
      (v) => (!!v || parseFloat(`${v}`.replace(',', '.').replace(/[^0-9.-]+/g, '')) === 0)
        || 'La percentuale da scontare è richiesta',
      (v) => (parseFloat(`${v}`.replace(',', '.').replace(/[^0-9.-]+/g, '')) >= 0
        && parseFloat(`${v}`.replace(',', '.').replace(/[^0-9.-]+/g, '')) <= 100)
        || 'La percentuale deve essere compresa tra 0 e 100',
    ],

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  watch: {
    value(newValue) {
      if (newValue === true) {
        const formatter = new Intl.NumberFormat('it-IT', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        if (!this.product) {
          // Sconto generale

          // Preimposta lo sconto generale (se impostato)
          this.discount = `${formatter.format(this.globalDiscount)}`;
          this.discountAccounting = `${formatter.format(this.globalDiscountAccounting)}`;
        } else {
          // Sconto del singolo prodotto

          // Preimposta lo sconto di codifica del singolo prodotto
          // eslint-disable-next-line no-lonely-if
          if ('discount' in this.product && this.product.discount) {
            this.discount = `${formatter.format(this.product.discount)}`;
          } else {
            // Preimposta lo sconto globale se manca quello del singolo prodotto
            this.discount = `${formatter.format(this.globalDiscount)}`;
          }

          // Preimposta lo sconto extra del singolo prodotto (se presente)
          // eslint-disable-next-line no-lonely-if
          if ('discountExtra' in this.product && this.product.discountExtra) {
            this.discountExtra = `${formatter.format(this.product.discountExtra)}`;
          } else {
            // Preimposta lo sconto extra a 0
            this.discountExtra = `${formatter.format(0)}`;
          }

          // Preimposta lo sconto contabile del singolo prodotto
          // eslint-disable-next-line no-lonely-if
          if ('discountAccounting' in this.product && this.product.discountAccounting) {
            this.discountAccounting = `${formatter.format(this.product.discountAccounting)}`;
          } else {
            // Preimposta lo sconto globale se manca quello del singolo prodotto
            this.discountAccounting = `${formatter.format(this.globalDiscountAccounting)}`;
          }
        }

        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    productName() {
      return this.product && 'name' in this.product
        && this.userLanguage && this.userLanguage in this.product.name
        ? this.product.name[this.userLanguage]
        : '';
    },

    hasGlobalDiscount() {
      return this.quotation && 'discount' in this.quotation && this.quotation.discount;
    },

    globalDiscount() {
      return this.hasGlobalDiscount ? this.quotation.discount : 0;
    },

    globalDiscountAccounting() {
      if (this.quotation && 'discountAccounting' in this.quotation && this.quotation.discountAccounting) {
        return this.quotation.discountAccounting;
      }
      return 0;
    },
  },

  methods: {
    onCancel() {
      this.dialog = false;
    },

    onConfirm() {
      const formRef = this.$refs.form;

      if (formRef.validate()) {
        const oldDiscount = parseFloat(`${this.quotation.discount}`.replace(',', '.').replace(/[^0-9.]+/g, ''));
        const oldDiscountAccounting = parseFloat(`${this.quotation.discountAccounting}`.replace(',', '.').replace(/[^0-9.]+/g, ''));

        const newDiscount = parseFloat(`${this.discount}`.replace(',', '.').replace(/[^0-9.]+/g, ''));
        const newDiscountAccounting = parseFloat(`${this.discountAccounting}`.replace(',', '.').replace(/[^0-9.]+/g, ''));
        const newDiscountExtra = parseFloat(`${this.discountExtra}`.replace(',', '.').replace(/[^0-9.]+/g, ''));

        const update = {
          product: this.product,
          discountExtra: newDiscountExtra / 100,
        };

        if (oldDiscount !== newDiscount) {
          update.discount = newDiscount / 100;
        }
        if (oldDiscountAccounting !== newDiscountAccounting) {
          update.discountAccounting = newDiscountAccounting / 100;
        }

        // eslint-disable-next-line no-console
        console.log('emit(updateDiscount)');
        this.$emit('updateDiscount', update);

        this.dialog = false;
      }
    },
  },
};
</script>

<style scoped>
.v-input.discount-input >>> input {
  font-size: 2rem;
  max-height: none;
}
</style>
