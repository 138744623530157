<template>
  <v-row>
    <v-col cols="12" sm="6">
       <v-select prepend-icon="mdi-palette"
          v-model="colorlist"
          :items="colorlists"
          item-text="name"
          item-value="id"
          return-object
          filled
          label="Cartella colori"
          placeholder="Scegli la cartella colori"
        ></v-select>
    </v-col>

    <v-col cols="12" sm="6">
      <v-autocomplete prepend-icon="mdi-format-color-fill"
        v-model="color"
        :items="colors"
        single-line
        filled
        label="Colore"
        placeholder="Scegli o inserisci il codice colore"
        item-text="code"
        item-value="id"
        :disabled="!colorlist"
        :error="product && color && rangeIndex === null"
        :error-messages="product && color && rangeIndex === null
          ? 'Tonalità non disponibile con questo prodotto' : null"
        return-object>
        <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            v-if="data.item"
            :input-value="data.selected"
            label
            close
            @click="data.select"
            @click:close="color = null"
          >
            <v-avatar left>
              <v-sheet
                tile
                height="60"
                width="60"
                :color="`rgba(${data.item.r}, ${data.item.g}, ${data.item.b}, 1)`">
              </v-sheet>
            </v-avatar>
            {{ data.item.code }}
          </v-chip>
        </template>

        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-avatar>
              <v-sheet
                tile
                height="60"
                width="60"
                :color="`rgba(${data.item.r}, ${data.item.g}, ${data.item.b}, 1)`">
              </v-sheet>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.code"></v-list-item-title>
              <v-list-item-subtitle v-html="`HBW: ${data.item.hbw}`"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-col>

    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="success" text
        class="confirm-button"
        :disabled="!colorlist || !color || (product && color && rangeIndex === null)"
        @click="onAnswer()">
        Conferma scelta
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ColorSelector',

  props: ['product'],

  data: () => ({
    colorlistsColors: null,
    colors: null,
    colorlist: null,
    color: null,
  }),

  computed: {
    colorlists() {
      return this.$store.getters['colorlists/docs'];
    },

    rangeIndex() {
      if (!this.product || !this.color) return null;

      let result = null;
      // eslint-disable-next-line no-restricted-syntax
      for (const index in this.product.priceranges) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.product.priceranges.hasOwnProperty(index)) {
          console.log('ColorSelector: rangeIndex() index =', index); // eslint-disable-line no-console
          const range = this.product.priceranges[index];

          if (range.colors.find((c) => c.code === this.color.code)) {
            console.log('ColorSelector: trovato!'); // eslint-disable-line no-console
            result = Number(index);
            break;
          }
        }
      }

      return result;
    },
  },

  watch: {
    async colorlist(newValue) {
      console.log('ColorSelector: watch.colorlist()'); // eslint-disable-line no-console

      // È stata selezionata una (nuova) cartella

      // Deseleziona l'eventuale colore selezionato
      this.color = null;

      // Recupera l'elenco dei colori della cartella
      if (newValue && this.colorlistsColors) {
        const colorlist = this.colorlistsColors.find((cl) => cl.id === this.colorlist.id);
        if (colorlist) {
          const colorlistColors = Object.values(colorlist.colors);
          colorlistColors.sort((a, b) => {
            if (a.code < b.code) {
              return -1;
            }
            if (a.code > b.code) {
              return 1;
            }

            return 0;
          });
          this.colors = colorlistColors;
        }
      }
    },

    colorlists(newValue) {
      console.log('ColorSelector: watch.colorlists()'); // eslint-disable-line no-console

      // È stato aggiornato l'elenco delle cartelle colori

      // Se non è selezionata nessuna cartella, seleziona la prima
      if (this.colorlist === null && Array.isArray(newValue) && newValue.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.colorlist = newValue[0];
      }
    },
  },

  async created() {
    console.log('ColorSelector: created()'); // eslint-disable-line no-console

    this.colorlistsColors = await this.$store.dispatch('colorlists/getColorlistsColors');

    if (this.colorlist === null && Array.isArray(this.colorlists) && this.colorlists.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.colorlist = this.colorlists[0];
    }
  },

  methods: {
    onAnswer() {
      if (!this.product) {
        this.$emit('onAnswer', {
          colorlist: this.colorlist,
          color: this.color,
        });
      } else {
        this.$emit('onAnswer', {
          colorlist: this.colorlist,
          color: this.color,
          rangeIndex: this.rangeIndex,
        });
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.colorlist = null;
        }, 1000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-button {
  margin-right: -16px;
}
</style>
