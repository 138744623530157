import Vue from 'vue';
import firebase from 'firebase';
import {
  FETCH_USER, REFRESH_CURRENT_USER, LOGOUT, SET_USER, CLEAR_USER,
} from '@/store/actions/auth';
import { DESTROY_PRODUCTS } from '@/store/actions/products';
import { DESTROY_COLORLISTS } from '@/store/actions/colorlists';
import { DESTROY_TAGS } from '@/store/actions/tags';
import { DESTROY_QUOTATIONS } from '@/store/actions/quotations';
import { DESTROY_PRICELISTS } from '@/store/actions/pricelists';
import { DESTROY_PHASES } from '../actions/phases';
import { DESTROY_USERS } from '../actions/users';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const emptyUser = {
  id: null,

  firstName: null,
  lastName: null,
  email: null,
  pricelist: null,

  loggedIn: false,
  superUser: false,
  uid: null,

  // Dettagli aggiuntivi
  type: null,
  company: null,
  address: null,
  postalCode: null,
  city: null,
  province: null,
  phone: null,
  phone1: null,
  checkbox1: false,
  checkbox2: false,
  showLogo: false,
  imageUrl: null,
  imageName: null,

  firebaseUser: null,
};

const state = {
  user: { ...emptyUser },
};

const getters = {
  user: (state) => state.user,
  currentUser: (state) => state.user,

  firebaseUser: (state) => state.user.firebaseUser,

  isEmailVerified: (state) => state.user.firebaseUser && state.user.firebaseUser.emailVerified,

  isLoggedIn: (state) => state.user.firebaseUser && state.user.firebaseUser.uid && state.user.firebaseUser.isAnonymous === false,
};

const actions = {
  [FETCH_USER]: async ({ commit }, firebaseUser) => {
    console.log('actions:FETCH_USER firebaseUser =', firebaseUser); // eslint-disable-line no-console

    if (firebaseUser && firebaseUser.uid) {
      Vue.set(state.user, 'uid', firebaseUser.uid);
      Vue.set(state.user, 'firebaseUser', firebaseUser);

      // Recupera le informazioni aggiuntive sull'utente dalla Collection `users`
      // di Firestore.
      const db = firebase.firestore();

      await db.collection('users').doc(firebaseUser.uid)
        .get()
        .then((queryDocumentSnapshot) => {
          let userDetails = null;

          if (queryDocumentSnapshot.exists) {
            // const queryDocumentSnapshot = querySnapshot.docs[0];
            userDetails = queryDocumentSnapshot.data();
            userDetails.id = queryDocumentSnapshot.id;
          }

          if (userDetails) {
            console.log('actions:FETCH_USER userDetails =', userDetails); // eslint-disable-line no-console

            Vue.set(state.user, 'id', userDetails.id);
            Vue.set(state.user, 'superUser', userDetails.isSuper === true);
            Vue.set(state.user, 'firstName', userDetails.firstName);
            Vue.set(state.user, 'lastName', userDetails.lastName);
            Vue.set(state.user, 'email', firebaseUser.email);
            Vue.set(state.user, 'pricelist', userDetails.pricelist || null);
            Vue.set(state.user, 'loggedIn', true);

            // Imposta i dettagli aggiuntivi
            if ('type' in userDetails) Vue.set(state.user, 'type', userDetails.type);
            if ('company' in userDetails) Vue.set(state.user, 'company', userDetails.company);
            if ('address' in userDetails) Vue.set(state.user, 'address', userDetails.address);
            if ('postalCode' in userDetails) Vue.set(state.user, 'postalCode', userDetails.postalCode);
            if ('city' in userDetails) Vue.set(state.user, 'city', userDetails.city);
            if ('province' in userDetails) Vue.set(state.user, 'province', userDetails.province);
            if ('phone' in userDetails) Vue.set(state.user, 'phone', userDetails.phone);
            if ('phone1' in userDetails) Vue.set(state.user, 'phone1', userDetails.phone1);
            if ('showLogo' in userDetails) Vue.set(state.user, 'showLogo', userDetails.showLogo);
            if ('imageUrl' in userDetails) Vue.set(state.user, 'imageUrl', userDetails.imageUrl);
            if ('imageName' in userDetails) Vue.set(state.user, 'imageName', userDetails.imageName);
            if ('checkbox1' in userDetails) Vue.set(state.user, 'checkbox1', userDetails.checkbox1 === true);
            if ('checkbox2' in userDetails) Vue.set(state.user, 'checkbox2', userDetails.checkbox2 === true);

            // Sincronizza `emailVerified` tra l'utente Firestore e l'utente Firebase Auth
            if (firebaseUser.emailVerified !== userDetails.emailVerified) {
              db.collection('users').doc(firebaseUser.uid)
                .update({
                  emailVerified: firebaseUser.emailVerified,
                })
                .then(() => {
                  console.log('actions:FETCH_USER emailVerified updated!'); // eslint-disable-line no-console
                })
                .catch((error) => {
                  console.error('actions:FETCH_USER error =', error); // eslint-disable-line no-console
                });
            }

            // Sincronizza `email` tra l'utente Firestore e l'utente Firebase Auth
            if (firebaseUser.email !== userDetails.email) {
              db.collection('users').doc(firebaseUser.uid)
                .update({
                  email: firebaseUser.email,
                })
                .then(() => {
                  console.log('actions:FETCH_USER email updated!'); // eslint-disable-line no-console
                })
                .catch((error) => {
                  console.error('actions:FETCH_USER error =', error); // eslint-disable-line no-console
                });
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Error getting documents: ', error);
        });

      return Promise.resolve();
    // eslint-disable-next-line no-else-return
    } else {
      commit(CLEAR_USER);

      return Promise.resolve();
    }
  },

  [REFRESH_CURRENT_USER]: ({ state }) => {
    console.log('actions:REFRESH_CURRENT_USER'); // eslint-disable-line no-console

    const user = firebase.auth().currentUser;

    console.log('actions:REFRESH_CURRENT_USER user =', user); // eslint-disable-line no-console

    if (state.user && state.user.id) {
      // Recupera le informazioni aggiuntive sull'utente
      const db = firebase.firestore();

      db.collection('users').doc(state.user.uid)
        .get()
        .then((queryDocumentSnapshot) => {
          let userDetails = null;

          if (queryDocumentSnapshot.exists) {
            // const queryDocumentSnapshot = querySnapshot.docs[0];
            userDetails = queryDocumentSnapshot.data();
            userDetails.id = queryDocumentSnapshot.id;
          }

          if (userDetails) {
            console.log('actions:REFRESH_CURRENT_USER userDetails =', userDetails); // eslint-disable-line no-console

            Vue.set(state.user, 'id', userDetails.id);
            Vue.set(state.user, 'superUser', userDetails.isSuper === true);
            Vue.set(state.user, 'firstName', userDetails.firstName);
            Vue.set(state.user, 'lastName', userDetails.lastName);
            Vue.set(state.user, 'email', user.email);
            Vue.set(state.user, 'pricelist', userDetails.pricelist || null);

            // Imposta i dettagli aggiuntivi
            if ('type' in userDetails) Vue.set(state.user, 'type', userDetails.type);
            if ('company' in userDetails) Vue.set(state.user, 'company', userDetails.company);
            if ('address' in userDetails) Vue.set(state.user, 'address', userDetails.address);
            if ('postalCode' in userDetails) Vue.set(state.user, 'postalCode', userDetails.postalCode);
            if ('city' in userDetails) Vue.set(state.user, 'city', userDetails.city);
            if ('province' in userDetails) Vue.set(state.user, 'province', userDetails.province);
            if ('phone' in userDetails) Vue.set(state.user, 'phone', userDetails.phone);
            if ('phone1' in userDetails) Vue.set(state.user, 'phone1', userDetails.phone1);
            if ('showLogo' in userDetails) Vue.set(state.user, 'showLogo', userDetails.showLogo);
            if ('imageUrl' in userDetails) Vue.set(state.user, 'imageUrl', userDetails.imageUrl);
            if ('imageName' in userDetails) Vue.set(state.user, 'imageName', userDetails.imageName);
            if ('checkbox1' in userDetails) Vue.set(state.user, 'checkbox1', userDetails.checkbox1);
            if ('checkbox2' in userDetails) Vue.set(state.user, 'checkbox2', userDetails.checkbox2);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Error getting documents: ', error);
        });
    }
  },

  [LOGOUT]: async ({ dispatch, commit }) => {
    console.log('actions:LOGOUT'); // eslint-disable-line no-console

    dispatch(`users/${DESTROY_USERS}`);
    dispatch(`products/${DESTROY_PRODUCTS}`);
    dispatch(`colorlists/${DESTROY_COLORLISTS}`);
    dispatch(`tags/${DESTROY_TAGS}`);
    dispatch(`quotations/${DESTROY_QUOTATIONS}`);
    dispatch(`pricelists/${DESTROY_PRICELISTS}`);
    dispatch(`phases/${DESTROY_PHASES}`);

    firebase.auth().signOut()
      .then(() => {
        console.log('actions:LOGOUT firebase.auth().signOut() success!'); // eslint-disable-line no-console

        commit(CLEAR_USER);

        return Promise.resolve();
      })
      .catch((error) => {
        console.error('actions:LOGOUT error =', error); // eslint-disable-line no-console
      });
  },

  waitUntilUserIsLoggedIn: async () => {
    console.log('actions:waitUntilUserIsLoggedIn'); // eslint-disable-line no-console

    // Attende fino a quando l'utente non è loggato (state.user.loggedIn === true)
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (state.user.loggedIn) {
          clearInterval(interval);

          resolve();
        }
      }, 1000);
    });
  },
};

const mutations = {
  [SET_USER]: (state, payload) => {
    console.log('mutations:SET_USER'); // eslint-disable-line no-console

    Vue.set(state, 'user', payload);
  },

  [CLEAR_USER]: (state) => {
    console.log('mutations:CLEAR_USER'); // eslint-disable-line no-console

    Vue.set(state, 'user', { ...emptyUser });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
