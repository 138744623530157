<template>
  <div class="view-modificapreventivo">
    <Loading v-model="loading" />

    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-file-document-box-outline" />

    <ManualSelection v-if="quotation"
      v-model="quotation"
      @updated="updated = true" />

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-actions>
            <v-btn text color="primary"
              :disabled="updating"
              :loading="updating"
              @click="onCancel()">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="success"
              :disabled="updating || !nextEnabled"
              :loading="updating"
              @click="onNext()">
              Aggiorna
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="leaveDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Vuoi abbandonare le modifiche?</v-card-title>

        <v-card-text>
          Non hai ancora creato il preventivo. Sei certo di voler uscire senza prima memorizzarlo?
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text
            @click="leaveDialog = false">
            Rimani su questa pagina
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="warning" text
            @click="next()">
            Lascia questa pagina
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import PageHeader from '@/components/PageHeader';
// import Test from '@/components/Test';
import ManualSelection from '@/components/ManualSelection';

export default {
  name: 'ModificaPreventivo',

  components: {
    Loading,
    PageHeader,
    // Test,
    ManualSelection,
  },

  data: () => ({
    loading: true,
    updating: false,
    updated: false,

    breadcrumbs: [
      {
        text: 'Preventivi',
        exact: true,
        to: { name: 'Preventivi' },
      },
    ],

    id: null,
    quotation: null,

    leaveDialog: false,
    next: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  async created() {
    console.log('this.$route.params.id = ', this.$route.params.id); // eslint-disable-line no-console
    this.id = this.$route.params.id;

    const { selectedProducts, ...quotation } = await this.$store.dispatch('quotations/getById', this.id);

    // TODO: Converte selectedProducts?

    this.quotation = {
      id: this.id,
      ...quotation,
      // selectedProducts: phasesEntries.map((e) => ({ id: e[0], name: e[1].name })),
      selectedProducts,
    };

    // Inserisce il nome prodotto nei breadcrumbs
    const text = `${this.quotation.name} - ${this.quotation.location} (${this.quotation.province.toUpperCase()})`;
    this.breadcrumbs.push({
      text,
      disabled: true,
    });

    this.loading = false;
  },

  computed: {
    nextEnabled() {
      return this.quotation
        && Array.isArray(this.quotation.selectedProducts)
        && this.quotation.selectedProducts.length > 0;
    },
  },

  methods: {
    onCancel() {
      // Torna al prodotto
      this.$router.go(-1);
    },

    async onNext() {
      this.updating = true;
      this.updated = false;

      // Aggiorna il preventivo
      await this.$store.dispatch('quotations/update', {
        ...this.quotation,
      });

      // Mostra il preventivo creato
      this.$router.replace({ name: 'Preventivo', params: { id: this.id } }, () => {});
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
};
</script>
