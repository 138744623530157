<template>
  <div class="component-pricelist-alert"
    v-if="currentUser && currentUser.superUser">
    <v-alert text type="warning" class="mb-4" dismissible>
      Attenzione, si sta creando un preventivo utilizzando il listino
      <strong>{{ priceListName }}</strong>.
    </v-alert>
  </div>
</template>

<script>
export default {
  props: ['quotation'],

  name: 'PricelistAlert',

  data: () => ({
  }),

  computed: {
    currentUser() {
      return this.$store.getters.user;
    },

    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    currentUserPriceList() {
      return Array.isArray(this.pricelists)
        && this.currentUser && 'pricelist' in this.currentUser
        ? this.pricelists.find((pl) => pl.id === this.currentUser.pricelist)
        : null;
    },

    priceListName() {
      return this.currentUserPriceList && 'name' in this.currentUserPriceList
        ? this.currentUserPriceList.name
        : '(sconosciuto)';
    },
  },

  methods: {
  },
};
</script>
