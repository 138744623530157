<template>
  <div class="view-prodotti">
    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-format-list-text" />

    <!-- v-alert
      v-if="!currentUser.pricelist"
      type="warning" class="mb-4"
      transition="scale-transition">
      <strong>Non ti è stato assegnato un listino.</strong><br>
      Potrai consultare i prodotti e creare preventivi ma non potrai visualizzare i prezzi.
    </v-alert -->

    <Products />

    <v-btn v-if="isSuperUser"
      bottom dark fab large fixed right
      color="success"
      :to="{ name: 'NuovoProdotto' }">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import Products from '@/components/Products';

export default {
  name: 'Prodotti',

  components: {
    PageHeader,
    Products,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: 'Prodotti',
        exact: true,
        to: { name: 'Prodotti' },
      },
    ],

    dialog: false,
  }),

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },

    isSuperUser() {
      return this.currentUser.superUser;
    },
  },
};
</script>
