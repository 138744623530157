<template>
  <div class="view-elencotag">
    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-tag-multiple" />

    <!-- v-alert text prominent type="warning" border="left">
      <v-row>
        <v-col>
          <strong>Attenzione!</strong> Quest'area sarà accessibile soltanto agli amministratori
          del software e rimarrà abilitata per tutti soltanto durante la fase di sviluppo.<br>
          Le modifiche effettuate qui potrebbero influire sul suo corretto funzionamento.
        </v-col>
      </v-row>
    </v-alert -->

    <Tags />

    <v-btn bottom dark fab large fixed right
      color="success"
      @click.stop="dialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <NewTagDialog v-model="dialog" />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import Tags from '@/components/Tags';
import NewTagDialog from '@/components/NewTagDialog';

export default {
  name: 'ElencoTag',

  components: {
    PageHeader,
    Tags,
    NewTagDialog,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: 'Tag',
        exact: true,
        to: { name: 'ElencoTag' },
      },
    ],

    dialog: false,
  }),
};
</script>
