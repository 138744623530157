<template>
  <div class="component-user-pricelist">
    <template v-if="hasPricelist">
      <!-- L'utente ha un listino associato -->
      <span class="mr-2">
        {{ pricelistName(user.pricelist) }}
      </span>
    </template>
    <template v-else>
      <!-- L'utente non ha un listino associato -->

      <!-- Se l'utente è privato, mostra "Nessun listino" -->
      <template v-if="user.type === 'privato'">
        <v-tooltip bottom >
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"
              class="my-1"
              x-small depressed outlined
              color="primary"
              @click="$emit('edit')">
              Nessun listino
            </v-btn>
          </template>
        <span>Per gli utenti privati non è previsto un listino</span>
      </v-tooltip>
      </template>
      <template v-else>
        <!-- Se l'utente non è privato, mostra "Da confermare" se ha una provincia associata -->
        <template v-if="user.province">
          <span class="mr-2">
            {{ pricelistToConfirm }}
          </span>
          <v-btn
            class="my-1"
            x-small depressed
            color="warning"
            @click="$emit('edit')">
            Da confermare
          </v-btn>
        </template>
        <template v-else>
          <!-- Se l'utente non è privato e non ha una provincia associata, mostra "Nessun listino" -->
          <v-tooltip bottom color="error">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on"
                class="my-1"
                x-small depressed
                color="error"
                @click="$emit('edit')">
                Nessun listino
              </v-btn>
            </template>
            <span>Non è possibile suggerire un listino se non è stata specificata la provincia</span>
          </v-tooltip>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { getPricelistByProvince } from '@/utils/italy';

export default {
  name: 'UserPricelist',

  props: ['user'],

  data: () => ({
  }),

  computed: {
    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    hasPricelist() {
      return !!this.user.pricelist;
    },

    // Restituisce il nome del listino da confermare (in base alla provincia, se presente)
    pricelistToConfirm() {
      return getPricelistByProvince(this.user.province) || null;
    },
  },

  methods: {
    pricelistName(pricelistId) {
      const pricelist = this.pricelists.find((pl) => pl.id === pricelistId);

      return pricelist ? pricelist.name : '';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
