export const INIT_NOTIFICATIONS = 'INIT_NOTIFICATIONS';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const DESTROY_NOTIFICATIONS = 'DESTROY_NOTIFICATIONS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const SET_NOTIFICATIONS_LISTENER = 'SET_NOTIFICATIONS_LISTENER';
export const DELETE_NOTIFICATION_ATTACHMENT = 'DELETE_NOTIFICATION_ATTACHMENT';
export const UPDATE_NOTIFICATION_ATTACHMENT = 'UPDATE_NOTIFICATION_ATTACHMENT';
