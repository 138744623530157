<template>
  <div class="component-productdiscounts">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span class="font-weight-medium"
          v-bind="attrs"
          v-on="on"
          @click="onDiscountEdit()">
          {{ discountText }}
        </span>
      </template>
      <span>Sconto di codifica</span>
    </v-tooltip>

    <v-tooltip bottom v-if="discountExtra">
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          @click="onDiscountEdit()">
          {{ discountExtraText }}
        </span>
      </template>
      <span>Sconto extra (premere per modificare)</span>
    </v-tooltip>

    <v-tooltip bottom v-if="discountAccounting">
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          @click="onDiscountEdit()">
          {{ discountAccountingText }}
        </span>
      </template>
      <span>Sconto finanziario</span>
    </v-tooltip>

    <DiscountDialog v-model="discountDialog"
      :quotation="quotation"
      :product="product"
      @updateDiscount="onUpdateDiscount($event)" />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import DiscountDialog from '@/components/DiscountDialog';

export default {
  name: 'ProductDiscounts',

  props: ['product', 'quotation'],

  components: {
    DiscountDialog,
  },

  data: () => ({
    discountDialog: false,
    formatter: new Intl.NumberFormat('it-IT', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  }),

  computed: {
    discount() {
      if (!this.product || !this.quotation) return 0;

      if ('discount' in this.product) {
        return this.product.discount;
      }

      return 'discount' in this.quotation && this.quotation.discount
        ? this.quotation.discount
        : 0;
    },

    discountExtra() {
      if (!this.product || 'discountExtra' in this.product === false) return 0;

      return this.product.discountExtra ?? 0;
    },

    discountAccounting() {
      if (!this.product || !this.quotation) return 0;

      if ('discountAccounting' in this.product) {
        return this.product.discountAccounting;
      }

      return 'discountAccounting' in this.quotation && this.quotation.discountAccounting
        ? this.quotation.discountAccounting
        : 0;
    },

    discountText() {
      return this.discount
        ? `${this.formatter.format(this.discount)}` : `${this.formatter.format(0)}`;
    },

    discountExtraText() {
      return this.discountExtra
        ? `${this.formatter.format(this.discountExtra)}` : `${this.formatter.format(0)}`;
    },

    discountAccountingText() {
      return this.discountAccounting
        ? `${this.formatter.format(this.discountAccounting)}` : `${this.formatter.format(0)}`;
    },
  },

  methods: {
    onDiscountEdit() {
      this.discountDialog = true;
    },

    onUpdateDiscount(evt) {
      // eslint-disable-next-line no-console
      console.log('ProductDiscounts.onUpdateDiscount() evt =', evt);

      const { discount, discountExtra, discountAccounting } = evt;

      this.$emit('update', {
        product: this.product,
        discount,
        discountExtra,
        discountAccounting,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.component-productdiscounts {
  display: flex;
  flex-flow: column nowrap;
  line-height: 1rem;
}
</style>
