<template>
  <div class="component-conditions">
    <v-card flat class="ml-4">
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12" class="py-0"
            v-for="(condition, index) of conditions"
            :key="`condition-${index}`">
            <v-autocomplete
              dense
              v-model="conditions[index]"
              :items="tags"
              item-text="name"
              item-value="slug"
              outlined
              chips
              small-chips
              deletable-chips
              placeholder="Linea di tag richiesti"
              hint="Tutti i tag specificati dovranno essere presenti (AND)"
              persistent-hint
              multiple
              @change="updated()"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text color="secondary" small
          :disabled="updating || isAddLineDisabled"
          :loading="updating"
          @click="onAddLine()">
          Aggiungi linea
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Conditions',

  props: ['value'],

  data: () => ({
    conditions: null,
    updating: false, // TODO: Usarla come proprietare e rivelare quando un richiedente aggiorna
  }),

  created() {
    console.log('Conditions: created()'); // eslint-disable-line no-console
    this.updateConditions(this.value);
  },

  watch: {
    value(newValue) {
      if (!newValue) {
        console.log('Conditions: watch.value(null)'); // eslint-disable-line no-console
        this.conditions = null;
        return;
      }
      console.log('Conditions: watch.value() newValue =', newValue); // eslint-disable-line no-console
      this.updateConditions(newValue);
    },

    conditions(newValue) {
      console.log('Conditions: watch.conditions() newValue =', newValue); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
    tags() {
      return this.$store.getters['tags/docs'];
    },

    isAddLineDisabled() {
      if (!this.conditions || !Array.isArray(this.conditions)) return true;
      return this.conditions[this.conditions.length - 1].length === 0;
    },
  },

  methods: {
    updated() {
      console.log('Conditions: updated()'); // eslint-disable-line no-console
      this.$emit('change');
    },

    updateConditions(newValue) {
      console.log('Conditions: updateChoice() newValue =', newValue); // eslint-disable-line no-console

      let conditions = [];

      if (newValue && newValue.constructor === Object) {
        // Se l'elenco prodotti è un oggetto,
        // lo converte in un elenco.
        conditions = Object.values(newValue);
      } else if (newValue && Array.isArray(newValue)) {
        conditions = newValue;
      }

      // Se non ci sono condizioni, aggiunge la prima (vuota).
      if (conditions.length === 0) conditions.push([]);

      this.conditions = conditions;
    },

    onAddLine() {
      this.conditions.push([]);
    },
  },
};
</script>

<style scoped>
</style>
