/* eslint-disable no-shadow */

import Vue from 'vue';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import {
  INIT_PRODUCTS, UPDATE_PRODUCTS, SET_PRODUCTS, DESTROY_PRODUCTS, CLEAR_PRODUCTS,
  SET_PRODUCTS_LISTENER,
} from '@/store/actions/products';

const getUserDetails = async (user) => {
  // eslint-disable-next-line no-console
  console.log('getUserDetails():');

  // Recupera le informazioni aggiuntive sull'utente
  const db = firebase.firestore();

  // const querySnapshot = await db.collection('users').where('uid', '==', user.uid).limit(1).get();
  const queryDocumentSnapshot = await db.collection('users').doc(user.uid).get();

  // eslint-disable-next-line no-console
  console.log('getUserDetails(): Query completa');

  let userDetails = null;

  if (queryDocumentSnapshot.exists) {
    // const queryDocumentSnapshot = querySnapshot.docs[0];
    userDetails = queryDocumentSnapshot.data();
  }

  return userDetails;
};

const state = {
  data: [],
  listener: null,
};

const getters = {
  docs: (state) => Object.values(state.data),
};

const actions = {
  [INIT_PRODUCTS]: ({ dispatch }) => {
    console.log('actions:INIT_PRODUCTS'); // eslint-disable-line no-console

    return dispatch(UPDATE_PRODUCTS);
  },

  [UPDATE_PRODUCTS]: async ({ rootGetters, commit, state }) => {
    console.log('actions:UPDATE_PRODUCTS'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const currentUser = rootGetters.user;
    const userDetails = await getUserDetails(currentUser);

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // // Recuper la mappa con l'ordinamento
    // const order = await getOrderMap('products');
    // if (order) {
    //   console.log('Esiste un ordinamento, lo utilizzo'); // eslint-disable-line no-console
    //   console.log(`order = ${JSON.stringify(order)}`); // eslint-disable-line no-console
    // }

    // Recupera la collection con l'elenco
    const collectionRef = db.collection('products');
    const listener = collectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (products)'); // eslint-disable-line no-console

      commit(SET_PRODUCTS, {
        snapshot,
        isSuper: userDetails.isSuper === true,
        userType: userDetails.type ?? null,
        // order,
      });
    });
    commit(SET_PRODUCTS_LISTENER, listener);
  },

  [DESTROY_PRODUCTS]: ({ state, commit }) => {
    console.log('actions:DESTROY_PRODUCTS'); // eslint-disable-line no-console

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Pulisce lo state
    commit(CLEAR_PRODUCTS);
  },

  add: async (context, payload) => {
    console.log('actions:add'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const collectionRef = db.collection('products');

    const newProduct = {
      ...payload,
    };

    if ('consumption' in newProduct
      && (typeof newProduct.consumption === 'string' || newProduct.consumption instanceof String)) {
      newProduct.consumption = Number(newProduct.consumption.replace(',', '.'));
    }

    if ('price' in newProduct) {
      Object.keys(newProduct.price).forEach((pricelist) => {
        if (typeof newProduct.price[pricelist] === 'string'
          || newProduct.price[pricelist] instanceof String) {
          newProduct.price[pricelist] = Number(newProduct.price[pricelist].replace(',', '.'));
        }
      });
    }

    // Evita che newProduct.url sia indefinito
    if (newProduct.url === undefined) newProduct.url = '';

    console.log('actions:add newProduct =', newProduct); // eslint-disable-line no-console

    return collectionRef.add(newProduct);
  },

  update: async (context, payload) => {
    console.log('actions:update'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Separa la proprietà 'id' dal resto
    const { id, ...data } = payload;

    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('consumption')
      && (typeof data.consumption === 'string' || data.consumption instanceof String)) {
      data.consumption = Number(data.consumption.replace(',', '.'));
    }

    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('price')) {
      Object.keys(data.price).forEach((pricelist) => {
        if (typeof data.price[pricelist] === 'string'
          || data.price[pricelist] instanceof String) {
          data.price[pricelist] = Number(data.price[pricelist].replace(',', '.'));
        }
      });
    }

    const docRef = db.doc(`/products/${id}`);

    return docRef.update({
      ...data,
    });
  },

  delete: async (context, payload) => {
    console.log('actions:delete'); // eslint-disable-line no-console

    // Recupera il riferimento al prodotto
    const db = firebase.firestore();
    const docRef = db.doc(`/products/${payload}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const doc = await docRef.get();
    const docData = doc.data();
    const imageName = docData.imageName || null;

    // Rimuove il file dallo storage
    if (imageName) {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child(imageName);
      await imageRef.delete();
    }

    // Rimuove il prodotto
    return docRef.delete();
  },

  getById: async (context, payload) => {
    console.log('actions:getById'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const docRef = db.doc(`/products/${payload}`);

    const doc = await docRef.get();

    if (!doc.exists) return null;

    return {
      id: doc.id,
      ...doc.data(),
    };
  },

  updateImage: async (context, payload) => {
    console.log('actions:updateImage'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Se stiamo modificando un prodotto esistente...
    if (payload.productId) {
      // ... elimina l'eventuale immagine precedente

      // Recupera il nome dell'immagine da eliminare dallo storage
      const docRef = db.doc(`/products/${payload.productId}`);
      const doc = await docRef.get();
      const docData = doc.data();
      const imageName = docData.imageName || null;

      // Rimuove il file dallo storage (se presente)
      if (imageName) {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(imageName);
        await imageRef.delete();
      }
    }

    const uploadImage = (imageName) => new Promise((resolve, reject) => {
      // Inizia l'upload del file verso lo storage
      const storageRef = firebase.storage().ref();
      const uploadRef = storageRef.child(imageName);
      const uploadTask = uploadRef.put(payload.file);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused'); // eslint-disable-line no-console
              break;
            case firebase.storage.TaskState.RUNNING: { // or 'running'
              // console.log('Upload is running'); // eslint-disable-line no-console
              const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${uploadProgress}% done`); // eslint-disable-line no-console
              break;
            }
            default:
          }
        }, (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              // state.importErrors.push(error.code);
              break;

            case 'storage/canceled':
              // User canceled the upload
              // state.importErrors.push(error.code);
              break;

            case 'storage/unknown':
            default:
              // Unknown error occurred, inspect error.serverResponse
              // state.importErrors.push(error.code);
              break;
          }

          return reject(error);
        }, () => {
          console.log('Upload complete'); // eslint-disable-line no-console

          return uploadTask.snapshot.ref.getDownloadURL().then(
            (downloadURL) => resolve(downloadURL),
          );
        });
    });

    // Prepara il nome del file della nuova immagine
    const imageName = `images/products/${uuidv4()}-${payload.file.name}`;

    // Carica l'immagine nello storage
    const imageUrl = await uploadImage(imageName);

    // eslint-disable-next-line no-console
    console.log('URL dell\'immagine:', imageUrl);

    // Memorizza il riferimento all'immagine nel prodotto
    if (payload.productId) {
      const docRef = db.doc(`/products/${payload.productId}`);

      await docRef.update({
        imageUrl,
        imageName,
      });
    }

    return Promise.resolve({
      imageUrl,
      imageName,
    });
  },

  removeImage: async (context, payload) => {
    console.log('actions:removeImage'); // eslint-disable-line no-console

    // Recupera il riferimento al prodotto
    const db = firebase.firestore();
    const docRef = db.doc(`/products/${payload.productId}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const doc = await docRef.get();
    const docData = doc.data();
    const imageName = docData.imageName || null;

    // Rimuove i riferimenti all'immagine nel prodotto
    await docRef.update({
      imageUrl: null,
      imageName: null,
    });

    // Rimuove il file dallo storage
    if (imageName) {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child(imageName);
      await imageRef.delete();
    }

    return Promise.resolve();
  },

  createProductsLight: async () => {
    console.log('actions:createProductsLight'); // eslint-disable-line no-console

    const functions = firebase.functions();

    const createProductsLight = functions.httpsCallable('createProductsLight');

    return createProductsLight();
  },
};

const mutations = {
  [SET_PRODUCTS_LISTENER]: (state, payload) => {
    console.log('mutations:SET_PRODUCTS_LISTENER'); // eslint-disable-line no-console

    Vue.set(state, 'listener', payload);
  },

  [SET_PRODUCTS]: (state, payload) => {
    console.log('mutations:SET_PRODUCTS'); // eslint-disable-line no-console

    const data = [];

    if (payload.snapshot) {
      payload.snapshot.forEach((doc) => {
        const docData = doc.data();

        data.push({
          id: doc.id,
          ...docData,
          // order: getOrder(payload.order, doc.id, docData.order),
        });
      });
    } else if (payload.list) {
      payload.list.forEach((doc) => {
        data.push({
          ...doc,
          // order: getOrder(payload.order, doc.id, doc.order),
        });
      });
    }

    // if (payload.snapshot || payload.list) {
    //   // Ordina
    //   data.sort(compareOrder);

    //   // eslint-disable-next-line no-console
    //   console.log('Ordinamento completato!');
    // }

    Vue.set(state, 'data', data.filter(
      (p) => {
        if (payload.isSuper
          || !payload.userType
          || !p.userTypes
          || !Array.isArray(p.userTypes)
          || p.userTypes.length === 0) return true;

        return p.userTypes.includes(payload.userType);
      },
    ));

    // eslint-disable-next-line no-console
    console.log(`state.data.length = ${state.data.length}`);
    // eslint-disable-next-line no-console
    // console.log('state.data =', state.data);
  },

  [CLEAR_PRODUCTS]: (state) => {
    console.log('mutations:CLEAR_PRODUCTS'); // eslint-disable-line no-console

    Vue.set(state, 'data', []);
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
