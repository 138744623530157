<template>
  <div class="component-product">
    <v-row v-if="!loading && product">
      <v-col cols="12" sm="4" md="4" lg="3" xl="2">
        <ProductImage v-model="product" />
      </v-col>

      <v-col cols="12" sm="8" md="8" lg="9" xl="10">
        <v-card flat>
          <v-card-text class="py-0">
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  v-if="languages.length > 1"
                  :value="product.name[userLanguage]"
                  disabled
                  label="Nome del prodotto"
                  filled
                  hide-details
                ></v-text-field>
                <v-text-field
                  v-else
                  :value="product.name[languages[0].id]"
                  :disabled="updating"
                  label="Nome del prodotto"
                  filled
                  hide-details
                  @change="v => { product.name[languages[0].id] = v; updated() }"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  :value="product.rev"
                  :disabled="updating"
                  label="Revisione del prodotto"
                  filled
                  hide-details
                  @change="v => { product.rev = v; updated() }"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  :value="product.url"
                  :disabled="updating"
                  label="URL della scheda tecnica"
                  filled
                  hide-details
                  dense
                  @change="v => { product.url = v; updated() }"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  :value="product.phases"
                  :disabled="updating"
                  :items="phases"
                  item-text="name"
                  return-object
                  chips
                  label="Fasi di lavorazione"
                  hint="Tutte le fasi di lavorazione in cui può essere utilizzato il prodotto"
                  persistent-hint
                  multiple
                  deletable-chips
                  filled
                  @change="v => { product.phases = v; updated() }"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-autocomplete
                  :value="product.userTypes"
                  :disabled="updating"
                  :items="userTypes"
                  filled dense
                  chips
                  small-chips
                  deletable-chips
                  label="Tipologia utente abilitata"
                  placeholder="Mostrato a tutti gli utenti"
                  :hint="'Il prodotto verrà mostrato solamente '
                    + 'alla tipologia di utente specificata '
                    + '(lasciare vuoto per mostrare a tutti)'"
                  persistent-hint
                  multiple
                  @change="v => { product.userTypes = v; updated() }"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <v-card outlined>
          <v-tabs
            v-show="languages.length > 1"
            v-model="selectedLanguage"
            background-color="secondary"
            dark>
            <v-tab v-for="language in languages" :key="language.id">
              {{ language.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="selectedLanguage">
            <v-tab-item
              v-for="language in languages"
              :key="language.id"
            >
              <v-card flat>
                <v-card-text v-if="languages.length > 1">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :value="product.name[language.id]"
                        :disabled="updating"
                        label="Nome del prodotto"
                        filled
                        hide-details
                        @change="v => { product.name[language.id] = v; updated() }"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-title>Descrizione</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <ckeditor
                        v-model="product.details[language.id]"
                        :editor="editor"
                        :config="editorConfig"
                        @input="updated()"></ckeditor>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-title>Voce di capitolato</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <ckeditor
                        v-model="product.specs[language.id]"
                        :editor="editor"
                        :config="editorConfig"
                        @input="updated()"></ckeditor>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>

      <!-- Allegati -->

      <v-col cols="12">
        <v-card outlined class="mt-4 attachments-card">
          <v-card-title>Allegati</v-card-title>

          <v-card-text>
            <v-alert
              v-if="!product.attachments || product.attachments.length === 0"
              type="info"
              outlined
              class="mb-4"
            >
              Non sono presenti allegati per questo prodotto.
            </v-alert>

            <v-card outlined class="mb-4"
              v-for="(attachment, index) in product.attachments"
              :key="`attachment-${index}`">
              <v-card-subtitle v-text="attachment.name"></v-card-subtitle>

              <v-card-text class="pb-0">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <!-- Tipo di documento -->
                    <v-select
                      v-model="attachment.category"
                      :items="attachmentCategories"
                      label="Tipo di documento"
                      filled
                      dense
                      hide-details
                      @change="updated()"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" v-if="attachment.category === 'altro'">
                    <v-text-field
                      v-model="attachment.customCategory"
                      label="Specifica la categoria"
                      filled
                      dense
                      hide-details
                      @input="updated()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <!-- Titolo -->
                    <v-text-field
                      v-model="attachment.name"
                      :disabled="updating"
                      label="Titolo (opzionale)"
                      filled
                      dense
                      hide-details
                      @input="updated()"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- Link (se link) -->
                <v-text-field
                  :value="attachment.url"
                  :disabled="updating"
                  label="URL"
                  placeholder="Inserire l'URL dell'allegato"
                  filled
                  dense
                  hide-details
                  @change="v => { attachment.url = v; updated() }"
                ></v-text-field>
              </v-card-text>

              <v-card-actions>
                <v-btn color="error" text small
                  :disabled="product.attachments.length < 1"
                  @click="onDeleteAttachment(index)" >
                  Elimina allegato
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-card-text>

          <v-card-actions class="pt-0">
            <v-spacer></v-spacer>

            <v-btn color="secondary" text small
              @click="onAddAttachment()" >
              Aggiungi allegato
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <!-- Consumo -->

      <v-col cols="12">
        <v-card outlined class="mt-4 consumption-card">
          <v-card-title>Consumo</v-card-title>

          <v-card-text>
            <v-row class="mt-6">
              <v-col cols="12" class="switch-continer">
                <v-switch color="info" class="mt-1"
                  v-model="product.standalone"
                  :label="product.standalone
                    ? 'Può essere selezionato e utilizzato da solo'
                    : 'Non può essere selezionato e utilizzato da solo'"
                  @change="updated()"></v-switch>
              </v-col>

              <v-col cols="12" class="switch-continer">
                <v-switch color="info" class="mt-1"
                  v-model="product.combined"
                  :label="product.combined
                    ? 'È consentito l\'utilizzo combinato con altri prodotti'
                    : 'Non è previsto l\'utilizzo combinato con altri prodotti'"
                  @change="updated()"></v-switch>
              </v-col>

              <template v-if="product.standalone">
                <v-col cols="12" :sm="product.consumptionType === 'fixed' ? 4 : 6">
                  <v-select
                    :value="product.consumptionType"
                    :disabled="['mq', 'pz'].includes(productUm)"
                    :items="[
                      { text: 'Fisso', value: 'fixed' },
                      { text: 'Variabile', value: 'variable' },
                    ]"
                    :hint="consumptionHint"
                    label="Consumo"
                    filled
                    persistent-hint
                    @change="v => { product.consumptionType = v; updated() }"
                  ></v-select>
                </v-col>

                <v-col cols="6" sm="4" v-if="product.consumptionType === 'fixed'">
                  <v-text-field
                    :value="product.consumption"
                    :disabled="updating || ['mq', 'pz'].includes(productUm)"
                    label="Consumo per m²"
                    filled
                    reverse
                    @change="v => { product.consumption = v; updated() }"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" :sm="product.consumptionType === 'fixed' ? 4 : 6">
                  <v-select
                    :value="productUm"
                    :items="um"
                    filled
                    label="Unità di misura"
                    @change="v => { product.um = v; updated() }"
                  ></v-select>
                </v-col>
              </template>
            </v-row>
          </v-card-text>

          <template v-if="product.consumptionType === 'variable'">
            <v-card-text>
              <v-card outlined class="mb-4"
                v-for="(consumptionrange, index) in product.consumptionranges"
                :key="`consumptionrange-${index}`">
                <v-card-subtitle v-text="consumptionrange.name"></v-card-subtitle>

                <v-card-text class="pb-0">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :value="product.consumptionranges[index].name"
                        :disabled="updating"
                        label="Nome fascia"
                        filled
                        @change="v => { product.consumptionranges[index].name = v; updated() }"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        :value="product.consumptionranges[index].consumption"
                        :disabled="updating"
                        label="Consumo per m²"
                        filled
                        reverse
                        @change="v => {
                          product.consumptionranges[index].consumption = v;
                          updated();
                        }"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="py-0"
                      v-if="Array.isArray(product.consumptionranges[index].requiredProducts)
                      && product.consumptionranges[index].requiredProducts.length > 0">
                      Questa fascia di consumo richiede l'utilizzo di questi prodotti:
                    </v-col>

                    <v-col cols="12" class="pt-0"
                      v-if="Array.isArray(product.consumptionranges[index].requiredProducts)
                      && product.consumptionranges[index].requiredProducts.length > 0">
                      <v-card class="my-0" flat
                        v-for="(requiredProduct, rpIndex)
                          in product.consumptionranges[index].requiredProducts"
                        :key="`consumptionrange-${rpIndex}`">
                        <v-card-text class="pb-0">
                          <v-row>
                            <v-col cols="12" sm="6" class="py-0"
                              :key="`rp-${index}-${rpIndex}`">
                              <v-autocomplete
                                :value="product.consumptionranges[index].requiredProducts[rpIndex]
                                  .product"
                                :disabled="updating"
                                :items="products.filter((p) => p.combined)"
                                :item-text="`name.${userLanguage}`"
                                return-object
                                label="Prodotto richiesto"
                                filled
                                @change="v => {
                                  product.consumptionranges[index].requiredProducts[rpIndex]
                                    .product = v;
                                  updated();
                                }"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="3" class="py-0"
                              :key="`rp-consumption-${index}-${rpIndex}`">
                              <v-text-field
                                :value="product.consumptionranges[index].requiredProducts[rpIndex]
                                  .consumption"
                                :disabled="updating"
                                label="Consumo per m²"
                                filled
                                dense
                                reverse
                                @change="v => {
                                  product.consumptionranges[index].requiredProducts[rpIndex]
                                    .consumption = v;
                                  updated();
                                }"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" class="py-0"
                              :key="`rp-um-${index}-${rpIndex}`">
                              <v-select
                                :value="product.consumptionranges[index].requiredProducts[rpIndex]
                                  .um"
                                :items="um"
                                filled
                                dense
                                label="Unità di misura"
                                @change="v => {
                                  product.consumptionranges[index].requiredProducts[rpIndex].um = v;
                                  updated();
                                }"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-card-text>

                        <v-card-actions class="pt-0">
                          <v-spacer></v-spacer>

                          <v-btn color="error" text small
                            @click="onRemoveRequiredProduct(index, rpIndex)" >
                            Rimuovi
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions class="pt-0">
                  <v-btn color="error" text small
                    :disabled="product.consumptionranges.length < 2"
                    @click="onDeleteConsumptionrange(index)" >
                    Elimina fascia
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn color="success" text small
                    @click="onAddRequiredProduct(index)" >
                    Aggiungi prodotto richiesto
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-card-text>

            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>

              <v-btn color="secondary" text small
               @click="onAddConsumptionrange()" >
                Aggiungi fascia di consumo
              </v-btn>
            </v-card-actions>
          </template>
        </v-card>

        <!-- Prezzi -->

        <v-card outlined class="mt-4 price-card">
          <v-card-title>Prezzi</v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-select
                  :value="product.priceType"
                  :items="[
                    { text: 'Prezzo unico', value: 'fixed' },
                    { text: 'Variabile in base al colore', value: 'colors' },
                    { text: 'Variabile in base alle dimensioni', value: 'width' },
                  ]"
                  label="Modalità memorizzazione prezzi"
                  filled
                  @change="v => { product.priceType = v; updated() }"
                ></v-select>
              </v-col>
            </v-row>

            <v-card outlined class="mb-4"
              v-for="(pricerange, index) in product.priceranges"
              :key="`pricerange-${index}`">
              <!-- v-card-subtitle v-text="pricerange.name"></v-card-subtitle -->

              <v-card-text class="py-0">
                <v-row>
                  <v-col cols="12" v-if="['colors', 'width'].includes(product.priceType)">
                    <v-text-field
                      :value="product.priceranges[index].name"
                      :disabled="updating"
                      label="Nome fascia"

                      @change="v => { product.priceranges[index].name = v; updated() }"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-data-table class="elevation-1 price-table"
                      :headers="priceHeaders"
                      :items="priceData(pricerange)"
                      disable-pagination
                      hide-default-footer>
                      <template v-slot:item.packaging="{ item }">
                        <v-edit-dialog
                          :return-value="item.packaging"
                          persistent
                          @save="priceUpdated(index, item)">
                          <template v-if="item.packaging">
                            {{ item.packaging }} {{ umLongPlural(product.um) }}
                          </template>
                          <span v-else class="red--text">{{ noPackagingText }}</span>
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.packaging"
                              :label="packagingPlaceholder"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-for="pl in pricelists" v-slot:[`item.${pl.id}`]="{ item }">
                        <v-edit-dialog
                          :key="pl.id"
                          :return-value="item[pl.id]"
                          @save="priceUpdated(index, item)">
                          <template v-if="item[pl.id]">
                            {{ item[pl.id] }}
                          </template>
                          <span v-else class="red--text">Prezzo assente</span>
                          <template v-slot:input>
                            <v-text-field
                              v-model="item[pl.id]"
                              :label="`Prezzo al ${umLong(product.um)}`"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-slot:item.action="{ item }">
                        <v-btn class="ml-2" icon x-small color="secondary"
                          @click="onDeletePrice(index, item)">
                          <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:footer>
                        <v-card-actions class="pt-0">
                          <v-spacer></v-spacer>

                          <v-btn color="secondary" text small
                          @click="onAddPrice(index)" >
                            Aggiungi
                          </v-btn>
                        </v-card-actions>
                      </template>
                    </v-data-table>
                  </v-col>

                  <v-col v-if="product.priceType === 'colors'" cols="12" class="d-flex mt-4">
                    <v-autocomplete
                      v-if="allAvailableColors"
                      :value="product.priceranges[index].colors"
                      :items="allAvailableColors"
                      single-line
                      label="Colore"
                      placeholder="Selezionare i codici colore"
                      item-text="code"
                      item-value="id"
                      multiple
                      outlined
                      hint="Codici colore associati a questa fascia di prezzo"
                      persistent-hint
                      return-object
                      @change="v => {
                        product.priceranges[index].colors = v;
                        updated();
                      }">
                      <template v-slot:selection="data">
                        <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          label
                          close
                          @click="data.select"
                          @click:close="removeColor(index, data.item)">
                          <v-avatar left>
                            <v-sheet
                              tile
                              height="60"
                              width="60"
                              :color="`rgba(${data.item.r}, ${data.item.g}, ${data.item.b}, 1)`">
                            </v-sheet>
                          </v-avatar>
                          {{ data.item.code }}
                        </v-chip>
                      </template>

                      <template v-slot:item="data">
                        <template>
                          <v-list-item-avatar>
                            <v-sheet
                              tile
                              height="60"
                              width="60"
                              :color="`rgba(${data.item.r}, ${data.item.g}, ${data.item.b}, 1)`">
                            </v-sheet>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.code"></v-list-item-title>
                            <v-list-item-subtitle
                              v-html="colorSubtitle(data.item)"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>

                    <v-menu bottom left>
                      <template v-slot:activator="{ on }">
                        <v-btn icon large v-on="on" class="ml-2 mt-2">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="onSelectAll(index)">
                          <v-list-item-title>Seleziona tutti</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions v-if="['colors', 'width'].includes(product.priceType)" class="pt-0">
                <v-btn color="error" text small
                  :disabled="product.priceranges.length < 2"
                  @click="onDeletePricerange(index)" >
                  Elimina fascia di prezzo
                </v-btn>

                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-card-text>

          <v-card-actions v-if="['colors', 'width'].includes(product.priceType)" class="pt-0">
            <v-spacer></v-spacer>

            <v-btn color="secondary" text small
              @click="onAddPricerange()" >
              Aggiungi fascia di prezzo
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unreachable */
/* eslint-disable max-len */

import { v4 as uuidv4 } from 'uuid';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import List from '@ckeditor/ckeditor5-list/src/list';
import ProductImage from '@/components/ProductImage';

export default {
  name: 'Product',

  components: {
    ProductImage,
  },

  props: {
    value: {
      type: Object,
    },
    updating: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    editor: ClassicEditor,
    editorConfig: {
      placeholder: 'Inserisci qui il testo',
      language: 'it',
      plugins: [
        EssentialsPlugin,
        BoldPlugin,
        ItalicPlugin,
        Underline,
        LinkPlugin,
        ParagraphPlugin,
        Alignment,
        Font,
        RemoveFormat,
        List,
      ],
      toolbar: {
        items: [
          'bold', 'italic', 'underline',
          '|',
          'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
          '|',
          'alignment:left', 'alignment:right', 'alignment:center',
          '|',
          'link', 'numberedList', 'bulletedList',
          '|',
          'undo', 'redo', 'removeFormat',
        ],
        shouldNotGroupWhenFull: true,
      },
    },

    id: null,
    product: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
    userLocale: 'it-IT', // TODO: Dovrà essere impostato nel profilo utente
    selectedLanguage: null,
    languages: [ // TODO: Hardcoded da qualche parte comune
      { id: 'it', name: 'Italiano' },
      // { id: 'en', name: 'Inglese' },
      // { id: 'de', name: 'Tedesco' },
    ],

    um: [
      { text: 'l/m²', value: 'l' },
      { text: 'kg/m²', value: 'kg' },
      { text: 'm²', value: 'mq' },
      { text: 'pz', value: 'pz' },
    ],

    allAvailableColors: null,

    priceHeaders: [],

    // Le possibili categorie sono:
    // - Scheda tecnica
    // - Cartella colore
    // - Certificato
    // - Opuscolo
    // - Dichiarazione ambientale di prodotto (EPD)
    // - Dichiarazione di prestazione
    // - Scheda tecnica di sostenibilità
    // - Scheda di sicurezza
    // - Altro (in questo caso è necessario specificare una categoria personalizzata)

    attachmentCategories: [
      { text: 'Scheda tecnica', value: 'scheda-tecnica' },
      { text: 'Cartella colore', value: 'cartella-colore' },
      { text: 'Certificato', value: 'certificato' },
      { text: 'Opuscolo', value: 'opuscolo' },
      { text: 'Dichiarazione ambientale di prodotto (EPD)', value: 'epd' },
      { text: 'Dichiarazione di prestazione', value: 'dichiarazione-prestazione' },
      { text: 'Scheda tecnica di sostenibilità', value: 'scheda-sostenibilità' },
      { text: 'Scheda di sicurezza', value: 'scheda-sicurezza' },
      { text: 'Altro', value: 'altro' },
    ],
  }),

  async created() {
    console.log('Product: created()'); // eslint-disable-line no-console

    if (this.product === null) this.product = this.value;
    // this.product = this.value;

    // this.initProduct();

    this.allAvailableColors = await this.$store.dispatch('colorlists/getAllAvailableColors');
  },

  watch: {
    value(newValue) {
      console.log('Product: watch.value()'); // eslint-disable-line no-console
      if (!newValue) {
        console.log('Product: watch.value() - newValue === null -> stop'); // eslint-disable-line no-console
        return;
      }
      if (this.product) {
        console.log('Product: watch.value() - this.product !== null -> stop'); // eslint-disable-line no-console
        return;
      }

      this.initProduct();
    },

    productUm(val) {
      if (['mq', 'pz'].includes(val)) {
        this.product.consumptionType = 'fixed';
        this.product.consumption = '';
        this.product.priceType = 'width';
      }

      this.updatePriceHeaders();
    },

    pricelists(newValue) {
      console.log('Product: watch.pricelists() newValue =', newValue); // eslint-disable-line no-console

      if (!newValue) return;

      // L'elenco dei listini è cambiato, aggiungo eventuali prezzi/listini assenti

      /*
      this.pricelists.forEach((pl) => {
        // [prezzo fisso] Crea il record del prezzo vuoto relativo al listino
        // TODO: Rimuovere quando i prezzi per lattaggi sono attivi
        if (!this.product.price.hasOwnProperty(pl.id)) {
          this.$set(this.product.price, pl.id, null);
        }

        // [prezzo variabile] Crea il record del prezzo vuoto relativo al listino
        // TODO: Rimuovere quando i prezzi per lattaggi sono attivi
        // eslint-disable-next-line no-restricted-syntax
        // for (const key in this.product.priceranges) {
        //   if (this.product.priceranges.hasOwnProperty(key)) {
        //     if (this.product.priceranges[key].hasOwnProperty('price')
        //       && !this.product.priceranges[key].price.hasOwnProperty(pl.id)) {
        //       this.$set(this.product.priceranges[key].price, pl.id, null);
        //     }
        //   }
        // }
      });
      */

      this.updatePriceHeaders();
    },
  },

  computed: {
    phases() {
      return this.$store.getters['phases/docs'];
    },

    userTypes() {
      // TODO: Rendere dinamico recuperando l'elenco da Firestore
      return [
        { value: 'privato', text: 'Privato' },
        { value: 'rivenditore', text: 'Rivenditore' },
        { value: 'venditore-esterno', text: 'Venditore esterno della rivendita' },
        { value: 'agente', text: 'Agente' },
        { value: 'imbianchino', text: 'Imbianchino' },
        { value: 'progettista', text: 'Progettista' },
        { value: 'keim', text: 'Funzionario KEIM' },
      ];
    },

    products() {
      return this.$store.getters['products/docs'];
    },

    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    productUm() {
      return this.product && 'um' in this.product ? this.product.um : null;
    },

    consumptionHint() {
      switch (this.product.consumptionType) {
        case 'fixed':
          return 'Varia proporzionalmente alla superficie';

        case 'variable':
          return 'Varia in base alla superficie e ad altri fattori';

        default:
          return null;
          break;
      }
      return this.product.consumptionType === 'fixed' ? 'Varia proporzionalmente alla superficie' : 'Varia in base alla superficie e ad altri fattori';
    },

    noPackagingText() {
      if (this.productUm === 'mq') return 'Superficie assente';
      if (this.productUm === 'pz') return 'Numero pezzi assente';
      return 'Lattaggio assente';
    },

    packagingPlaceholder() {
      if (this.productUm === 'mq') return 'Superficie totale';
      if (this.productUm === 'pz') return 'Numero pezzi';
      return `Lattaggio in ${this.umLongPlural(this.productUm)}`;
    },
  },

  methods: {
    // isObject(val) {
    //   return (typeof val === 'object');
    // },

    initProduct() {
      const newValue = this.value;

      console.log('Product: watch.value() - newValue =', newValue); // eslint-disable-line no-console

      this.product = newValue;

      this.updatePriceHeaders();

      if (!this.product.hasOwnProperty('specs') || !this.product.specs) {
        this.$set(this.product, 'specs', {
          it: '',
          en: '',
          de: '',
        });
      }

      // Prezzo
      // -------

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      // Di default la modalità di memorizzazione dei prezzi è a prezzo unico (fixed)
      if (!newValue.hasOwnProperty('priceType') || !newValue.priceType) this.$set(this.product, 'priceType', 'fixed');

      // Se non esiste l'elenco delle fasce di prezzo, lo crea (vuoto).
      if (!newValue.hasOwnProperty('priceranges')) {
        this.$set(this.product, 'priceranges', []);
      }

      // Se non sono impostate fasce di prezzo, crea la prima (vuota)
      if (this.product.priceranges.length === 0) {
        const pricerange = {
          name: 'Fascia di prezzo 1',
          // packaging: null, // TODO: Rimuovere quando i prezzi per lattaggio saranno completi
          colors: [],
          // price: {}, // TODO: Rimuovere quando i prezzi per lattaggio saranno completi
          prices: [],
        };

        this.product.priceranges.push(pricerange);
      }

      // Corregge l'elenco dei prezzi per listino se necessario
      if (this.product.priceType === 'fixed') {
        // eslint-disable-next-line no-prototype-builtins
        if (!newValue.hasOwnProperty('price')) {
          this.$set(this.product, 'price', {});
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const pl in newValue.price) {
            if (newValue.price.hasOwnProperty(pl)) {
              this.$set(this.product.price, pl, formatter.format(newValue.price[pl]));
            }
          }
        }
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const prIndex in newValue.priceranges) {
        // eslint-disable-next-line no-prototype-builtins
        if (newValue.priceranges.hasOwnProperty(prIndex)) {
          // this.product.priceranges[prIndex].consumption = new Intl.NumberFormat('it-IT').format(this.product.priceranges[prIndex].consumption);

          // TODO: Rimuovere quando i prezzi per lattaggio saranno completi
          // eslint-disable-next-line no-prototype-builtins
          // if (!newValue.priceranges[prIndex].hasOwnProperty('price')) {
          //   this.$set(this.product.priceranges[prIndex], 'price', {});
          // } else {
          //   // eslint-disable-next-line no-restricted-syntax
          //   for (const pl in newValue.priceranges[prIndex].price) {
          //     if (newValue.priceranges[prIndex].price.hasOwnProperty(pl)) {
          //       this.$set(this.product.priceranges[prIndex].price, pl, formatter.format(newValue.priceranges[prIndex].price[pl]));
          //     }
          //   }
          // }

          if (Array.isArray(newValue.priceranges[prIndex].prices) && newValue.priceranges[prIndex].prices.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const pIndex in newValue.priceranges[prIndex].prices) {
              if (newValue.priceranges[prIndex].prices.hasOwnProperty(pIndex)) {
                // eslint-disable-next-line no-restricted-syntax
                for (const plId in newValue.priceranges[prIndex].prices[pIndex]) {
                  if (newValue.priceranges[prIndex].prices[pIndex].hasOwnProperty(plId)) {
                    if (plId !== 'id' && plId !== 'packaging') {
                      this.$set(
                        this.product.priceranges[prIndex].prices[pIndex],
                        plId,
                        formatter.format(newValue.priceranges[prIndex].prices[pIndex][plId]),
                      );
                    }
                  }
                }
                // const element = this.product.priceranges[prIndex].prices[pIndex];
                // this.$set(this.product.priceranges[prIndex].price, pl, formatter.format(newValue.priceranges[prIndex].price[pl]));
              }
            }
          }
        }
      }

      // Consumo
      // -------

      // Di default il prodotto può essere usato da solo e non va combinato
      if (!newValue.hasOwnProperty('standalone')) this.$set(this.product, 'standalone', true);
      if (!newValue.hasOwnProperty('combined')) this.$set(this.product, 'combined', false);

      // Di default l'unità di misura per il consumo è kg/m2
      if (!newValue.hasOwnProperty('um')) this.$set(this.product, 'um', 'kg');

      // Di default il consumo è fisso
      if (!newValue.hasOwnProperty('consumptionType') || !newValue.consumptionType) this.$set(this.product, 'consumptionType', 'fixed');

      // Se non esiste l'elenco delle fasce di consumo, lo crea (vuoto)
      if (!newValue.hasOwnProperty('consumptionranges')) {
        this.$set(this.product, 'consumptionranges', []);
      }

      // Se non sono impostate fasce di consumo, crea la prima (vuota)
      if (this.product.consumptionranges.length === 0) {
        const consumptionrange = {
          name: 'Fascia di consumo 1',
          consumption: null,
        };

        this.product.consumptionranges.push(consumptionrange);
      }

      // Corregge il formato del consumo (virgola/punto)
      if (this.product.consumptionType === 'fixed') {
        // Consumo fisso
        if (!newValue.hasOwnProperty('consumption')) {
          this.$set(this.product, 'consumption', null);
        } else {
          this.$set(this.product, 'consumption', new Intl.NumberFormat('it-IT').format(newValue.consumption));
        }
      } else
      if (this.product.consumptionType === 'variable') {
        // Consumo variabile
        // eslint-disable-next-line no-restricted-syntax
        for (const crIndex in this.product.consumptionranges) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.product.consumptionranges.hasOwnProperty(crIndex)) {
            this.product.consumptionranges[crIndex].consumption = new Intl.NumberFormat('it-IT').format(this.product.consumptionranges[crIndex].consumption);

            // Corregge i consumi di eventuali prodotti aggiuntivi richiesti
            if (Array.isArray(this.product.consumptionranges[crIndex].requiredProducts)
              && this.product.consumptionranges[crIndex].requiredProducts.length > 0) {
              // eslint-disable-next-line no-restricted-syntax
              for (const rpIndex in this.product.consumptionranges[crIndex].requiredProducts) {
                if (this.product.consumptionranges[crIndex].requiredProducts.hasOwnProperty(rpIndex)) {
                  this.$set(this.product.consumptionranges[crIndex].requiredProducts[rpIndex], 'consumption', new Intl.NumberFormat('it-IT').format(newValue.consumptionranges[crIndex].requiredProducts[rpIndex].consumption));
                }
              }
            }
          }
        }
      }

      if (!newValue.hasOwnProperty('packaging')) this.$set(this.product, 'packaging', null);

      // Se non esiste l'elenco degli allegati, lo crea (vuoto)
      if ('attachments' in newValue === false) this.$set(this.product, 'attachments', []);
    },

    updated() {
      console.log('Product: updated()'); // eslint-disable-line no-console
      this.$emit('updated');
    },

    umLong(um) {
      switch (um) {
        case 'kg': return 'kilogrammo';
        case 'l': return 'litro';
        case 'mq': return 'mq';
        case 'pz': return 'pezzo';
        default: return '';
      }
    },

    umLongPlural(um) {
      switch (um) {
        case 'kg': return 'kilogrammi';
        case 'l': return 'litri';
        case 'mq': return 'mq';
        case 'pz': return 'pezzi';
        default: return '';
      }
    },

    colorSubtitle(color) {
      let subtitle = '';
      if (color.colorlist) {
        subtitle += `${color.colorlist}`;
      }
      if (color.colorlist && color.hbw) subtitle += ' - ';
      if (color.hbw) {
        subtitle += `HBW: ${color.hbw}`;
      }
      return subtitle;
    },

    priceData(pricerange) {
      const result = [];

      if (pricerange && pricerange.hasOwnProperty('prices')
        && Array.isArray(pricerange.prices) && pricerange.prices.length > 0) {
        // eslint-disable-next-line no-unused-vars
        pricerange.prices.forEach((price) => {
          // Una linea per ogni tipologia di conferzione
          result.push(price);
        });
      }

      // eslint-disable-next-line no-console
      // console.log('result =', result);

      return result;
    },

    updatePriceHeaders() {
      console.log('Product: updatePriceHeaders()'); // eslint-disable-line no-console

      if (!Array.isArray(this.pricelists) || this.pricelists.length === 0) {
        console.log('Product: updatePriceHeaders() - this.pricelists ancora vuoto, rinuncio.'); // eslint-disable-line no-console
        return;
      }

      this.priceHeaders = [];

      this.priceHeaders.push({
        text: this.productUm !== 'mq' ? 'Confezione' : 'Superifie totale',
        align: 'start',
        sortable: false,
        value: 'packaging',
      });

      this.pricelists.forEach((pl) => {
        // Aggiunge l'header del prezzo alla tabella
        this.priceHeaders.push({
          text: `Prezzo al ${this.umLong(this.productUm)} (listino ${pl.name})`,
          align: 'end',
          sortable: false,
          value: pl.id,
        });
      });

      this.priceHeaders.push({
        text: '',
        align: 'end',
        sortable: false,
        value: 'action',
      });
    },

    priceUpdated(prIndex, price) {
      // console.log('Product: priceUpdated() prIndex =', prIndex); // eslint-disable-line no-console
      // console.log('Product: priceUpdated() price =', price); // eslint-disable-line no-console

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      const pIndex = this.product.priceranges[prIndex].prices.findIndex((p) => p.id === price.id);
      // console.log('Product: priceUpdated() pIndex =', pIndex); // eslint-disable-line no-console

      const newPrice = { ...price };
      // console.log('Product: priceUpdated() newPrice =', newPrice); // eslint-disable-line no-console

      newPrice.packaging = price.packaging
        ? `${parseFloat(`${price.packaging}`.replace(',', '.').replace(/[^0-9.]+/g, ''))}`.replace('.', ',')
        : null;

      this.pricelists.forEach((pl) => {
        newPrice[pl.id] = price[pl.id] ? formatter.format(parseFloat(`${price[pl.id]}`.replace(',', '.').replace(/[^0-9.]+/g, ''))) : null;
      });


      this.$set(this.product.priceranges[prIndex].prices, pIndex, newPrice);

      this.updated();
    },

    onAddAttachment() {
      console.log('Product: onAddAttachment()'); // eslint-disable-line no-console

      this.product.attachments.push({
        type: 'link',
        category: 'scheda tecnica',
        customCategory: null,
        name: '',
        url: '',
      });
    },

    onDeleteAttachment(index) {
      console.log('Product: onDeleteAttachment() index =', index); // eslint-disable-line no-console
      const newAttachments = [...this.product.attachments];
      newAttachments.splice(index, 1);
      this.$set(this.product, 'attachments', newAttachments);
      this.updated();
    },

    onAddPrice(prIndex) {
      console.log('Product: onAddPrice() prIndex =', prIndex); // eslint-disable-line no-console

      // const formatter = new Intl.NumberFormat('it-IT', {
      //   style: 'currency',
      //   currency: 'EUR',
      // });

      // Se non esiste l'elenco dei prezzi e relativo packaging, lo crea (vuoto).
      if (!this.product.priceranges[prIndex].hasOwnProperty('prices')) {
        this.$set(this.product.priceranges[prIndex], 'prices', []);
      }

      const id = uuidv4();

      const price = {
        id,
        packaging: null,
      };

      this.pricelists.forEach((pl) => {
        // price[pl.id] = formatter.format(0);
        price[pl.id] = null;
      });

      console.log('Product: onAddPrice() price =', price); // eslint-disable-line no-console

      this.product.priceranges[prIndex].prices.push(price);
      this.updated();
    },

    onDeletePrice(prIndex, price) {
      const newPrices = this.product.priceranges[prIndex].prices
        .filter((p) => p.id !== price.id);
      console.log('Product: onDeletePrice() newPrices =', newPrices); // eslint-disable-line no-console
      this.$set(this.product.priceranges[prIndex], 'prices', newPrices);
      this.updated();
    },

    onAddPricerange() {
      // Se non l'elenco delle fasce di prezzo, lo crea (vuoto).
      if (!this.product.hasOwnProperty('priceranges')) {
        this.$set(this.product, 'priceranges', []);
      }

      const pricerange = {
        name: `Fascia di prezzo ${this.product.priceranges.length + 1}`,
        packaging: null,
        colors: [],
        price: {},
      };

      this.pricelists.forEach((pl) => {
        pricerange.price[pl.id] = null;
      });

      this.product.priceranges.push(pricerange);
      this.updated();
    },

    onDeletePricerange(index) {
      const newPriceranges = [...this.product.priceranges];
      newPriceranges.splice(index, 1);
      this.$set(this.product, 'priceranges', newPriceranges);
      this.updated();
    },

    onAddConsumptionrange() {
      // Se non esiste l'elenco delle fasce di consumo, lo crea (vuoto).
      if (!this.product.hasOwnProperty('consumptionranges') || !this.product.consumptionranges) {
        this.$set(this.product, 'consumptionranges', []);
      }

      const consumptionrange = {
        name: `Fascia di consumo ${this.product.consumptionranges.length + 1}`,
        consumption: null,
      };

      this.product.consumptionranges.push(consumptionrange);
      this.updated();
    },

    onDeleteConsumptionrange(index) {
      const newConsumptionranges = [...this.product.consumptionranges];
      newConsumptionranges.splice(index, 1);
      this.$set(this.product, 'consumptionranges', newConsumptionranges);
      this.updated();
    },

    onAddRequiredProduct(index) {
      // Se non esiste l'elenco delle fasce di consumo, lo crea (vuoto).
      if (!this.product.consumptionranges[index].hasOwnProperty('requiredProducts')
        || !this.product.consumptionranges[index].requiredProducts) {
        this.$set(this.product.consumptionranges[index], 'requiredProducts', []);
      }

      const requiredProduct = {
        name: `Prodotto richiesto ${this.product.consumptionranges[index].requiredProducts.length + 1}`,
        consumption: null,
        um: 'kg',
      };

      this.product.consumptionranges[index].requiredProducts.push(requiredProduct);
      this.updated();
    },

    onRemoveRequiredProduct(index, rpIndex) {
      const newRequiredProducts = [...this.product.consumptionranges[index].requiredProducts];
      newRequiredProducts.splice(rpIndex, 1);
      this.$set(this.product.consumptionranges[index], 'requiredProducts', newRequiredProducts);
      this.updated();
    },

    removeColor(index, color) {
      console.log('Product: removeColor() color =', color); // eslint-disable-line no-console

      const colorIndex = this.product.priceranges[index].colors.findIndex((c) => c.code === color.code);

      console.log('Product: removeColor() colorIndex =', colorIndex); // eslint-disable-line no-console

      if (colorIndex >= 0) {
        const newColors = [...this.product.priceranges[index].colors];
        newColors.splice(colorIndex, 1);
        this.$set(this.product.priceranges[index], 'colors', newColors);
        this.updated();
      }
    },

    onSelectAll(index) {
      console.log('Product: onSelectAll() index =', index); // eslint-disable-line no-console

      this.$set(this.product.priceranges[index], 'colors', this.allAvailableColors);

      // this.allAvailableColors.forEach((color) => {
      //   const exists = this.product.priceranges[index].colors.findIndex((c) => c.code === color.code);
      //   if (!exists) this.product.priceranges[index].colors.push(color);
      // });
    },
  },
};
</script>

<style scoped>
.switch-continer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.attachments-card,
.consumption-card,
.price-card {
  background-color: #f8f8f8;
}

.price-table {
  background-color: #fffff0;
}
</style>
