<template>
  <div class="component-productselection">
    <v-alert
      text dense
      type="info"
      border="left">
      Per ognuna delle fasi di lavorazione richieste qui elencate,
      selezionare i prodotti (tra quelli suggeriti) e rivedere le procedure.
    </v-alert>

    <v-row no-gutters>
      <v-col>
        <v-expansion-panels
          focusable
          multiple
          >
          <v-expansion-panel
            v-for="(phase, phaseIndex) in selectedPhases"
            :key="`phase-${phaseIndex}`">
            <v-expansion-panel-header
              @click="openPhase(phase)">
              <v-row align="center">
                <v-col>
                  <!-- v-checkbox value="John"></v-checkbox -->
                  <span class="text-h6">{{ phase.name }}</span>
                </v-col>
                <v-col align="right" class="pr-6">
                  <template v-if="phase.withProcedure">
                    <v-btn depressed small
                      :color="proceduresStatus[phase.id] === true ? 'success' : 'warning'">
                      Procedura
                      <v-icon dark right>mdi-script-text-outline</v-icon>
                    </v-btn>
                  </template>

                  <template v-if="hasProducts(phase)">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on"
                          depressed small class="ml-2"
                          :color="phaseChoiceSelected(phase.id) ? 'success' : 'warning'">
                          Prodotti
                          <v-icon dark right>mdi-format-list-text</v-icon>
                        </v-btn>
                      </template>
                      <span>Seleziona quali prodotti utilizzare</span>
                    </v-tooltip>

                  </template>
                </v-col>
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row class="mt-4 mb-0">
                <v-col cols="12" v-if="phase.withProcedure">
                  <v-alert
                    class="mb-0"
                    text
                    type="success"
                    border="left"
                    v-html="phase.procedure">
                  </v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" v-for="(choice, index) of visibleChoices(phase)"
                  :key="`choice-${phaseIndex}-${choice.index}`">
                  <small class="text--disabled">Scelta {{ index }}
                    <span v-if="user.superUser">(priorità {{ priority(choice) }})</span></small>
                  <ProductSelectionChoice
                    :choice="choice"
                    :status="status"
                    :index="index"
                    :done="phaseChoiceSelected(phase.id)"
                    :solo="visibleChoices(phase).length === 1"
                    :selected="isChoiceSelected(getPhaseIndex(phase), choice.index)"
                    @select="onSelectChoice(phase.id, choice.index)"
                    @unselect="onUnselectChoice(phase.id, choice.index)"
                    @update="onUpdate" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- ProductDetailsDialog v-model="detailsDialog"
      :product="selected"
      :quotation="quotation"
      @select="onSelect"
      @unselect="onUnselect" / -->
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins, no-restricted-syntax, no-console */

import ProductSelectionChoice from '@/components/ProductSelectionChoice';

export default {
  name: 'ProductSelection',

  props: ['value', 'status'],

  components: {
    ProductSelectionChoice,
  },

  data: () => ({
    detailsDialog: false,

    quotation: {},

    // L'elenco delle scelte selezionate
    selectedChoices: [],

    selected: null,

    proceduresStatus: {},
    productsStatus: {},

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  created() {
    if (!this.value) {
      console.log('ProductSelection: created() new quotation'); // eslint-disable-line no-console
      this.$set(this.quotation, 'selectedProducts', []);
    } else {
      console.log('ProductSelection: created() existing quotation'); // eslint-disable-line no-console
      this.quotation = this.value;
    }
  },

  watch: {
    value(newValue) {
      console.log('ProductSelection: watch -> value'); // eslint-disable-line no-console
      this.quotation = newValue;

      this.updateSelectedProducts();
    },

    quotation(newValue) {
      console.log('ProductSelection: watch -> quotation'); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },

    allChoicesSelected(newValue) {
      console.log('ProductSelection: watch.allChoicesSelected()'); // eslint-disable-line no-console
      this.$emit('complete', newValue);
    },
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    products() {
      return this.$store.getters['products/docs'];
    },

    phases() {
      return this.$store.getters['phases/docs'];
    },

    tags() {
      return this.status && this.status.hasOwnProperty('tags') && Array.isArray(this.status.tags)
        ? this.status.tags
        : [];
    },

    selectedPhases() {
      const selectedPhases = [];

      if (!this.status) return [];

      this.phases.forEach((phase) => {
        // Verifica se la fase va visualizzata
        const conditions = 'conditions' in phase
          && phase.conditions.constructor === Object
          ? Object.values(phase.conditions)
          : null;

        // Se non sono impostate condizioni, la fase è visibile
        if (!conditions
          || !Array.isArray(conditions)
          || conditions.length === 0) return true;

        // Sono definite delle condizioni, verifico se una di queste è soddisfatta
        let visible = false;
        for (const condition of conditions) {
          const includeTags = Array.isArray(condition)
            ? condition
            : condition.include;
          const excludeTags = Array.isArray(condition)
            ? []
            : condition.exclude;

          const excludeCommonTags = excludeTags.filter((tag) => this.status.tags.includes(tag));

          if (excludeCommonTags.length > 0) {
            visible = false;
            break;
          }

          const includeCommonTags = includeTags.filter((tag) => this.status.tags.includes(tag));

          if (includeCommonTags.length === includeTags.length) {
            visible = true;
            break;
          }
        }

        if (visible) selectedPhases.push(phase);

        return null;
      });

      return selectedPhases;
    },

    allChoicesSelected() {
      let result = true;
      this.selectedPhases.forEach((phase) => {
        if (this.phaseChoiceSelected(phase.id) === false) {
          result = false;
        }

        if (phase.withProcedure === true
            && this.proceduresStatus[phase.id] === false) {
          result = false;
        }
      });
      return result;
    },
  },

  methods: {
    getPhaseIndex(phase) {
      return this.phases.findIndex((p) => p.id === phase.id);
    },

    openPhase(phase) {
      // Se la fase contiene una procedura, viene indicata come completata
      this.$set(this.proceduresStatus, phase.id, true);

      // Se la fase ha un solo prodotto...
      if (this.isSolo(phase) && this.hasProducts(phase)) {
        console.log('ProductSelection: openPhase() unico prodotto'); // eslint-disable-line no-console
        console.log('ProductSelection: openPhase() phase.id =', phase.id); // eslint-disable-line no-console

        // Trova l'index della fase selezionata
        // const phaseIndex = this.phases.findIndex((p) => p.id === phase.id);

        const choices = this.visibleChoices(phase);

        this.onSelectChoice(phase.id, choices[0].index);
      }
      // ...lo seleziona automaticamente.
    },

    isSolo(phase) {
      return this.visibleChoices(phase).length === 1;
    },

    choiceProducts(choice) {
      const products = 'products' in choice
        && Array.isArray(choice.products)
        && choice.products.length > 0
        ? choice.products
        : [];

      // Invece di ritornare i prodotti memorizzati nella fase,
      // recupera il prodotto dall'elenco aggiornato.

      // Se il prodotto non è presente è possibile che non sia
      // accessibile alla tipologia di utente corrente.

      return this.products.filter((p) => products.findIndex((cp) => p.id === cp.id) > -1);
    },

    hasProducts(phase) {
      // Verifica se la fase ha prodotti

      if (!phase || 'choices' in phase === false) return false;

      const choices = Object.values(phase.choices);

      return choices.filter((c) => this.choiceProducts(c).length > 0).length > 0;
    },

    isRecommended(choice) {
      switch (choice.recommended) {
        case 'always':
          return true;

        case 'conditions': {
          if (!choice.hasOwnProperty('recommendedConditions')) {
            return false;
          }

          let recommended = false;
          // Scorre ogni linea di condizioni
          for (const condition of Object.values(choice.recommendedConditions)) {
            const conditionTags = Object.values(condition);
            const commonTags = conditionTags.filter((tag) => this.tags.includes(tag));
            if (commonTags.length === conditionTags.length) {
              recommended = true;
              break;
            }
          }
          return recommended;
        }

        default:
          return false;
      }
    },

    priority(choice) {
      if ('priorities' in choice === false
        || !choice.priorities
        || (Array.isArray(choice.priorities) && choice.priorities.length === 0)) {
        return 0;
      }

      let result = 0;

      // Scorre ogni linea di regole
      for (const p of Object.values(choice.priorities)) {
        const commonTags = p.tags.filter((tag) => this.tags.includes(tag));
        if (commonTags.length === p.tags.length) {
          result += Number(p.value);
        }
      }

      return result;
    },

    visibleChoices(phase) {
      // console.log('ProductSelection: visibleChoices() phase =', phase);

      const choices = Object.values(phase.choices)
        .map((c) => {
          const { selected, ...data } = c;

          return data;
        })
        .filter((choice) => {
          switch (choice.visibility) {
            case 'always':
              return true;

            case 'conditions': {
              if (!choice.hasOwnProperty('visibilityConditions')) {
                console.log('ProductSelection: choice.visibilityConditions =', choice.visibilityConditions); // eslint-disable-line no-console
                return false;
              }

              let visible = false;
              // Scorre ogni linea di condizioni
              for (const condition of Object.values(choice.visibilityConditions)) {
                const conditionTags = Object.values(condition);
                const commonTags = conditionTags.filter((tag) => this.tags.includes(tag));
                if (commonTags.length === conditionTags.length) {
                  visible = true;
                  break;
                }
              }
              return visible;
            }

            default:
              return false;
          }
        });

      choices.sort((a, b) => {
        if (this.isRecommended(a) && !this.isRecommended(b)) {
          return -1;
        }
        if (!this.isRecommended(a) && this.isRecommended(b)) {
          return 1;
        }

        if (this.priority(a) < this.priority(b)) {
          return 1;
        }
        if (this.priority(a) > this.priority(b)) {
          return -1;
        }

        return 0;
      });

      return choices.filter((c) => this.choiceProducts(c).length > 0 || c.products.length === 0);
    },

    phaseChoiceSelected(phaseId) {
      // Trova l'index della fase selezionata
      const phaseIndex = this.phases.findIndex((p) => p.id === phaseId);
      // console.log('ProductSelection: phaseChoiceSelected() phaseIndex =',
      // phaseIndex); // eslint-disable-line no-console

      if (!Array.isArray(this.phases)
        || !this.phases[phaseIndex].hasOwnProperty('choices')) return false;

      // console.log('ProductSelection: phaseChoiceSelected() this.phases[phaseIndex].choices =',
      // this.phases[phaseIndex].choices); // eslint-disable-line no-console

      const choices = Object.values(this.phases[phaseIndex].choices);
      // console.log('ProductSelection: phaseChoiceSelected() choices =',
      // choices); // eslint-disable-line no-console

      // Non ci sono scelte perciò è completa
      if (Array.isArray(choices) && choices.length === 0) return true;

      // Ritorna true se almeno una scelta è selezionata
      return Array.isArray(choices)
        && choices.length > 0
        && choices.some((c) => this.isChoiceSelected(phaseIndex, c.index));
    },

    updated() {
      this.$emit('updated', true);
    },

    isChoiceSelected(phaseIndex, choiceIndex) {
      // console.log(`ProductSelection: isChoiceSelected(${phaseIndex}, ${choiceIndex}) =`,
      // eslint-disable-next-line max-len
      //   this.selectedChoices.includes(`${phaseIndex}-${choiceIndex}`)); // eslint-disable-line no-console
      return this.selectedChoices.includes(`${phaseIndex}-${choiceIndex}`);
    },

    async updateSelectedProducts() {
      console.log('ProductSelection: updateSelectedProducts()'); // eslint-disable-line no-console

      const selectedProducts = [];

      this.selectedPhases.forEach((phase) => {
        const phaseIndex = this.phases.findIndex((p) => p.id === phase.id);

        if (phase.hasOwnProperty('choices') && Object.keys(phase.choices).length > 0) {
          for (const choice of Object.values(phase.choices)) {
            // eslint-disable-next-line no-continue
            if (!this.isChoiceSelected(phaseIndex, choice.index)) continue;

            const choiceProducts = this.choiceProducts(choice);
            if (Array.isArray(choiceProducts) && choiceProducts.length > 0) {
              selectedProducts.push(...choiceProducts);
              break;
            }
          }
        }
      });

      console.log('ProductSelection: updateSelectedProducts() selectedProducts =', selectedProducts.map((sp) => sp.id)); // eslint-disable-line no-console

      // Rimuove dall'elenco attuale i prodotti non più selezionati
      this.$set(
        this.quotation,
        'selectedProducts',
        this.quotation.selectedProducts.filter(
          (sp) => selectedProducts.find((p) => p.id === sp.id),
        ),
      );

      // Aggiunge all'elenco attuale i prodotti mancanti

      for (const selectedProduct of selectedProducts) {
        // eslint-disable-next-line no-await-in-loop
        const product = await this.$store.dispatch('products/getById', selectedProduct.id);

        const exists = this.quotation.selectedProducts.find((p) => p.id === product.id);

        if (!exists) {
          this.quotation.selectedProducts.push(product);
        }
      }
    },

    onSelectChoice(phaseId, choiceIndex, update = true) {
      console.log('ProductSelection: onSelectChoice()'); // eslint-disable-line no-console
      console.log('ProductSelection: onSelectChoice() phaseId =', phaseId); // eslint-disable-line no-console
      console.log('ProductSelection: onSelectChoice() choiceIndex =', choiceIndex); // eslint-disable-line no-console

      // Trova l'index della fase selezionata
      const phaseIndex = this.phases.findIndex((p) => p.id === phaseId);
      console.log('ProductSelection: onSelectChoice() phaseIndex =', phaseIndex); // eslint-disable-line no-console

      const choiceString = `${phaseIndex}-${choiceIndex}`;

      // Elimina tutti quelli della stessa fase
      this.selectedChoices = this.selectedChoices.filter((sc) => !sc.startsWith(`${phaseIndex}-`));

      if (!this.selectedChoices.includes(choiceString)) {
        this.selectedChoices.push(`${phaseIndex}-${choiceIndex}`);
      }

      if (update) this.updateSelectedProducts();
    },

    onUnselectChoice(phaseId, choiceIndex) {
      console.log('ProductSelection: onUnselectChoice()'); // eslint-disable-line no-console
      console.log('ProductSelection: onUnselectChoice() phaseId =', phaseId); // eslint-disable-line no-console
      console.log('ProductSelection: onUnselectChoice() choice =', choiceIndex); // eslint-disable-line no-console

      // Trova l'index della fase selezionata
      const phaseIndex = this.phases.findIndex((p) => p.id === phaseId);
      console.log('ProductSelection: onUnselectChoice() phaseIndex =', phaseIndex); // eslint-disable-line no-console

      this.selectedChoices = this.selectedChoices.filter((sc) => sc !== `${phaseIndex}-${choiceIndex}`);

      this.updateSelectedProducts();
    },

    onUpdate(product) {
      // eslint-disable-next-line no-console
      console.log('ProductSelection.onUpdate() product =', product);

      this.$set(
        this.quotation.selectedProducts,
        this.quotation.selectedProducts.findIndex((p) => p.id === product.id),
        product,
      );
    },
  },
};
</script>

<style>
</style>
