<template>
  <div class="view-preventivi">
    <Loading v-model="loading" />

    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-file-document-box-outline" />

    <Quotations @ready="loading = false" />

    <v-btn bottom dark fab large fixed right
      color="success"
      @click.stop="dialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <NewQuotationDialog v-model="dialog" />
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import PageHeader from '@/components/PageHeader';
import Quotations from '@/components/Quotations';
import NewQuotationDialog from '@/components/NewQuotationDialog';

export default {
  name: 'Preventivi',

  components: {
    Loading,
    PageHeader,
    Quotations,
    NewQuotationDialog,
  },

  data: () => ({
    loading: true,

    breadcrumbs: [
      {
        text: 'Preventivi',
        exact: true,
        to: { name: 'Preventivi' },
      },
    ],

    dialog: false,
  }),
};
</script>
