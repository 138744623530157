<template>
  <div class="component-notifications">
    <v-row no-gutters
      v-if="notifications && notifications.length > 0">
      <v-col>
        <v-card outlined>
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="12" class="d-flex flex-wrap mb-2 align-end justify-start">
                <!--  TODO: Aggiungere eventuali altri filtri per l'elenco notifiche -->

                <v-spacer></v-spacer>

                <v-switch color="orange" class="mt-0"
                  v-model="filters.archived"
                  :disabled="loading"
                  hide-details
                  label="Mostra archiviate"></v-switch>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table class="elevation-0"
            :headers="headers"
            :items="orderedNotifications"
            item-key="id"
            :items-per-page="20"
            :loading="loading"
            sort-by="name"
            :sort-desc="false"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 30, -1],
              disableItemsPerPage: loading,
              disablePagination: loading,
            }">
            <!-- Slot per la colonna "Titolo e contenuto" -->
            <template v-slot:item.message="{ item }">
              <strong>{{ item.title }}</strong><br>
              {{ item.body }}
            </template>

            <!-- Slot per la colonna "CTA" (mostra un check se presente) -->
            <template v-slot:item.cta="{ item }">
                <v-btn v-if="item.cta" icon @click="openUrl(item.ctaUrl)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon color="success" v-on="on">mdi-check</v-icon>
                    </template>
                    Apri l'URL della Call To Action
                  </v-tooltip>
                </v-btn>
              <v-btn v-else icon disabled>
                <v-icon color="grey">mdi-close</v-icon>
              </v-btn>
            </template>

            <!-- Slot per la colonna "Allegato" (mostra un check se presente) -->
            <template v-slot:item.attachment="{ item }">
              <v-btn v-if="item.attachment" icon @click="openUrl(item.attachmentUrl)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon color="success" v-on="on">mdi-check</v-icon>
                  </template>
                  Scarica l'allegato
                </v-tooltip>
              </v-btn>
              <v-btn v-else icon disabled>
                <v-icon color="grey">mdi-close</v-icon>
              </v-btn>
            </template>

            <!-- Slot per la colonna "Destinatari" -->
            <template v-slot:item.recipients="{ item }">
              <v-chip v-for="(recipient, index) in item.recipients"
                :key="`notification-${item.id}-recipient-${index}`"
                class="mr-1 my-1"
                color="primary"
                label
                outlined
                small>
                {{ userTypeText(recipient) }}
              </v-chip>
            </template>

            <!-- Slot per la colonna "Inserita il" -->
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt ? item.createdAt.toDate().toLocaleString(userLanguage) : 'Non disponibile' }}
            </template>

            <!-- Slot per la colonna "Inviata il" -->
            <template v-slot:item.sentAt="{ item }">
              <span :class="{ 'text--disabled': !item.sentAt }">
                {{ item.sentAt ? item.sentAt.toDate().toLocaleString(userLanguage) : 'Non inviata' }}
              </span>
            </template>

            <!-- Slot per la colonna "Stato" -->
            <template v-slot:item.status="{ item }">
              <v-chip :color="statusColor(item.status)"
                :dark="statusDark(item.status)"
                :outlined="statusOutlined(item.status)"
                label small>
                {{ statusText(item.status) }}
              </v-chip>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-row class="d-flex justify-end flex-nowrap">
                <v-icon small color="orange" class="mr-2"
                  v-if="item.archived">
                  mdi-archive
                </v-icon>

                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on"
                      :disabled="loading">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Modifica (se già inviata e consegnata)-->
                    <v-list-item
                      v-if="item.status === 'sent'"
                      @click="onEdit(item)">
                      <v-list-item-title>Modifica</v-list-item-title>
                    </v-list-item>

                    <!-- Annulla l'invio -->
                    <v-list-item
                      v-if="item.status === 'todo'"
                      @click="onCancelSend(item)">
                      <v-list-item-title>Annulla invio (torna a bozza)</v-list-item-title>
                    </v-list-item>

                    <!-- Invia di nuovo (se in errore) -->
                    <v-list-item
                      v-if="item.status === 'error'"
                      @click="onSend(item)">
                      <v-list-item-title>Invia di nuovo</v-list-item-title>
                    </v-list-item>

                    <!-- Archivia/Disarchivia -->
                    <v-list-item
                      v-if="item.status === 'archived' || item.status === 'sent'"
                      @click="onArchive(item)">
                      <v-list-item-title v-if="item.status === 'archived'">Disarchivia</v-list-item-title>
                      <v-list-item-title v-else>Archivia</v-list-item-title>
                    </v-list-item>

                    <!-- Elimina -->
                    <v-list-item
                      @click="onDelete(item)">
                      <v-list-item-title>Elimina</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn class="ml-2" fab x-small depressed color="secondary"
                  :disabled="loading || item.status !== 'draft'"
                  @click="onEdit(item)">
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-alert text prominent type="success" border="left"
      v-else-if="notifications">
      <v-row align="center">
        <v-col class="grow">
          Al momento non è presente nessuna notifica.
        </v-col>
        <v-col class="shrink">
          <v-btn color="success"
            @click="newDialog = true">Aggiungi una notifica</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <EditNotificationDialog v-model="newDialog" :selected="selected" />
    <DeleteNotificationDialog v-model="deleteDialog" :selected="selected" />
  </div>
</template>

<script>
import EditNotificationDialog from '@/components/notifications/EditNotificationDialog';
import DeleteNotificationDialog from '@/components/notifications/DeleteNotificationDialog';
import { UPDATE_NOTIFICATION } from '@/store/actions/notifications';

export default {
  name: 'Notifications',

  components: {
    EditNotificationDialog,
    DeleteNotificationDialog,
  },

  data: () => ({
    newDialog: false,
    deleteDialog: false,

    selected: null,

    headers: [
      {
        text: 'Titolo e contenuto',
        align: 'start',
        value: 'message',
        sortable: false,
      },
      {
        text: 'CTA',
        align: 'center',
        value: 'cta',
        sortable: false,
      },
      {
        text: 'Allegato',
        align: 'center',
        value: 'attachment',
        sortable: false,
      },
      {
        text: 'Destinatari',
        align: 'start',
        value: 'recipients',
        sortable: false,
      },
      {
        text: 'Inserita il',
        align: 'center',
        value: 'createdAt',
        sortable: false,
      },
      {
        text: 'Inviata il',
        align: 'center',
        value: 'sentAt',
        sortable: false,
      },
      {
        text: 'Stato',
        align: 'start',
        value: 'status',
        sortable: false,
      },
      {
        text: '',
        align: 'end',
        value: 'action',
        sortable: false,
      },
    ],

    filters: {
      archived: false,
    },

    loading: false,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  computed: {
    notifications() {
      return this.$store.getters['notifications/docs'];
    },

    filteredNotifications() {
      return this.notifications
        ? this.notifications.filter((notification) => {
          // Se la notifica è archiviata e i filtri non prevedono di mostrarle, la escludo
          if (notification.status === 'archived' && !this.filters.archived) {
            return false;
          }

          return true;
        })
        : [];
    },

    orderedNotifications() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.filteredNotifications.sort((a, b) => {
        const aSeconds = 'createdAt' in a && a.createdAt ? a.createdAt.seconds || 0 : 0;
        const bSeconds = 'createdAt' in b && b.createdAt ? b.createdAt.seconds || 0 : 0;

        return bSeconds - aSeconds;
      });
    },
  },

  watch: {
    notifications(val) {
      if (val && Array.isArray(val)) {
        this.$emit('ready');
      }
    },
  },

  created() {
    if (this.notifications && Array.isArray(this.notifications)) {
      this.$emit('ready');
    }
  },

  methods: {
    userTypeText(type) {
      switch (type) {
        case 'rivenditore':
          return 'Rivenditore';
        case 'venditore-esterno':
          return 'Venditore esterno della rivendita';
        case 'agente':
          return 'Agente';
        case 'imbianchino':
          return 'Imbianchino';
        case 'progettista':
          return 'Progettista';
        case 'keim':
          return 'Funzionario KEIM';

        default: // 'privato'
          return 'Privato';
      }
    },

    statusText(status) {
      switch (status) {
        case 'draft':
          return 'Bozza';
        case 'todo':
          return 'Da inviare';
        case 'sent':
          return 'Inviata';
        case 'archived':
          return 'Archiviata';
        case 'error':
          return 'Errore';
        case 'sending':
          return 'Invio in corso';

        default:
          return 'Sconosciuto';
      }
    },

    statusColor(status) {
      switch (status) {
        case 'draft':
          return 'grey';
        case 'sending':
        case 'todo':
          return 'orange';
        case 'sent':
          return 'green';
        case 'archived':
          return 'orange';
        case 'error':
          return 'red';

        default:
          return 'grey';
      }
    },

    statusDark(status) {
      switch (status) {
        case 'todo':
        case 'draft':
        case 'sent':
        case 'archived':
        case 'error':
        case 'sending':
          return true;

        default:
          return false;
      }
    },

    statusOutlined(status) {
      switch (status) {
        case 'archived':
          return true;

        default:
          return false;
      }
    },

    openUrl(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },

    onSend(notification) {
      // eslint-disable-next-line no-console
      console.log('onSend', notification);

      // Aggiorna lo stato della notifica a "todo"
      this.$store.dispatch(`notifications/${UPDATE_NOTIFICATION}`, {
        id: notification.id,
        status: 'todo',
      });
    },

    onArchive(notification) {
      // eslint-disable-next-line no-console
      console.log('onArchive', notification);

      // Aggiorna lo stato della notifica a "archived"
      this.$store.dispatch(`notifications/${UPDATE_NOTIFICATION}`, {
        id: notification.id,
        status: notification.status === 'archived' ? 'sent' : 'archived',
      });
    },

    onCancelSend(notification) {
      // eslint-disable-next-line no-console
      console.log('onCancelSend', notification);

      // Aggiorna lo stato della notifica a "draft"
      this.$store.dispatch(`notifications/${UPDATE_NOTIFICATION}`, {
        id: notification.id,
        status: 'draft',
      });
    },

    onDelete(notification) {
      // eslint-disable-next-line no-console
      console.log('onDelete', notification);

      this.selected = notification;
      this.deleteDialog = true;
    },

    onEdit(notification) {
      this.selected = notification;
      this.newDialog = true;
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
