<template>
  <div class="component-phase">
    <v-row v-if="!loading && phase">
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="phase.name"
                  :disabled="updating"
                  label="Nome della fase"
                  filled
                  autofocus
                  @keydown="updated()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="switch-continer">
                <v-switch color="info" class="mt-1"
                  v-model="phase.withProcedure"
                  :label="phase.withProcedure
                    ? 'La fase di lavorazione include una procedura'
                    : 'La fase di lavorazione non include una procedura'"
                  @change="updated()"></v-switch>
              </v-col>

              <v-col>
                <ckeditor
                  v-model="phase.procedure"
                  :disabled="!phase.withProcedure"
                  :editor="editor"
                  :config="editorConfig"
                  @input="updated()"></ckeditor>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title>
            Condizioni
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text>
            <v-alert
              text dense
              type="info"
              border="left">
              Questa fase di lavorazione sarà mostrata soltanto se almeno una delle
              seguenti condizioni verrà soddisfatta.<br>
              Una condizione è soddisfatta quanto tutti i tag del campo verde
              e nessuno di quello rosso saranno presenti nell'elenco dei tag ottenuti
              al termine del test.
            </v-alert>

            <v-row>
              <template v-for="(condition, index) of phase.conditions">
                <v-col cols="12" class="pb-0" :key="`condition-${index}`">
                  <h4 class="mb-2">Condizione {{ index + 1 }}</h4>

                  <v-autocomplete
                    background-color="#E8F5E9"
                    v-model="phase.conditions[index].include"
                    :items="tags"
                    item-text="name"
                    item-value="slug"
                    outlined dense
                    chips
                    small-chips
                    deletable-chips
                    placeholder="Linea di tag richiesti"
                    hint="Tutti i tag specificati dovranno essere presenti (AND)"
                    persistent-hint
                    multiple
                    @change="updated()"
                  ></v-autocomplete>

                  <v-autocomplete
                    background-color="#FFEBEE"
                    v-model="phase.conditions[index].exclude"
                    :items="tags"
                    item-text="name"
                    item-value="slug"
                    outlined dense
                    chips
                    small-chips
                    deletable-chips
                    placeholder="Linea di tag da escludere"
                    hint="Nessuno dei tag specificati dovrà essere presente (OR)"
                    persistent-hint
                    multiple
                    @change="updated()"
                  ></v-autocomplete>
                </v-col>
              </template>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text color="primary"
              :disabled="updating || isAddLineDisabled"
              :loading="updating"
              @click="onAddLine()">
              Aggiungi condizione
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>
            Prodotti
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text>
            <v-alert
              text dense
              type="info"
              border="left">
              Quando questa fase di lavorazione sarà richiesta e mostrata,
              sarà data la possibilità all'utente di scegliere tra
              1 o più prodotti (o gruppi di prodotti).
            </v-alert>

            <v-row v-if="phase">
              <v-col cols="12" v-for="(choice, index) of phase.choices"
                :key="`choice-${index}`">

                <PhaseChoice
                  v-model="phase.choices[index]"
                  :count="index + 1"
                  @change="updated()"
                  @delete="onDelete(index)" />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text color="primary"
              :disabled="updating || isAddChoiceDisabled"
              :loading="updating"
              @click="onAddChoice()">
              Aggiungi scelta
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unreachable */
/* eslint-disable max-len */

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import List from '@ckeditor/ckeditor5-list/src/list';
import PhaseChoice from '@/components/PhaseChoice';

export default {
  name: 'Phase',

  components: {
    PhaseChoice,
  },

  props: {
    value: {
      type: Object,
    },
    updating: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    editor: ClassicEditor,
    editorConfig: {
      placeholder: 'Inserisci qui il testo',
      language: 'it',
      plugins: [
        EssentialsPlugin,
        BoldPlugin,
        ItalicPlugin,
        Underline,
        LinkPlugin,
        ParagraphPlugin,
        Alignment,
        Font,
        RemoveFormat,
        List,
      ],
      toolbar: {
        items: [
          'bold', 'italic', 'underline',
          '|',
          'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
          '|',
          'alignment:left', 'alignment:right', 'alignment:center',
          '|',
          'link', 'numberedList', 'bulletedList',
          '|',
          'undo', 'redo', 'removeFormat',
        ],
        shouldNotGroupWhenFull: true,
      },
    },

    id: null,
    phase: {},

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
    userLocale: 'it-IT', // TODO: Dovrà essere impostato nel profilo utente
  }),

  // created() {
  //   console.log('Phase: created()'); // eslint-disable-line no-console
  //   this.phase = this.value;
  // },

  watch: {
    value(newValue) {
      console.log('Phase: watch.value()'); // eslint-disable-line no-console
      if (!newValue) {
        console.log('Phase: watch.value(null)'); // eslint-disable-line no-console
        return;
      }
      if ('id' in this.phase) {
        console.log('Phase: this.phase !== null'); // eslint-disable-line no-console
        return;
      }

      console.log('Phase: newValue =', newValue); // eslint-disable-line no-console

      // eslint-disable-next-line prefer-const
      let { conditions, choices, ...phase } = newValue;

      if (!conditions) conditions = [];
      if (!choices) choices = [];

      if (conditions.constructor === Object) {
        conditions = Object.values(conditions);
      }
      if (choices.constructor === Object) {
        choices = Object.values(choices);
      }

      if (conditions.length === 0) {
        conditions.push({
          include: [],
          exclude: [],
        });
      }
      // if (choices.length === 0) {
      //   choices.push({
      //   });
      // }

      this.phase = {
        ...phase,
        conditions: conditions.map((c) => {
          // Converte la vecchia versione delle condizioni senza esclusioni
          if (Array.isArray(c)) return { include: c, exclude: [] };
          return c;
        }),
        choices,
      };
    },

    phase(newValue) {
      console.log('Phase: watch.phase()'); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
    tags() {
      return this.$store.getters['tags/docs'];
    },

    isAddLineDisabled() {
      if (!this.phase || !Array.isArray(this.phase.conditions)) return true;
      return this.phase.conditions[this.phase.conditions.length - 1].include.length === 0
        && this.phase.conditions[this.phase.conditions.length - 1].exclude.length === 0;
    },

    isAddChoiceDisabled() {
      return false;
      // if (!this.phase || !Array.isArray(this.phase.choices)) return true;
      // return this.phase.choices[this.phase.choices.length - 1].length === 0;
    },
  },

  methods: {
    updated() {
      console.log('Phase: updated()'); // eslint-disable-line no-console
      this.$emit('updated');
    },

    onDelete(index) {
      console.log('Phase: onDelete() index =', index); // eslint-disable-line no-console

      // console.log('Phase: onDelete() this.phase.choices[index].procedure =', this.phase.choices[index].procedure); // eslint-disable-line no-console

      const choices = [...this.phase.choices];

      // console.log('Phase: onDelete() newChoices[index].procedure =', newChoices[index].procedure); // eslint-disable-line no-console

      // console.log('Phase: onDelete() this.phase.choices =', this.phase.choices); // eslint-disable-line no-console

      // newChoices.splice(index, 1);

      // console.log('Phase: onDelete() newChoices =', newChoices); // eslint-disable-line no-console

      this.$set(this.phase, 'choices', []);

      // eslint-disable-next-line no-restricted-syntax
      for (const pIndex in choices) {
        if (Object.hasOwnProperty.call(choices, pIndex)) {
          console.log('Phase: onDelete() pIndex =', pIndex); // eslint-disable-line no-console
          if (Number(pIndex) !== Number(index)) {
            console.log('Phase: onDelete() push choices[pIndex] =', choices[pIndex]); // eslint-disable-line no-console
            this.phase.choices.push(choices[pIndex]);
          }
        }
      }

      this.updated();
    },

    onAddLine() {
      this.phase.conditions.push({
        include: [],
        exclude: [],
      });
    },

    onAddChoice() {
      if (!this.phase || 'choices' in this.phase === false) return;

      this.phase.choices.push({});
    },
  },
};
</script>

<style scoped>
.switch-continer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}
</style>
