<template>
  <div class="component-productdetailsdialog">
    <v-dialog v-model="dialog" max-width="800" scrollable>
      <v-card v-if="product">
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" md="5" lg="4" xl="3">
              <v-img v-if="hasImage" :src="product.imageUrl" aspect-ratio="1" class="mb-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-img v-else src="../assets/product-placeholder.png" aspect-ratio="1" class="mb-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <v-select class="mb-4 mt-8"
                v-model="packaging"
                :items="packagingItems"
                :label="packagingLabel"
                :hint="!['mq', 'pz'].includes(product.um)
                  ? 'Prezzo e incidenza per queste confezioni' : ''"
                :persistent-hint="!['mq', 'pz'].includes(product.um)"
                :readonly="packagingItems.length === 1"
                outlined
                dense
              ></v-select>

              <v-text-field v-if="userPricelist"
                class="mt-0"
                v-model="price"
                readonly
                label="Prezzo"
                filled
                outlined
                reverse
                dense
              ></v-text-field>

              <v-text-field
                v-if="!['mq', 'pz'].includes(product.um)
                  && (!product.hasOwnProperty('standalone') || product.standalone === true)"
                v-model="consumption"
                readonly
                label="Consumo"
                filled
                outlined
                reverse
                dense></v-text-field>

              <v-text-field v-if="userPricelist
                && !['mq', 'pz'].includes(product.um)
                && (!product.hasOwnProperty('standalone') || product.standalone === true)"
                class="mt-0"
                v-model="incidence"
                readonly
                label="Incidenza"
                filled
                outlined
                reverse
                dense
              ></v-text-field>

              <v-menu bottom left
                :disabled="!hasAttachments">
                <template v-slot:activator="{ on: menu }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn text outlined class="ml-1" small block
                        v-on="{ ...tooltip, ...menu }"
                        :disabled="!hasAttachments">
                        <v-icon left>mdi-download</v-icon>
                        {{ hasAttachments ? 'Scarica allegati' : 'Nessun allegato' }}
                      </v-btn>
                    </template>
                    <span>{{ attachmentsTooltip }}</span>
                  </v-tooltip>
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="(attachment, index) in product.attachments"
                    :key="`attachment-${index}`"
                    @click="onDownloadAttachment(attachment)">
                    <v-list-item-icon class="mr-2">
                      <v-icon small>mdi-file-download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ getAttachmentDisplayName(attachment, index) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>

            <v-col cols="12" md="7" lg="8" xl="9">
              <span class="title" v-text="product.name[userLanguage]"></span>

              <!-- ProductPhases :product="product" / -->

              <div class="mt-3 pb-3" v-html="product.details[userLanguage]"></div>

              <template v-if="product.consumptionType === 'variable'">
                <v-select
                  v-model="consumptionRange"
                  :items="product.consumptionranges.map((cr, index) => ({
                    text: cr.name,
                    value: index,
                  }))"
                  label="Fascia di consumo"
                  outlined
                  dense
                  @change="onUpdate()"
                ></v-select>
              </template>

              <template v-if="['colors', 'width'].includes(product.priceType)">
                <v-select
                  v-model="priceRange"
                  :items="product.priceranges.map((pr, index) => ({
                    text: pr.name,
                    value: index,
                  }))"
                  label="Fascia di prezzo"
                  outlined
                  dense
                  @change="color = null; colorlist = null; onUpdate()"
                ></v-select>

                <v-list two-line class="pt-0 mb-5"
                  v-if="product.priceType === 'colors'">
                  <v-list-item class="px-1"
                    v-if="color"
                    @click="onSelectColor">
                    <v-list-item-avatar class="my-1">
                      <v-sheet
                        tile
                        height="40"
                        width="60"
                        :color="`rgba(${color.r}, ${color.g}, ${color.b}, 1)`">
                      </v-sheet>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title v-html="color.code"></v-list-item-title>
                      <v-list-item-subtitle v-html="colorlist.name"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="px-1"
                    v-else
                    @click="onSelectColor">
                    <v-list-item-avatar class="my-1">
                      <v-icon class="grey lighten-2 white--text"
                      >mdi-eyedropper-variant</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="py-0">
                      <v-list-item-title>Scegli il colore</v-list-item-title>
                      <!-- v-list-item-subtitle>(opzionale)</v-list-item-subtitle -->
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>

              <v-alert
                v-if="!isSelected
                  && (product.hasOwnProperty('combined') && product.combined === true)
                  && (product.hasOwnProperty('standalone') && product.standalone === false)"
                text dense
                type="info"
                border="left">
                Utilizzabile solamente se combinato con altri prodotti.
                Verrà selezionato automaticamente quando necessario.
              </v-alert>

              <v-alert
                v-if="isSelected && isCombined"
                text dense
                type="orange"
                border="left">
                Prodotto selezionato automaticamente perché richiesto da un altro
                prodotto in elenco.
              </v-alert>

              <v-alert
                v-if="requiredProducts.length === 1"
                text dense
                type="info"
                border="left">
                Va utilizzato combinato con un altro prodotto ({{ requiredProductsNames }})
                che verrà selezionato automaticamente.
              </v-alert>

              <v-alert
                v-if="requiredProducts.length > 1"
                text dense
                type="info"
                border="left">
                Va utilizzato combinato con altri prodotti ({{ requiredProductsNames }})
                che verranno selezionati automaticamente.
              </v-alert>

              <v-alert
                v-if="(product.hasOwnProperty('combined') && product.combined === true)"
                text dense
                type="info"
                border="left">
                Per l'utilizzo combinato il consumo e l'incidenza verranno calcolati
                in fase di preventivo.
              </v-alert>

              <v-alert
                v-if="!isSelected
                  && product.hasOwnProperty('combined') && !!product.combined
                  && (!product.hasOwnProperty('standalone') || !!product.standalone)"
                text dense
                type="info"
                border="left">
                Utilizzabile sia da solo che combinato con altri prodotti.
                In quel caso il consumo e l'incidenza verranno calcolati in fase di preventivo.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer v-if="!select"></v-spacer>

          <v-btn color="primary" text
            :disabled="loading"
            @click="onCancel">
            Chiudi
          </v-btn>

          <v-spacer v-if="select"></v-spacer>

          <v-btn v-if="select && quotation
            && (!product.hasOwnProperty('standalone') || product.standalone === true)"
            color="success" text
            :disabled="loading"
            :loading="loading"
            @click="onSelect()">
            Aggiungi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="colorDialog" max-width="700">
      <v-card v-if="product">
        <v-card-title>Seleziona il colore</v-card-title>
        <v-card-text>
          <p>Scegliendo un colore verrà selezionata automaticamente la fascia di prezzo
            corrispondente.</p>

          <ColorSelector
            :product="product"
            @onAnswer="onColor" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable quote-props */

// import ProductPhases from '@/components/ProductPhases';
import ColorSelector from '@/components/ColorSelector';

export default {
  name: 'ProductDetailsDialog',

  props: {
    value: {
      type: Boolean,
    },
    product: {
      type: Object,
    },
    quotation: {
      type: Object,
    },

    // Possibilità di selezionare il prodotto
    select: {
      type: Boolean,
      default: true,
    },
    // Possibilità di deselezionare il prodotto
    unselect: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    // ProductPhases,
    ColorSelector,
  },

  data: () => ({
    dialog: false,
    colorDialog: false,
    loading: false,

    packaging: 0,
    consumptionRange: null,
    priceRange: null,
    colorlist: null,
    color: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  watch: {
    value(newValue) {
      console.log('ProductDetailsDialog: watch.value() newValue =', newValue); // eslint-disable-line no-console

      if (newValue) {
        // (Ri)seleziona il lattaggio più grande
        this.packaging = 0;

        // Se il consumo è variabile,
        // seleziona la prima fascia di consumo.
        if (this.product.consumptionType === 'variable'
          && Array.isArray(this.product.consumptionranges)
          && this.product.consumptionranges.length > 0) {
          // eslint-disable-next-line no-prototype-builtins
          this.consumptionRange = !this.product.hasOwnProperty('consumptionRange') || this.product.consumptionRange === null
            ? 0 : this.product.consumptionRange;
        }

        // Se il prezzo è variabile,
        // seleziona la prima fascia di prezzo.
        if (['colors', 'width'].includes(this.product.priceType)
          && Array.isArray(this.product.priceranges)
          && this.product.priceranges.length > 0) {
          // eslint-disable-next-line no-prototype-builtins
          this.priceRange = !this.product.hasOwnProperty('priceRange') || this.product.priceRange === null
            ? 0 : this.product.priceRange;
        }

        this.color = this.product.color || null;
        this.colorlist = this.product.colorlist || null;
      }

      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    userPricelist() {
      return this.user && this.user.pricelist ? this.user.pricelist : null;
    },

    selectedProducts() {
      return this.quotation && 'selectedProducts' in this.quotation
        && Array.isArray(this.quotation.selectedProducts)
        ? this.quotation.selectedProducts
        : [];
    },

    isSelected() {
      if (!this.product
        || !this.quotation || !Array.isArray(this.quotation.selectedProducts)) return false;
      return this.quotation.selectedProducts.find((p) => p.id === this.product.id);
    },

    isCombined() {
      // Se il prodotto non può essere combinato si ferma subito (false)
      if ('combined' in this.product === false || !this.product.combined) return false;

      // Il prodotto è combinabile

      // Se non può essere utilizzato da solo, si ferma subito (true).
      if ('standalone' in this.product && this.product.standalone === false) return true;

      // Verifica se un prodotto tra quelli scelti lo richiede come combinato

      // Conta il numero di prodotti richiesti di questo tipo
      const reqCount = this.allRequiredProducts.filter((rp) => rp.id === this.product.id).length;

      // eslint-disable-next-line no-console
      console.log('isCombined() reqCount =', reqCount);

      // Nessun prodotto richiesto di questo tipo
      if (reqCount === 0) return false;

      // È richiesto un prodotto di questo tipo per l'uso combinato

      // Riserva il primo prodotto di questo tipo per l'uso combinato

      // Calcola la posizione in elenco di questo prodotto
      const spList = this.selectedProducts.filter((rp) => rp.id === this.product.id);
      const spIndex = spList.findIndex((rp) => rp.sId === this.product.sId);

      // Ritorna true solo se è il primo
      return Boolean(spIndex === 0);
    },

    hasImage() {
      return this.product
        // eslint-disable-next-line no-prototype-builtins
        && this.product.hasOwnProperty('imageUrl') && this.product.imageUrl !== null;
    },

    allRequiredProducts() {
      if (!Array.isArray(this.quotation.selectedProducts)) return [];

      const result = [];
      this.quotation.selectedProducts.forEach((product) => {
        if (product.consumptionType === 'variable'
          && Array.isArray(product.consumptionranges[product.consumptionRange || 0]
            .requiredProducts)
          && product.consumptionranges[product.consumptionRange || 0].requiredProducts.length > 0) {
          result.push(
            ...product.consumptionranges[product.consumptionRange || 0].requiredProducts
              .map((rp) => rp.product),
          );
        }
      });

      return result;
    },

    requiredProducts() {
      return this.product
        && this.product.consumptionType === 'variable'
        && Array.isArray(
          this.product.consumptionranges[this.consumptionRange].requiredProducts,
        )
        ? this.product.consumptionranges[this.consumptionRange].requiredProducts
        : [];
    },

    requiredProductsNames() {
      return this.requiredProducts.map((rp) => rp.product.name[this.userLanguage]).join(', ');
    },

    consumption() {
      if (!this.product) return '';
      // if (this.isCombined) return '';

      if (this.product.consumptionType === 'variable'
        && this.consumptionRange !== null) {
        return `${new Intl.NumberFormat('it-IT').format(this.product.consumptionranges[this.consumptionRange].consumption)} ${this.product.um}/m²`;
      }

      return `${new Intl.NumberFormat('it-IT').format(this.product.consumption)} ${this.product.um}/m²`;
    },

    price() {
      if (!this.product) return '';

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      const pr = ['colors', 'width'].includes(this.product.priceType) && this.priceRange !== null
        ? this.priceRange
        : 0;

      return `${formatter.format(this.product.priceranges[pr].prices[this.packaging][this.userPricelist])}`.replace('€', `€/${this.product.um}`);
    },

    incidence() {
      // if (this.isCombined) return '';

      const floatPrice = parseFloat(`${this.price}`.replace(',', '.').replace(/[^0-9.]+/g, ''));
      const floatConsumption = parseFloat(`${this.consumption}`.replace(',', '.').replace(/[^0-9.]+/g, ''));
      const result = floatPrice * floatConsumption;

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      return `${formatter.format(result)}/m²`;
    },

    packagingItems() {
      if (!this.product) return [];

      // eslint-disable-next-line no-prototype-builtins
      // const pr = !this.product.hasOwnProperty('priceRange') || this.product.priceRange === null
      //   ? 0 : this.product.priceRange;

      const pr = ['colors', 'width'].includes(this.product.priceType) && this.priceRange !== null
        ? this.priceRange
        : 0;

      return this.product.priceranges[pr].prices.map((p, index) => ({
        text: `${p.packaging} ${this.umLongPlural}`,
        value: index,
      }));
    },

    umLong() {
      if (!this.product) return '';

      switch (this.product.um) {
        case 'kg': return 'kilogrammo';
        case 'l': return 'litro';
        case 'mq': return 'mq';
        case 'pz': return 'pezzo';
        default: return '';
      }
    },

    umLongPlural() {
      if (!this.product) return '';

      switch (this.product.um) {
        case 'kg': return 'kilogrammi';
        case 'l': return 'litri';
        case 'mq': return 'mq';
        case 'pz': return 'pezzi';
        default: return '';
      }
    },

    packagingLabel() {
      if (this.product.um === 'mq') return 'Superficie';
      if (this.product.um === 'pz') return 'Quantità';
      return 'Confezione';
    },

    hasAttachments() {
      return this.product?.attachments?.length > 0;
    },

    attachmentsTooltip() {
      const count = this.product?.attachments?.length || 0;
      return count === 1
        ? '1 allegato disponibile'
        : `${count} allegati disponibili`;
    },
  },

  methods: {
    onCancel() {
      this.dialog = false;
    },

    onSelect() {
      this.loading = true;

      this.$emit('select', {
        ...this.product,
        consumptionRange: this.consumptionRange,
        priceRange: this.priceRange,
        colorlist: this.colorlist,
        color: this.color,
      });

      this.dialog = false;

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    onUnselect() {
      this.loading = true;

      this.$emit('unselect', this.product);

      this.dialog = false;

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    onUpdate() {
      console.log('ProductDetailsDialog: onUpdate()'); // eslint-disable-line no-console

      this.loading = true;

      // Restituisce il prodotto con le eventuali modifiche di fasce di prezzo o consumo
      this.$emit('update', {
        ...this.product,
        consumptionRange: this.consumptionRange,
        priceRange: this.priceRange,
        colorlist: this.colorlist || null,
        color: this.color || null,
      });

      // this.dialog = false;

      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    onSelectColor() {
      this.colorDialog = true;
    },

    onColor(colorInfo) {
      console.log('ProductDetailsDialog: onColor() colorInfo =', colorInfo); // eslint-disable-line no-console

      // eslint-disable-next-line no-unused-vars
      const { colorlist, color, rangeIndex } = colorInfo;

      this.colorlist = colorlist;
      this.color = color;
      this.priceRange = rangeIndex;

      this.colorDialog = false;

      this.onUpdate();
    },

    onDownload() {
      // eslint-disable-next-line no-console
      console.log('ProductDetailsDialog: onDownload()');

      // eslint-disable-next-line no-restricted-globals
      if ('url' in this.product && this.product.url) {
        window.open(this.product.url.replace('www.keim.com', 'www.keim.it'), '_blank');
      }
    },

    onDownloadAttachment(attachment) {
      if (attachment?.url) {
        window.open(attachment.url, '_blank');
      }
    },

    getAttachmentDisplayName(attachment, index) {
      if (attachment.name?.trim()) {
        return attachment.name;
      }

      // Mappa delle categorie
      const categoryMap = {
        'scheda-tecnica': 'Scheda tecnica',
        'cartella-colore': 'Cartella colore',
        'certificato': 'Certificato',
        'opuscolo': 'Opuscolo',
        'epd': 'Dichiarazione ambientale di prodotto (EPD)',
        'dichiarazione-prestazione': 'Dichiarazione di prestazione',
        'scheda-sostenibilità': 'Scheda tecnica di sostenibilità',
        'scheda-sicurezza': 'Scheda di sicurezza',
      };

      if (attachment.category === 'altro' && attachment.customCategory?.trim()) {
        return attachment.customCategory;
      }

      if (attachment.category && categoryMap[attachment.category]) {
        return categoryMap[attachment.category];
      }

      return `Allegato ${index + 1}`;
    },
  },
};
</script>

<style scoped>
.v-list-item.v-list-item--link.theme--light {
  min-height: auto;
}
</style>
