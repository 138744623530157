<template>
  <div class="component-newuserdialog">
    <v-dialog v-model="dialog" max-width="800" scrollable>
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>
        <v-card>
          <v-card-title class="headline">Aggiungi un utente</v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-alert
              type="error" dismissible v-model="alert" class="mb-4"
              transition="scale-transition">
              {{ error }}
            </v-alert>

            <v-alert
              v-if="!user.pricelist"
              type="warning" class="mb-4"
              transition="scale-transition">
              <strong>Non è stato selezionato un listino.</strong><br>
              L'utente non potrà visualizzare i prezzi dei prodotti.
            </v-alert>

            <v-row>
              <v-col cols="12" md="6" lg="6" xl="6">
                <UserImage v-model="user" />
              </v-col>

              <v-col cols="12" md="6" lg="6" xl="6">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="user.type"
                      :disabled="submitting"
                      :items="userTypes"
                      label="Tipologia"
                      clearable
                      hide-details
                      filled
                      required
                      :rules="[(v) => !!v || 'La tipologia di utente è richiesta']"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                      v-model="user.pricelist"
                      :disabled="submitting"
                      :items="pricelists"
                      item-text="name"
                      item-value="id"
                      label="Listino"
                      placeholder="Nessun listino selezionato"
                      clearable
                      hide-details
                      filled
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-switch color="error" class="mt-0"
                      v-model="user.isSuper"
                      :label="user.isSuper ? 'È un Super Utente' : 'NON è un Super Utente'"
                      :hint="user.isSuper
                        ? 'Vede tutti i preventivi e ha accesso completo alla gestione.'
                        : 'Vede soltanto i suoi preventivi e non ha accesso alla gestione.'"
                      persistent-hint></v-switch>
                  </v-col>

                  <v-col cols="12">
                    <v-switch color="info" class="mt-0"
                      v-model="user.showLogo"
                      :disabled="submitting || !hasImage"
                      :label="user.showLogo
                        ? 'Usa l\'immagine nei preventivi'
                        : 'Non usare l\'immagine nei preventivi'"
                      :hint="user.showLogo
                        ? 'L\'immagine verrà mostrata nei preventivi assieme ai dati completi.'
                        : 'L\'immagine NON verrà mostrata nei preventivi.'"
                      persistent-hint></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" v-if="hasCompany">
                <v-text-field type="text"
                  v-model="user.company"
                  :disabled="submitting"
                  label="Ragione sociale"
                  :rules="[v => !!v || 'La ragione sociale è richiesta']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="text"
                  v-model="user.firstName"
                  :disabled="submitting"
                  label="Nome"
                  :rules="[v => !!v || 'Il nome dell\'utente è richiesto']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="text"
                  v-model="user.lastName"
                  :disabled="submitting"
                  label="Cognome"
                  :rules="[v => !!v || 'Il cognome dell\'utente è richiesto']"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="user.address"
                  :disabled="submitting"
                  label="Indirizzo"
                  dense
                  rows="1"
                  no-resize auto-grow
                ></v-textarea>
              </v-col>

              <v-col cols="2">
                <v-text-field type="text" dense
                  v-model="user.postalCode"
                  :disabled="submitting"
                  label="CAP"
                ></v-text-field>
              </v-col>

              <v-col cols="5">
                <v-text-field type="text" dense
                  v-model="user.city"
                  :disabled="submitting"
                  label="Città"
                ></v-text-field>
              </v-col>

              <v-col cols="5">
                <v-autocomplete dense
                  v-model="user.province"
                  :disabled="submitting"
                  :items="provinces"
                  clearable
                  label="Provincia"
                  :rules="[v => !!v || 'La provincia è richiesta']"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field ref="phone"
                  v-model="user.phone"
                  :disabled="submitting"
                  label="Telefono"
                  name="phone"
                  prepend-icon="mdi-phone"
                  type="tel"
                  :rules="[v => !!v || 'Il telefono è richiesto']"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field ref="phone1"
                    v-model="user.phone1"
                    :disabled="submitting"
                    label="Telefono (opzionale)"
                    name="phone1"
                    prepend-icon="mdi-phone"
                    type="tel"
                  />
              </v-col>

              <v-col cols="12">
                <v-text-field ref="email"
                    v-model="user.email"
                    :disabled="submitting"
                    label="Email"
                    name="email"
                    prepend-icon="mdi-email"
                    type="email"
                    :rules="[v => !!v || 'L\'indirizzo email è richiesto']"
                  />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field ref="password"
                  v-model="user.password"
                  :disabled="submitting"
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  :rules="[v => !!v || 'La password è richiesta']"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field ref="passwordConfirm"
                  v-model="passwordConfirm"
                  :disabled="submitting"
                  id="passwordConfirm"
                  label="Conferma password"
                  name="passwordConfirm"
                  prepend-icon="mdi-lock"
                  type="password"
                  :rules="[v => !!v || 'La conferma della password è richiesta']"
                />
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="user.checkbox1"
                  color="success"
                  required
                  :rules="[(v) => !!v || 'L\'utente deve accettare l\'Informativa sul Trattamento dei Dati Personali']"
                >
                  <template v-slot:label>
                    <div>
                      L'utente ha letto e accettato le condizioni dell'Informativa
                        sul Trattamento dei Dati Personali
                        (obbligatorio)
                    </div>
                  </template>
                </v-checkbox>
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="user.checkbox2"
                  label="L'utente acconsente all'invio di comunicazioni commerciali riguardanti nuovi prodotti o novità sui prodotti già esistenti (facoltativo)"
                  color="success"
                ></v-checkbox>
              </v-col>
            </v-row>

            <p>Premendo il pulsante <strong>Aggiungi</strong>, l'utente verrà
              aggiunto all'elenco.</p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="submitting"
              @click="onCancel">
              Annulla
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn type="submit"
              color="success"
              :loading="submitting"
              :disabled="!valid || submitting"
              @click="onAdd">
              Aggiungi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { ADD_USER } from '@/store/actions/users';
import UserImage from '@/components/UserImage';
import { getProvince } from '@/utils/italy';

export default {
  name: 'NewUserDialog',

  components: {
    UserImage,
  },

  props: ['value'],

  data: () => ({
    dialog: false,

    submitting: false,
    valid: true,
    alert: false,
    // success: false,
    error: '',

    userTypes: [
      { value: 'privato', text: 'Privato' },
      { value: 'rivenditore', text: 'Rivenditore' },
      { value: 'venditore-esterno', text: 'Venditore esterno della rivendita' },
      { value: 'agente', text: 'Agente' },
      { value: 'imbianchino', text: 'Imbianchino' },
      { value: 'progettista', text: 'Progettista' },
      { value: 'keim', text: 'Funzionario KEIM' },
    ],

    user: {
      type: null,
      company: '',
      firstName: '',
      lastName: '',
      address: '',
      postalCode: '',
      city: '',
      province: null,
      phone: '',
      phone1: '',
      email: '',
      password: '',
      pricelist: '',
      isSuper: false,
      showLogo: false,
      checkbox1: false,
      checkbox2: false,
    },

    passwordConfirm: '',
  }),

  watch: {
    value(newValue) {
      const formRef = this.$refs.form;

      // Reset del modulo quando il dialogo viene aperto
      if (newValue === true) {
        this.user.type = null;
        this.user.company = '';
        this.user.firstName = '';
        this.user.lastName = '';
        this.user.address = '';
        this.user.postalCode = '';
        this.user.city = '';
        this.user.province = null;
        this.user.phone = '';
        this.user.phone1 = '';
        this.user.email = '';
        this.user.password = '';
        this.confirmPassword = '';
        this.user.pricelist = '';
        this.user.isSuper = false;
        this.user.showLogo = false;
        this.user.checkbox1 = false;
        this.user.checkbox2 = false;

        this.alert = false;
        this.error = '';

        if (formRef) {
          formRef.resetValidation();
        }
      }
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    hasCompany() {
      return ['rivenditore', 'venditore-esterno', 'agente', 'imbianchino', 'progettista', 'keim']
        .includes(this.user.type);
    },

    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    hasImage() {
      return 'imageUrl' in this.user && this.user.imageUrl !== null;
    },

    provinces: () => getProvince(),
  },

  methods: {
    onCancel() {
      this.dialog = false;
    },

    async onAdd() {
      const formRef = this.$refs.form;

      if (!formRef.validate()) return;

      this.submitting = true;
      this.alert = false;
      // this.success = false;
      this.error = '';

      if (this.user.password !== this.passwordConfirm) {
        this.error = 'Le password non corrispondono';
        this.alert = true;
        this.submitting = false;
        return;
      }

      try {
        await this.$store.dispatch(`users/${ADD_USER}`, this.user);

        this.submitting = false;
        this.dialog = false;
      } catch (error) {
        console.error(`${this.$options.name}: onAdd() error =`, error); // eslint-disable-line no-console

        this.submitting = false;

        switch (error) {
          case 'auth/invalid-email':
            this.error = "L'indirizzo email non è nel formato corretto";
            break;

          case 'auth/email-already-exists':
            this.error = "L'indirizzo email è già in uso";
            break;

          default:
            this.error = `Errore sconosciuto (${error})`;
            break;
        }

        this.alert = true;
      }
    },
  },
};
</script>
