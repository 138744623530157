<template>
  <div class="view-notifiche">
    <Loading v-model="loading" />

    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-bell" />

    <Notifications @ready="loading = false" />

    <v-btn bottom dark fab large fixed right
      color="success"
      @click.stop="dialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <EditNotificationDialog v-model="dialog" />
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import PageHeader from '@/components/PageHeader';
import Notifications from '@/components/notifications/Notifications';
import EditNotificationDialog from '@/components/notifications/EditNotificationDialog';

export default {
  name: 'Notifiche',

  components: {
    Loading,
    PageHeader,
    Notifications,
    EditNotificationDialog,
  },

  data: () => ({
    loading: true,

    breadcrumbs: [
      {
        text: 'Notifiche',
        exact: true,
        to: { name: 'Notifiche' },
      },
    ],

    dialog: false,
  }),
};
</script>
