<template>
  <div class="component-notification-attachment">
    <!-- Campo testuale per visualizzare il nome dell'allegato -->
    <v-text-field class="mt-0"
      v-model="notification.attachmentOriginalName"
      label="Nome allegato"
      hide-details
      readonly
      disabled
      :loading="updating">
      <template v-slot:append>
        <!-- Pulsante per caricare l'allegato -->
        <v-btn v-if="!hasAttachment"
          outlined small
          class="mb-1"
          color="success"
          :disabled="updating || !notification.attachment"
          @click="upload()">
          <v-icon left>mdi-file-upload</v-icon>
          Scegli...
        </v-btn>

        <!-- Pulsante per rimuovere l'allegato -->
        <v-btn v-else
          outlined small
          class="mb-1"
          color="error"
          :disabled="updating"
          @click="remove()">
          <v-icon left>mdi-delete</v-icon>
          Rimuovi
        </v-btn>
      </template>
    </v-text-field>

    <!-- Campo nascosto per caricare l'allegato -->
    <input type="file" id="fileInput" ref="fileInput"
      accept="*"
      @change="handleFileChange($event)">
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import { UPDATE_NOTIFICATION_ATTACHMENT, DELETE_NOTIFICATION_ATTACHMENT } from '@/store/actions/notifications';


export default {
  name: 'NotificationAttachment',

  props: ['value'],

  data: () => ({
    updating: false,
    notification: null,
  }),

  created() {
    this.notification = this.value;
  },

  watch: {
    value(newValue) {
      console.log('NotificationAttachment: watch -> value'); // eslint-disable-line no-console
      if (newValue && 'id' in newValue) {
        if (!this.notification) this.notification = newValue;
        else if (this.notification && 'id' in this.notification && this.notification.id !== newValue.id) this.notification = newValue;
      }
    },

    notification(newValue) {
      console.log('NotificationAttachment: watch -> notification'); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
    hasAttachment() {
      return this.notification && 'attachmentUrl' in this.notification && this.notification.attachmentUrl;
    },
  },

  methods: {
    async remove() {
      console.log(`${this.constructor.name}: remove()`); // eslint-disable-line no-console

      this.updating = true;

      await this.$store.dispatch(`notifications/${DELETE_NOTIFICATION_ATTACHMENT}`, this.notification);

      this.$set(this.notification, 'attachmentUrl', null);
      this.$set(this.notification, 'attachmentName', null);
      this.$set(this.notification, 'attachmentOriginalName', null);

      this.$refs.fileInput.value = null;

      this.updating = false;
    },

    upload() {
      this.$refs.fileInput.click();
    },

    async handleFileChange(event) {
      console.log(`${this.constructor.name}: handleFileChange()`); // eslint-disable-line no-console

      const file = event.target.files[0];
      console.log('file =', file); // eslint-disable-line no-console
      if (!file) return;

      this.updating = true;

      const { attachmentUrl, attachmentName, attachmentOriginalName } = await this.$store.dispatch(`notifications/${UPDATE_NOTIFICATION_ATTACHMENT}`, {
        notification: this.notification,
        file,
      });

      if (attachmentUrl && attachmentName && attachmentOriginalName) {
        this.$set(this.notification, 'attachmentUrl', attachmentUrl);
        this.$set(this.notification, 'attachmentName', attachmentName);
        this.$set(this.notification, 'attachmentOriginalName', attachmentOriginalName);
      }

      this.updating = false;
    },
  },
};
</script>

<style scoped>
#fileInput {
  display: none;
}
</style>
