<template>
  <div class="view-fasi">
    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-progress-check" />

    <!-- v-alert text prominent type="warning" border="left">
      <v-row>
        <v-col>
          <strong>Attenzione!</strong> Quest'area sarà accessibile soltanto agli amministratori
          del software e rimarrà abilitata per tutti soltanto durante la fase di sviluppo.<br>
          Le modifiche effettuate qui potrebbero influire sul suo corretto funzionamento.
        </v-col>
      </v-row>
    </v-alert -->

    <Phases />

    <v-btn bottom dark fab large fixed right
      color="success"
      @click.stop="dialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <NewPhaseDialog v-model="dialog" />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import Phases from '@/components/Phases';
import NewPhaseDialog from '@/components/NewPhaseDialog';

export default {
  name: 'Fasi',

  components: {
    PageHeader,
    Phases,
    NewPhaseDialog,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: 'Fasi di lavorazione',
        exact: true,
        to: { name: 'Fasi' },
      },
    ],

    dialog: false,
  }),
};
</script>
