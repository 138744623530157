<template>
  <div class="component-manualselection">
    <v-row align="stretch">
      <v-col
        cols="12" order="last"
        sm="8" order-sm="first">
        <PricelistAlert />

        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="filters.search"
                  outlined
                  placeholder="Cerca..."
                  append-icon="mdi-magnify"
                  clearable
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8" class="px-1">
                <v-autocomplete
                  v-model="filters.phase"
                  :items="phases"
                  item-text="name"
                  item-value="id"
                  placeholder="Tutte le fasi di lavorazione"
                  outlined
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredProducts"
            item-key="id"
            :items-per-page="15"
            :sort-by="`name.${userLanguage}`"
            :sort-desc="false"
            class="elevation-0">

            <!--
            show-select
            @item-selected="updated()"
            -->

            <!-- template v-slot:item.data-table-select="{ item, isSelected, select }">
              <v-simple-checkbox
                :value="isSelected"
                :readonly="item.hasOwnProperty('standalone') && item.standalone === false"
                :disabled="item.hasOwnProperty('standalone') && item.standalone === false"
                @input="select($event)"
              ></v-simple-checkbox>
            </template -->

            <!-- template v-slot:header.data-table-select>
            </template -->

            <template v-slot:[`item.name`]="{ item }">
              {{ item.name[userLanguage] }}
            </template>

            <template v-slot:[`item.phase`]="{ item }">
              <ProductPhases :product="item" />
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-row class="d-flex justify-end flex-nowrap">
                <v-tooltip bottom
                  :disabled="!hasAttachments(item)">
                  <template v-slot:activator="{ on }">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on: menu }">
                        <v-btn
                          v-on="{ ...on, ...menu }"
                          :disabled="!hasAttachments(item)"
                          :dark="hasAttachments(item)"
                          class="ml-1" fab x-small depressed color="secondary">
                          <v-icon dark>mdi-download</v-icon>
                        </v-btn>
                      </template>

                      <v-list dense>
                        <v-list-item
                          v-for="(attachment, index) in item.attachments"
                          :key="`attachment-${index}`"
                          @click="onDownloadAttachment(attachment)">
                          <v-list-item-icon class="mr-2">
                            <v-icon small>mdi-file-download</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ getAttachmentDisplayName(attachment, index) }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <span>{{ getAttachmentsTooltip(item) }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ml-1" fab x-small depressed color="secondary" dark
                      @click="onDetails(item)">
                      <v-icon dark>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Mostra i dettagli del prodotto</span>
                </v-tooltip>

                <v-btn
                  v-if="item.hasOwnProperty('standalone') && item.standalone === false"
                  class="ml-1" fab x-small depressed color="grey" light disabled>
                  <v-icon dark>mdi-circle-off-outline</v-icon>
                </v-btn>
                <v-tooltip v-else
                  bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ml-1" fab x-small depressed color="success" dark
                      @click="onSelect(item)">
                      <v-icon dark>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Aggiungi ai prodotti selezionati</span>
                </v-tooltip>
              </v-row>

              <!-- v-btn class="ml-2" fab x-small depressed color="secondary" dark
                @click="onDetails(item)">
                <v-icon dark>mdi-eye</v-icon>
              </v-btn>

              <v-btn class="ml-2" fab x-small depressed color="secondary" dark
                @click="onDetails(item)">
                <v-icon dark>mdi-plus-thick</v-icon>
              </v-btn -->
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <v-col
        cols="12" order="first"
        sm="4" order-sm="last">
        <SelectedProducts
          :quotation="quotation"
          unselect
          details
          @unselect="onUnselect"
          @update="onUpdate" />
      </v-col>
    </v-row>

    <ProductDetailsDialog v-model="detailsDialog"
      :product="selected"
      :quotation="quotation"
      @select="onSelect"
      @unselect="onUnselect"
      @update="onUpdate" />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unreachable */
/* eslint-disable quote-props */

import { v4 as uuidv4 } from 'uuid';
import ProductDetailsDialog from '@/components/ProductDetailsDialog';
import ProductPhases from '@/components/ProductPhases';
import SelectedProducts from '@/components/SelectedProducts';
import PricelistAlert from '@/components/PricelistAlert';

export default {
  name: 'ManualSelection',

  props: ['value'],

  components: {
    ProductDetailsDialog,
    ProductPhases,
    SelectedProducts,
    PricelistAlert,
  },

  data: () => ({
    detailsDialog: false,

    quotation: null,

    selected: null,

    headers: [
      { text: 'Nome del prodotto', value: 'name', sortable: false },
      { text: 'Fase di lavorazione', value: 'phase', sortable: false },
      {
        text: '',
        value: 'action',
        sortable: false,
        align: 'end',
      },
    ],

    filters: {
      search: '',
      phase: null,
    },

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  created() {
    if (!this.value) {
      console.log('ManualSelection: created() new quotation'); // eslint-disable-line no-console
      this.quotation = {
        selectedProducts: [],
      };
    } else {
      console.log('ManualSelection: created() existing quotation'); // eslint-disable-line no-console
      this.quotation = this.value;
    }
  },

  watch: {
    value(newValue) {
      console.log('ManualSelection: watch.value()'); // eslint-disable-line no-console
      this.quotation = newValue;
    },

    quotation(newValue) {
      console.log('ManualSelection: watch.quotation()'); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },

    selectedProducts(newValue) {
      console.log('ManualSelection: watch.selectedProducts()'); // eslint-disable-line no-console
      console.log('ManualSelection: watch.selectedProducts() newValue =', newValue); // eslint-disable-line no-console

      // Prepara l'elenco dei prodotti richiesti in base ai prodotti attualmente selezionati
      const requiredProducts = [];
      newValue.forEach((product) => {
        if (product.consumptionType === 'variable'
          && Array.isArray(product.consumptionranges[product.consumptionRange || 0]
            .requiredProducts)
          && product.consumptionranges[product.consumptionRange || 0].requiredProducts.length > 0) {
          requiredProducts.push(
            ...product.consumptionranges[product.consumptionRange || 0].requiredProducts
              .map((rp) => rp.product),
          );
        }
      });

      // Seleziona automaticamente i prodotti richiesti (se non già selezionati)
      if (requiredProducts.length > 0) {
        console.log('ManualSelection: watch.selectedProducts() requiredProducts =', requiredProducts); // eslint-disable-line no-console

        requiredProducts.forEach((rp) => {
          // Verifica se il prodotto è già in lista
          const exists = newValue.find((p) => p.id === rp.id);
          if (!exists) {
            // Usa la versione aggiornata del prodotto
            const updatedRP = this.products.find((p) => p.id === rp.id);
            if (updatedRP) {
              const urp = JSON.parse(JSON.stringify(updatedRP));
              urp.sId = uuidv4();

              this.quotation.selectedProducts.push(urp);
            }
          }
        });
      }

      // Deseleziona automaticamente i prodotti richiesti non più richiesti
      // Inizia analizzando prima tutti i prodotti
      newValue
        .filter((p) => this.isCombined(p))
        .forEach((product) => {
          // Ho trovato un prodotto combinato,
          // verifico se è ancora richiesto.
          const required = requiredProducts.find((rp) => rp.id === product.id);

          // Se non è più richiesto e non può essere usato da solo,
          // lo elimino.
          if (!required && product.hasOwnProperty('standalone') && product.standalone === false) {
            this.$set(
              this.quotation,
              'selectedProducts',
              newValue.filter((p) => p.id !== product.id),
            );
          }
        });
    },
  },

  computed: {
    products() {
      return this.$store.getters['products/docs'];
    },

    filteredProducts() {
      return this.products
        ? this.products.filter((p) => {
          // Non mostra  gli archiviati
          if (p.archived) return false;

          // Filtro sulla fase
          // eslint-disable-next-line no-prototype-builtins
          if (this.filters.phase && !p.phases.hasOwnProperty(this.filters.phase)) return false;

          // Campo di ricerca
          if (this.filters.search && this.filters.search.trim()
            && !p.name[this.userLanguage]
              .toLowerCase().includes(this.filters.search.toLowerCase())) return false;

          return true;
        })
        : [];
    },

    phases() {
      return this.$store.getters['phases/docs'];
    },

    selectedProducts() {
      // eslint-disable-next-line no-prototype-builtins
      return this.quotation && this.quotation.hasOwnProperty('selectedProducts')
        && Array.isArray(this.quotation.selectedProducts)
        ? this.quotation.selectedProducts
        : [];
    },

    allRequiredProducts() {
      if (!Array.isArray(this.quotation.selectedProducts)) return [];

      const result = [];
      this.quotation.selectedProducts.forEach((product) => {
        if (product.consumptionType === 'variable'
          && Array.isArray(product.consumptionranges[product.consumptionRange || 0]
            .requiredProducts)
          && product.consumptionranges[product.consumptionRange || 0].requiredProducts.length > 0) {
          result.push(
            ...product.consumptionranges[product.consumptionRange || 0].requiredProducts
              .map((rp) => rp.product),
          );
        }
      });

      return result;
    },
  },

  methods: {
    // Il preventivo è stato aggiornato (va eventualmente salvato)
    updated() {
      this.$emit('updated', true);
    },

    isCombined(product) {
      // Se il prodotto non può essere combinato si ferma subito (false)
      if ('combined' in product === false || !product.combined) return false;

      // Il prodotto è combinabile

      // Se non può essere utilizzato da solo, si ferma subito (true).
      if ('standalone' in product && product.standalone === false) return true;

      // Verifica se un prodotto tra quelli scelti lo richiede come combinato

      // Conta il numero di prodotti richiesti di questo tipo
      const reqCount = this.allRequiredProducts.filter((rp) => rp.id === product.id).length;

      // Nessun prodotto richiesto di questo tipo
      if (reqCount === 0) return false;

      // È richiesto un prodotto di questo tipo per l'uso combinato

      // Riserva il primo prodotto di questo tipo per l'uso combinato

      // Calcola la posizione in elenco di questo prodotto
      const spList = this.selectedProducts.filter((rp) => rp.id === product.id);
      const spIndex = spList.findIndex((rp) => rp.sId === product.sId);

      // Ritorna true solo se è il primo
      return Boolean(spIndex === 0);
    },

    onSelect(product) {
      this.updated();

      const selectedProduct = JSON.parse(JSON.stringify(product));

      selectedProduct.sId = uuidv4();

      // eslint-disable-next-line no-console
      console.log('ManualSelection.onSelect() selectedProduct =', selectedProduct);

      this.$set(
        this.quotation,
        'selectedProducts',
        [...this.quotation.selectedProducts, selectedProduct],
      );

      // this.quotation.selectedProducts.push(selectedProduct);
    },

    onUnselect(product) {
      // eslint-disable-next-line no-console
      console.log('ManualSelection.onUnselect() product =', product);

      this.updated();

      this.$set(
        this.quotation,
        'selectedProducts',
        this.quotation.selectedProducts
          .filter((p) => {
            if ('sId' in p && 'sId' in product) {
              // Se entrambi i prodotti hanno l'sId usa quello
              return p.sId !== product.sId;
            }
            return p.id !== product.id;
          }),
      );
    },

    onUpdate(product) {
      // eslint-disable-next-line no-console
      console.log('ManualSelection.onUpdate() product =', product);

      this.$set(
        this.quotation.selectedProducts,
        this.quotation.selectedProducts
          .findIndex((p) => {
            if ('sId' in p && 'sId' in product) {
              // Se entrambi i prodotti hanno l'sId usa quello
              return p.sId === product.sId;
            }
            return p.id === product.id;
          }),
        product,
      );
    },

    onDetails(product) {
      // eslint-disable-next-line no-console
      console.log('ManualSelection: onDetails() product =', product);

      this.selected = this.quotation.selectedProducts
        .find((p) => {
          if ('sId' in p && 'sId' in product) {
            // Se entrambi i prodotti hanno l'sId usa quello
            return p.sId === product.sId;
          }
          return p.id === product.id;
        });

      if (!this.selected) {
        // eslint-disable-next-line no-console
        console.log('ManualSelection: il prodotto non è tra quelli selezionati');
        this.selected = product;
      }

      this.detailsDialog = true;
    },

    hasAttachments(item) {
      return item?.attachments?.length > 0;
    },

    getAttachmentsTooltip(item) {
      const count = item?.attachments?.length || 0;
      return count === 1
        ? '1 allegato disponibile'
        : `${count} allegati disponibili`;
    },

    onDownloadAttachment(attachment) {
      if (attachment?.url) {
        window.open(attachment.url, '_blank');
      }
    },

    getAttachmentDisplayName(attachment, index) {
      if (attachment.name?.trim()) {
        return attachment.name;
      }

      // Mappa delle categorie
      const categoryMap = {
        'scheda-tecnica': 'Scheda tecnica',
        'cartella-colore': 'Cartella colore',
        'certificato': 'Certificato',
        'opuscolo': 'Opuscolo',
        'epd': 'Dichiarazione ambientale di prodotto (EPD)',
        'dichiarazione-prestazione': 'Dichiarazione di prestazione',
        'scheda-sostenibilità': 'Scheda tecnica di sostenibilità',
        'scheda-sicurezza': 'Scheda di sicurezza',
      };

      if (attachment.category === 'altro' && attachment.customCategory?.trim()) {
        return attachment.customCategory;
      }

      if (attachment.category && categoryMap[attachment.category]) {
        return categoryMap[attachment.category];
      }

      return `Allegato ${index + 1}`;
    },
  },
};
</script>

<style>
</style>
