<template>
  <div class="component-tags">
    <v-row no-gutters
      v-if="tags.length > 0">
      <v-col>
        <v-card outlined>
          <v-simple-table>
            <template v-slot:default>
                <tr v-for="item in tags" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td><pre>{{ item.slug }}</pre></td>
                  <td class="text-right text-no-wrap">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="onRename(item)">
                          <v-list-item-title>Rinomina</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="onDelete(item)">
                          <v-list-item-title>Elimina</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <!-- v-btn class="ml-2" fab x-small depressed color="success" dark
                      @click="onEdit(item)">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn -->
                  </td>
                </tr>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-alert text prominent type="success" border="left"
      v-else>
      <v-row>
        <v-col
          class="d-flex flex-column flex-sm-row justify-space-between align-start align-sm-center">
          <span>Al momento non è presente nessun tag.</span>
          <v-btn color="success" class="mt-4 mt-sm-0"
            @click.stop="newDialog = true">Crea un tag</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <NewTagDialog v-model="newDialog" />
    <RenameTagDialog v-model="renameDialog" :tag="selected" />
    <DeleteTagDialog v-model="deleteDialog" :tag="selected" />
  </div>
</template>

<script>
import NewTagDialog from '@/components/NewTagDialog';
import RenameTagDialog from '@/components/RenameTagDialog';
import DeleteTagDialog from '@/components/DeleteTagDialog';

export default {
  name: 'Tags',

  components: {
    NewTagDialog,
    RenameTagDialog,
    DeleteTagDialog,
  },

  data: () => ({
    newDialog: false,
    renameDialog: false,
    deleteDialog: false,

    selected: null,

    headers: [
      { text: 'Nome del listino', value: 'name' },
      {
        text: '',
        value: 'action',
        sortable: false,
        align: 'end',
      },
    ],
  }),

  computed: {
    tags() {
      return this.$store.getters['tags/docs'];
    },
  },

  methods: {
    onRename(item) {
      this.selected = item;
      this.renameDialog = true;
    },

    onDelete(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
  },
};
</script>
