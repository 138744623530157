<template>
  <div class="view-fase">
    <Loading v-model="loading" />

    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-progress-check" />

    <Phase v-model="phase"
      :updating="updating"
      :loading="loading"
      @updated="updated = true" />

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-actions v-if="phase">
            <v-btn text color="primary"
              :disabled="updating"
              :loading="updating"
              @click="onCancel()">
              Torna all'elenco
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn text color="success"
              :disabled="updating || !updated"
              :loading="updating"
              @click="update()">
              Aggiorna
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="leaveDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Vuoi abbandonare le modifiche?</v-card-title>

        <v-card-text>
          Non hai salvato le modifiche alla fase. Sei certo di voler uscire senza prima salvarle?
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text
            @click="leaveDialog = false">
            Rimani su questa pagina
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="warning" text
            @click="next()">
            Lascia questa pagina
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import PageHeader from '@/components/PageHeader';
import Phase from '@/components/Phase';

export default {
  name: 'Fase',

  components: {
    Loading,
    PageHeader,
    Phase,
  },

  data: () => ({
    loading: true,
    updating: false,
    updated: false,

    breadcrumbs: [
      {
        text: 'Fasi di lavorazione',
        exact: true,
        to: { name: 'Fasi' },
      },
    ],

    id: null,
    phase: null,

    leaveDialog: false,
    next: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  async created() {
    console.log('this.$route.params.id = ', this.$route.params.id); // eslint-disable-line no-console
    this.id = this.$route.params.id;

    const phase = await this.$store.dispatch('phases/getById', this.id);
    console.log('phase = ', phase); // eslint-disable-line no-console

    this.phase = {
      id: this.id,
      ...phase,
    };

    // Inserisce il nome prodotto nei breadcrumbs
    this.breadcrumbs.push({
      text: this.phase.name,
      disabled: true,
    });

    this.loading = false;
  },

  methods: {
    onCancel() {
      // Torna all'elenco delle fasi
      this.$router.replace({ name: 'Fasi' }, () => {});
    },

    async update() {
      this.updating = true;

      // Ripulisce le condizioni dalle linee vuote e le
      // trasforma in un Object.
      const conditions = {
        ...this.phase.conditions
          .filter((c) => c.include.length > 0 || c.exclude.length > 0),
      };

      // Ripulisce le scelte da quelle senza prodotti e
      // ripulisce le condizioni della scelta dalle linee vuote e le
      // trasforma in un Object.
      const choices = {
        ...this.phase.choices
          .map((c, index) => ({
            index,
            products: c.products.map((p) => ({
              id: p.id,
              name: p.name,
            })),
            visibility: c.visibility,
            visibilityConditions: c.visibility === 'conditions'
              ? {
                ...c.visibilityConditions.filter((vc) => vc.length > 0),
              }
              : {},
            recommended: c.recommended,
            recommendedConditions: c.recommended === 'conditions'
              ? {
                ...c.recommendedConditions.filter((rc) => rc.length > 0),
              }
              : {},
            withProcedure: c.withProcedure || false,
            procedure: c.withProcedure ? c.procedure || '' : '',

            priorities: c.priorities.filter((p) => Number(p.value) !== 0),
          })),
      };

      const update = {
        id: this.id,
        name: this.phase.name,
        withProcedure: this.phase.withProcedure || false,
        procedure: this.phase.procedure || '',
        conditions,
        choices,
      };

      console.log('Fase: update() update =', update); // eslint-disable-line no-console

      await this.$store.dispatch('phases/update', update);

      // Aggiorna il nome della fase nei breadcrumbs
      this.breadcrumbs.pop();
      this.breadcrumbs.push({
        text: this.phase.name,
        disabled: true,
      });

      this.updated = false;
      this.updating = false;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
};
</script>
