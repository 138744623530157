var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-productarea"},[_vm._l((_vm.requiringProducts),function(rp,rpIndex){return [_c('v-tooltip',{key:("area-p" + (_vm.product.id) + "-rp" + (rp.id) + "-" + rpIndex),attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"rp-area",domProps:{"textContent":_vm._s(((rp.area) + " m²"))}},'span',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.requiringProductTooltip(rp)))])])]}),((this.isCombined && this.isTheOne) || !this.isCombined)?_c('div',{staticClass:"p-area-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"p-area",domProps:{"textContent":_vm._s((_vm.area + " m²"))},on:{"click":_vm.onEdit}},'span',attrs,false),on))]}}],null,false,4226414130)},[_c('span',[_vm._v(_vm._s(_vm.productTooltip))])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"p-lock",attrs:{"icon":"","x-small":""},on:{"click":_vm.onToggleLockedArea}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.locked ? 'warning' : 'secondary'}},[_vm._v(_vm._s(_vm.icon))])],1)]}}],null,false,973756033)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.toggleTooltip)}})])],1):_vm._e(),_c('EditProductAreaDialog',{attrs:{"product":_vm.product,"quotation":_vm.quotation},on:{"toggleLockedArea":_vm.onToggleLockedArea,"updateArea":_vm.onUpdateArea},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }