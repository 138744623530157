<template>
  <div class="component-phaseproducts mt-4">
    <span class="subtitle-2">Elenco prodotti:</span>

    <v-card flat>
      <v-card-text>
        <v-alert
          v-if="!Array.isArray(selectedProducts) || selectedProducts.length === 0"
          text dense
          type="warning"
          border="left">
          Questa scelta non include nessun prodotto.
        </v-alert>

        <v-simple-table
          v-if="Array.isArray(selectedProducts) && selectedProducts.length > 0">
          <template v-slot:default>
            <draggable tag="tbody"
              v-model="selectedProducts"
              handle=".sortHandle"
              draggable=".sortableRow"
              @change="updated()">
              <tr class="sortableRow"
                v-for="product in selectedProducts" :key="product.id">
                <td class="px-1" style="width: 0.1%">
                  <v-btn style="cursor: move" icon class="sortHandle">
                    <v-icon>mdi-drag</v-icon>
                  </v-btn>
                </td>
                <td>{{ product.name[userLanguage] }}</td>
                <td class="text-right text-no-wrap">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" class="ml-2">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="onDelete(product)">
                        <v-list-item-title>Elimina</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </draggable>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text color="secondary" small
          :disabled="updating"
          :loading="updating"
          @click="onAddProductDialog">
          Aggiungi prodotto
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="400">
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>

        <v-card>
          <v-card-title class="headline">Aggiungi un prodotto alla scelta</v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                Selezionare il prodotto da aggiungere alla scelta.
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="newProduct"
                  :items="availableProducts"
                  single-line
                  filled
                  label="Prodotto"
                  placeholder="Scegli quale prodotto inserire"
                  item-text="name.it"
                  item-value="id"
                  clearable
                  :rules="[v => !!v || 'Il prodotto è richiesto']"
                  return-object>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              @click="onCancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn type="submit"
              color="success" text
              :disabled="!valid"
              @click="onAddProduct">
              Aggiungi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable max-len */

import draggable from 'vuedraggable';

export default {
  name: 'PhaseProducts',

  components: {
    draggable,
  },

  props: ['value'],

  data: () => ({
    updating: false,

    selectedProducts: null,

    dialog: false,
    valid: false,
    newProduct: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
    userLocale: 'it-IT', // TODO: Dovrà essere impostato nel profilo utente
  }),

  created() {
    console.log('PhaseProducts: created()'); // eslint-disable-line no-console
    this.updateProducts(this.value);
  },

  watch: {
    value(newValue) {
      if (!newValue) {
        console.log('PhaseProducts: watch.value(null)'); // eslint-disable-line no-console
        return;
      }
      console.log('PhaseProducts: watch.value() newValue =', newValue); // eslint-disable-line no-console

      this.updateProducts(newValue);
    },

    selectedProducts(newValue) {
      console.log('PhaseProducts: watch.selectedProducts() newValue =', newValue); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
    products() {
      return this.$store.getters['products/docs'];
    },

    availableProducts() {
      return !this.selectedProducts || (Array.isArray(this.selectedProducts) && this.selectedProducts.length === 0)
        ? this.products
        // eslint-disable-next-line arrow-body-style
        : this.products.filter((product) => {
          const spIds = this.selectedProducts.map((sp) => sp.id);
          return !spIds.includes(product.id);
        });
    },
  },

  methods: {
    updated() {
      console.log('PhaseProducts: updated()'); // eslint-disable-line no-console
      this.$emit('change');
    },

    updateProducts(newValue) {
      console.log('PhaseProducts: updateProducts() newValue =', newValue); // eslint-disable-line no-console

      let selectedProducts = [];

      if (newValue && newValue.constructor === Object) {
        // Se l'elenco prodotti è un oggetto,
        // lo converte in un elenco.
        selectedProducts = Object.values(newValue);
      } else if (newValue && Array.isArray(newValue)) {
        selectedProducts = newValue;
      }

      this.selectedProducts = selectedProducts;
    },

    onDelete(product) {
      this.selectedProducts = this.selectedProducts.filter((sp) => sp.id !== product.id);
      this.updated();
    },

    onAddProductDialog() {
      const formRef = this.$refs.form;

      if (formRef) {
        formRef.reset();
        formRef.resetValidation();
      }

      this.dialog = true;
    },

    onAddProduct() {
      const formRef = this.$refs.form;

      if (formRef.validate()) {
        this.loading = true;

        this.selectedProducts.push(this.newProduct);
        this.updated();

        this.dialog = false;
        this.loading = false;
      }
    },

    onCancel() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
</style>
