<template>
  <div class="component-deletephasedialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline">Elimina la fase</v-card-title>

          <v-card-text>
            <p>Confermi di voler eliminare la fase di lavorazione selezionata?</p>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="cancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="error" text
              :loading="loading"
              @click="deleteItem()">
              Elimina
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['value', 'phase'],

  name: 'DeletePhaseDialog',

  data: () => ({
    dialog: false,
    loading: false,
  }),

  watch: {
    value(newValue) {
      if (newValue === true) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    async deleteItem() {
      this.loading = true;

      await this.$store.dispatch('phases/delete', this.phase.id);

      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>
