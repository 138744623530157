<template>
  <div class="component-colorlists">
    <v-row no-gutters
      v-if="colorlists.length > 0">
      <v-col>
        <v-card>
          <!-- v-card-title>
            <v-spacer></v-spacer>
          </v-card-title -->

          <v-simple-table>
            <template v-slot:default>
              <draggable tag="tbody"
                v-model="colorlists"
                handle=".sortHandle"
                draggable=".sortableRow">
                <tr class="sortableRow" v-for="item in colorlists" :key="item.id">
                  <td class="px-1" style="width: 0.1%">
                    <v-btn style="cursor: move" icon class="sortHandle">
                      <v-icon>mdi-drag</v-icon>
                    </v-btn>
                  </td>
                  <td>{{ item.name }}</td>
                  <td class="text-right text-no-wrap">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="onRename(item)">
                          <v-list-item-title>Rinomina</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="onDelete(item)">
                          <v-list-item-title>Elimina</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-btn class="ml-2" fab x-small depressed color="secondary" dark
                      @click="onEdit(item)">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-alert text prominent type="success" border="left"
      v-else>
      <v-row>
        <v-col
          class="d-flex flex-column flex-sm-row justify-space-between align-start align-sm-center">
          <span>Al momento non è presente nessuna cartella.</span>
          <v-btn color="success" class="mt-4 mt-sm-0"
            @click.stop="newDialog = true">Crea un cartella colori</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <NewColorlistDialog v-model="newDialog" />
    <RenameColorlistDialog v-model="renameDialog" :colorlist="selected" />
    <DeleteColorlistDialog v-model="deleteDialog" :colorlist="selected" />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import NewColorlistDialog from '@/components/NewColorlistDialog';
import RenameColorlistDialog from '@/components/RenameColorlistDialog';
import DeleteColorlistDialog from '@/components/DeleteColorlistDialog';

export default {
  name: 'Colorlists',

  components: {
    draggable,
    NewColorlistDialog,
    RenameColorlistDialog,
    DeleteColorlistDialog,
  },

  data: () => ({
    newDialog: false,
    renameDialog: false,
    deleteDialog: false,

    selected: null,
  }),

  computed: {
    colorlists: {
      get() {
        return this.$store.getters['colorlists/docs'];
      },
      set(colorlists) {
        this.$store.dispatch('colorlists/reorder', colorlists);
      },
    },
  },

  methods: {
    onRename(item) {
      this.selected = item;
      this.renameDialog = true;
    },

    onDelete(item) {
      this.selected = item;
      this.deleteDialog = true;
    },

    onEdit(item) {
      this.$router.push({ name: 'CartellaColori', params: { id: item.id } });
    },
  },
};
</script>
