/* eslint-disable no-shadow */

import Vue from 'vue';
import firebase from 'firebase';
import {
  compareOrder, getOrder, getOrderMap, setOrderMap,
} from '@/utils/orderMaps';
import {
  INIT_PRICELISTS, UPDATE_PRICELISTS, SET_PRICELISTS,
  CLEAR_PRICELISTS, DESTROY_PRICELISTS, SET_PRICELISTS_LISTENER,
} from '@/store/actions/pricelists';

const state = {
  data: [],
  listener: null,
};

const getters = {
  docs: (state) => Object.values(state.data),
};

const actions = {
  [INIT_PRICELISTS]: ({ dispatch }) => {
    console.log('actions:INIT_PRICELISTS'); // eslint-disable-line no-console

    return dispatch(UPDATE_PRICELISTS);
  },

  [UPDATE_PRICELISTS]: async ({ commit, state }) => {
    console.log('actions:UPDATE_PRICELISTS'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Recuper la mappa con l'ordinamento
    const order = await getOrderMap('pricelists');
    if (order) {
      console.log('Esiste un ordinamento, lo utilizzo'); // eslint-disable-line no-console
      console.log(`order = ${JSON.stringify(order)}`); // eslint-disable-line no-console
    }

    // Recupera la collection con l'elenco
    const collectionRef = db.collection('pricelists');
    const listener = collectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (pricelists)'); // eslint-disable-line no-console

      commit(SET_PRICELISTS, {
        snapshot,
        order,
      });
    });
    commit(SET_PRICELISTS_LISTENER, listener);
  },

  [DESTROY_PRICELISTS]: ({ state, commit }) => {
    console.log('actions:DESTROY_PRICELISTS'); // eslint-disable-line no-console

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Pulisce lo state
    commit(CLEAR_PRICELISTS);
  },

  add: (context, payload) => {
    console.log('actions:add'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const collectionRef = db.collection('pricelists');

    return collectionRef.add({
      ...payload,
      order: (new Date()).getTime(),
    });
  },

  update: (context, payload) => {
    console.log('actions:update'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Separa la proprietà 'id' dal resto
    const { id, ...data } = payload;

    const docRef = db.doc(`/pricelists/${id}`);

    return docRef.update({
      ...data,
    });
  },

  delete: (context, payload) => {
    console.log('actions:delete'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const docRef = db.doc(`/pricelists/${payload}`);

    return docRef.delete();
  },

  // eslint-disable-next-line no-async-promise-executor
  reorder: async ({ commit }, payload) => {
    console.log('actions:reorder'); // eslint-disable-line no-console

    // Memorizza l'ordinamento attuale dell'elenco nel payload
    const order = {};
    payload.forEach((pricelist, index) => {
      order[pricelist.id] = index;
    });

    console.log(`order = ${JSON.stringify(order)}`); // eslint-disable-line no-console

    commit(SET_PRICELISTS, {
      list: payload,
      order,
    });

    return setOrderMap('pricelists', order);
  },
};

const mutations = {
  [SET_PRICELISTS_LISTENER]: (state, payload) => {
    console.log('mutations:SET_PRICELISTS_LISTENER'); // eslint-disable-line no-console

    Vue.set(state, 'listener', payload);
  },

  [SET_PRICELISTS]: (state, payload) => {
    console.log('mutations:SET_PRICELISTS'); // eslint-disable-line no-console

    const data = [];

    if (payload.snapshot) {
      payload.snapshot.forEach((doc) => {
        const docData = doc.data();

        data.push({
          id: doc.id,
          ...docData,
          order: getOrder(payload.order, doc.id, docData.order),
        });
      });
    } else if (payload.list) {
      payload.list.forEach((doc) => {
        data.push({
          ...doc,
          order: getOrder(payload.order, doc.id, doc.order),
        });
      });
    }

    if (payload.snapshot || payload.list) {
      // Ordina
      data.sort(compareOrder);

      // eslint-disable-next-line no-console
      console.log('Ordinamento completato!');
    }

    Vue.set(state, 'data', data);

    // eslint-disable-next-line no-console
    console.log(`state.data.length = ${state.data.length}`);
    // eslint-disable-next-line no-console
    // console.log('state.data =', state.data);
  },

  [CLEAR_PRICELISTS]: (state) => {
    console.log('mutations:CLEAR_PRICELISTS'); // eslint-disable-line no-console

    Vue.set(state, 'data', []);
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
