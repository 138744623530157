/* eslint-disable no-shadow */

import Vue from 'vue';
import firebase from 'firebase';
import moment from 'moment';
import {
  INIT_QUOTATIONS, UPDATE_QUOTATIONS, SET_QUOTATIONS,
  CLEAR_QUOTATIONS, DESTROY_QUOTATIONS, SET_QUOTATIONS_LISTENER,
} from '@/store/actions/quotations';

moment.locale('it');

const getUserDetails = async (user) => {
  // eslint-disable-next-line no-console
  console.log('actions:UPDATE_QUOTATIONS getUserDetails');

  // Recupera le informazioni aggiuntive sull'utente
  const db = firebase.firestore();

  // const querySnapshot = await db.collection('users').where('uid', '==', user.uid).limit(1).get();
  const queryDocumentSnapshot = await db.collection('users').doc(user.uid).get();

  // eslint-disable-next-line no-console
  console.log('actions:UPDATE_QUOTATIONS getUserDetails - query completa');

  let userDetails = null;

  if (queryDocumentSnapshot.exists) {
    // const queryDocumentSnapshot = querySnapshot.docs[0];
    userDetails = queryDocumentSnapshot.data();
  }

  return userDetails;
};

const state = {
  data: null,
  listener: null,
};

const getters = {
  docs: (state) => (state.data ? Object.values(state.data) : null),
};

const actions = {
  [INIT_QUOTATIONS]: ({ dispatch }, payload) => {
    console.log('actions:INIT_QUOTATIONS'); // eslint-disable-line no-console

    return dispatch(UPDATE_QUOTATIONS, payload);
  },

  [UPDATE_QUOTATIONS]: async ({ commit, state, rootGetters }, payload) => {
    console.log('actions:UPDATE_QUOTATIONS'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // // Recuper la mappa con l'ordinamento
    // const order = await getOrderMap('products');
    // if (order) {
    //   console.log('Esiste un ordinamento, lo utilizzo'); // eslint-disable-line no-console
    //   console.log(`order = ${JSON.stringify(order)}`); // eslint-disable-line no-console
    // }

    let uid = null;
    let superUser = false;

    // eslint-disable-next-line no-unused-vars
    const currentUser = rootGetters.user;

    // eslint-disable-next-line no-console
    console.log('actions:UPDATE_QUOTATIONS currentUser =', currentUser);

    if (payload) {
      uid = payload.uid;

      const userDetails = await getUserDetails(payload);

      // eslint-disable-next-line no-console
      console.log('actions:UPDATE_QUOTATIONS userDetails =', userDetails);

      superUser = userDetails.isSuper === true;
    } else {
      uid = currentUser.uid;
      superUser = currentUser.superUser;
    }

    // eslint-disable-next-line no-console
    console.log('actions:UPDATE_QUOTATIONS uid =', uid);

    // eslint-disable-next-line no-console
    console.log('actions:UPDATE_QUOTATIONS superUser =', superUser);

    let collectionRef = null;

    // Recupera la collection con l'elenco
    if (superUser) {
      collectionRef = db.collection('quotationsLight');
    } else {
      collectionRef = db.collection('quotationsLight').where('uid', '==', uid);
    }

    // const collectionRef = currentUser.superUser
    //   ? db.collection('quotations')
    //   : db.collection('quotations'); // .where('uid', '==', currentUser.data.uid);

    const listener = collectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (quotations)'); // eslint-disable-line no-console

      commit(SET_QUOTATIONS, {
        snapshot,
        // order,
      });
    });
    commit(SET_QUOTATIONS_LISTENER, listener);
  },

  [DESTROY_QUOTATIONS]: ({ state, commit }) => {
    console.log('actions:DESTROY_QUOTATIONS'); // eslint-disable-line no-console

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Pulisce lo state
    commit(CLEAR_QUOTATIONS);
  },

  add: async ({ rootGetters }, payload) => {
    console.log('actions:add'); // eslint-disable-line no-console

    // const today = moment().toDate();

    const db = firebase.firestore();

    // eslint-disable-next-line no-unused-vars
    const currentUser = rootGetters.user;
    const { firebaseUser, loggedIn, ...fixedCurrentUser } = currentUser;

    const { uid } = currentUser;

    // eslint-disable-next-line no-unreachable
    const collectionRef = db.collection('quotations');

    return collectionRef.add({
      uid,
      user: fixedCurrentUser,
      name: 'Preventivo',
      createdAt: new Date(),
      archived: false,
      ...payload,
      // order: (new Date()).getTime(),
    });
  },

  update: async (context, payload) => {
    console.log('actions:update'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Separa la proprietà 'id' dal resto
    const { id, ...data } = payload;

    const docRef = db.doc(`/quotations/${id}`);

    return docRef.update({
      ...data,
    });
  },

  delete: async (context, payload) => {
    console.log('actions:delete'); // eslint-disable-line no-console

    // Recupera il riferimento al prodotto
    const db = firebase.firestore();
    const docRef = db.doc(`/quotations/${payload}`);

    // Rimuove il prodotto
    return docRef.delete();
  },

  getById: async (context, payload) => {
    console.log('actions:getById'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const docRef = db.doc(`/quotations/${payload}`);

    const doc = await docRef.get();

    if (!doc.exists) return null;

    return doc.data();
  },

  createQuotationsLight: async () => {
    console.log('actions:createQuotationsLight'); // eslint-disable-line no-console

    const functions = firebase.functions();

    const createQuotationsLight = functions.httpsCallable('createQuotationsLight');

    return createQuotationsLight();
  },
};

const mutations = {
  [SET_QUOTATIONS_LISTENER]: (state, payload) => {
    console.log('mutations:SET_QUOTATIONS_LISTENER'); // eslint-disable-line no-console

    Vue.set(state, 'listener', payload);
  },

  [SET_QUOTATIONS]: (state, payload) => {
    console.log('mutations:SET_QUOTATIONS'); // eslint-disable-line no-console

    const data = [];

    if (payload.snapshot) {
      payload.snapshot.forEach((doc) => {
        const docData = doc.data();

        data.push({
          id: doc.id,
          ...docData,
          // order: getOrder(payload.order, doc.id, docData.order),
        });
      });
    } else if (payload.list) {
      payload.list.forEach((doc) => {
        data.push({
          ...doc,
          // order: getOrder(payload.order, doc.id, doc.order),
        });
      });
    }

    // if (payload.snapshot || payload.list) {
    //   // Ordina
    //   data.sort(compareOrder);

    //   // eslint-disable-next-line no-console
    //   console.log('Ordinamento completato!');
    // }

    Vue.set(state, 'data', data);

    // eslint-disable-next-line no-console
    console.log(`state.data.length = ${state.data.length}`);
    // eslint-disable-next-line no-console
    // console.log('state.data =', state.data);
  },

  [CLEAR_QUOTATIONS]: (state) => {
    console.log('mutations:CLEAR_QUOTATIONS'); // eslint-disable-line no-console

    Vue.set(state, 'data', []);
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
