var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-phasechoice"},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"subtitle-1",domProps:{"textContent":_vm._s(("Scelta " + _vm.count))}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":[
              { text: 'Scelta sempre visibile', value: 'always' },
              { text: 'Scelta visibile in base ai tag', value: 'conditions' } ],"hint":_vm.visibilityHint,"persistent-hint":"","prepend-icon":"mdi-eye"},on:{"change":function($event){return _vm.updated()}},model:{value:(_vm.choice.visibility),callback:function ($$v) {_vm.$set(_vm.choice, "visibility", $$v)},expression:"choice.visibility"}}),(_vm.choice.visibility === 'conditions')?_c('Conditions',{on:{"change":function($event){return _vm.updated()}},model:{value:(_vm.choice.visibilityConditions),callback:function ($$v) {_vm.$set(_vm.choice, "visibilityConditions", $$v)},expression:"choice.visibilityConditions"}}):_vm._e(),_c('v-select',{attrs:{"items":[
              { text: 'Scelta selezionabile ma non consigliata', value: 'never' },
              { text: 'Scelta consigliata', value: 'always' },
              { text: 'Scelta consigliata in base ai tag', value: 'conditions' } ],"hint":_vm.recommendedHint,"persistent-hint":"","prepend-icon":"mdi-star"},on:{"change":function($event){return _vm.updated()}},model:{value:(_vm.choice.recommended),callback:function ($$v) {_vm.$set(_vm.choice, "recommended", $$v)},expression:"choice.recommended"}}),(_vm.choice.recommended === 'conditions')?_c('Conditions',{on:{"change":function($event){return _vm.updated()}},model:{value:(_vm.choice.recommendedConditions),callback:function ($$v) {_vm.$set(_vm.choice, "recommendedConditions", $$v)},expression:"choice.recommendedConditions"}}):_vm._e(),_c('PhasePriorities',{on:{"change":function($event){return _vm.updated()}},model:{value:(_vm.choice.priorities),callback:function ($$v) {_vm.$set(_vm.choice, "priorities", $$v)},expression:"choice.priorities"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('PhaseProducts',{on:{"change":function($event){return _vm.updated()}},model:{value:(_vm.choice.products),callback:function ($$v) {_vm.$set(_vm.choice, "products", $$v)},expression:"choice.products"}}),_c('v-row',[_c('v-col',{staticClass:"switch-continer",attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"color":"info","label":_vm.choice.withProcedure
                  ? 'La scelta include delle indicazioni'
                  : 'La scelta non include delle indicazioni'},on:{"change":function($event){return _vm.updated()}},model:{value:(_vm.choice.withProcedure),callback:function ($$v) {_vm.$set(_vm.choice, "withProcedure", $$v)},expression:"choice.withProcedure"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ckeditor',{attrs:{"disabled":!_vm.choice.withProcedure,"editor":_vm.editor,"config":_vm.editorConfig},on:{"input":function($event){return _vm.updated()}},model:{value:(_vm.choice.procedure),callback:function ($$v) {_vm.$set(_vm.choice, "procedure", $$v)},expression:"choice.procedure"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","loading":_vm.updating},on:{"click":function($event){return _vm.deleteItem()}}},[_vm._v(" Elimina scelta ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }