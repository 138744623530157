<template>
  <div class="component-teststages">
    <v-stepper class="elevation-0"
      v-if="status && status.currentStage"
      v-model="status.currentStage">
      <v-stepper-header>
        <template v-for="(stage, index) of test">
          <v-stepper-step
            :key="`test-stage-${index}`"
            :step="index+1"
            :complete="isStageComplete(stage)"
            :color="stageColor(stage)">
            {{ stage.title }}
          </v-stepper-step>

          <v-divider
            v-if="index < test.length - 1"
            :key="`test-stage-${index}-divider`"></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'TestStages',

  props: ['value', 'test'],

  components: {
  },

  data: () => ({
    status: null,
  }),

  created() {
    this.status = this.value;
  },

  watch: {
    value(newValue) {
      console.log('TestStages: watch -> value'); // eslint-disable-line no-console
      this.status = newValue;
    },

    status(newValue) {
      console.log('TestStages: watch -> status'); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
  },

  methods: {
    isStageComplete(stage) {
      return this.status
        && 'stages' in this.status
        && stage.name in this.status.stages
        && 'complete' in this.status.stages[stage.name]
        ? this.status.stages[stage.name].complete
        : false;
    },

    stageColor(stage) {
      return this.isStageComplete(stage) ? 'success' : 'warning';
    },
  },
};
</script>

<style>
</style>
