<template>
  <div class="component-user-image">
    <v-card>
      <v-card-text>
        <v-img v-if="hasImage" :src="user.imageUrl" aspect-ratio="1">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center">
              <v-progress-circular indeterminate color="secondary"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-img v-else src="../assets/user-placeholder.png" aspect-ratio="1">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center">
              <v-progress-circular indeterminate color="secondary"></v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <!-- Campo nascosto per caricare l'allegato -->
        <input type="file" id="fileInput" ref="fileInput"
          accept="image/*"
          @change="handleFileChange($event)">
      </v-card-text>

      <v-card-actions>
        <v-btn text color="primary"
          v-if="hasImage"
          :disabled="updating"
          :loading="updating"
          @click="remove()">
          Rimuovi
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn text color="primary"
          v-if="hasImage"
          :disabled="updating"
          :loading="updating"
          @click="upload()">
          Sostituisci
        </v-btn>
        <v-btn text color="primary"
          v-if="!hasImage"
          :disabled="updating"
          :loading="updating"
          @click="upload()">
          Carica foto
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'UserImage',

  props: ['value'],

  data: () => ({
    updating: false,
    user: null,
  }),

  created() {
    this.user = this.value;
  },

  watch: {
    value(newValue) {
      console.log('UserImage: watch -> value'); // eslint-disable-line no-console
      if (newValue && 'id' in newValue) {
        if (!this.user) this.user = newValue;
        else if (this.user && 'id' in this.user && this.user.id !== newValue.id) this.user = newValue;
      }
    },

    user(newValue) {
      console.log('UserImage: watch -> user'); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
    hasImage() {
      return this.user.hasOwnProperty('imageUrl') && this.user.imageUrl !== null;
    },
  },

  methods: {
    async remove() {
      this.updating = true;

      // Se stiamo modificando un prodotto esistente...
      if (this.user.hasOwnProperty('id') && this.user.id !== null) {
        // ... elimina l'immagine anche da Firebase
        await this.$store.dispatch('users/removeImage', {
          userId: this.user.id,
        });
      }

      this.$set(this.user, 'imageUrl', null);
      this.$set(this.user, 'imageName', null);

      this.$refs.fileInput.value = '';

      this.updating = false;
    },

    upload() {
      this.$refs.fileInput.click();
    },

    async handleFileChange(evt) {
      console.log(`${this.constructor.name}: handleFileChange()`); // eslint-disable-line no-console

      console.log('evt.target.files =', evt.target.files); // eslint-disable-line no-console

      if (evt.target.files && evt.target.files.length === 1) {
        this.updating = true;

        const { imageUrl, imageName } = await this.$store.dispatch('users/updateImage', {
          userId: this.user.id,
          file: evt.target.files[0],
        });

        if (imageUrl && imageName) {
          this.$set(this.user, 'imageUrl', imageUrl);
          this.$set(this.user, 'imageName', imageName);
        }

        this.updating = false;
      }
    },
  },
};
</script>

<style scoped>
  .user-image {
    min-height: 300px;
  }

  #fileInput {
    display: none;
  }
</style>
