<template>
  <div class="component-edituserdialog">
    <v-dialog v-model="dialog" max-width="600" scrollable>
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>
        <v-card>
          <v-card-title class="headline">Modifica un utente</v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-alert
              v-if="!updatedUser.pricelist"
              type="warning" class="mb-4"
              transition="scale-transition">
              <strong>Non è stato selezionato un listino.</strong><br>
              L'utente non potrà visualizzare i prezzi dei prodotti.
            </v-alert>

            <v-row>
              <v-col cols="12" md="6" lg="6" xl="6">
                <UserImage v-model="user" />
              </v-col>

              <v-col cols="12" md="6" lg="6" xl="6">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="updatedUser.type"
                      :disabled="loading"
                      :items="userTypes"
                      label="Tipologia"
                      hide-details
                      filled
                      required
                      :rules="[(v) => !!v || 'La tipologia di utente è richiesta']"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-autocomplete
                      v-model="updatedUser.pricelist"
                      :disabled="loading"
                      :items="pricelists"
                      item-text="name"
                      item-value="id"
                      label="Listino"
                      placeholder="Nessun listino selezionato"
                      clearable
                      :hide-details="!pricelistHint"
                      :hint="pricelistHint"
                      :persistent-hint="!!pricelistHint"
                      :class="{
                        'to-confirm': !!pricelistHint && !updatedUser.pricelist,
                        'confirmed': !!pricelistHint && updatedUser.pricelist,
                      }"
                      filled
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-switch color="error" class="mt-0"
                      v-model="updatedUser.isSuper"
                      :label="updatedUser.isSuper ? 'È un Super Utente' : 'NON è un Super Utente'"
                      :hint="updatedUser.isSuper
                        ? 'Vede tutti i preventivi e ha accesso completo alla gestione.'
                        : 'Vede soltanto i suoi preventivi e non ha accesso alla gestione.'"
                      persistent-hint></v-switch>
                  </v-col>

                  <v-col cols="12">
                    <v-switch color="info" class="mt-0"
                      v-model="updatedUser.showLogo"
                      :disabled="loading || !hasImage"
                      :label="updatedUser.showLogo
                        ? 'Usa l\'immagine nei preventivi'
                        : 'Non usare l\'immagine nei preventivi'"
                      :hint="updatedUser.showLogo
                        ? 'L\'immagine verrà mostrata nei preventivi assieme ai dati completi.'
                        : 'L\'immagine NON verrà mostrata nei preventivi.'"
                      persistent-hint></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" v-if="hasCompany">
                <v-text-field type="text"
                  v-model="updatedUser.company"
                  :disabled="loading"
                  label="Ragione sociale"
                  :rules="[v => !!v || 'La ragione sociale è richiesta']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="text"
                  v-model="updatedUser.firstName"
                  :disabled="loading"
                  label="Nome"
                  :rules="[v => !!v || 'Il nome dell\'utente è richiesto']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="text"
                  v-model="updatedUser.lastName"
                  :disabled="loading"
                  label="Cognome"
                  :rules="[v => !!v || 'Il cognome dell\'utente è richiesto']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-if="hasCompany">
                <v-textarea
                  v-model="updatedUser.address"
                  :disabled="loading"
                  label="Indirizzo"
                  dense
                  rows="1"
                  no-resize auto-grow
                ></v-textarea>
              </v-col>

              <v-col cols="2">
                <v-text-field type="text" dense
                  v-model="updatedUser.postalCode"
                  :disabled="loading"
                  label="CAP"
                ></v-text-field>
              </v-col>

              <v-col cols="5">
                <v-text-field type="text" dense
                  v-model="updatedUser.city"
                  :disabled="loading"
                  label="Città"
                ></v-text-field>
              </v-col>

              <v-col cols="5">
                <v-autocomplete dense
                  v-model="updatedUser.province"
                  :disabled="loading"
                  :items="provinces"
                  clearable
                  label="Provincia"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-text-field type="text"
                  :value="email"
                  disabled
                  label="Email"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="text"
                  v-model="updatedUser.phone"
                  :disabled="loading"
                  label="Telefono"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field type="text"
                  v-model="updatedUser.phone1"
                  :disabled="loading"
                  label="Telefono"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="updatedUser.checkbox1"
                  color="success"
                  required
                  :rules="[(v) => !!v || 'L\'utente deve accettare l\'Informativa sul Trattamento dei Dati Personali']"
                >
                  <template v-slot:label>
                    <div>
                      L'utente ha letto e accettato le condizioni dell'Informativa
                        sul Trattamento dei Dati Personali
                        (obbligatorio)
                    </div>
                  </template>
                </v-checkbox>
              </v-col>

              <v-col cols="12">
                <v-checkbox
                  v-model="updatedUser.checkbox2"
                  label="L'utente acconsente all'invio di comunicazioni commerciali riguardanti nuovi prodotti o novità sui prodotti già esistenti (facoltativo)"
                  color="success"
                ></v-checkbox>
              </v-col>
            </v-row>

            <p>Premendo il pulsante <strong>Aggiorna</strong>, le informazioni dell'utente
              verranno modificate.</p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="onCancel">
              Annulla
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn type="submit"
              color="success"
              :loading="loading"
              :disabled="!valid || loading"
              @click="onUpdate">
              Aggiorna
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { UPDATE_USER } from '@/store/actions/users';
import { REFRESH_CURRENT_USER } from '@/store/actions/auth';
import UserImage from '@/components/UserImage';
import { getProvince, getPricelistByProvince } from '@/utils/italy';

export default {
  name: 'EditUserDialog',

  components: {
    UserImage,
  },

  props: ['value', 'user'],

  data: () => ({
    dialog: false,
    valid: false,
    loading: false,

    userTypes: [
      { value: 'privato', text: 'Privato' },
      { value: 'rivenditore', text: 'Rivenditore' },
      { value: 'venditore-esterno', text: 'Venditore esterno della rivendita' },
      { value: 'agente', text: 'Agente' },
      { value: 'imbianchino', text: 'Imbianchino' },
      { value: 'progettista', text: 'Progettista' },
      { value: 'keim', text: 'Funzionario KEIM' },
    ],

    updatedUser: {
      type: 'privato',
      firstName: '',
      lastName: '',
      company: '',
      address: '',
      postalCode: '',
      city: '',
      province: '',
      phone: '',
      phone1: '',
      pricelist: '',
      isSuper: false,
      showLogo: false,
      checkbox1: false,
      checkbox2: false,
    },
  }),

  watch: {
    value(newValue) {
      if (newValue === true) {
        this.updatedUser.type = this.user.type || 'privato';
        this.updatedUser.firstName = this.user.firstName;
        this.updatedUser.lastName = this.user.lastName;
        this.updatedUser.company = this.user.company || '';
        this.updatedUser.address = this.user.address || '';
        this.updatedUser.postalCode = this.user.postalCode || '';
        this.updatedUser.city = this.user.city || '';
        this.updatedUser.province = this.user.province || '';
        this.updatedUser.phone = this.user.phone || '';
        this.updatedUser.phone1 = this.user.phone1 || '';
        this.updatedUser.pricelist = this.user.pricelist;
        this.updatedUser.isSuper = this.user.isSuper === true;
        this.updatedUser.showLogo = this.user.showLogo === true;
        this.updatedUser.checkbox1 = this.user.checkbox1 === true;
        this.updatedUser.checkbox2 = this.user.checkbox2 === true;
      }
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.getters.user;
    },

    isSelf() {
      return this.currentUser && this.user && this.currentUser.id === this.user.id;
    },

    hasCompany() {
      return ['rivenditore', 'venditore-esterno', 'agente', 'imbianchino', 'progettista', 'keim']
        .includes(this.updatedUser.type);
    },

    email() {
      return this.user && 'email' in this.user ? this.user.email : '';
    },

    hasImage() {
      return this.user && 'imageUrl' in this.user && this.user.imageUrl !== null;
    },

    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    provinces: () => getProvince(),

    // Restituisce il nome del listino da confermare (in base alla provincia, se presente)
    pricelistToConfirm() {
      return getPricelistByProvince(this.updatedUser.province) || null;
    },

    pricelistHint() {
      if (!this.user || this.user.pricelist || !this.user.province) return null;
      return `Listino suggerito in base alla provincia: ${this.pricelistToConfirm}`;
    },
  },

  methods: {
    onCancel() {
      this.dialog = false;
    },

    async onUpdate() {
      const formRef = this.$refs.form;

      if (!formRef.validate()) return;

      this.loading = true;

      await this.$store.dispatch(`users/${UPDATE_USER}`, {
        user: {
          id: this.user.id,
          ...this.updatedUser,
        },
      });

      if (this.isSelf) {
        console.log('Stai modificando te stesso'); // eslint-disable-line no-console
        await this.$store.dispatch(REFRESH_CURRENT_USER);
      }

      this.dialog = false;
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input::v-deep {
  &.to-confirm {
    .v-input__control {
      .v-input__slot {
        background-color: var(--v-warning-lighten3);
      }
    }
  }

  &.confirmed {
    .v-input__control {
      .v-input__slot {
        background-color: var(--v-success-lighten3);
      }
    }
  }
}
</style>
