<template>
  <div class="view-listini">
    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-palette" />

    <!-- v-alert text prominent type="warning" border="left">
      <v-row>
        <v-col>
          <strong>Attenzione!</strong> Quest'area sarà accessibile soltanto agli amministratori
          del software e rimarrà abilitata per tutti soltanto durante la fase di sviluppo.<br>
          Le modifiche effettuate qui potrebbero influire sul suo corretto funzionamento.
        </v-col>
      </v-row>
    </v-alert -->

    <Colorlists />

    <v-speed-dial
      v-model="fab"
      fixed
      bottom
      right
      direction="top"
      open-on-hover
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          dark fab large
          color="success">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-btn fab dark small color="success"
        @click.stop="dialog = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn fab dark small color="orange"
        @click.stop="publishColorlistsDialog = true">
        <v-icon>mdi-publish</v-icon>
      </v-btn>
    </v-speed-dial>

    <NewColorlistDialog v-model="dialog" />

    <PublishColorlistsDialog
      v-model="publishColorlistsDialog" />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import Colorlists from '@/components/Colorlists';
import NewColorlistDialog from '@/components/NewColorlistDialog';
import PublishColorlistsDialog from '@/components/PublishColorlistsDialog';

export default {
  name: 'Listini',

  components: {
    PageHeader,
    Colorlists,
    NewColorlistDialog,
    PublishColorlistsDialog,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: 'Cartelle colori',
        exact: true,
        to: { name: 'Listini' },
      },
    ],

    fab: false,
    dialog: false,
    publishColorlistsDialog: false,
  }),
};
</script>
