<template>
  <div class="component-editproductareadialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Modifica la superficie</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-form ref="form" v-model="valid" lazy-validation
                @submit.prevent="onConfirm">
                <v-text-field class="area-input"
                  v-model="area"
                  label="Superficie in m²"
                  filled
                  outlined
                  dense
                  reverse
                  autofocus
                  :rules="areaRules"
                  required
                  @focus="$event.target.select()"
                  ></v-text-field>
              </v-form>
            </v-col>
          </v-row>

          <v-alert text
            :type="locked ? 'warning' : 'info'"
            :color="locked ? 'warning' : 'secondary'"
            :icon="locked ? 'mdi-link' : 'mdi-link-off'">
            <v-row align="center" no-gutters>
              <v-col class="grow">
                <template v-if="locked">
                  <strong>Superficie collegata</strong><br>
                  Modificando questa superficie verrà cambiata automaticamente
                  anche quella degli altri prodotti collegati.
                </template>
                <template v-else>
                  <strong>Superficie autonoma</strong><br>
                  La superficie su cui verrà utilizzato questo prodotto
                  è modificabile autonomamente.
                </template>
              </v-col>
              <v-col class="shrink">
                <v-btn
                  :color="locked ? 'warning' : 'secondary'"
                  depressed
                  v-text="locked ? 'Scollega' : 'Collega'"
                  @click="onToggleLockedArea"></v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text
            @click="onConfirm">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'EditProductAreaDialog',

  props: ['value', 'product', 'quotation'],

  data: () => ({
    dialog: false,
    area: 0,
    valid: false,
    areaRules: [
      (v) => !!v || 'La superficie è richiesta',
      (v) => parseFloat(`${v}`.replace(',', '.').replace(/[^0-9.-]+/g, '')) >= 0 || 'La superficie deve essere maggiore o uguale a 0',
    ],
  }),

  watch: {
    value(newValue) {
      if (newValue === true) {
        this.area = this.selectedProduct.area;

        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);

        const oldArea = parseFloat(`${this.selectedProduct.area}`.replace(',', '.').replace(/[^0-9.]+/g, ''));
        const newArea = parseFloat(`${this.area}`.replace(',', '.').replace(/[^0-9.]+/g, ''));

        if (oldArea !== newArea) {
          // eslint-disable-next-line no-console
          console.log('emit(updateArea)');
          this.$emit('updateArea', newArea);
        }
      }
    },
  },

  computed: {
    selectedProduct() {
      if (!this.product) return null;

      return this.quotation.selectedProducts
        .find((sp) => {
          if ('sId' in this.product && this.product.sId !== null && 'sId' in sp) {
            return this.product.sId === sp.sId;
          }
          return sp.id === this.product.id;
        });
    },

    locked() {
      return this.product
        && this.product.hasOwnProperty('lockedArea')
        && this.product.lockedArea === true;
    },
  },

  methods: {
    onToggleLockedArea() {
      this.$emit('toggleLockedArea');
    },

    onConfirm() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.v-input.area-input >>> input {
  font-size: 2rem;
  max-height: none;
}
</style>
