<template>
  <v-row>
    <v-col>
      <v-radio-group v-model="radioValue" column>
        <v-radio v-for="answer of answers"
          :key="answer.title"
          :label="answer.title"
          color="success"
          :value="answer.title"
          @change="onAnswer(answer)"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'QuestionRadio',

  props: ['value', 'answers'],

  data: () => ({
    status: null,
    radioValue: null,
  }),

  created() {
    console.log('QuestionRadio: created()'); // eslint-disable-line no-console
    this.status = this.value;
  },

  watch: {
    value(newValue) {
      console.log('QuestionRadio: watch.value()'); // eslint-disable-line no-console
      this.status = newValue;
      this.radioValue = null;
    },
  },

  computed: {
  },

  methods: {
    onAnswer(answer) {
      this.$emit('onAnswer', answer);
      this.$nextTick(() => {
        setTimeout(() => {
          this.radioValue = null;
        }, 1000);
      });
    },
  },
};
</script>

<style scoped>
</style>
