var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-manualselection"},[_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{attrs:{"cols":"12","order":"last","sm":"8","order-sm":"first"}},[_c('PricelistAlert'),_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","placeholder":"Cerca...","append-icon":"mdi-magnify","clearable":"","dense":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","md":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.phases,"item-text":"name","item-value":"id","placeholder":"Tutte le fasi di lavorazione","outlined":"","clearable":"","dense":""},model:{value:(_vm.filters.phase),callback:function ($$v) {_vm.$set(_vm.filters, "phase", $$v)},expression:"filters.phase"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredProducts,"item-key":"id","items-per-page":15,"sort-by":("name." + _vm.userLanguage),"sort-desc":false},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name[_vm.userLanguage])+" ")]}},{key:"item.phase",fn:function(ref){
var item = ref.item;
return [_c('ProductPhases',{attrs:{"product":item}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"d-flex justify-end flex-nowrap"},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!_vm.hasAttachments(item)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"disabled":!_vm.hasAttachments(item),"dark":_vm.hasAttachments(item),"fab":"","x-small":"","depressed":"","color":"secondary"}},Object.assign({}, on, menu)),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.attachments),function(attachment,index){return _c('v-list-item',{key:("attachment-" + index),on:{"click":function($event){return _vm.onDownloadAttachment(attachment)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-download")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getAttachmentDisplayName(attachment, index))+" ")])],1)],1)}),1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getAttachmentsTooltip(item)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","depressed":"","color":"secondary","dark":""},on:{"click":function($event){return _vm.onDetails(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Mostra i dettagli del prodotto")])]),(item.hasOwnProperty('standalone') && item.standalone === false)?_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","x-small":"","depressed":"","color":"grey","light":"","disabled":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-circle-off-outline")])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","depressed":"","color":"success","dark":""},on:{"click":function($event){return _vm.onSelect(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiungi ai prodotti selezionati")])])],1)]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","order":"first","sm":"4","order-sm":"last"}},[_c('SelectedProducts',{attrs:{"quotation":_vm.quotation,"unselect":"","details":""},on:{"unselect":_vm.onUnselect,"update":_vm.onUpdate}})],1)],1),_c('ProductDetailsDialog',{attrs:{"product":_vm.selected,"quotation":_vm.quotation},on:{"select":_vm.onSelect,"unselect":_vm.onUnselect,"update":_vm.onUpdate},model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }