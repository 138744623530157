<template>
  <div class="component-phases">
    <v-row no-gutters
      v-if="phases.length > 0">
      <v-col>
        <v-card outlined>
          <!-- v-card-title>
            <v-spacer></v-spacer>
          </v-card-title -->

          <v-simple-table>
            <template v-slot:default>
              <draggable tag="tbody"
                v-model="phases"
                handle=".sortHandle"
                draggable=".sortableRow">
                <tr class="sortableRow" v-for="item in phases" :key="item.id">
                  <td class="px-1" style="width: 0.1%">
                    <v-btn style="cursor: move" icon class="sortHandle">
                      <v-icon>mdi-drag</v-icon>
                    </v-btn>
                  </td>
                  <td>{{ item.name }}</td>
                  <td class="text-right text-no-wrap">
                    <v-tooltip top v-if="item.withProcedure">
                      <template v-slot:activator="{ on }">
                        <v-icon color="info" v-on="on">
                          mdi-script-text-outline
                        </v-icon>
                      </template>
                      <span>Include una procedura</span>
                    </v-tooltip>

                    <v-tooltip top v-if="hasProducts(item)">
                      <template v-slot:activator="{ on }">
                        <v-icon color="info" v-on="on">
                          mdi-format-list-text
                        </v-icon>
                      </template>
                      <span>Include prodotti</span>
                    </v-tooltip>

                    <v-menu bottom left>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" class="ml-2">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="onRename(item)">
                          <v-list-item-title>Rinomina</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="onDelete(item)">
                          <v-list-item-title>Elimina</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-btn class="ml-2" fab x-small depressed color="secondary" dark
                      @click="onEdit(item)">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-alert text prominent type="success" border="left"
      v-else>
      <v-row>
        <v-col
          class="d-flex flex-column flex-sm-row justify-space-between align-start align-sm-center">
          <span>Al momento non è presente nessuna fase di lavorazione.</span>
          <v-btn color="success" class="mt-4 mt-sm-0"
            @click.stop="newDialog = true">Crea una fase</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <NewPhaseDialog v-model="newDialog" />
    <RenamePhaseDialog v-model="renameDialog" :phase="selected" />
    <DeletePhaseDialog v-model="deleteDialog" :phase="selected" />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import NewPhaseDialog from '@/components/NewPhaseDialog';
import RenamePhaseDialog from '@/components/RenamePhaseDialog';
import DeletePhaseDialog from '@/components/DeletePhaseDialog';

export default {
  name: 'Phases',

  components: {
    draggable,
    NewPhaseDialog,
    RenamePhaseDialog,
    DeletePhaseDialog,
  },

  data: () => ({
    newDialog: false,
    renameDialog: false,
    deleteDialog: false,

    selected: null,
  }),

  computed: {
    phases: {
      get() {
        return this.$store.getters['phases/docs'];
      },
      set(phases) {
        this.$store.dispatch('phases/reorder', phases);
      },
    },
  },

  methods: {
    hasProducts(phase) {
      // eslint-disable-next-line no-prototype-builtins
      return phase && phase.hasOwnProperty('choices')
        && Object.values(phase.choices).length > 0;
    },

    onRename(item) {
      this.selected = item;
      this.renameDialog = true;
    },

    onDelete(item) {
      this.selected = item;
      this.deleteDialog = true;
    },

    onEdit(item) {
      this.$router.push({ name: 'Fase', params: { id: item.id } });
    },
  },
};
</script>
