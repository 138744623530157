<template>
  <div class="component-user-consent">
    <!--
      Mostra un'icona verde se l'utente ha un consenso associato, rossa altrimenti.

      Passando col mouse sopra l'icona, mostra un tooltip con il testo
      "Consenso" o "Nessun consenso".
     -->
    <v-tooltip
      :color="user.checkbox1 ? 'success' : 'error'"
      bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          :color="user.checkbox1 ? 'success' : 'error'"
        >
          {{ user.checkbox1
            ? 'mdi-checkbox-marked-circle'
            : 'mdi-close-circle' }}
        </v-icon>
      </template>
      <span>{{ user.checkbox2
        ? "L'utente ha letto e accettato le condizioni dell'Informativa sul Trattamento dei Dati Personali"
        : "L'utente NON ha letto e accettato le condizioni dell'Informativa sul Trattamento dei Dati Personali" }}</span>
    </v-tooltip>

    <v-tooltip
      :color="user.checkbox2 ? 'success' : 'warning'"
      bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          :color="user.checkbox2 ? 'success' : 'warning'"
        >
          {{ user.checkbox2
            ? 'mdi-checkbox-marked-circle-outline'
            : 'mdi-close-circle-outline' }}
        </v-icon>
      </template>
      <span>{{ user.checkbox2
        ? "L'utente acconsente all'invio di comunicazioni commerciali"
        : "L'utente NON acconsente all'invio di comunicazioni commerciali" }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'UserConsent',

  props: ['user'],

  data: () => ({
  }),
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
