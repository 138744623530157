<template>
  <div class="component-editpackagingdialog">
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-card-title class="headline">Modifica il numero di confezioni</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="(pack, index) in packages"
                      :key="index">
                      <td class="text-right">
                        <span class="display-1 font-weight-light">{{ pack.packaging }}</span>
                        <span class="font-weight-light mr-1">{{ umLongPlural }}</span>
                      </td>
                      <td>
                        <span class="display-1 font-weight-light text--secondary">x&nbsp;</span>
                        <span class="display-1 font-weight-light">{{ pack.quantity }}</span>
                      </td>
                      <td class="d-flex flex-nowrap justify-end align-center">
                        <v-btn
                          class="ma-1"
                          fab x-small depressed dark
                          color="red lighten-2"
                          @click="onDecrement(index)"
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>

                        <v-btn
                          class="ma-1"
                          fab x-small depressed dark
                          color="success lighten-2"
                          @click="onIncrement(index)"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <v-col cols="4">
              <v-text-field
                v-if="product.um !== 'pz'"
                :value="estimatedQuantityText"
                readonly
                label="Quantità stimata"
                filled
                outlined
                dense
                ></v-text-field>

              <v-text-field
                :value="quantityText"
                readonly
                label="Quantità selezionata"
                filled
                outlined
                dense
                :error="under"
                ></v-text-field>
            </v-col>
          </v-row>

          <v-alert
            v-if="product.um !== 'pz'"
            :type="under ? 'error' : 'warning'" v-model="error" class="mb-4"
            transition="scale-transition">
            <span v-if="under">
              La quantità effettiva calcolata con le confezioni selezionate è
              <strong>inferiore</strong> alla quantità stimata in base all'incidenza
              e alla superficie.
            </span>
            <span v-if="over">
              La quantità effettiva calcolata con le confezioni selezionate è
              <strong>superiore</strong> alla quantità stimata in base all'incidenza
              e alla superficie.
            </span>
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="success" text
            @click="onCancel">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'EditPackagingDialog',

  props: ['value', 'product', 'estimatedQuantity'],

  data: () => ({
    dialog: false,
  }),

  watch: {
    value(newValue) {
      if (newValue === true) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    packages() {
      if (!this.product) return [];

      return this.product.priceranges[this.product.priceRange || 0].prices;
    },

    umLongPlural() {
      if (!this.product) return '';

      switch (this.product.um) {
        case 'kg': return 'kilogrammi';
        case 'l': return 'litri';
        case 'pz': return 'pezzi';
        case 'mq': return 'mq';
        default: return '';
      }
    },

    // La quantità totale calcolata in base al lattaggio selezionato
    quantity() {
      if (!this.product) return null;

      let q = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const priceIndex in this.product.priceranges[this.product.priceRange || 0].prices) {
        if (this.product.priceranges[this.product.priceRange || 0]
          .prices.hasOwnProperty(priceIndex)) {
          const price = this.product.priceranges[this.product.priceRange || 0].prices[priceIndex];

          q += parseFloat(`${price.packaging}`.replace(',', '.').replace(/[^0-9.]+/g, '')) * price.quantity;
        }
      }

      return q;
    },

    quantityText() {
      if (!this.product) return '';

      return !Number.isNaN(this.quantity) ? `${new Intl.NumberFormat('it-IT').format(this.quantity)} ${this.product.um}` : '';
    },

    // La versione testuale della quantità totale stimata
    estimatedQuantityText() {
      if (!this.product) return '';

      const rq = this.estimatedQuantity;

      return !Number.isNaN(rq) ? `${new Intl.NumberFormat('it-IT').format(rq)} ${this.product.um}` : '';
    },

    under() {
      return this.quantity < this.estimatedQuantity;
    },

    over() {
      return this.quantity > this.estimatedQuantity;
    },

    error() {
      return this.under || this.over;
    },
  },

  methods: {
    onIncrement(index) {
      this.$emit('increment', index);
    },

    onDecrement(index) {
      if (this.product.priceranges[this.product.priceRange || 0].prices[index].quantity > 0) {
        this.$emit('decrement', index);
      }
    },

    onCancel() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
</style>
