<template>
  <div class="view-preventivopdf">
    <QuotationPDF v-model="quotation"
      :loading="loading" />
  </div>
</template>

<script>
import QuotationPDF from '@/components/QuotationPDF';

export default {
  name: 'PreventivoPDF',

  components: {
    QuotationPDF,
  },

  data: () => ({
    loading: true,

    id: null,
    quotation: null,
  }),

  async created() {
    console.log('this.$route.params.id = ', this.$route.params.id); // eslint-disable-line no-console
    this.id = this.$route.params.id;

    const { selectedProducts, ...quotation } = await this.$store.dispatch('quotations/getById', this.id);

    this.quotation = {
      id: this.id,
      ...quotation,
      selectedProducts,
    };

    this.loading = false;
  },
};
</script>
