/* eslint-disable no-shadow */

import Vue from 'vue';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';

import {
  INIT_NOTIFICATIONS, UPDATE_NOTIFICATIONS, SET_NOTIFICATIONS, CLEAR_NOTIFICATIONS,
  SET_NOTIFICATIONS_LISTENER, ADD_NOTIFICATION, UPDATE_NOTIFICATION, DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION_ATTACHMENT, DELETE_NOTIFICATION_ATTACHMENT,
} from '@/store/actions/notifications';

// Dove verranno memorizzati gli allegati su Firebase Storage
const attachmentsPath = 'attachments/notifications/';

const state = {
  data: null,
  listener: null,
};

const getters = {
  docs: (state) => (state.data ? Object.values(state.data) : null),
};

const actions = {
  [INIT_NOTIFICATIONS]: ({ dispatch }) => {
    console.log('actions:INIT_NOTIFICATIONS()'); // eslint-disable-line no-console

    dispatch(UPDATE_NOTIFICATIONS);
  },

  [UPDATE_NOTIFICATIONS]: ({ commit }) => {
    console.log('actions:UPDATE_NOTIFICATIONS()'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Recupera la collection con l'elenco
    const collectionRef = db.collection('notifications');

    // Attiva il listener
    const listener = collectionRef.orderBy('createdAt', 'desc').onSnapshot((snapshot) => {
      console.log('actions:UPDATE_NOTIFICATIONS() => onSnapshot()'); // eslint-disable-line no-console

      commit(SET_NOTIFICATIONS, { snapshot });
    });
    commit(SET_NOTIFICATIONS_LISTENER, listener);
  },

  [ADD_NOTIFICATION]: (context, payload) => {
    console.log('actions:ADD_NOTIFICATION() payload =', payload); // eslint-disable-line no-console

    const db = firebase.firestore();

    const createdAt = firebase.firestore.FieldValue.serverTimestamp();

    // Se nel payload è presente un id, stiamo aggiornando un documento esistente
    if ('id' in payload && payload.id) {
      // Rimuoviamo l'id dal payload
      const { id, ...data } = payload;

      db.collection('notifications').doc(payload.id).update({ ...data });
    } else {
      db.collection('notifications').add({ ...payload, createdAt });
    }
  },

  [UPDATE_NOTIFICATION]: (context, payload) => {
    console.log('actions:UPDATE_NOTIFICATION() payload =', payload); // eslint-disable-line no-console

    // L'update per funzionare correttamente richiede l'id del documento
    if (!('id' in payload && payload.id)) {
      console.error('actions:UPDATE_NOTIFICATION() => payload.id is missing'); // eslint-disable-line no-console
      return;
    }

    context.dispatch(ADD_NOTIFICATION, payload);
  },

  [DELETE_NOTIFICATION]: (context, payload) => {
    console.log('actions:DELETE_NOTIFICATION() payload =', payload); // eslint-disable-line no-console

    const db = firebase.firestore();

    db.collection('notifications').doc(payload).delete();
  },

  [UPDATE_NOTIFICATION_ATTACHMENT]: async (context, payload) => {
    console.log('actions:UPDATE_NOTIFICATION_ATTACHMENT() payload =', payload); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Recupera l'eventuale id della notifica passata nel payload
    const notificationId = payload && 'id' in payload ? payload.id : null;

    // Recupera l'eventuale nome dell'allegato da sostituire passato nel payload
    let acutalAttachmentName = payload && 'attachmentName' in payload ? payload.attachmentName : null;

    // Se il payload contiene una notifica esistente (che include l'id)...
    if (notificationId) {
      console.log('actions:UPDATE_NOTIFICATION_ATTACHMENT() => notificationId =', notificationId); // eslint-disable-line

      // Recupera il riferimento alla notifica
      const notificationRef = db.collection('notifications').doc(notificationId);

      // Recupera il nome dell'allegato da eliminare dallo storage
      const notificationDoc = await notificationRef.get();
      const notificationData = notificationDoc.data();
      if ('attachmentName' in notificationData) {
        acutalAttachmentName = notificationData.attachmentName;
      }
    }

    // Rimuove il file dallo storage (se presente)
    if (acutalAttachmentName) {
      const storageRef = firebase.storage().ref();
      const attachmentRef = storageRef.child(`${attachmentsPath}${acutalAttachmentName}`);
      await attachmentRef.delete();

      console.log(`actions:UPDATE_NOTIFICATION_ATTACHMENT() => allegato eliminato: ${attachmentsPath}${acutalAttachmentName}`); // eslint-disable-line no-console
    }

    const uploadAttachment = (attachmentName) => new Promise((resolve, reject) => {
      // Inizia l'upload del file verso lo storage
      const storageRef = firebase.storage().ref();
      const uploadRef = storageRef.child(`${attachmentsPath}${attachmentName}`);
      const uploadTask = uploadRef.put(payload.file);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused'); // eslint-disable-line no-console
              break;
            case firebase.storage.TaskState.RUNNING: { // or 'running'
              // console.log('Upload is running'); // eslint-disable-line no-console
              const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${uploadProgress}% done`); // eslint-disable-line no-console
              break;
            }
            default:
          }
        }, (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              // state.importErrors.push(error.code);
              break;

            case 'storage/canceled':
              // User canceled the upload
              // state.importErrors.push(error.code);
              break;

            case 'storage/unknown':
            default:
              // Unknown error occurred, inspect error.serverResponse
              // state.importErrors.push(error.code);
              break;
          }

          return reject(error);
        }, () => {
          console.log('Upload complete'); // eslint-disable-line no-console

          return uploadTask.snapshot.ref.getDownloadURL().then(
            (downloadURL) => resolve(downloadURL),
          );
        });
    });

    // Prepara il nome del file del nuovo allegato
    const attachmentOriginalName = payload.file.name;
    const newAttachmentName = `${uuidv4()}-${attachmentOriginalName}`;

    // Carica l'allegato nello storage
    const attachmentUrl = await uploadAttachment(newAttachmentName);

    // eslint-disable-next-line no-console
    console.log('URL dell\'allegato:', attachmentUrl);

    // Memorizza il riferimento all'immagine nel prodotto
    if (notificationId) {
      const docRef = db.doc(`/notifications/${notificationId}`);

      await docRef.update({
        attachmentUrl,
        attachmentName: newAttachmentName,
        attachmentOriginalName,
      });
    }

    return Promise.resolve({
      attachmentUrl,
      attachmentName: newAttachmentName,
      attachmentOriginalName,
    });
  },

  // Rimuove l'allegato alla notifica
  [DELETE_NOTIFICATION_ATTACHMENT]: async (context, payload) => {
    console.log('actions:DELETE_NOTIFICATION_ATTACHMENT() payload =', payload); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Recupera dal payload l'eventuale id della notifica
    const notificationId = payload && 'id' in payload ? payload.id : null;

    // Recupera dal payload l'eventuale nome dell'allegato da eliminare
    let attachmentName = payload && 'attachmentName' in payload ? payload.attachmentName : null;

    // Se il payload contiene una notifica esistente (che include l'id)...
    if (notificationId) {
      console.log('actions:DELETE_NOTIFICATION_ATTACHMENT() => notificationId =', notificationId); // eslint-disable-line

      // Recupera il riferimento alla notifica
      const notificationRef = db.collection('notifications').doc(notificationId);

      // Recupera il nome dell'allegato da eliminare dallo storage
      const notificationDoc = await notificationRef.get();
      const notificationData = notificationDoc.data();
      if ('attachmentName' in notificationData) {
        attachmentName = notificationData.attachmentName;
      }

      // Rimuove i riferimenti all'allegato
      await notificationRef.update({
        attachmentUrl: null,
        attachmentName: null,
        attachmentOriginalName: null,
      });
    }

    // Elimina l'allegato dallo storage
    if (attachmentName) {
      try {
        const storageRef = firebase.storage().ref();
        const attachmentRef = storageRef.child(`${attachmentsPath}${attachmentName}`);
        await attachmentRef.delete();

        console.log(`actions:DELETE_NOTIFICATION_ATTACHMENT() => allegato eliminato: ${attachmentsPath}${attachmentName}`); // eslint-disable-line no-console
      } catch (error) {
        console.error('actions:DELETE_NOTIFICATION_ATTACHMENT() => errore durante l\'eliminazione dell\'allegato:', error); // eslint-disable-line no-console
      }
    } else {
      console.error('actions:DELETE_NOTIFICATION_ATTACHMENT() => attachmentName assente, impossibile rimuovere il file da Firebase Storage'); // eslint-disable-line no-console
    }

    return Promise.resolve();
  },
};

const mutations = {
  [SET_NOTIFICATIONS_LISTENER]: (state, listener) => {
    console.log('mutations:SET_NOTIFICATIONS_LISTENER()'); // eslint-disable-line no-console

    Vue.set(state, 'listener', listener);
  },

  [SET_NOTIFICATIONS]: (state, payload) => {
    console.log('mutations:SET_NOTIFICATIONS() payload =', payload); // eslint-disable-line no-console

    const data = [];

    if (payload.snapshot) {
      payload.snapshot.forEach((doc) => {
        console.log('mutations:SET_NOTIFICATIONS() doc.id =', doc.id); // eslint-disable-line no-console
        console.log('mutations:SET_NOTIFICATIONS() doc.data() =', doc.data()); // eslint-disable-line no-console

        data.push({ id: doc.id, ...doc.data() });
      });
    } else if (payload.list) {
      data.push(...payload.list);
    }

    Vue.set(state, 'data', data);
  },

  [CLEAR_NOTIFICATIONS]: (state) => {
    console.log('mutations:CLEAR_NOTIFICATIONS()'); // eslint-disable-line no-console

    Vue.set(state, 'data', []);
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
