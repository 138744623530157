<template>
  <div class="component-products">
    <v-row no-gutters
      v-if="products.length > 0">
      <v-col>
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="filters.search"
                  outlined
                  placeholder="Cerca..."
                  append-icon="mdi-magnify"
                  clearable
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="px-1">
                <v-autocomplete
                  v-model="filters.phase"
                  :items="phases"
                  item-text="name"
                  item-value="id"
                  placeholder="Tutte le fasi di lavorazione"
                  outlined
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col v-if="isSuperUser"
                cols="12" md="6" class="archived-switch-continer">
                <v-switch color="orange" class="mt-1"
                  v-model="filters.archived" label="Mostra archiviati"></v-switch>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table class="elevation-0"
            :headers="headers"
            :items="filteredProducts"
            item-key="id"
            :items-per-page="20"
            :sort-by="`name.${userLanguage}`"
            :sort-desc="false"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 30, -1],
            }">

            <template v-slot:[`item.name`]="{ item }">
              {{ item.name[userLanguage] }}
            </template>

            <template v-slot:[`item.phases`]="{ item }">
              <ProductPhases :product="item" />
            </template>

            <template v-slot:[`item.combined`]="{ item }">
              <v-icon small v-if="!item.hasOwnProperty('standalone')
                || item.standalone === true" color="secondary">mdi-bitbucket</v-icon>
              <v-icon small v-if="item.combined" color="secondary">mdi-yin-yang</v-icon>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <div class="d-flex justify-end">
                <template v-if="isSuperUser">
                  <v-icon small color="orange" class="mr-2"
                    v-if="item.archived">
                    mdi-archive
                  </v-icon>

                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <!-- v-list-item
                        @click="onRename(item)">
                        <v-list-item-title>Rinomina</v-list-item-title>
                      </v-list-item -->
                      <v-list-item
                        @click="onDelete(item)">
                        <v-list-item-title>Elimina</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-btn class="ml-2" fab x-small depressed color="secondary" dark
                    @click="onEdit(item)">
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                </template>

                <v-menu bottom left
                  :disabled="!hasAttachments(item)">
                  <template v-slot:activator="{ on: menu }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          v-on="{ ...tooltip, ...menu }"
                          :disabled="!hasAttachments(item)"
                          :dark="hasAttachments(item)"
                          class="ml-1" fab x-small depressed color="secondary">
                          <v-icon dark>mdi-download</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ getAttachmentsTooltip(item) }}</span>
                    </v-tooltip>
                  </template>

                  <v-list dense>
                    <v-list-item
                      v-for="(attachment, index) in item.attachments"
                      :key="`attachment-${index}`"
                      @click="onDownloadAttachment(attachment)">
                      <v-list-item-icon class="mr-2">
                        <v-icon small>mdi-file-download</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ getAttachmentDisplayName(attachment, index) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      class="ml-1" fab x-small depressed color="secondary" dark
                      @click="onDetails(item)">
                      <v-icon dark>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Mostra i dettagli del prodotto</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-alert text prominent type="success" border="left"
      v-else>
      <v-row>
        <v-col
          class="d-flex flex-column flex-sm-row justify-space-between align-start align-sm-center">
          <span>Al momento non è presente nessun prodotto.</span>
          <v-btn v-if="isSuperUser"
            color="success" class="mt-4 mt-sm-0"
            :to="{ name: 'NuovoProdotto' }">Aggiungi un prodotto</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <ProductDetailsDialog
      v-model="detailsDialog"
      :product="selected"
      :select="false" />

    <DeleteProductDialog v-model="deleteDialog" :product="selected" />
  </div>
</template>

<script>
/* eslint-disable quote-props */

import ProductDetailsDialog from '@/components/ProductDetailsDialog';
import DeleteProductDialog from '@/components/DeleteProductDialog';
import ProductPhases from '@/components/ProductPhases';

export default {
  name: 'Products',

  components: {
    ProductDetailsDialog,
    DeleteProductDialog,
    ProductPhases,
  },

  data: () => ({
    newDialog: false,
    renameDialog: false,
    detailsDialog: false,
    deleteDialog: false,

    selected: null,

    headers: [],

    filters: {
      search: '',
      phase: null,
      archived: false,
    },

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  created() {
    this.headers.push({ text: 'Nome del prodotto', value: 'name', sortable: false });
    this.headers.push({ text: 'Fasi di lavorazione', value: 'phases', sortable: false });

    if (this.isSuperUser) {
      this.headers.push({
        text: '',
        value: 'combined',
        sortable: false,
        align: 'center',
      });
      this.headers.push({
        text: 'Revisione',
        value: 'rev',
        sortable: false,
        align: 'center',
      });
    }

    this.headers.push({
      text: '',
      value: 'action',
      sortable: false,
      align: 'end',
    });
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },

    isSuperUser() {
      return this.currentUser.superUser;
    },

    products() {
      return this.$store.getters['products/docs'];
    },

    filteredProducts() {
      return this.products
        ? this.products.filter((p) => {
          // Filtro sugli archiviati
          if (!this.filters.archived && p.archived) return false;

          // Filtro sulla fase
          // eslint-disable-next-line no-prototype-builtins
          if (this.filters.phase && !p.phases.hasOwnProperty(this.filters.phase)) return false;

          // TODO: Campo di ricerca
          if (this.filters.search && this.filters.search.trim()
            && !p.name[this.userLanguage]
              .toLowerCase().includes(this.filters.search.toLowerCase())) return false;

          return true;
        })
        : [];
    },

    phases() {
      return this.$store.getters['phases/docs'];
    },
  },

  methods: {
    onRename(item) {
      this.selected = item;
      this.renameDialog = true;
    },

    onDelete(item) {
      this.selected = item;
      this.deleteDialog = true;
    },

    onEdit(item) {
      this.$router.push({ name: 'Prodotto', params: { id: item.id } });
    },

    onDetails(product) {
      // eslint-disable-next-line no-console
      console.log('Products: onDetails() product =', product);

      this.selected = product;

      if (this.selected) this.detailsDialog = true;
    },

    hasAttachments(product) {
      return Array.isArray(product.attachments) && product.attachments.length > 0;
    },

    onDownloadAttachment(attachment) {
      if (attachment && attachment.url) {
        window.open(attachment.url, '_blank');
      }
    },

    getAttachmentDisplayName(attachment, index) {
      if (attachment.name?.trim()) {
        return attachment.name;
      }

      // Mappa delle categorie
      const categoryMap = {
        'scheda-tecnica': 'Scheda tecnica',
        'cartella-colore': 'Cartella colore',
        'certificato': 'Certificato',
        'opuscolo': 'Opuscolo',
        'epd': 'Dichiarazione ambientale di prodotto (EPD)',
        'dichiarazione-prestazione': 'Dichiarazione di prestazione',
        'scheda-sostenibilità': 'Scheda tecnica di sostenibilità',
        'scheda-sicurezza': 'Scheda di sicurezza',
      };

      // Se la categoria è "altro", usa la categoria personalizzata
      if (attachment.category === 'altro' && attachment.customCategory?.trim()) {
        return attachment.customCategory;
      }

      // Altrimenti usa il nome della categoria standard se disponibile
      if (attachment.category && categoryMap[attachment.category]) {
        return categoryMap[attachment.category];
      }

      // Se non c'è nessuna informazione utile, usa il numero progressivo
      return `Allegato ${index + 1}`;
    },

    getAttachmentsTooltip(item) {
      const count = item.attachments?.length || 0;
      return count === 1
        ? '1 allegato disponibile'
        : `${count} allegati disponibili`;
    },
  },
};
</script>

<style scoped>
.archived-switch-continer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: stretch;
}
</style>
