<template>
  <div class="view-cartellacolori">
    <Loading v-model="loading" />

    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-palette" />

    <Colorlist v-model="colorlist"
      :updating="updating"
      :loading="loading" />

    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-actions v-if="colorlist">
            <v-btn text color="primary"
              :disabled="updating"
              :loading="updating"
              @click="onCancel()">
              Torna all'elenco
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-btn absolute dark fab large
      bottom right
      color="success"
      @click.stop="newDialog = true">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <NewColorDialog v-model="newDialog" :colorlist="colorlist" />
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import PageHeader from '@/components/PageHeader';
import Colorlist from '@/components/Colorlist';
import NewColorDialog from '@/components/NewColorDialog';

export default {
  name: 'CartellaColori',

  components: {
    Loading,
    PageHeader,
    Colorlist,
    NewColorDialog,
  },

  data: () => ({
    loading: true,
    updating: false,

    newDialog: false,

    breadcrumbs: [
      {
        text: 'Cartelle colori',
        exact: true,
        to: { name: 'CartelleColori' },
      },
    ],

    id: null,
    colorlist: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  async created() {
    console.log('this.$route.params.id = ', this.$route.params.id); // eslint-disable-line no-console
    this.id = this.$route.params.id;

    const colorlist = await this.$store.dispatch('colorlists/getById', this.id);
    console.log('colorlist = ', colorlist); // eslint-disable-line no-console

    this.colorlist = {
      id: this.id,
      ...colorlist,
    };

    // Inserisce il nome prodotto nei breadcrumbs
    this.breadcrumbs.push({
      text: this.colorlist.name,
      disabled: true,
    });

    await this.$store.dispatch('colorlists/selectColorlist', { colorlistId: this.colorlist.id });

    this.loading = false;
  },

  destroyed() {
    console.log('destroyed()'); // eslint-disable-line no-console

    this.$store.dispatch('colorlists/unselectColorlist');
  },

  methods: {
    onCancel() {
      // Torna all'elenco delle fasi
      this.$router.replace({ name: 'CartelleColori' }, () => {});
    },
  },
};
</script>
