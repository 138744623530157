/* eslint-disable no-shadow */

import Vue from 'vue';
import firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';

import {
  INIT_USERS, UPDATE_USERS, SET_USERS, ADD_USER, DELETE_USER, UPDATE_USER,
  UPDATE_USER_CREDENTIALS, CLEAR_USERS, DESTROY_USERS, SET_USERS_LISTENER,
  SIGNUP_USER,
} from '@/store/actions/users';

const state = {
  data: null,
  listener: null,
};

const getters = {
  docs: (state) => (state.data ? Object.values(state.data) : null),
};

const actions = {
  [INIT_USERS]: ({ dispatch }) => {
    console.log('actions:INIT_USERS'); // eslint-disable-line no-console

    return dispatch(UPDATE_USERS);
  },

  [UPDATE_USERS]: async ({ commit, state }) => {
    console.log('actions:UPDATE_USERS'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Recupera la collection con l'elenco
    const collectionRef = db.collection('users');
    const listener = collectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (users)'); // eslint-disable-line no-console

      commit(SET_USERS, {
        snapshot,
      });
    });
    commit('SET_USERS_LISTENER', listener);
  },

  [ADD_USER]: async (context, userData) => {
    console.log('actions:ADD_USER userData =', userData); // eslint-disable-line no-console

    const createUser = firebase.functions().httpsCallable('createUser');

    let result = null;
    try {
      result = await createUser(userData);
      console.log('result =', result); // eslint-disable-line no-console
    } catch (error) {
      console.log('error =', error); // eslint-disable-line no-console
      return Promise.reject(error);
    }

    const error = result?.data?.error;
    const code = result?.data?.code;

    if (error) return Promise.reject(code);

    return Promise.resolve();
  },

  [UPDATE_USER]: async (context, { user }) => {
    console.log('actions:UPDATE_USER'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Separa 'id' dal resto
    const { id, pricelist, ...data } = user;

    // console.log('actions:UPDATE_USER id =', id); // eslint-disable-line no-console
    // console.log('actions:UPDATE_USER data =', data); // eslint-disable-line no-console

    const docRef = db.doc(`/users/${id}`);

    return docRef.update({
      // Include `pricelist` solo se è definito
      ...(pricelist ? { pricelist } : {}),
      ...data,
    });
  },

  [UPDATE_USER_CREDENTIALS]: async (context, { user }) => {
    console.log('actions:UPDATE_USER_CREDENTIALS'); // eslint-disable-line no-console

    // Per aggiornare le credenziali di un utente, è necessario passare l'uid,
    // l'email e la nuova password.
    if (!user || !('uid' in user) || !('email' in user) || !('password' in user)) {
      return Promise.reject(new Error('Invalid parameters'));
    }

    const updateUser = firebase.functions().httpsCallable('updateUser');

    let result = null;
    try {
      result = await updateUser({
        uid: user.uid,
        email: user.email,
        password: user.password,
      });
      console.log('actions:UPDATE_USER_CREDENTIALS result =', result); // eslint-disable-line no-console
    } catch (error) {
      console.log('actions:UPDATE_USER_CREDENTIALS error =', error); // eslint-disable-line no-console
      return Promise.reject(error);
    }

    const error = result?.data?.error;
    const code = result?.data?.code;

    if (error) return Promise.reject(code);

    return Promise.resolve();
  },

  [DELETE_USER]: async (context, { user }) => {
    console.log('actions:DELETE_USER'); // eslint-disable-line no-console
    console.log('actions:DELETE_USER user =', user); // eslint-disable-line no-console

    const { id, uid } = user;

    const deleteUser = firebase.functions().httpsCallable('deleteUser');
    const result = await deleteUser({ uid });
    console.log('result =', result); // eslint-disable-line no-console

    // Recupera il riferimento all'utente
    const db = firebase.firestore();
    const docRef = db.doc(`/users/${id}`);

    // Rimuove l'utente
    return docRef.delete();
  },

  [SIGNUP_USER]: async (context, userData) => {
    console.log('actions:SIGNUP_USER userData =', userData); // eslint-disable-line no-console

    const signupUser = firebase.functions().httpsCallable('signupUser');

    let result = null;
    try {
      result = await signupUser(userData);
      console.log('result =', result); // eslint-disable-line no-console
    } catch (error) {
      console.log('error =', error); // eslint-disable-line no-console
      return Promise.reject(error);
    }

    const error = result?.data?.error;
    const code = result?.data?.code;

    if (error) return Promise.reject(code);

    return Promise.resolve();
  },


  [DESTROY_USERS]: ({ state, commit }) => {
    console.log('actions:DESTROY_USERS'); // eslint-disable-line no-console

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Pulisce lo state
    commit(CLEAR_USERS);
  },

  updateImage: async (context, payload) => {
    console.log('actions:updateImage'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Se stiamo modificando un utente esistente...
    if (payload.userId) {
      // ... elimina l'eventuale immagine precedente

      // Recupera il nome dell'immagine da eliminare dallo storage
      const docRef = db.doc(`/users/${payload.userId}`);
      const doc = await docRef.get();
      const docData = doc.data();
      const imageName = docData.imageName || null;

      // Rimuove il file dallo storage (se presente)
      if (imageName) {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(imageName);
        await imageRef.delete();
      }
    }

    const uploadImage = (imageName) => new Promise((resolve, reject) => {
      // Inizia l'upload del file verso lo storage
      const storageRef = firebase.storage().ref();
      const uploadRef = storageRef.child(imageName);
      const uploadTask = uploadRef.put(payload.file);

      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused'); // eslint-disable-line no-console
              break;
            case firebase.storage.TaskState.RUNNING: { // or 'running'
              // console.log('Upload is running'); // eslint-disable-line no-console
              const uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload is ${uploadProgress}% done`); // eslint-disable-line no-console
              break;
            }
            default:
          }
        }, (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              // state.importErrors.push(error.code);
              break;

            case 'storage/canceled':
              // User canceled the upload
              // state.importErrors.push(error.code);
              break;

            case 'storage/unknown':
            default:
              // Unknown error occurred, inspect error.serverResponse
              // state.importErrors.push(error.code);
              break;
          }

          return reject(error);
        }, () => {
          console.log('Upload complete'); // eslint-disable-line no-console

          return uploadTask.snapshot.ref.getDownloadURL().then(
            (downloadURL) => resolve(downloadURL),
          );
        });
    });

    // Prepara il nome del file della nuova immagine
    const imageName = `images/users/${uuidv4()}-${payload.file.name}`;

    // Carica l'immagine nello storage
    const imageUrl = await uploadImage(imageName);

    // eslint-disable-next-line no-console
    console.log('URL dell\'immagine:', imageUrl);

    // Memorizza il riferimento all'immagine nel prodotto
    if (payload.userId) {
      const docRef = db.doc(`/users/${payload.userId}`);

      await docRef.update({
        imageUrl,
        imageName,
      });
    }

    return Promise.resolve({
      imageUrl,
      imageName,
    });
  },

  removeImage: async (context, payload) => {
    console.log('actions:removeImage'); // eslint-disable-line no-console

    // Recupera il riferimento all'utente
    const db = firebase.firestore();
    const docRef = db.doc(`/users/${payload.userId}`);

    // Recupera il nome dell'immagine da eliminare dallo storage
    const doc = await docRef.get();
    const docData = doc.data();
    const imageName = docData.imageName || null;

    // Rimuove i riferimenti all'immagine
    await docRef.update({
      imageUrl: null,
      imageName: null,
    });

    // Rimuove il file dallo storage
    if (imageName) {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child(imageName);
      await imageRef.delete();
    }

    return Promise.resolve();
  },

  fixUserIds: async () => {
    console.log('actions:fixUserIds'); // eslint-disable-line no-console

    const functions = firebase.functions();

    const fixUserIds = functions.httpsCallable('fixUserIds');

    return fixUserIds();
  },

  fixQuotationsUsers: async () => {
    console.log('actions:fixQuotationsUsers'); // eslint-disable-line no-console

    const functions = firebase.functions();

    const fixQuotationsUsers = functions.httpsCallable('fixQuotationsUsers');

    return fixQuotationsUsers();
  },

  sendVerificationEmail: async (context, uid) => {
    console.log('actions:sendVerificationEmail'); // eslint-disable-line no-console

    const sendVerificationEmail = firebase.functions().httpsCallable('sendVerificationEmail');

    let result = null;
    try {
      result = await sendVerificationEmail({ uid });
      console.log('result =', result); // eslint-disable-line no-console
    } catch (error) {
      console.log('error =', error); // eslint-disable-line no-console
      return Promise.reject(error);
    }

    const error = result?.data?.error;
    const code = result?.data?.code;

    if (error) return Promise.reject(code);

    return Promise.resolve();
  },

  forceEmailVerification: async (context, uid) => {
    console.log('actions:forceEmailVerification'); // eslint-disable-line no-console

    const forceEmailVerification = firebase.functions().httpsCallable('forceEmailVerification');

    let result = null;
    try {
      result = await forceEmailVerification({ uid });
      console.log('result =', result); // eslint-disable-line no-console
    } catch (error) {
      console.log('error =', error); // eslint-disable-line no-console
      return Promise.reject(error);
    }

    const error = result?.data?.error;
    const code = result?.data?.code;

    if (error) return Promise.reject(code);

    return Promise.resolve();
  },

  exportUsers: async () => {
    console.log('actions:exportUsers'); // eslint-disable-line no-console

    const exportUsers = firebase.functions().httpsCallable('exportUsers');

    let result = null;
    try {
      result = await exportUsers();
      console.log('result =', result); // eslint-disable-line no-console
    } catch (error) {
      console.log('error =', error); // eslint-disable-line no-console
      return Promise.reject(error);
    }

    const error = result?.data?.error;
    const code = result?.data?.code;

    if (error) return Promise.reject(code);

    return Promise.resolve(result);
  },
};

const mutations = {
  [SET_USERS_LISTENER]: (state, payload) => {
    console.log('mutations:SET_USERS_LISTENER'); // eslint-disable-line no-console

    Vue.set(state, 'listener', payload);
  },

  [SET_USERS]: (state, payload) => {
    console.log('mutations:SET_USERS'); // eslint-disable-line no-console

    const data = [];

    if (payload.snapshot) {
      payload.snapshot.forEach((doc) => {
        const docData = doc.data();

        data.push({
          id: doc.id,
          ...docData,
        });
      });
    } else if (payload.list) {
      payload.list.forEach((doc) => {
        data.push({
          ...doc,
        });
      });
    }

    Vue.set(state, 'data', data);

    // eslint-disable-next-line no-console
    console.log(`state.data.length = ${state.data.length}`);
    // eslint-disable-next-line no-console
    // console.log('state.data =', state.data);
  },

  [CLEAR_USERS]: (state) => {
    console.log('mutations:CLEAR_USERS'); // eslint-disable-line no-console

    Vue.set(state, 'data', []);
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
