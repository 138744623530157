<template>
  <div class="component-quotation">
    <v-row v-if="quotation" class="component-quotation-content">
      <v-col cols="12">
        <DifferentUserAlert :quotation="quotation" />

        <v-card outlined>
          <v-toolbar color="secondary" dark flat dense>
            <v-toolbar-title>Preventivo</v-toolbar-title>
            <v-spacer></v-spacer>

            <!-- Switch per mostrare/nascondere i prezzi di listino -->
            <div v-if="!!quotationUserPriceList"
              class="switch-container">
              <v-switch color="orange" hide-details
                v-model="showPrices"
                label="Mostra i prezzi di listino"></v-switch>
            </div>


            <!-- Switch per mostrare/nascondere le voci di capitolato e le schede tecniche -->
            <div class="switch-container">
              <v-switch color="orange" hide-details
                v-model="showSpecs"
                label="Mostra le voci di capitolato e le schede tecniche"></v-switch>
            </div>

            <v-tooltip v-if="!!quotationUserPriceList"
              bottom>
              <template v-slot:activator="{ on }">
                <v-btn dark text outlined class="ml-1" small
                  v-on="on"
                  @click="onDiscountEdit()">
                  <v-icon left>mdi-percent</v-icon> Sconto
                </v-btn>
              </template>
              <span>Applica uno sconto percentuale</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn dark text outlined class="ml-1" small
                  v-on="on"
                  @click="onEdit()">
                  <v-icon left>mdi-pencil</v-icon> Modifica
                </v-btn>
              </template>
              <span>Aggiungi o rimuovi dei prodotti</span>
            </v-tooltip>

            <!-- v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn icon dark v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="onEdit()">
                  <v-list-item-title>Modifica elenco prodotti</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu -->
          </v-toolbar>

          <v-card-text>
            <v-simple-table v-if="quotation.selectedProducts.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Prodotto</th>
                    <th class="text-right text-no-wrap">Consumo al m²</th>
                    <th v-if="!!quotationUserPriceList" class="incidence-header">Incidenza al m²</th>
                    <th class="area-header">Superficie</th>
                    <th class="text-right">Confezioni</th>
                    <th class="text-right">Quantità</th>
                    <th v-if="!!quotationUserPriceList && hasDiscount" class="text-right">Sconto</th>
                    <th v-if="!!quotationUserPriceList" class="text-right">Costo</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(product, pIndex) in quotation.selectedProducts">
                    <tr :key="`product-${product.id}-${pIndex}`">
                      <td class="subtitle-1 text-no-wrap">
                        <div class="product-name">
                          <v-tooltip bottom
                            :disabled="!hasAttachments(product)">
                            <template v-slot:activator="{ on: tooltip }">
                              <v-menu bottom left>
                                <template v-slot:activator="{ on: menu }">
                                  <v-slide-x-transition>
                                    <v-btn
                                      v-on="{ ...tooltip, ...menu }"
                                      v-show="showSpecs"
                                      :disabled="!hasAttachments(product)"
                                      :dark="hasAttachments(product)"
                                      class="mr-2" fab x-small depressed color="secondary">
                                      <v-icon dark>mdi-download</v-icon>
                                    </v-btn>
                                  </v-slide-x-transition>
                                </template>

                                <v-list dense>
                                  <v-list-item
                                    v-for="(attachment, index) in product.attachments"
                                    :key="`attachment-${index}`"
                                    @click="onDownloadAttachment(attachment)">
                                    <v-list-item-icon class="mr-2">
                                      <v-icon small>mdi-file-download</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ getAttachmentDisplayName(attachment, index) }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </template>
                            <span>{{ getAttachmentsTooltip(product) }}</span>
                          </v-tooltip>

                          <v-list-item-content>
                            <v-list-item-title>
                              <span style="cursor: pointer"
                                v-html="product.name[userLanguage]"
                                @click="onDetails(product)"></span>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <div class="product-buttons">
                                <template v-if="product.consumptionType === 'variable'">
                                  <v-btn class="product-button"
                                    color="secondary" x-small
                                    outlined
                                    @click="onDetails(product)"
                                    v-text="selectedConsumptionRange(product)"></v-btn>
                                </template>

                                <template v-if="isCombined(product)">
                                  <v-btn class="product-button"
                                    color="warning" x-small
                                    outlined
                                    @click="onDetails(product)">Combinato</v-btn>
                                </template>

                                <template v-if="['colors', 'width'].includes(product.priceType)">
                                  <v-btn class="product-button"
                                    color="secondary" x-small
                                    outlined
                                    @click="onDetails(product)"
                                    v-text="selectedPriceRange(product)"></v-btn>
                                </template>

                                <template v-if="product.priceType === 'colors'">
                                  <v-btn class="product-button"
                                    v-if="product.color"
                                    color="secondary" x-small
                                    outlined
                                    @click="onDetails(product)">
                                    <v-icon
                        :color="`rgba(${product.color.r}, ${product.color.g}, ${product.color.b}, 1)`"
                                      left>mdi-circle</v-icon> {{ product.color.code }}
                                  </v-btn>

                                  <v-btn class="product-button"
                                    v-else-if="!isWhite(product)"
                                    color="error" x-small
                                    outlined
                                    @click="onDetails(product)">
                                    Manca il colore
                                  </v-btn>
                                </template>
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </div>
                      </td>

                      <td class="text-right text-no-wrap body-2"
                        v-html="consumptionText(product)"></td>

                      <td v-if="!!quotationUserPriceList"
                        class="text-right body-2">
                        <div class="p-incidences">
                          <div class="p-incidence-row"
                            v-for="(incidence, iIndex) in incidences(product)"
                            :key="`p${product.id}-${pIndex}-i${iIndex}`">
                            <span class="p-incidence"
                              v-html="incidence.text"></span>

                            <v-tooltip v-if="product.um !== 'pz'"
                              bottom max-width="300">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="p-incidence-checkbox" x-small
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="onToggleIncidence(incidence)">
                                  <v-icon
                                    v-if="isIncidenceSelected(incidence)"
                                    color="warning">mdi-checkbox-marked</v-icon>
                                  <v-icon
                                    v-else
                                    color="secondary">mdi-checkbox-blank-outline</v-icon>
                                </v-btn>
                              </template>
                              <span v-if="isIncidenceSelected(incidence)">
                                Selezionato per il calcolo dell'incidenza totale
                              </span>
                              <span v-else>
                                Non utilizzato nel calcolo dell'incidenza totale
                                (selezionare per includerlo)
                              </span>
                            </v-tooltip>
                            <v-btn v-else icon class="p-incidence-checkbox" x-small disabled>
                              <v-icon
                                color="secondary">mdi-checkbox-blank-outline</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </td>

                      <td class="text-right body-2">
                        <span v-if="product.um === 'pz'" class="pr-8">-</span>
                        <ProductArea v-else
                          :product="product"
                          :quotation="quotation"
                          :userLanguage="userLanguage"
                          @toggleLockedArea="onToggleLockedArea(pIndex)"
                          @updateArea="onUpdateArea(pIndex, $event)" />
                      </td>

                      <td class="text-right body-2">
                        <ProductSelectedPackaging
                          :product="product"
                          :estimatedQuantity="estimatedQuantity(product)"
                          :over="quantity(product) > estimatedQuantity(product)"
                          :under="quantity(product) < estimatedQuantity(product)"
                          @increment="onIncrement"
                          @decrement="onDecrement" />
                      </td>

                      <td class="text-right body-2 font-weight-medium text-no-wrap">
                        {{ quantityText(product) }}
                      </td>

                      <td v-if="!!quotationUserPriceList && hasDiscount"
                        class="text-right caption text-no-wrap">
                        <ProductDiscounts
                          :product="product"
                          :quotation="quotation"
                          @update="onUpdateDiscount($event)" />
                      </td>

                      <td v-if="!!quotationUserPriceList"
                        class="text-right subtitle-1 font-weight-medium text-no-wrap">
                        {{ priceText(product) }}
                      </td>
                    </tr>

                    <!-- Mostra i prezzi di listino per ogni lattaggio selezionato -->
                    <template v-if="product && !!quotationUserPriceList">
                      <tr class="prices-row"
                        v-for="(price, priceIndex) in product.priceranges[product.priceRange || 0].prices.filter((p) => p.quantity > 0)"
                        :key="`prices-${product.id}-${pIndex}-${priceIndex}`"
                        v-show="showPrices"
                      >
                        <td colspan="2" class="pricesContent"
                          v-html="productNameWithPriceRange(product)">
                        </td>
                        <td class="pricesContent"></td>
                        <td class="pricesContent"></td>
                        <td class="text-right pricesContent">{{ productPackaging(product, price) }}</td>
                        <td class="pricesContent"></td>
                        <td v-if="hasDiscount" class="pricesContent"></td>
                        <td class="text-right pricesContent">{{ productPrice(product, price) }}</td>
                      </tr>
                    </template>

                    <!-- Mostra le voci di capitolato e le schede tecniche -->
                    <template v-if="product && product.specs
                        && userLanguage in product.specs">
                      <tr class="specs-row" :key="`specs-${product.id}-${pIndex}`"
                        v-show="showSpecs">
                        <td colspan="2">
                          <v-expand-transition>
                            <v-card flat
                              v-show="showSpecs"
                            >
                              <v-card-text class="specsContent"
                                v-html="product.specs[userLanguage]"></v-card-text>
                            </v-card>
                          </v-expand-transition>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td v-if="hasDiscount"></td>
                        <td></td>
                      </tr>
                    </template>
                  </template>

                  <tr>
                    <td></td>
                    <td></td>
                    <td v-if="!!quotationUserPriceList"
                      class="text-right body-2">
                      <v-tooltip bottom max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <span class="final-incidence"
                            :class="{ disabled: selectedIncidences.length === 0 }"
                            v-bind="attrs"
                            v-on="on">{{ finalIncidence }}</span>
                        </template>
                        <span v-if="selectedIncidences.length === 0">
                          Per calolare l'incidenza totale, selezionare le singole incidenze
                          da includere nel calcolo
                        </span>
                        <span v-else>
                          Incidenza totale calcolata sommando le singole incidenze selezionate
                        </span>
                      </v-tooltip>
                    </td>
                    <td></td>
                    <td></td>
                    <td v-if="!!quotationUserPriceList && hasDiscount"></td>
                    <td></td>
                    <td v-if="!!quotationUserPriceList" class="text-right title">{{ totalPrice() }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ProductDetailsDialog v-model="detailsDialog"
      :quotation="quotation"
      :product="selected"
      :select="false"
      :unselect="false"
      @update="onUpdateProduct" />

    <DiscountDialog v-model="discountDialog"
      :quotation="quotation"
      :product="selected"
      @updateDiscount="onUpdateDiscount($event)" />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable quote-props */

import ProductDetailsDialog from '@/components/ProductDetailsDialog';
import ProductSelectedPackaging from '@/components/ProductSelectedPackaging';
import ProductArea from '@/components/ProductArea';
import DifferentUserAlert from '@/components/DifferentUserAlert';
import DiscountDialog from '@/components/DiscountDialog';
import ProductDiscounts from '@/components/ProductDiscounts';

export default {
  name: 'Quotation',

  props: {
    value: {
      type: Object,
    },
    updating: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ProductDetailsDialog,
    ProductSelectedPackaging,
    ProductArea,
    DifferentUserAlert,
    DiscountDialog,
    ProductDiscounts,
  },

  data: () => ({
    id: null,
    quotation: null,

    selected: null,

    detailsDialog: false,
    discountDialog: false,

    areaUpdated: false,

    // Mostra/nasconde le voci di capitolato e le schede tecniche
    showSpecs: false,

    // Mostra/nasconde i prezzi di listino
    showPrices: false,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  created() {
    this.quotation = this.value;
  },

  watch: {
    value(newValue) {
      console.log('Quotation: watch.value()'); // eslint-disable-line no-console

      this.quotation = newValue;

      // Corregge i lockedArea per ogni prodotto selezionato
      // eslint-disable-next-line no-restricted-syntax
      for (const pIndex in this.quotation.selectedProducts) {
        if (this.quotation.selectedProducts.hasOwnProperty(pIndex)) {
          if (!this.quotation.selectedProducts[pIndex].hasOwnProperty('lockedArea')) {
            // Se il prodotto è combinato con qualcos'altro,
            // evita il blocco e imposta l'area iniziale a 0
            if (this.isCombined(this.quotation.selectedProducts[pIndex])) {
              this.$set(this.quotation.selectedProducts[pIndex], 'lockedArea', false);
              this.$set(this.quotation.selectedProducts[pIndex], 'area', 0);
            } else {
              this.$set(this.quotation.selectedProducts[pIndex], 'lockedArea', true);
            }
          }
        }
      }

      // Corregge l'area della superficie comune
      // eslint-disable-next-line no-prototype-builtins
      if (!newValue.hasOwnProperty('area')) this.$set(this.quotation, 'area', 100);

      // Calcola i lattaggi iniziali per ogni prodotto
      // eslint-disable-next-line no-restricted-syntax
      for (const product of this.quotation.selectedProducts) {
        this.updateInitialPackaging(product);
      }

      this.$emit('ready');
    },

    quotation(newValue) {
      console.log('Quotation: watch.quotation()'); // eslint-disable-line no-console

      this.$emit('input', newValue);
    },

    area(newValue) {
      console.log('Quotation: watch.area() newValue =', newValue); // eslint-disable-line no-console
      // Aggiorna le aree dei prodotti con lockedArea === true
      // eslint-disable-next-line no-restricted-syntax
      for (const pIndex in this.quotation.selectedProducts) {
        if (this.quotation.selectedProducts.hasOwnProperty(pIndex)) {
          if (this.quotation.selectedProducts[pIndex].hasOwnProperty('lockedArea')
            && this.quotation.selectedProducts[pIndex].lockedArea === true) {
            this.$set(this.quotation.selectedProducts[pIndex], 'area', `${new Intl.NumberFormat('it-IT').format(newValue)}`);
          }
        }
      }
    },

    showPrices(newValue) {
      console.log(`${this.constructor.name}.watch.showPrices() newValue =`, newValue); // eslint-disable-line no-console

      this.$emit('options', { showPrices: newValue });
    },

    showSpecs(newValue) {
      console.log(`${this.constructor.name}.watch.showSpecs() newValue =`, newValue); // eslint-disable-line no-console

      this.$emit('options', { showSpecs: newValue });
    },
  }, // watch

  computed: {
    user() {
      return this.$store.getters.user;
    },

    users() {
      return this.$store.getters['users/docs'];
    },

    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    products() {
      return this.$store.getters['products/docs'];
    },

    quotationUser() {
      return Array.isArray(this.users) && this.quotation && 'uid' in this.quotation
        ? this.users.find((u) => u.uid === this.quotation.uid)
        : null;
    },

    quotationUserPriceList() {
      return Array.isArray(this.pricelists) && this.quotationUser && 'pricelist' in this.quotationUser
        ? this.pricelists.find((pl) => pl.id === this.quotationUser.pricelist)
        : null;
    },

    area() {
      return this.quotation && this.quotation.hasOwnProperty('area') ? this.quotation.area : null;
    },

    selectedIncidences() {
      return this.quotation
        && this.quotation.hasOwnProperty('selectedIncidences')
        && Array.isArray(this.quotation.selectedIncidences)
        ? this.quotation.selectedIncidences
        : [];
    },

    finalIncidence() {
      if (this.selectedIncidences.length === 0) return 'Incidenza totale?';

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      return formatter.format(
        this.selectedIncidences
          // Rimuove i prodotti non più selezionati
          // eslint-disable-next-line arrow-body-style
          .filter((incidence) => {
            // eslint-disable-next-line arrow-body-style
            return this.quotation.selectedProducts.findIndex((p) => {
              return 'sId' in p
                ? p.sId === incidence.id.substring(1, p.sId.length + 1)
                : p.id === incidence.id.substring(1, p.id.length + 1);
            }) > -1;
          })
          .reduce((accumulator, incidence) => accumulator + incidence.value, 0),
      );
    },

    allRequiredProducts() {
      if (!this.quotation
        || !Array.isArray(this.quotation.selectedProducts)) return [];

      const result = [];
      this.quotation.selectedProducts.forEach((product) => {
        if (product.consumptionType === 'variable'
          && Array.isArray(product.consumptionranges[product.consumptionRange || 0]
            .requiredProducts)
          && product.consumptionranges[product.consumptionRange || 0].requiredProducts.length > 0) {
          result.push(
            ...product.consumptionranges[product.consumptionRange || 0].requiredProducts
              .map((rp) => rp.product),
          );
        }
      });

      return result;
    },

    selectedProducts() {
      return this.quotation && 'selectedProducts' in this.quotation
        && Array.isArray(this.quotation.selectedProducts)
        ? this.quotation.selectedProducts
        : [];
    },

    hasDiscount() {
      return this.quotation
        && ('discount' in this.quotation
          || 'discountExtra' in this.quotation
          || 'discountAccounting' in this.quotation);
    },
  },

  methods: {
    productName(product, suffix = '') {
      return product && product.name && this.userLanguage in product.name
        ? `${product.name[this.userLanguage]}${suffix}`
        : '';
    },

    productNameWithPriceRange(product) {
      const suffix = product.priceranges.length > 1
        ? `<br><small>${product.priceranges[product.priceRange || 0].name}</small>`
        : '';

      return this.productName(product, suffix);
    },

    productPackaging(product, price) {
      if (product.um === 'pz') return '1 pz';

      return product && product.um && price && price.packaging
        ? `${price.packaging} ${product.um}`
        : '';
    },

    productPrice(product, priceData) {
      if (!product || !priceData || !this.quotationUserPriceList) return '';

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      const price = `${formatter.format(priceData[this.quotationUserPriceList.id])}`.replace('€', `€/${product.um}`);

      return price;
    },

    isTheOne(product) {
      const spList = this.selectedProducts.filter((rp) => rp.id === product.id);

      return spList.length === 1;
    },

    convertToFloat(value) {
      return parseFloat(`${value}`.replace(/[^0-9,]+/g, '').replace(',', '.'));
    },

    isCombined(product) {
      // Se il prodotto non può essere combinato si verma subito (false)
      if ('combined' in product === false || !product.combined) return false;

      // Il prodotto è combinabile

      // Se non può essere utilizzato da solo, di ferma subito (true).
      if ('standalone' in product && product.standalone === false) return true;

      // Verifica se un prodotto tra quelli scelti lo richiede come combinato

      // Conta il numero di prodotti richiesti di questo tipo
      const reqCount = this.allRequiredProducts.filter((rp) => rp.id === product.id).length;

      // Nessun prodotto richiesto di questo tipo
      if (reqCount === 0) return false;

      // È richiesto un prodotto di questo tipo per l'uso combinato

      // Riserva il primo prodotto di questo tipo per l'uso combinato

      // Calcola la posizione in elenco di questo prodotto
      const spList = this.selectedProducts.filter((rp) => rp.id === product.id);
      const spIndex = spList.findIndex((rp) => rp.sId === product.sId);

      // Ritorna true solo se è il primo
      return Boolean(spIndex === 0);
    },

    isWhite(product) {
      if (product.priceType !== 'colors') return false;

      const pricerange = product.priceranges[product.priceRange || 0];

      if (pricerange.colors.length !== 1) return false;

      return pricerange.colors[0].r === 255
        && pricerange.colors[0].g === 255
        && pricerange.colors[0].b === 255;
    },

    selectedConsumptionRange(product) {
      // eslint-disable-next-line no-prototype-builtins
      if (!product.hasOwnProperty('consumptionRange') || product.consumptionRange === null) {
        return product.consumptionranges[0].name;
      }
      return product.consumptionranges[product.consumptionRange].name;
    },

    selectedPriceRange(product) {
      // eslint-disable-next-line no-prototype-builtins
      if (!product.hasOwnProperty('priceRange') || product.priceRange === null) {
        return product.priceranges[0].name;
      }
      return product.priceranges[product.priceRange].name;
    },

    onDetails(product) {
      this.selected = product;
      this.detailsDialog = true;
    },

    updateRequiredProducts() {
      // eslint-disable-next-line no-console
      console.log('Quotation.updateRequiredProducts()');

      if (this.allRequiredProducts.length > 0) {
        this.allRequiredProducts.forEach((rp) => {
          // Verifica se il prodotto è già in lista
          const exists = this.quotation.selectedProducts.find((p) => p.id === rp.id);
          if (!exists) {
            this.quotation.selectedProducts.push(rp);

            // Corregge i lockedArea per ogni prodotto selezionato
            // eslint-disable-next-line no-restricted-syntax
            for (const pIndex in this.quotation.selectedProducts) {
              if (this.quotation.selectedProducts.hasOwnProperty(pIndex)) {
                if (!this.quotation.selectedProducts[pIndex].hasOwnProperty('lockedArea')) {
                  // Se il prodotto è combinato con qualcos'altro,
                  // evita il blocco e imposta l'area iniziale a 0
                  if (this.isCombined(this.quotation.selectedProducts[pIndex])) {
                    this.$set(this.quotation.selectedProducts[pIndex], 'lockedArea', false);
                    this.$set(this.quotation.selectedProducts[pIndex], 'area', 0);
                  } else {
                    this.$set(this.quotation.selectedProducts[pIndex], 'lockedArea', true);
                  }
                }
              }
            }

            this.updateInitialPackaging(rp, true);
          }
        });
      }

      // Deseleziona automaticamente i prodotti richiesti non più richiesti
      // Inizia analizzando prima tutti i prodotti
      this.quotation.selectedProducts
        .filter((p) => p.hasOwnProperty('combined') && p.combined === true)
        .forEach((product) => {
          // Ho trovato un prodotto combinato,
          // verifico se è ancora richiesto.
          const required = this.allRequiredProducts.find((rp) => rp.id === product.id);

          // Se non è più richiesto...
          if (!required) {
            // ... se non può essere usato da solo...
            if ('standalone' in product
              && product.standalone === false) {
              // ... lo elimino
              this.$set(
                this.quotation,
                'selectedProducts',
                this.quotation.selectedProducts.filter((p) => p.id !== product.id),
              );
            } else {
              // ... altrimenti aggiorno il suo lattaggio richiesto
              this.updateInitialPackaging(product, true);
            }
          }
        });
    },

    onUpdateProduct(product) {
      // eslint-disable-next-line no-console
      console.log('Quotation.onUpdateProduct() product =', product);

      this.$set(
        this.quotation.selectedProducts,
        this.quotation.selectedProducts
          .findIndex((p) => {
            if ('sId' in product && product.sId !== null && 'sId' in p) {
              return product.sId === p.sId;
            }
            // return false;
            return p.id === product.id;
          }),
        product,
      );

      this.updateRequiredProducts();

      this.updateInitialPackaging(product, true);

      this.updated();
    },

    updateInitialPackaging(product, force = false) {
      // eslint-disable-next-line no-console
      console.log('Quotation.updateInitialPackaging() product =', product);

      const pIndex = this.quotation.selectedProducts
        .findIndex((p) => {
          if ('sId' in product && product.sId !== null && 'sId' in p) {
            return product.sId === p.sId;
          }
          // return false;
          return p.id === product.id;
        });

      // eslint-disable-next-line no-console
      console.log('Quotation.updateInitialPackaging() pIndex =', pIndex);

      // Verifica che il priceRange del prodotto sia corretto
      if (!product.priceranges.hasOwnProperty(product.priceRange)) {
        this.$set(
          this.quotation.selectedProducts[pIndex],
          'priceRange',
          0,
        );
      }

      // È stato selezionato un lattaggio manuale?
      const withoutPackaging = Boolean(
        product.priceranges[product.priceRange || 0].prices
          .find((p) => !p.hasOwnProperty('quantity')),
      );

      console.log(`Quotation.updateInitialPackaging() withoutPackaging = ${withoutPackaging}`); // eslint-disable-line no-console

      if (withoutPackaging || force) {
        console.log(`Quotation.updateInitialPackaging() Calcolo il lattaggio iniziale del prodotto ${product.name[this.userLanguage]}`); // eslint-disable-line no-console

        const quantity = this.estimatedQuantity(product);
        console.log(`Quotation.updateInitialPackaging() Quantità richiesta = ${quantity}`); // eslint-disable-line no-console

        // Ottiene il lattaggio massimo del prodotto
        const maxPackaging = this.convertToFloat(product.priceranges[product.priceRange || 0]
          .prices[0].packaging);
        console.log(`Quotation.updateInitialPackaging() maxPackaging = ${maxPackaging}`); // eslint-disable-line no-console

        if (product.um === 'pz') {
          // eslint-disable-next-line no-restricted-syntax
          for (const priceIndex in product.priceranges[product.priceRange || 0].prices) {
            if (product.priceranges[product.priceRange || 0].prices.hasOwnProperty(priceIndex)) {
              this.$set(
                this.quotation.selectedProducts[pIndex]
                  .priceranges[product.priceRange || 0]
                  .prices[priceIndex],
                'quantity',
                priceIndex > 0 ? 0 : 1,
              );
            }
          }
        } else {
          // Calcolo la quantità di latte con lattaggio massimo
          let maxPackagingQuantity = Math.trunc(quantity / maxPackaging);
          if (maxPackagingQuantity * maxPackaging < quantity) {
            maxPackagingQuantity += 1;
          }
          console.log(`Quotation.updateInitialPackaging() maxPackagingQuantity = ${maxPackagingQuantity}`); // eslint-disable-line no-console

          // eslint-disable-next-line no-restricted-syntax
          for (const priceIndex in product.priceranges[product.priceRange || 0].prices) {
            if (product.priceranges[product.priceRange || 0].prices.hasOwnProperty(priceIndex)) {
              this.$set(
                this.quotation.selectedProducts[pIndex]
                  .priceranges[product.priceRange || 0]
                  .prices[priceIndex],
                'quantity',
                priceIndex > 0 ? 0 : maxPackagingQuantity,
              );
            }
          }
        }
      }
    },

    onUpdateDiscount(evt) {
      // eslint-disable-next-line no-console
      console.log('Quotation.onUpdateDiscount() evt =', evt);

      const {
        discount, discountAccounting, discountExtra, product,
      } = evt;

      if (!product) {
        if (discount !== undefined) {
          // Nuovo sconto di codifica (globale)

          this.$set(this.quotation, 'discount', discount);

          // eslint-disable-next-line no-restricted-syntax
          for (const pIndex in this.quotation.selectedProducts) {
            if (this.quotation.selectedProducts.hasOwnProperty(pIndex)) {
              this.$set(this.quotation.selectedProducts[pIndex], 'discount', discount);
            }
          }
        }

        if (discountAccounting !== undefined) {
          // Nuovo sconto finanziario (globale)

          this.$set(this.quotation, 'discountAccounting', discountAccounting);

          // eslint-disable-next-line no-restricted-syntax
          for (const pIndex in this.quotation.selectedProducts) {
            if (this.quotation.selectedProducts.hasOwnProperty(pIndex)) {
              this.$set(this.quotation.selectedProducts[pIndex], 'discountAccounting', discountAccounting);
            }
          }
        }
      } else {
        // Modifiche allo sconto di un singolo prodotto

        // Trova l'indice del prodotto utilizzando l'identificativo univoco sId (se presente)
        const pIndex = this.quotation.selectedProducts.findIndex((p) => ('sId' in p
          ? p.sId === product.sId
          : p.id === product.id));

        if (discount !== undefined) {
          this.$set(this.quotation.selectedProducts[pIndex], 'discount', discount);
        }
        if (discountExtra !== undefined) {
          this.$set(this.quotation.selectedProducts[pIndex], 'discountExtra', discountExtra);
        }
      }

      this.updated();
    },

    onUpdateArea(pIndex, area) {
      // eslint-disable-next-line no-console
      console.log('Quotation.updateAreas() pIndex =', pIndex);
      // eslint-disable-next-line no-console
      console.log('Quotation.updateAreas() area =', area);

      const { lockedArea } = this.quotation.selectedProducts[pIndex];

      // eslint-disable-next-line no-unreachable
      if (lockedArea) {
        // È un'area vincolata, aggiorna quella globale (che aggiorna tutte).
        this.$set(this.quotation, 'area', this.convertToFloat(area));
      } else {
        // È un'area svincolata, corregge il formato.
        this.$set(this.quotation.selectedProducts[pIndex], 'area', `${new Intl.NumberFormat('it-IT').format(this.convertToFloat(area))}`);
      }

      if (lockedArea) {
        // eslint-disable-next-line no-restricted-syntax
        for (const product of this.quotation.selectedProducts) {
          if (product.lockedArea === true && product.um !== 'pz') {
            this.updateInitialPackaging(product, true);
          } else if (this.isCombined(product)) {
            // Scorre tutti i prodotti e controlla se qualcuno di loro ha l'area bloccata
            let isLocked = false;
            this.quotation.selectedProducts.forEach((sp) => {
              const required = sp.consumptionType === 'variable'
                && Array.isArray(sp.consumptionranges[sp.consumptionRange || 0].requiredProducts)
                && sp.consumptionranges[sp.consumptionRange || 0].requiredProducts
                  .find((rp) => rp.product.id === product.id);
              if (required && sp.lockedArea === true) {
                isLocked = true;
              }
            });

            if (isLocked) this.updateInitialPackaging(product, true);
          }
        }
      } else {
        this.updateInitialPackaging(this.quotation.selectedProducts[pIndex], true);
      }

      this.updated();
    },

    onToggleLockedArea(pIndex) {
      if (this.quotation.selectedProducts.hasOwnProperty(pIndex)
        && this.quotation.selectedProducts[pIndex].hasOwnProperty('lockedArea')) {
        this.$set(this.quotation.selectedProducts[pIndex], 'lockedArea', !this.quotation.selectedProducts[pIndex].lockedArea);
      }

      // Se l'area è stata vincolata, ripristina il valore globale
      if (this.quotation.selectedProducts[pIndex].lockedArea === true) {
        this.$set(this.quotation.selectedProducts[pIndex], 'area', `${new Intl.NumberFormat('it-IT').format(this.quotation.area)}`);
      }

      this.updated();
    },

    // La quantità totale calcolata in base al lattaggio selezionato
    quantity(product) {
      // eslint-disable-next-line no-console
      // console.log('Quotation.quantity()');

      if (!product) return null;

      let q = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const priceIndex in product.priceranges[product.priceRange || 0].prices) {
        if (product.priceranges[product.priceRange || 0].prices.hasOwnProperty(priceIndex)) {
          const price = product.priceranges[product.priceRange || 0].prices[priceIndex];

          // eslint-disable-next-line no-console
          // console.log('Quotation.quantity() price =', price);

          // eslint-disable-next-line no-console
          // console.log('Quotation.quantity() packaging =', price.packaging);
          // eslint-disable-next-line no-console
          // console.log('Quotation.quantity() quantity =', price.quantity);

          q += this.convertToFloat(price.packaging) * price.quantity;
        }
      }

      // eslint-disable-next-line no-console
      // console.log('Quotation.quantity() q =', q);

      return q;
    },

    quantityText(product) {
      if (!product) return '';

      const quantity = this.quantity(product);

      return quantity ? `${new Intl.NumberFormat('it-IT').format(quantity)} ${product.um}` : '0';
    },

    // La quantità totale stimata di prodotto
    estimatedQuantity(product) {
      if (!product) return null;

      if (product.um === 'mq') {
        return product.lockedArea
          ? this.quotation.area
          : this.convertToFloat(product.area);
      }

      if (this.isCombined(product)) {
        let q = 0;
        // Scorre tutti i prodotti per identificare quelli che richiedono
        // l'utilizzo di questo prodotto.
        this.quotation.selectedProducts.forEach((sp) => {
          // Verifica se richiede qualche prodotto combinato
          if (sp.consumptionType === 'variable'
            && Array.isArray(sp.consumptionranges[sp.consumptionRange || 0].requiredProducts)
            && sp.consumptionranges[sp.consumptionRange || 0].requiredProducts.length > 0) {
            // Verifica se richiede il prodotto combinato
            const required = sp.consumptionranges[sp.consumptionRange || 0].requiredProducts
              .find((rp) => rp.product.id === product.id);
            if (required) {
              q += sp.lockedArea
                ? required.consumption * this.quotation.area
                : required.consumption * this.convertToFloat(sp.area);
            }
          }
        });

        // Se è consentito anche l'utilizzo "standalone",
        // ed è l'unico prodotto di questo tipo in elenco...
        if (
          ('standalone' in product === false || product.standalone === true)
          && this.isTheOne(product)
        ) {
          // ... aggiunge la sua quantità
          q += product.lockedArea
            ? this.consumption(product) * this.quotation.area
            : this.consumption(product) * this.convertToFloat(product.area);
        }

        return q;
      }

      if (!this.quotation.area) return null;
      if (!this.consumption(product)) return null;

      return product.lockedArea
        ? this.consumption(product) * this.quotation.area
        : this.consumption(product) * this.convertToFloat(product.area);
    },

    consumption(product) {
      if (product.consumptionType === 'variable') {
        return product.consumptionranges[product.consumptionRange || 0].consumption;
      }

      return product.consumption;
    },

    consumptionText(product) {
      if (!product) return '';

      if ('um' in product && ['pz', 'mq'].includes(product.um)) return '-';

      // Per i prodotti combinati, mostra i consumi per ogni prodotto con cui è miscelato
      if (this.isCombined(product)) {
        let result = '';
        this.quotation.selectedProducts.forEach((sp) => {
          const required = sp.consumptionType === 'variable'
            && Array.isArray(sp.consumptionranges[sp.consumptionRange || 0].requiredProducts)
            && sp.consumptionranges[sp.consumptionRange || 0].requiredProducts
              .find((rp) => rp.product.id === product.id);
          if (required) {
            result += `<span class="caption text--disabled">Con ${sp.name[this.userLanguage]}: </span> `;
            result += `${new Intl.NumberFormat('it-IT').format(required.consumption)} ${product.um}`;
            result += '<br>';
          }
        });

        // Se è consentito anche l'utilizzo "standalone",
        // ed è l'unico prodotto di questo tipo in elenco...
        if (
          ('standalone' in product === false || product.standalone === true)
          && this.isTheOne(product)
        ) {
          // ... aggiunge la sua riga di consumo
          result += '<small class="text--disabled">Utilizzo autonomo: </small> ';
          result += `${new Intl.NumberFormat('it-IT').format(this.consumption(product))} ${product.um}`;
          result += '<br>';
        }

        return result;
      }

      return `${new Intl.NumberFormat('it-IT').format(this.consumption(product))} ${product.um}`;
    },

    incidence(product) {
      if (['pz'].includes(product.um)) return null;

      return product.area > 0 ? this.price(product) / this.convertToFloat(product.area) : null;
    },

    requiringProducts(product) {
      return this.quotation.selectedProducts.filter((sp) => {
        const requiring = sp.consumptionType === 'variable'
          && Array.isArray(sp.consumptionranges[sp.consumptionRange || 0].requiredProducts)
          && sp.consumptionranges[sp.consumptionRange || 0].requiredProducts
            .find((rp) => rp.product.id === product.id);
        return requiring;
      });
    },

    consumptionArray(product) {
      const cArray = [];
      if (product) {
        if (this.isCombined(product)) {
          // let result = '';
          this.quotation.selectedProducts.forEach((sp) => {
            const required = sp.consumptionType === 'variable'
              && Array.isArray(sp.consumptionranges[sp.consumptionRange || 0].requiredProducts)
              && sp.consumptionranges[sp.consumptionRange || 0].requiredProducts
                .find((rp) => rp.product.id === product.id);
            if (required) {
              cArray.push(required.consumption);
            }
          });

          // Se è consentito anche l'utilizzo "standalone",
          // ed è l'unico prodotto di questo tipo in elenco...
          if (
            ('standalone' in product === false || product.standalone === true)
            && this.isTheOne(product)
          ) {
            cArray.push(this.consumption(product));
          }

          return cArray;
        }

        return [this.consumption(product)];
      }

      return cArray;
    },

    areaArray(product) { // v2
      const aArray = [];

      if (product) {
        if (this.isCombined(product)) {
          this.requiringProducts(product).forEach((rp) => {
            aArray.push(this.convertToFloat(rp.area));
          });

          // Se è consentito anche l'utilizzo "standalone",
          // ed è l'unico prodotto di questo tipo in elenco...
          if (
            ('standalone' in product === false || product.standalone === true)
            && this.isTheOne(product)
          ) {
            // ... aggiunge la sua riga di area
            aArray.push(this.convertToFloat(product.area));
          }

          return aArray;
        }

        return [this.convertToFloat(product.area)];
      }

      return aArray;
    },

    incidences(product) {
      if (!product) return '';

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      const incidences = [];

      // Per i prodotti combinati,
      // mostra i consumi per ogni prodotto con cui è miscelato
      if (this.isCombined(product)) {
        const cArray = this.consumptionArray(product);
        // eslint-disable-next-line no-console
        // console.log('FLK cArray =', cArray);

        const aArray = this.areaArray(product);
        // eslint-disable-next-line no-console
        // console.log('FLK aArray =', aArray);

        let quantityFromConsumption = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const index in cArray) {
          if (cArray.hasOwnProperty(index)) {
            quantityFromConsumption += cArray[index] * aArray[index];
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const index in cArray) {
          if (cArray.hasOwnProperty(index)) {
            const incidence = aArray[index] > 0
              ? (cArray[index] * this.price(product)) / quantityFromConsumption
              : null;

            incidences.push({
              id: `p${product.sId || product.id}-r${index}`,
              value: incidence || 0,
              text: incidence ? `${formatter.format(incidence)}` : '-',
            });
          }
        }
      } else {
        // Prodotto non combinato, procede con il calcolo normale dell'incidenza
        const incidence = this.incidence(product);

        incidences.push({
          id: `p${product.sId || product.id}`,
          value: incidence || 0,
          text: incidence ? `${formatter.format(incidence)}` : '-',
        });
      }

      return incidences;
    },

    isIncidenceSelected(incidence) { // v2
      return Boolean(this.selectedIncidences.find((i) => i.id === incidence.id));
    },

    selectIncidence(incidence) {
      if (!this.quotation) return false;
      if (this.quotation && !Array.isArray(this.quotation.selectedIncidences)) {
        this.$set(this.quotation, 'selectedIncidences', []);
      }
      this.quotation.selectedIncidences.push(incidence);
      return true;
    },

    unselectIncidence(incidence) {
      if (!this.quotation) return false;
      this.$set(
        this.quotation,
        'selectedIncidences',
        this.quotation.selectedIncidences.filter((i) => i.id !== incidence.id),
      );
      return true;
    },

    refreshIncidences() {
      // eslint-disable-next-line no-console
      console.log('Quotation.refreshIncidences()');

      if (!this.quotation?.selectedIncidences) return false;

      const newSelectedIncidences = [];

      this.quotation.selectedProducts.forEach((product) => {
        const incidences = this.incidences(product);
        incidences.forEach((incidence) => {
          if (this.isIncidenceSelected(incidence)) {
            newSelectedIncidences.push(incidence);
          }
        });
      });

      this.$set(this.quotation, 'selectedIncidences', newSelectedIncidences);

      return true;
    },

    onToggleIncidence(incidence) { // v2
      if (this.isIncidenceSelected(incidence)) {
        this.unselectIncidence(incidence);
      } else {
        this.selectIncidence(incidence);
      }

      this.updated();
    },

    price(product) {
      if (!product || !this.quotationUserPriceList) return null;

      // Identifica la fascia di prezzo attualmente selezionata
      const priceRange = product.priceType === 'colors'
        ? product.priceRange || 0
        : 0;

      // Prepara l'elenco dei lattaggi attualmente selezionati
      const packages = product.priceranges[priceRange].prices
        .filter((p) => p.quantity > 0);

      let price = 0;
      packages.forEach((pack) => {
        const size = this.convertToFloat(pack.packaging);
        price += pack.quantity * size * pack[this.quotationUserPriceList.id];
      });

      if (!price) return null;

      const discount = this.discount(product);
      const discountExtra = this.discountExtra(product);
      const discountAccounting = this.discountAccounting(product);

      let result = price;
      if (discount) result -= (result * discount);
      if (discountExtra) result -= (result * discountExtra);
      if (discountAccounting) result -= (result * discountAccounting);

      return result;
    },

    priceText(product) { // v2
      const price = this.price(product);

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      if (!price) return `${formatter.format(0)}`;

      return `${formatter.format(price)}`;
    },

    totalPrice() { // v2
      let result = 0;

      this.quotation.selectedProducts.forEach((product) => {
        result += this.price(product);
      });

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      return `${formatter.format(result)}`;
    },

    discount(product) { // v2
      if (!product || !this.quotation) return 0;

      if ('discount' in product) {
        return product.discount;
      }

      return 'discount' in this.quotation && this.quotation.discount
        ? this.quotation.discount
        : 0;
    },

    discountExtra(product) { // v2
      if (!product || 'discountExtra' in product === false) return 0;

      return product.discountExtra ?? 0;
    },

    discountAccounting(product) { // v2
      if (!product || !this.quotation) return 0;

      if ('discountAccounting' in product) {
        return product.discountAccounting;
      }

      return 'discountAccounting' in this.quotation && this.quotation.discountAccounting
        ? this.quotation.discountAccounting
        : 0;
    },

    onDiscountEdit(product = null) { // v2
      this.selected = product;
      this.discountDialog = true;
    },

    onIncrement(args) {
      // eslint-disable-next-line no-console
      console.log(`Quotation.onIncrement() id = ${args.id} index = ${args.index}`);
      // eslint-disable-next-line no-console
      console.log(`Quotation.onIncrement() sId = ${args.sId}`);

      const pIndex = this.quotation.selectedProducts.findIndex((p) => {
        if ('sId' in args && args.sId !== null && 'sId' in p) {
          return p.sId === args.sId;
        }
        return p.id === args.id;
      });

      // eslint-disable-next-line no-console
      console.log(`Quotation.onIncrement() pIndex = ${pIndex}`);

      const product = this.quotation.selectedProducts[pIndex];
      let { quantity } = product.priceranges[product.priceRange || 0].prices[args.index];

      // eslint-disable-next-line no-console
      console.log(`Quotation.onIncrement() quantity = ${quantity}`);

      quantity += 1;

      this.quotation.selectedProducts[pIndex]
        .priceranges[product.priceRange || 0].prices[args.index].quantity = quantity;

      this.updated();
    },

    onDecrement(args) {
      // eslint-disable-next-line no-console
      console.log(`Quotation.onDecrement() id = ${args.id} index = ${args.index}`);
      // eslint-disable-next-line no-console
      console.log(`Quotation.onDecrement() sId = ${args.sId}`);

      const pIndex = this.quotation.selectedProducts.findIndex((p) => {
        if ('sId' in args && args.sId !== null && 'sId' in p) {
          return p.sId === args.sId;
        }
        return p.id === args.id;
      });

      // eslint-disable-next-line no-console
      console.log(`Quotation.onDecrement() pIndex = ${pIndex}`);

      const product = this.quotation.selectedProducts[pIndex];
      let { quantity } = product.priceranges[product.priceRange || 0].prices[args.index];

      // eslint-disable-next-line no-console
      console.log(`Quotation.onDecrement() quantity = ${quantity}`);

      quantity -= 1;

      this.quotation.selectedProducts[pIndex]
        .priceranges[product.priceRange || 0].prices[args.index].quantity = quantity;

      this.updated();
    },

    onDownload(product) {
      // eslint-disable-next-line no-console
      console.log('Quotation: onDownload()');

      // eslint-disable-next-line no-restricted-globals
      if ('url' in product && product.url) {
        window.open(product.url.replace('www.keim.com', 'www.keim.it'), '_blank');
      }
    },

    updated() { // v2
      // eslint-disable-next-line no-console
      console.log('Quotation.updated()');

      this.refreshIncidences();

      this.$emit('updated');
    },

    onEdit() { // v2
      this.$router.push({ name: 'ModificaPreventivo', params: { id: this.quotation.id } });
    },

    hasAttachments(product) {
      return Array.isArray(product.attachments) && product.attachments.length > 0;
    },

    getAttachmentsTooltip(product) {
      const count = product.attachments?.length || 0;
      return count === 1
        ? '1 allegato disponibile'
        : `${count} allegati disponibili`;
    },

    onDownloadAttachment(attachment) {
      if (attachment?.url) {
        window.open(attachment.url, '_blank');
      }
    },

    getAttachmentDisplayName(attachment, index) {
      if (attachment.name?.trim()) {
        return attachment.name;
      }

      const categoryMap = {
        'scheda-tecnica': 'Scheda tecnica',
        'cartella-colore': 'Cartella colore',
        'certificato': 'Certificato',
        'opuscolo': 'Opuscolo',
        'epd': 'Dichiarazione ambientale di prodotto (EPD)',
        'dichiarazione-prestazione': 'Dichiarazione di prestazione',
        'scheda-sostenibilità': 'Scheda tecnica di sostenibilità',
        'scheda-sicurezza': 'Scheda di sicurezza',
      };

      if (attachment.category === 'altro' && attachment.customCategory?.trim()) {
        return attachment.customCategory;
      }

      if (attachment.category && categoryMap[attachment.category]) {
        return categoryMap[attachment.category];
      }

      return `Allegato ${index + 1}`;
    },
  },
};
</script>

<style scoped>
.product-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.product-button {
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.v-btn.recalculate-button {
  height: 40px;
  width: 100%;
}

.productArea {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: flex-end;
}
.productArea .v-text-field {
  max-width: 150px;
  font-size: 0.875rem !important;
}
.productArea .v-text-field >>> .v-input__icon {
  font-size: 1rem !important;
  /* background-color: red; */
}
.productArea .v-text-field >>> .v-icon.v-icon {
  font-size: 1rem !important;
}
.productArea .v-text-field >>> .v-input__append-outer {
  margin-left: 0;
}

.packaging {
  display: block;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.38) !important;
  text-transform: lowercase !important;
  white-space: nowrap !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding-left: 8px;
  padding-right: 8px;
  height: auto;
  min-height: 100%;
}

#app.print .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 5px;
  padding-right: 5px;
}

.v-application.print .body-2 {
  font-size: 0.8rem !important;
}

.v-application.print .subtitle-1 {
  font-size: 0.9rem !important;
}
.v-application.print .caption {
  font-size: 0.60rem !important;
}
.v-application.print .v-btn:not(.v-btn--round).v-size--x-small {
  padding-left: 0;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th.area-header,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th.incidence-header {
  padding-right: 1rem;
  text-align: right;
}

.p-incidence-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.p-incidence {
  white-space: nowrap;
}

.p-incidence-checkbox {
  width: 2rem;
}

.final-incidence {
  padding-right: 2rem;
}
.final-incidence.disabled {
  padding-right: 0;
  color: grey;
}

.switch-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: stretch;
  margin-right: 2rem;
}

.switch-container >>> .v-input__slot {
  margin-bottom: 0;
}

.specsContent,
.pricesContent {
  line-height: 1rem;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.38) !important;
  padding-right: 0;
  padding-left: 0;
}

.pricesContent {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  line-height: 0.8;
  height: 32px !important;
}

.specs-row,
.prices-row {
  background: #fff !important;
}

.product-name {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  align-content: flex-start;
  height: 100% !important;
}
</style>
