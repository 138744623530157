<template>
  <div class="view-prodotto">
    <Loading v-model="loading" />

    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-format-list-text">
      <template v-slot:left-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="mr-2">
              <v-icon small color="orange"
                v-if="product && product.archived">
                mdi-archive
              </v-icon>
            </v-btn>
          </template>
          <span>Archiviato</span>
        </v-tooltip>
      </template>
    </PageHeader>

    <Product v-model="product"
      :updating="updating"
      :loading="loading"
      @updated="updated = true" />

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-actions v-if="product">
            <v-btn text color="primary"
              :disabled="updating"
              :loading="updating"
              @click="onCancel()">
              Torna all'elenco
            </v-btn>

            <v-btn text color="orange"
              v-if="!product.archived"
              :disabled="updating"
              :loading="updating"
              @click="archive(true)">
              Archivia
            </v-btn>
            <v-btn text color="orange"
              v-if="product.archived"
              :disabled="updating"
              :loading="updating"
              @click="archive(false)">
              Recupera
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn text color="success"
              :disabled="updating || !updated"
              :loading="updating"
              @click="update()">
              Aggiorna
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="leaveDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Vuoi abbandonare le modifiche?</v-card-title>

        <v-card-text>
          Non hai salvato le modifiche al prodotto. Sei certo di voler uscire senza prima salvarle?
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text
            @click="leaveDialog = false">
            Rimani su questa pagina
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="warning" text
            @click="next()">
            Lascia questa pagina senza salvare
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */

import Loading from '@/components/Loading';
import PageHeader from '@/components/PageHeader';
import Product from '@/components/Product';

export default {
  name: 'Prodotto',

  components: {
    Loading,
    PageHeader,
    Product,
  },

  data: () => ({
    loading: true,
    updating: false,
    updated: false,

    breadcrumbs: [
      {
        text: 'Prodotti',
        exact: true,
        to: { name: 'Prodotti' },
      },
    ],

    id: null,
    product: null,

    leaveDialog: false,
    next: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
    selectedLanguage: null,
    languages: [ // TODO: Hardcoded da qualche parte comune
      { id: 'it', name: 'Italiano' },
      { id: 'en', name: 'Inglese' },
      { id: 'de', name: 'Tedesco' },
    ],
  }),

  async created() {
    console.log('this.$route.params.id = ', this.$route.params.id); // eslint-disable-line no-console
    this.id = this.$route.params.id;

    const { phases, ...product } = await this.$store.dispatch('products/getById', this.id);
    console.log('phases = ', phases); // eslint-disable-line no-console
    console.log('product = ', product); // eslint-disable-line no-console

    const phasesEntries = Object.entries(phases);
    console.log('phasesEntries = ', phasesEntries); // eslint-disable-line no-console

    this.product = {
      id: this.id,
      ...product,
      phases: phasesEntries.map((e) => ({ id: e[0], name: e[1].name })),
    };

    // Inserisce il nome prodotto nei breadcrumbs
    this.breadcrumbs.push({
      text: this.product.name[this.userLanguage],
      disabled: true,
    });

    this.loading = false;
  },

  methods: {
    onCancel() {
      // Torna all'elenco dei prodotti
      this.$router.replace({ name: 'Prodotti' }, () => {});
    },

    async archive(value) {
      this.updating = true;
      this.product.archived = value;
      await this.$store.dispatch('products/update', {
        id: this.id,
        archived: value,
      });
      this.updating = false;
    },

    async update() {
      this.updating = true;

      const update = {
        id: this.id,
        name: this.product.name,
        rev: this.product.rev || null,
        url: this.product.url || null,
        details: this.product.details,
        specs: this.product.specs,
        // consumption: this.product.consumption,
        um: this.product.um,

        // Prezzi
        priceType: this.product.priceType,
        packaging: this.product.packaging,
        price: {},
        priceranges: [],

        // Consumo
        consumptionType: this.product.consumptionType,
        combined: this.product.combined || false,
        standalone: this.product.hasOwnProperty('standalone') ? this.product.standalone : true,
        consumptionranges: [],

        phases: {},

        userTypes: 'userTypes' in this.product && Array.isArray(this.product.userTypes) && this.product.userTypes.length > 0
          ? this.product.userTypes
          : [],

        // Aggiungi gli allegati all'oggetto update
        attachments: this.product.attachments.map((attachment) => ({
          type: attachment.type,
          category: attachment.category,
          customCategory: attachment.category === 'altro' ? attachment.customCategory : null,
          name: attachment.name.trim(),
          url: attachment.url.trim(),
        })),
      };

      // Prepara una map delle fasi selezionate
      this.product.phases.forEach((phase) => {
        update.phases[phase.id] = {
          name: phase.name,
        };
      });

      if (this.product.consumptionType === 'fixed') {
        if (this.product.consumption) {
          update.consumption = parseFloat(`${this.product.consumption}`.replace(',', '.').replace(/[^0-9.]+/g, ''));
        }
      }

      if (this.product.consumptionType === 'variable') {
        // eslint-disable-next-line no-restricted-syntax
        for (const crIndex in this.product.consumptionranges) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.product.consumptionranges.hasOwnProperty(crIndex)) {
            const consumptionrange = {
              name: this.product.consumptionranges[crIndex].name.trim(),
              consumption: parseFloat(`${this.product.consumptionranges[crIndex].consumption}`.replace(',', '.').replace(/[^0-9.]+/g, '')),
            };
            // Include se presenti i prodotti aggiuntivi richiesti
            if (Array.isArray(this.product.consumptionranges[crIndex].requiredProducts)
              && this.product.consumptionranges[crIndex].requiredProducts.length > 0) {
              consumptionrange.requiredProducts = this.product.consumptionranges[crIndex]
                .requiredProducts.map((rp) => ({
                  product: rp.product,
                  consumption: parseFloat(`${rp.consumption}`.replace(',', '.').replace(/[^0-9.]+/g, '')),
                  um: rp.um,
                }));
            } else {
              consumptionrange.requiredProducts = [];
            }
            update.consumptionranges.push(consumptionrange);
          }
        }
      }

      /*
      // Memorizza ed eventualmente corregge i prezzi unici (fixed)
      // TODO: Verrà rimosso con prezzi per lattagio completi
      if (this.product.priceType === 'fixed') {
        // eslint-disable-next-line no-restricted-syntax
        for (const pl in this.product.price) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.product.price.hasOwnProperty(pl)) {
            update.price[pl] = parseFloat(`${this.product.price[pl]}`.replace(',', '.').replace(/[^0-9.]+/g, ''));
          }
        }
      }
      */

      // eslint-disable-next-line no-restricted-syntax
      for (const prIndex in this.product.priceranges) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.product.priceranges.hasOwnProperty(prIndex)) {
          const pricerange = {
            name: this.product.priceranges[prIndex].name.trim(),
            // TODO: Verrà rimosso con prezzi per lattagio completi
            packaging: this.product.priceranges[prIndex].packaging
              ? this.product.priceranges[prIndex].packaging.trim()
              : null,
            colors: this.product.priceranges[prIndex].colors,
            prices: [],
          };

          /*
          // TODO: Verrà rimosso con prezzi per lattagio completi
          if (this.product.priceType === 'colors') {
            pricerange.price = {};

            // eslint-disable-next-line no-restricted-syntax
            for (const plIndex in this.product.priceranges[prIndex].price) {
              // eslint-disable-next-line no-prototype-builtins
              if (this.product.priceranges[prIndex].price.hasOwnProperty(plIndex)) {
                pricerange.price[plIndex] = parseFloat(`${this.product.priceranges[prIndex].price[plIndex]}`
                  .replace(',', '.').replace(/[^0-9.]+/g, ''));
              }
            }
          }
          */

          // Corregge e prepare i prezzi per ogni lattaggio
          // eslint-disable-next-line no-restricted-syntax
          for (const pIndex in this.product.priceranges[prIndex].prices) {
            if (this.product.priceranges[prIndex].prices.hasOwnProperty(pIndex)) {
              const { id, packaging, ...prices } = this.product.priceranges[prIndex].prices[pIndex];
              const price = {
                id,
                packaging,
              };

              // eslint-disable-next-line no-restricted-syntax
              for (const plId in prices) {
                if (prices.hasOwnProperty(plId)) {
                  price[plId] = parseFloat(`${prices[plId]}`
                    .replace(',', '.').replace(/[^0-9.]+/g, ''));
                }
              }

              pricerange.prices.push(price);
            }
          }

          update.priceranges.push(pricerange);
        }
      }

      await this.$store.dispatch('products/update', update);

      // Aggiorna il nome prodotto nei breadcrumbs
      this.breadcrumbs.pop();
      this.breadcrumbs.push({
        text: this.product.name[this.userLanguage],
        disabled: true,
      });

      this.updated = false;
      this.updating = false;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
};
</script>
