var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-product-selected-packaging"},[_vm._l((_vm.packages),function(pack,index){return [_c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"packaging-button",attrs:{"color":"secondary","x-small":"","outlined":!_vm.print,"text":_vm.print},domProps:{"innerHTML":_vm._s(_vm.packageText(pack))},on:{"click":_vm.onEditPackaging}},'v-btn',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Confezioni selezionate (premere per modificare)")])])]}),(_vm.packages.length === 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"packaging-button",attrs:{"color":"error","x-small":"","outlined":!_vm.print,"text":_vm.print},on:{"click":_vm.onEditPackaging}},'v-btn',attrs,false),on),[_vm._v("NESSUNA")])]}}],null,false,1864207018)},[_c('span',[_vm._v("Nessuna confezione selezionata (premere per modificare)")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300","color":_vm.tooltipColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"estimated-quantity",class:{ 'orange--text': _vm.over, 'red--text': _vm.under }},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.estimatedQuantityText)+" ")])]}}])},[(_vm.under)?_c('span',[_vm._v("Con le confezioni selezionate si ottiene una quantità "),_c('strong',[_vm._v("inferiore")]),_vm._v(" a quella stimata in base al consumo e alla superficie")]):(_vm.over)?_c('span',[_vm._v("Con le confezioni selezionate si ottiene una quantità "),_c('strong',[_vm._v("superiore")]),_vm._v(" a quella stimata in base al consumo e alla superficie")]):_c('span',[_vm._v("La quantità stimata in base al consumo e alla superficie coincide con quella ottenuta con le confezioni selezionate")])]),_c('EditPackagingDialog',{attrs:{"product":_vm.product,"estimatedQuantity":_vm.estimatedQuantity},on:{"increment":_vm.onIncrement,"decrement":_vm.onDecrement},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }