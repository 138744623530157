<template>
  <v-row>
    <v-col cols="12">
      <v-checkbox v-for="answer of answers"
        :key="answer.title"
        v-model="checkboxesValue"
        :label="answer.title"
        color="success"
        :value="answer"
      ></v-checkbox>
    </v-col>
    <v-col cols="12">
      <v-spacer></v-spacer>

      <v-btn color="success" text
        @click="onAnswer()">
        Conferma scelte
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'QuestionCheckboxes',

  props: ['value', 'answers'],

  data: () => ({
    status: null,
    checkboxesValue: [],
  }),

  created() {
    console.log('QuestionCheckboxes: created()'); // eslint-disable-line no-console
    this.status = this.value;
  },

  watch: {
    value(newValue) {
      console.log('QuestionCheckboxes: watch.value()'); // eslint-disable-line no-console
      this.status = newValue;
      this.checkboxesValue = [];
    },
  },

  computed: {
  },

  methods: {
    onAnswer() {
      this.$emit('onAnswer', this.checkboxesValue);
      this.$nextTick(() => {
        setTimeout(() => {
          this.checkboxesValue = [];
        }, 1000);
      });
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
