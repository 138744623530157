<template>
  <div class="view-preventivo">
    <Loading v-model="loading" />

    <PageHeader
      v-if="!print"
      :breadcrumbs="breadcrumbs"
      icon="mdi-file-document-box-outline"
      >
      <template v-slot:right-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon small v-on="on"
              @click="editDetailsDialog = true">
              <v-icon small color="primary">
                mdi-pencil-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Modifica nome preventivo, località e provincia</span>
        </v-tooltip>
      </template>

      <template v-slot:left-items>
        <v-tooltip bottom v-if="quotation && quotation.archived">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon color="orange">
                mdi-archive
              </v-icon>
            </v-btn>
          </template>
          <span>Il preventivo è archiviato</span>
        </v-tooltip>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text outlined class="ml-1"
              v-bind="attrs"
              v-on="on">
              <v-icon left>mdi-printer</v-icon> Stampa
            </v-btn>
          </template>
          <v-list>
            <v-subheader>Stampa:</v-subheader>
            <v-list-item @click="onPrint({ type: 0 })">
              <v-list-item-title>{{ exportLabel }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="quotationUserPriceList"
              @click="onPrint({ type: 2 })">
              <v-list-item-title>Prodotti e voci di capitolato (no prezzi)</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text outlined class="ml-1"
              v-bind="attrs"
              v-on="on">
              <v-icon left>mdi-file-pdf-outline</v-icon> Salva
            </v-btn>
          </template>
          <v-list>
            <v-subheader>Esporta in formato PDF:</v-subheader>
            <v-list-item @click="onSavePDF({ type: 0 })">
              <v-list-item-title>{{ exportLabel }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="quotationUserPriceList"
              @click="onSavePDF({ type: 2 })">
              <v-list-item-title>Prodotti e voci di capitolato (no prezzi)</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" class="ml-2">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-subheader>Esporta in formato Excel:</v-subheader>
            <v-list-item @click="onSaveXLS({ type: 0 })">
              <v-list-item-title>{{ exportLabel }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="quotationUserPriceList"
              @click="onSaveXLS({ type: 2 })">
              <v-list-item-title>Prodotti e voci di capitolato (no prezzi)</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </PageHeader>

    <Quotation v-model="quotation"
      :updating="updating"
      @options="onOptions"
      @ready="loading = false"
      @updated="updated = true" />

    <v-row v-if="!print">
      <v-col cols="12">
        <v-card outlined>
          <v-card-actions v-if="quotation">
            <v-btn text color="primary"
              :disabled="updating"
              :loading="updating"
              @click="onCancel()">
              Torna all'elenco
            </v-btn>

            <v-btn text color="orange"
              v-if="!quotation.archived"
              :disabled="updating"
              :loading="updating"
              @click="archive(true)">
              Archivia
            </v-btn>
            <v-btn text color="orange"
              v-if="quotation.archived"
              :disabled="updating"
              :loading="updating"
              @click="archive(false)">
              Recupera
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="success"
              :text="updating || !updated"
              :disabled="updating || !updated"
              :loading="updating"
              @click="update()">
              Salva modifiche
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <EditQuotationDetailsDialog v-model="editDetailsDialog" :quotation="quotation" />

    <v-dialog v-model="leaveDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Vuoi abbandonare le modifiche?</v-card-title>

        <v-card-text>
          Non hai salvato le modifiche al preventivo.
          Sei certo di voler uscire senza prima salvarle?
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text
            @click="leaveDialog = false">
            Rimani su questa pagina
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="warning" text
            @click="next()">
            Lascia questa pagina
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import PageHeader from '@/components/PageHeader';
import Quotation from '@/components/Quotation';
import EditQuotationDetailsDialog from '@/components/EditQuotationDetailsDialog';

const axios = require('axios').default;

export default {
  name: 'Preventivo',

  components: {
    Loading,
    PageHeader,
    Quotation,
    EditQuotationDetailsDialog,
  },

  data: () => ({
    loading: true,
    updating: false,

    breadcrumbs: [
      {
        text: 'Preventivi',
        exact: true,
        to: { name: 'Preventivi' },
      },
    ],

    id: null,
    quotation: null,

    updated: false,
    leaveDialog: false,
    next: null,

    editDetailsDialog: false,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente

    // Opzioni di visualizzazione/stampa del preventivo
    showPrices: false,
    showSpecs: false,
  }),

  async created() {
    console.log('this.$route.params.id = ', this.$route.params.id); // eslint-disable-line no-console
    this.id = this.$route.params.id;

    const { selectedProducts, ...quotation } = await this.$store.dispatch('quotations/getById', this.id);

    // TODO: Converte selectedProducts?

    this.quotation = {
      id: this.id,
      ...quotation,
      // selectedProducts: phasesEntries.map((e) => ({ id: e[0], name: e[1].name })),
      selectedProducts,
    };

    // Inserisce il nome prodotto nei breadcrumbs
    const text = `${this.quotation.name} - ${this.quotation.location} (${this.quotation.province.toUpperCase()})`;
    this.breadcrumbs.push({
      text,
      disabled: true,
    });
  },

  watch: {
    async editDetailsDialog(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        const { selectedProducts, ...quotation } = await this.$store.dispatch('quotations/getById', this.id);

        // TODO: Converte selectedProducts?

        this.quotation = {
          id: this.id,
          ...quotation,
          // selectedProducts: phasesEntries.map((e) => ({ id: e[0], name: e[1].name })),
          selectedProducts,
        };

        this.updateBreadcrumbs();
      }
    },
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    users() {
      return this.$store.getters['users/docs'];
    },

    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    quotationUser() {
      return Array.isArray(this.users) && this.quotation && 'uid' in this.quotation
        ? this.users.find((u) => u.uid === this.quotation.uid)
        : null;
    },

    quotationUserPriceList() {
      return Array.isArray(this.pricelists) && this.quotationUser && 'pricelist' in this.quotationUser
        ? this.pricelists.find((pl) => pl.id === this.quotationUser.pricelist)
        : null;
    },

    functionsURL() {
      return this.$firebaseFunctionsURL;
    },

    hostingURL() {
      return this.$firebaseHostingURL;
    },

    print() {
      return Boolean(this.$route.query.print);
    },

    exportLabel() {
      if (this.quotationUserPriceList) {
        if (!this.showPrices && !this.showSpecs) return 'Solo prodotti e prezzi';

        if (this.showPrices && !this.showSpecs) return 'Prodotti, prezzi e prezzi di listino';

        if (!this.showPrices && this.showSpecs) return 'Prodotti, prezzi e voci di capitolato';

        return 'Prodotti, prezzi, prezzi di listino e voci di capitolato';
      }

      // Nessun listino associato
      return this.showSpecs ? 'Prodotti e voci di capitolato (no prezzi)' : 'Solo prodotti (no prezzi)';
    },
  },

  methods: {
    // Aggiorna il nome nei breadcrumbs
    updateBreadcrumbs() {
      this.breadcrumbs.pop();

      const text = `${this.quotation.name} - ${this.quotation.location} (${this.quotation.province.toUpperCase()})`;
      this.breadcrumbs.push({
        text,
        disabled: true,
      });
    },

    onCancel() {
      // Torna all'elenco dei preventivi
      this.$router.replace({ name: 'Preventivi' }, () => {});
    },

    async archive(value) {
      this.updating = true;
      this.quotation.archived = value;
      await this.$store.dispatch('quotations/update', {
        id: this.id,
        archived: value,
      });
      this.updating = false;
    },

    async update() {
      console.log('NuovoPreventivo: update()'); // eslint-disable-line no-console

      this.updating = true;

      const quotation = {
        id: this.id,
        name: this.quotation.name,
        area: this.quotation.area || 100,
        selectedProducts: this.quotation.selectedProducts,
        selectedIncidences: 'selectedIncidences' in this.quotation ? this.quotation.selectedIncidences : [],
        user: this.quotationUser,
      };

      if ('discount' in this.quotation) {
        quotation.discount = this.quotation.discount;
      }

      if ('discountAccounting' in this.quotation) {
        quotation.discountAccounting = this.quotation.discountAccounting;
      }

      console.log('NuovoPreventivo: update() quotation =', quotation); // eslint-disable-line no-console

      await this.$store.dispatch('quotations/update', quotation);

      this.updateBreadcrumbs();

      this.updated = false;
      this.updating = false;
    },

    // Sono state aggiornate le opzioni del preventivo
    onOptions(event) {
      console.log(`${this.constructor.name}: onOptions() event =`, event); // eslint-disable-line no-console

      if (event && 'showPrices' in event) {
        this.showPrices = event.showPrices;
      }

      if (event && 'showSpecs' in event) {
        this.showSpecs = event.showSpecs;
      }
    },

    async onSavePDF({ type }) {
      await this.update();

      const url = this.quotationUserPriceList
        ? `${this.functionsURL}downloadPDF?q=${this.id}&pricelist=${this.quotationUserPriceList.id}&showPrices=${this.showPrices}&showSpecs=${this.showSpecs}&type=${type}`
        : `${this.functionsURL}downloadPDF?q=${this.id}&showSpecs=${this.showSpecs}&type=${type}`;

      // eslint-disable-next-line no-restricted-globals
      window.open(url, '_blank');
    },

    async onPrint({ type }) {
      await this.update();

      const url = this.quotationUserPriceList
        ? `${this.hostingURL}preventivi/${this.id}/pdf?print=1&pricelist=${this.quotationUserPriceList.id}&showPrices=${this.showPrices}&showSpecs=${this.showSpecs}&type=${type}`
        : `${this.hostingURL}preventivi/${this.id}/pdf?print=1&showSpecs=${this.showSpecs}&type=${type}`;

      // eslint-disable-next-line no-restricted-globals
      window.open(url, '_blank');
    },

    async onSaveXLS({ type }) {
      await this.update();

      const filename = `${this.quotation.name} - ${this.quotation.location} (${this.quotation.province.toUpperCase()}).xlsx`;

      const params = this.quotationUserPriceList
        ? {
          q: this.id,
          pricelist: this.quotationUserPriceList.id,
          showPrices: this.showPrices,
          showSpecs: this.showSpecs,
          type,
          xls: true,
        }
        : {
          q: this.id,
          showSpecs: this.showSpecs,
          type,
          xls: true,
        };

      axios.get(`${this.functionsURL}downloadXLS`, {
        responseType: 'text',
        headers: { 'Content-Type': 'application/octet-stream' },
        params,
      }).then((response) => {
        const byteCharacters = atob(response.data);
        const byteNumbers = new Array(byteCharacters.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const blobURL = window.URL.createObjectURL(blob);
          const tempLink = document.createElement('a');
          tempLink.style.display = 'none';
          tempLink.href = blobURL;
          tempLink.setAttribute('download', filename);

          if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
          }
          document.body.appendChild(tempLink);
          tempLink.click();
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }
      });
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
};
</script>
