<template>
  <div class="component-pricelists">
    <v-row no-gutters
      v-if="pricelists.length > 0">
      <v-col>
        <v-card>
          <!-- v-card-title>
            <v-spacer></v-spacer>
          </v-card-title -->

          <v-simple-table>
            <template v-slot:default>
              <draggable tag="tbody"
                v-model="pricelists"
                handle=".sortHandle"
                draggable=".sortableRow">
                <tr class="sortableRow" v-for="item in pricelists" :key="item.id">
                  <td class="px-1" style="width: 0.1%">
                    <v-btn style="cursor: move" icon class="sortHandle">
                      <v-icon>mdi-drag</v-icon>
                    </v-btn>
                  </td>
                  <td>{{ item.name }}</td>
                  <td class="text-right text-no-wrap">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          @click="onRename(item)">
                          <v-list-item-title>Rinomina</v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          @click="onDelete(item)">
                          <v-list-item-title>Elimina</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <!-- v-btn class="ml-2" fab x-small depressed color="success" dark
                      @click="onEdit(item)">
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn -->
                  </td>
                </tr>
              </draggable>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-alert text prominent type="success" border="left"
      v-else>
      <v-row>
        <v-col
          class="d-flex flex-column flex-sm-row justify-space-between align-start align-sm-center">
          <span>Al momento non è presente nessun listino.</span>
          <v-btn color="success" class="mt-4 mt-sm-0"
            @click.stop="newDialog = true">Crea un listino</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <NewPricelistDialog v-model="newDialog" />
    <RenamePricelistDialog v-model="renameDialog" :pricelist="selected" />
    <DeletePricelistDialog v-model="deleteDialog" :pricelist="selected" />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import NewPricelistDialog from '@/components/NewPricelistDialog';
import RenamePricelistDialog from '@/components/RenamePricelistDialog';
import DeletePricelistDialog from '@/components/DeletePricelistDialog';

export default {
  name: 'Pricelists',

  components: {
    draggable,
    NewPricelistDialog,
    RenamePricelistDialog,
    DeletePricelistDialog,
  },

  data: () => ({
    newDialog: false,
    renameDialog: false,
    deleteDialog: false,

    selected: null,

    headers: [
      { text: 'Nome del listino', value: 'name' },
      {
        text: '',
        value: 'action',
        sortable: false,
        align: 'end',
      },
    ],
  }),

  computed: {
    pricelists: {
      get() {
        return this.$store.getters['pricelists/docs'];
      },
      set(pricelists) {
        this.$store.dispatch('pricelists/reorder', pricelists);
      },
    },
  },

  methods: {
    onRename(item) {
      this.selected = item;
      this.renameDialog = true;
    },

    onDelete(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
  },
};
</script>
