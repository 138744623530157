<template>
  <div class="component-deleteuserdialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline">Elimina l'utente</v-card-title>

          <v-card-text>
            <p>Confermi di voler eliminare l'utente selezionato?</p>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="cancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="error" text
              :loading="loading"
              @click="deleteItem()">
              Elimina
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { DELETE_USER } from '@/store/actions/users';

export default {
  props: ['value', 'user'],

  name: 'DeleteUserDialog',

  data: () => ({
    dialog: false,
    loading: false,
  }),

  watch: {
    value(newValue) {
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    async deleteItem() {
      if (this.user && this.user.id) {
        this.loading = true;

        await this.$store.dispatch(`users/${DELETE_USER}`, { user: this.user });

        this.loading = false;
        this.dialog = false;
      }
    },
  },
};
</script>
