import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import it from 'vuetify/es5/locale/it';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#363940', // PANTONE 419 U
        secondary: '#948272', // PANTONE 7504 U
        accent: '#EBB5A5', // PANTONE 7415 U
        error: '#DE4343', // PANTONE 2035 U
        info: '#00BAAE', // PANTONE 3262 U
        success: '#18975E', // PANTONE 355 U
        warning: '#F69941', // PANTONE 2012 U
      },
    },
  },
  lang: {
    locales: { it },
    current: 'it',
  },
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
});
