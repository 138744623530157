import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import phases from './modules/phases';
import pricelists from './modules/pricelists';
import colorlists from './modules/colorlists';
import quotations from './modules/quotations';
import products from './modules/products';
import tags from './modules/tags';
import users from './modules/users';
import notifications from './modules/notifications';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    phases,
    pricelists,
    colorlists,
    products,
    tags,
    quotations,
    users,
    notifications,
  },
});
