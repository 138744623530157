<template>
  <div class="component-test">
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <TestStages
            v-model="status"
            :test="test" />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8">
        <template v-if="currentStage !== 4">
          <TestStage
            v-for="(stage, index) of test"
            :key="`stage-${index}`"
            v-show="status.currentStage === index+1"
            v-model="status"
            :stage="stage" />
        </template>

        <template v-if="currentStage === 4">
          <ProductSelection
            v-if="currentStage === 4"
            v-model="quotation"
            :status="status"
            @complete="onComplete" />
        </template>
      </v-col>

      <v-col cols="4" class="d-flex flex-column">
        <template v-if="currentStage !== 4">
          <SelectedPhases
            :status="status" />
        </template>

        <template v-if="currentStage === 4">
          <SelectedProducts
            :quotation="quotation"
            no-actions
            @update="onUpdate" />
        </template>

        <div v-if="user.superUser"
          class="mt-4">
          <v-chip
            v-for="tag of status.tags"
            :key="tag"
            class="mr-1 mb-1"
            x-small
          >
            {{ tag }}
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import { v4 as uuidv4 } from 'uuid';
import TestStages from '@/components/TestStages';
import TestStage from '@/components/TestStage';
import SelectedPhases from '@/components/SelectedPhases';
import ProductSelection from '@/components/ProductSelection';
import SelectedProducts from '@/components/SelectedProducts';

export default {
  name: 'Test',

  components: {
    TestStages,
    TestStage,
    SelectedPhases,
    ProductSelection,
    SelectedProducts,
  },

  data: () => ({
    quotation: null,

    test: [
      {
        name: 'stage1',
        title: 'Tipologia del supporto',

        questions: [
          {
            name: 'interno-esterno',
            title: "È all'interno o all'esterno?",
            type: 'buttons',
            answers: [
              {
                title: 'Interno',
                // Per l'interno l'unica finitura possibile è il tinteggio (no rivestimento)
                tag: ['interno', 'finitura-tinteggio'],
              },
              {
                title: 'Esterno',
                tag: 'esterno',
              },
            ],
          },
          {
            name: 'tipo-muratura',
            title: 'Che tipo di supporto?',
            type: 'buttons',
            answers: [
              {
                title: 'Blocchi in laterizio',
                tag: 'laterizio',
              },
              {
                title: 'Cartongesso',
                tag: 'cartongesso',
                hidden: ['esterno'],
              },
              {
                title: 'Pannelli di fibrocemento',
                tag: 'fibrocemento',
                hidden: ['esterno'],
              },
              {
                title: 'Cemento armato',
                tag: 'armato',
              },
              {
                title: 'Isolamento a cappotto',
                tag: 'cappotto',
              },
              {
                title: 'Pietra naturale (pietre tenere, sedimentarie)',
                tag: ['muratura-pietra', 'moderno', 'no-velature'],
              },
              {
                title: 'Mattoni faccia a vista',
                tag: 'facciaavista',
              },
            ],
          },

          {
            name: 'tipo-supporto',
            title: 'Che tipo di supporto?',
            type: 'buttons',
            visible: ['laterizio'],
            answers: [
              {
                title: 'Intonaco minerale',
                tag: 'intonaco-minerale',
              },
              {
                title: 'Intonaco di puro gesso',
                tag: 'intonaco-gesso',
                hidden: ['esterno'],
              },
              {
                title: 'Cemento cellulare',
                tag: 'cemento-cellulare',
              },
              {
                title: 'Intonaci argillosi',
                tag: 'intonaci-argillosi',
                hidden: ['esterno'],
              },
              {
                title: 'Carta da parati',
                tag: 'cartadaparati',
                hidden: ['esterno'],
              },
            ],
          },

          {
            name: 'tipo-intonaco-minerale',
            title: 'Che tipo di intonaco minerale?',
            type: 'radio',
            visible: ['intonaco-minerale'],
            answers: [
              {
                title: 'Calce aerea (1 parte di calce idrata e 3 parti di sabbia)',
                tag: 'intonaco-calce-aerea',
              },
              {
                title: 'Grassello di calce (1 parte di grassello e 3 o 4 parti di sabbia)',
                tag: 'intonaco-grassello',
              },
              {
                title: 'Calce idraulica (1 parte di calce idraulica e 3 o 4 parti di sabbia)',
                tag: 'intonaco-calce-idraulica',
              },
              {
                title: 'Malta bastarda (⅓ di calce ⅓ di cemento e ⅓ di sabbia)',
                tag: 'intonaco-malta-bastarda',
              },
              {
                title: 'Malta cementizia (½ cemento ½ sabbia)',
                tag: 'intonaco-malta-cementizia',
              },
            ],
          },

          {
            name: 'tipo-intonaco-gesso',
            title: 'Che tipo di intonaco di puro gesso?',
            type: 'radio',
            visible: ['intonaco-gesso'],
            answers: [
              {
                title: 'Malta di gesso',
                tag: 'intonaco-gesso-malta',
              },
              {
                title: 'Malta di gesso e sabbia',
                tag: 'intonaco-gesso-malta-sabbia',
              },
              {
                title: 'Malta di gesso scagliola',
                tag: 'intonaco-gesso-malta-scagliola',
              },
            ],
          },

          {
            name: 'tipo-armato',
            title: 'Cemento armato gettato in opera o prefabbricato?',
            type: 'buttons',
            visible: ['armato'],
            answers: [
              {
                title: 'Gettato in opera',
                tag: 'armato-gettato',
              },
              {
                title: 'Prefabbricato',
                tag: 'armato-prefabbricato',
              },
            ],
          },

          {
            name: 'superficie-armato',
            title: 'Superficie liscia o granulosa?',
            type: 'buttons',
            visible: ['armato-prefabbricato'],
            answers: [
              {
                title: 'Superficie liscia',
                tag: 'prefabbricato-liscio',
              },
              {
                title: 'Superficie granulosa',
                tag: 'prefabbricato-granuloso',
              },
            ],
          },

          {
            name: 'quale-isolante',
            title: 'Quale materiale isolante?',
            type: 'radio',
            visible: ['cappotto'],
            answers: [
              {
                title: 'Polistirene espanso sinterizzato (EPS)',
                tag: 'isolante-eps',
              },
              {
                title: 'Polistirene espanso sinterizzato con grafite (EPS)',
                tag: 'isolante-eps-grafite',
              },
              {
                title: 'Polistirene espanso estruso (XPS)',
                tag: 'isolante-xps',
              },
              {
                title: 'Lana di roccia',
                tag: 'isolante-lana-roccia',
              },
              {
                title: 'Lana di vetro',
                tag: 'isolante-lana-vetro',
              },
              {
                title: 'Fibra di legno',
                tag: 'isolante-fibra-legno',
              },
              {
                title: 'Calcio silicato',
                tag: 'isolante-calcio-silicato',
              },
              {
                title: 'Sughero',
                tag: 'isolante-sughero',
              },
              {
                title: 'Aerogel di silice',
                tag: 'isolante-aerogel',
              },
            ],
          },

          // Tinteggiatura attuale
          {
            name: 'tinteggiatura-interno',
            title: 'È già presente un tinteggio?',
            visible: ['interno'],
            hidden: ['facciaavista'],
            type: 'buttons',
            answers: [
              {
                title: 'Sì',
                tag: 'tinteggiato',
              },
              {
                title: 'No',
                tag: 'non-tinteggiato',
              },
            ],
          },
          {
            name: 'tinteggiatura-esterno',
            title: 'È già presente un tinteggio o un rivestimento?',
            visible: ['esterno'],
            hidden: ['facciaavista'],
            type: 'buttons',
            answers: [
              {
                title: 'Sì, è tinteggiato',
                tag: 'tinteggiato',
              },
              {
                title: 'Sì, è presente un rivestimento',
                tag: 'rivestito',
              },
              {
                title: 'No',
                tag: 'non-tinteggiato',
              },
            ],
          },
          {
            name: 'tinteggiatura-facciaavista',
            title: 'È già presente un tinteggio o un protettivo idrorepellente, trasparente?',
            visible: ['facciaavista'],
            type: 'buttons',
            answers: [
              {
                title: 'Sì, è tinteggiato',
                tag: 'tinteggiato',
              },
              {
                title: 'Sì, è presente un protettivo idrorepellente, trasparente',
                tag: 'protettivo-idrorepellente',
              },
              {
                title: 'No',
                tag: 'non-tinteggiato',
              },
            ],
          },
          {
            name: 'tipo-rivestimento',
            title: 'Che tipo di rivestimento?',
            type: 'buttons',
            visible: ['rivestito'],
            answers: [
              {
                title: 'Rivestimento minerale',
                tag: 'rivestimento-minerale',
              },
              {
                title: 'Rivestimento organico',
                tag: 'rivestimento-organico',
              },
            ],
          },
          {
            name: 'tipo-rivestimento-minerale',
            title: 'Che tipo di legante minerale utilizza il rivestimento?',
            type: 'buttons',
            visible: ['rivestimento-minerale'],
            answers: [
              {
                title: 'Calce',
                tag: 'rivestimento-calce',
              },
              {
                title: 'Cementizio',
                tag: 'rivestimento-cementizio',
              },
              {
                title: 'Silicati',
                tag: 'rivestimento-silicati',
              },
            ],
          },
          {
            name: 'tipo-rivestimento-organico',
            title: 'Che tipo di legante organico utilizza il rivestimento?',
            type: 'buttons',
            visible: ['rivestimento-organico'],
            answers: [
              {
                title: 'Acrilico (plastico)',
                tag: 'rivestimento-organico-acrilico',
              },
              {
                title: 'Acril-silossanico',
                tag: 'rivestimento-organico-acrilsilossanico',
              },
              {
                title: 'Silossanico',
                tag: 'rivestimento-organico-silossanico',
              },
              {
                title: 'Elastomerico',
                tag: 'rivestimento-organico-elastomerico',
              },
            ],
          },
          {
            name: 'tipo-pittura',
            title: 'Che tipo di pittura?',
            type: 'buttons',
            visible: ['tinteggiato'],
            answers: [
              {
                title: 'Pittura minerale',
                tag: 'pittura-minerale',
              },
              {
                title: 'Pittura organica',
                tag: 'pittura-organica',
              },
            ],
          },
          {
            name: 'tipo-pittura-minerale',
            title: 'Che tipo di pittura minerale?',
            type: 'buttons',
            visible: ['pittura-minerale'],
            answers: [
              {
                title: 'Calce',
                tag: 'pittura-calce',
              },
              {
                title: 'Silicati',
                tag: 'pittura-silicati',
              },
            ],
          },
          {
            name: 'tipo-pittura-organica',
            title: 'Che tipo di pittura organica?',
            type: 'buttons',
            visible: ['pittura-organica'],
            answers: [
              {
                title: 'Tempera',
                tag: 'pittura-tempera',
                hidden: ['esterno'],
              },
              {
                title: 'Traspirante',
                tag: 'pittura-traspirante',
                hidden: ['esterno'],
              },
              {
                title: 'Lavabile',
                tag: 'pittura-lavabile',
                hidden: ['esterno'],
              },
              {
                title: 'Smalto',
                tag: 'pittura-smalto',
                hidden: ['esterno'],
              },
              {
                title: 'Quarzo',
                tag: 'pittura-quarzo',
                hidden: ['interno'],
              },
              {
                title: 'Acril-silossanica',
                tag: 'pittura-acrilsilossanica',
                hidden: ['interno'],
              },
              {
                title: 'Silossanica',
                tag: 'pittura-silossanica',
                hidden: ['interno'],
              },
              {
                title: 'Elastomerica',
                tag: 'pittura-elastomerica',
                hidden: ['interno'],
              },
            ],
          },

          // Tinteggiatora o rivestimento ben ancorati
          {
            name: 'tinteggiatura-ben-ancorata', // C1
            title: 'La tinteggiatura è ben ancorata al supporto?',
            type: 'buttons',
            visible: ['tinteggiato'],
            answers: [
              {
                title: 'Sì',
                tag: 'tinteggiatura-ben-ancorata',
              },
              {
                title: 'No',
                tag: 'tinteggiatura-non-ancorata',
              },
            ],
          },

          {
            name: 'rivestimento-ben-ancorato', // C1
            title: 'Il rivestimento è ben ancorato al supporto?',
            type: 'buttons',
            visible: ['rivestito'],
            answers: [
              {
                title: 'Sì',
                tag: 'rivestimento-ben-ancorato',
              },
              {
                title: 'No',
                tag: 'rivestimento-non-ancorato',
              },
            ],
          },
        ],
      },

      // Stage 2
      {
        name: 'stage2',
        title: 'Analisi del supporto',

        questions: [
          // Domande specifiche sul cemento armato
          {
            name: 'armato-carbonatazione',
            title: 'Ci sono porzioni di calcestruzzo in fase di distacco per problemi di carbonatazione dei ferri?',
            type: 'buttons',
            visible: ['armato'],
            answers: [
              {
                title: 'Sì',
                tag: 'armato-carbonatazione',
              },
              {
                title: 'No',
              },
            ],
          },
          {
            name: 'armato-nidi',
            title: 'Ci sono dei nidi di ghiaia?',
            type: 'buttons',
            visible: ['armato-gettato'],
            answers: [
              {
                title: 'Sì',
                tag: 'armato-nidi',
              },
              {
                title: 'No',
              },
            ],
          },
          {
            name: 'armato-olio',
            title: "C'è dell'olio disarmante?",
            type: 'buttons',
            visible: ['prefabbricato-liscio'],
            answers: [
              {
                title: 'Sì',
                tag: 'armato-olio',
              },
              {
                title: 'No',
              },
            ],
          },

          // Domande specifiche sull'isolamento a cappotto
          {
            name: 'cappotto-completo',
            title: "L'isolamento a cappotto è completato?",
            type: 'buttons',
            visible: ['cappotto'],
            hidden: ['tinteggiato'],
            answers: [
              {
                title: 'Sì',
                tag: 'cappotto-completo',
              },
              {
                title: 'No',
                tag: 'cappotto-incompleto',
              },
            ],
          },
          {
            name: 'cappotto-pannelli',
            title: 'Vanno incollati e rasati i pannelli del cappotto?',
            type: 'radio',
            visible: ['cappotto-incompleto'],
            answers: [
              {
                title: 'I pannelli sono già incollati ma vanno rasati',
                tag: 'cappotto-pannelli_da_rasare',
              },
              {
                title: 'I pannelli vanno incollati e rasati',
                tag: ['cappotto-pannelli_da_incollare', 'cappotto-pannelli_da_rasare'],
              },
            ],
          },
          {
            name: 'cappotto-finitura',
            title: 'Serve la finitura per il cappotto isolante?',
            type: 'buttons',
            visible: ['cappotto'],
            hidden: ['tinteggiato'],
            answers: [
              {
                title: 'Sì',
                tag: 'cappotto-finitura',
              },
              {
                title: 'No',
              },
            ],
          },

          // Domande su microorganismi (muffe e alghe)
          {
            name: 'microorganismi-alghe', // C-esterno
            title: 'Ci sono microorganismi/alghe?',
            type: 'buttons',
            visible: ['esterno'],
            hidden: ['cappotto-incompleto'],
            answers: [
              {
                title: 'Sì',
                tag: ['microorganismi', 'microorganismi-alghe'],
              },
              {
                title: 'No',
                tag: ['no-microorganismi', 'no-microorganismi-alghe'],
              },
            ],
          },
          {
            name: 'microorganismi-muffe', // C-interno
            title: 'Ci sono microorganismi/muffe?',
            type: 'buttons',
            visible: ['interno'],
            hidden: ['cemento-cellulare', 'cartongesso', 'fibrocemento', 'cappotto-incompleto'],
            answers: [
              {
                title: 'Sì',
                tag: ['microorganismi', 'microorganismi-muffe'],
              },
              {
                title: 'No',
                tag: ['no-microorganismi', 'no-microorganismi-muffe'],
              },
            ],
          },
          {
            name: 'entita-microorganismi', // C+
            title: 'Qual è l’entità del problema dei microorganismi?',
            type: 'buttons',
            visible: ['microorganismi-muffe'],
            answers: [
              {
                title: 'Lieve/Limitata',
                tag: 'microorganismi-lieve',
              },
              {
                title: 'Moderata',
                tag: 'microorganismi-moderata',
              },
              {
                title: 'Estesa/Ponti termici',
                tag: 'microorganismi-estesa',
              },
            ],
          },

          // Domande sulle crepe
          {
            name: 'crepe',
            title: 'Ci sono crepe?',
            type: 'buttons',
            visible: true,
            hidden: ['cappotto-incompleto', 'cartongesso', 'muratura-pietra', 'facciaavista'],
            answers: [
              {
                title: 'Sì',
                tag: 'crepe',
              },
              {
                title: 'No',
              },
            ],
          },
          {
            name: 'quali-crepe',
            title: 'Quali tipi di crepe ci sono?',
            type: 'checkboxes',
            visible: ['crepe'],
            answers: [
              {
                title: 'Micro-cavillature o fessurazioni da ritiro', // E
                tag: 'crepe-microcavillature',
                visible: true,
                hidden: ['cartadaparati', 'cartongesso', 'fibrocemento'],
              },
              {
                title: 'Lesioni strutturali',
                tag: 'crepe-lesioni_strutturali',
                visible: ['esterno'],
              },
              {
                title: 'Fessurazioni a 45° in corrispondenza dei davanzali e delle finestre',
                tag: 'crepe-fessurazioni_45',
                visible: ['esterno'],
              },
              {
                title: 'Fessurazioni in corrispondenza delle fughe delle lastre isolanti',
                tag: 'crepe-fessurazioni_lastre_isolanti',
                visible: ['cappotto'],
              },
            ],
          },

          // Infiltrazioni
          {
            name: 'infiltrazioni',
            title: 'Ci sono infiltrazioni dai balconi o dalla copertura?',
            type: 'buttons',
            visible: ['esterno'],
            answers: [
              {
                title: 'Sì',
                tag: 'infiltrazioni',
              },
              {
                title: 'No',
              },
            ],
          },

          {
            name: 'risalita', // A
            title: 'Ci sono problemi di umidità di risalita?',
            type: 'buttons',
            visible: true,
            hidden: ['cemento-cellulare', 'intonaci-argillosi', 'cartadaparati', 'cartongesso', 'fibrocemento'],
            answers: [
              {
                title: 'Sì',
                tag: 'risalita',
              },
              {
                title: 'No',
              },
            ],
          },
          {
            name: 'intonaco-deumidificante', // B
            title: "È presente dell'intonaco deumidificante?",
            type: 'buttons',
            visible: true,
            hidden: ['intonaco-gesso', 'cemento-cellulare', 'intonaci-argillosi', 'cartadaparati', 'cartongesso', 'fibrocemento', 'esterno', 'facciaavista'],
            answers: [
              {
                title: 'Sì',
                tag: 'intonaco-deumidificante',
              },
              {
                title: 'No',
              },
            ],
          },

          // Supporto assorbente/polveroso
          {
            name: 'supporto-assorbente-polveroso', // D
            title: 'Il supporto è molto assorbente e/o molto polveroso?',
            type: 'buttons',
            visible: true,
            hidden: ['cartadaparati', 'pittura-lavabile', 'pittura-smalto'],
            answers: [
              {
                title: 'Sì',
                tag: ['supporto-assorbente', 'supporto-polveroso'],
              },
              {
                title: 'No',
                tag: ['supporto-non-assorbente', 'supporto-non-polveroso'],
              },
            ],
          },

          {
            name: 'supporto-pocoassorbente-lucido', // D1
            title: 'Il supporto è poco assorbente e/o lucido?',
            type: 'buttons',
            visible: ['pittura-lavabile', 'pittura-smalto', 'prefabbricato-liscio', 'protettivo-idrorepellente'],
            hidden: ['supporto-assorbente'],
            answers: [
              {
                title: 'Sì',
                tag: 'supporto-pocoassorbente-lucido',
              },
              {
                title: 'No',
                tag: 'supporto-non-pocoassorbente-lucido',
              },
            ],
          },

          //

          {
            name: 'stuccature', // F
            title: 'Ci sono stuccature, riprese o rappezzi?',
            type: 'buttons',
            visible: true,
            hidden: ['intonaco-gesso', 'cartadaparati', 'cappotto', 'muratura-pietra'],
            answers: [
              {
                title: 'Sì, ruvide',
                tag: ['stuccature', 'stuccature-ruvide'],
              },
              {
                title: 'Sì, lisce',
                visible: ['crepe-microcavillature'],
              },
              {
                title: 'Sì, lisce',
                tag: ['stuccature', 'stuccature-lisce'],
                visible: true,
                hidden: ['crepe-microcavillature'],
              },
              {
                title: 'No',
                tag: ['no-stuccature'],
              },
            ],
          },

          {
            name: 'radente', // H
            title: 'Il supporto è soggetto ad esposizione di luce radente?',
            type: 'buttons',
            visible: ['interno'],
            hidden: ['facciaavista'],
            answers: [
              {
                title: 'Sì',
                tag: 'radente',
              },
              {
                title: 'No',
              },
            ],
          },

          {
            name: 'rasatura-totale-interna',
            title: 'Occorre una rasatura totale della parete?',
            type: 'buttons',
            visible: ['intonaco-calce-idraulica', 'intonaco-malta-bastarda', 'intonaco-malta-cementizia', 'intonaco-gesso', 'cartongesso', 'prefabbricato-liscio'],
            hidden: ['esterno', 'microorganismi-moderata', 'microorganismi-estesa', 'no-stuccature'],
            answers: [
              {
                title: 'Sì',
                tag: 'rasatura-totale-interna',
              },
              {
                title: 'No',
              },
            ],
          },
        ],
      },

      {
        name: 'stage3',
        title: 'Tipologia di finitura',

        questions: [
          {
            name: 'tipologia-finitura',
            title: 'Quale tipologia di finitura vuoi applicare?',
            type: 'buttons',
            visible: true,
            hidden: ['interno', 'muratura-pietra', 'facciaavista'],
            answers: [
              {
                title: 'Tinteggio',
                tag: 'finitura-tinteggio',
              },
              {
                title: 'Rivestimento/Intonachino',
                tag: 'finitura-rivestimento',
              },
            ],
          },
          {
            name: 'tipologia-finitura-pietra', // H
            title: 'Vuoi applicare un protettivo idrorepellente, trasparente opaco?',
            type: 'buttons',
            visible: ['muratura-pietra'],
            hidden: ['interno', 'facciaavista'],
            answers: [
              {
                title: 'Sì',
                tag: 'protettivo-idrorepellente',
              },
              {
                title: 'No',
              },
            ],
          },
          // {
          //   name: 'tipologia-finitura-facciaavista-idropresente',
          //   title: 'Vuoi applicare un tinteggio?',
          //   type: 'buttons',
          //   visible: ['protettivo-idrorepellente'],
          //   answers: [
          //     {
          //       title: 'Sì',
          //       tag: 'finitura-tinteggio',
          //     },
          //     {
          //       title: 'No',
          //       tag: 'no-tinteggio',
          //     },
          //   ],
          // },
          {
            name: 'tipologia-finitura-facciaavista',
            title: 'Quale tipologia di finitura vuoi applicare?',
            type: 'buttons',
            visible: ['facciaavista'],
            hidden: ['protettivo-idrorepellente'],
            answers: [
              {
                title: 'Tinteggio',
                tag: 'finitura-tinteggio',
              },
              {
                title: 'Protettivo idrorepellente, trasparente opaco',
                tag: 'protettivo-idrorepellente',
              },
              {
                title: 'Niente',
                tag: 'no-tinteggio',
              },
            ],
          },

          // {
          //   name: 'granulometria-rivestimento',
          //   title: 'Quale granulometria è richiesta?',
          //   type: 'buttons',
          //   visible: ['finitura-rivestimento'],
          //   hidden: true,
          //   answers: [
          //     {
          //       title: '0,7 mm',
          //       tag: 'granulometria-0_7',
          //     },
          //     {
          //       title: '1 mm',
          //       tag: 'granulometria-1',
          //     },
          //     {
          //       title: '1,2 mm',
          //       tag: 'granulometria-1_2',
          //     },
          //     {
          //       title: '1,5 mm',
          //       tag: 'granulometria-1_5',
          //     },
          //     {
          //       title: '2 mm',
          //       tag: 'granulometria-2',
          //     },
          //     {
          //       title: '3 mm',
          //       tag: 'granulometria-3',
          //     },
          //   ],
          // },
          {
            name: 'storico',
            title: 'È un edificio sotto il vincolo della soprintendenza dei beni architettonici?',
            type: 'buttons',
            visible: true,
            hidden: ['cappotto', 'muratura-pietra'],
            answers: [
              {
                title: 'Sì',
                tag: ['storico', 'no-velature'],
              },
              {
                title: 'No',
                tag: 'moderno',
              },
            ],
          },
          {
            name: 'velature',
            title: 'Vuoi realizzare delle velature?',
            type: 'buttons',
            visible: true,
            hidden: ['finitura-rivestimento', 'cappotto', 'muratura-pietra', 'no-tinteggio', 'protettivo-idrorepellente'],
            answers: [
              {
                title: 'Sì',
                tag: 'velature',
              },
              {
                title: 'No',
                tag: 'no-velature',
              },
            ],
          },
          // {
          //   name: 'bianco-colorato',
          //   title: 'Bianco o colorato?',
          //   type: 'buttons',
          //   visible: true,
          //   hidden: false,
          //   answers: [
          //     {
          //       title: 'Bianco',
          //       tag: 'finitura-bianco',
          //     },
          //     {
          //       title: 'Colorato',
          //       tag: 'finitura-colore',
          //     },
          //   ],
          // },
          // {
          //   name: 'selezione-colore',
          //   title: 'Quale colore vuoi utilizzare?',
          //   type: 'color',
          //   visible: ['finitura-colore'],
          //   hidden: true,
          //   last: true,
          // },
        ],
      },

      {
        name: 'stage4',
        title: 'Scelta dei prodotti',
      },
    ],

    status: {
      currentStage: 1,
      // currentStage: 4,
      currentStep: 1,

      tags: [],

      colorlist: null,
      color: null,

      complete: false,

      stages: {
        stage1: {
          complete: false,
          questions: {},
          tags: [],
        },
        stage2: {
          complete: false,
          questions: {},
          tags: [],
        },
        stage3: {
          complete: false,
          questions: {},
          tags: [],
        },
        stage4: {
          complete: false,
          // questions: {},
          // tags: [],
        },
      },
    },
  }),

  computed: {
    user() {
      return this.$store.getters.user;
    },

    complete() {
      return this.status.complete;
    },

    currentStage() {
      return this.status.currentStage;
    },

    products() {
      return this.$store.getters['products/docs'];
    },

    selectedProducts() {
      // eslint-disable-next-line no-prototype-builtins
      return this.quotation && 'selectedProducts' in this.quotation
        && Array.isArray(this.quotation.selectedProducts)
        ? this.quotation.selectedProducts
        : [];
    },

    allRequiredProducts() {
      if (!Array.isArray(this.quotation.selectedProducts)) return [];

      const result = [];
      this.quotation.selectedProducts.forEach((product) => {
        if (product.consumptionType === 'variable'
          && Array.isArray(product.consumptionranges[product.consumptionRange || 0]
            .requiredProducts)
          && product.consumptionranges[product.consumptionRange || 0].requiredProducts.length > 0) {
          result.push(
            ...product.consumptionranges[product.consumptionRange || 0].requiredProducts
              .map((rp) => rp.product),
          );
        }
      });

      return result;
    },
  },

  watch: {
    value(newValue) {
      console.log('Test: watch -> value'); // eslint-disable-line no-console
      this.quotation = newValue;
    },

    quotation(newValue) {
      console.log('Test: watch -> quotation'); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },

    // complete(newValue) {
    //   console.log('Test: watch.complete()'); // eslint-disable-line no-console
    //   this.$emit('complete', newValue ? this.status : null);
    // },

    selectedProducts(newValue) {
      console.log('ManualSelection: watch.selectedProducts()'); // eslint-disable-line no-console

      // Prepara l'elenco dei prodotti richiesti in base ai prodotti attualmente selezionati
      const requiredProducts = [];
      newValue.forEach((product) => {
        if (product.consumptionType === 'variable'
          && Array.isArray(product.consumptionranges[product.consumptionRange || 0]
            .requiredProducts)
          && product.consumptionranges[product.consumptionRange || 0].requiredProducts.length > 0) {
          requiredProducts.push(
            ...product.consumptionranges[product.consumptionRange || 0].requiredProducts
              .map((rp) => rp.product),
          );
        }
      });

      // Seleziona automaticamente i prodotti richiesti (se non già selezionati)
      if (requiredProducts.length > 0) {
        console.log('ManualSelection: watch.selectedProducts() requiredProducts =', requiredProducts); // eslint-disable-line no-console

        requiredProducts.forEach((rp) => {
          // Verifica se il prodotto è già in lista
          const exists = newValue.find((p) => p.id === rp.id);
          if (!exists) {
            // Usa la versione aggiornata del prodotto
            const updatedRP = this.products.find((p) => p.id === rp.id);
            if (updatedRP) {
              const urp = JSON.parse(JSON.stringify(updatedRP));
              urp.sId = uuidv4();

              this.quotation.selectedProducts.push(urp);
            }
          }
        });
      }

      // Deseleziona automaticamente i prodotti richiesti non più richiesti
      // Inizia analizzando prima tutti i prodotti
      newValue
        .filter((p) => this.isCombined(p))
        .forEach((product) => {
          // Ho trovato un prodotto combinato,
          // verifico se è ancora richiesto.
          const required = requiredProducts.find((rp) => rp.id === product.id);

          // Se non è più richiesto e non può essere usato da solo,
          // lo elimino.
          if (!required && product.hasOwnProperty('standalone') && product.standalone === false) {
            this.$set(
              this.quotation,
              'selectedProducts',
              newValue.filter((p) => p.id !== product.id),
            );
          }
        });
    },
  },

  methods: {
    back() {
      this.$router.replace({ name: 'Preventivi' }, () => {});
    },

    isCombined(product) {
      // Se il prodotto non può essere combinato si ferma subito (false)
      if ('combined' in product === false || !product.combined) return false;

      // Il prodotto è combinabile

      // Se non può essere utilizzato da solo, si ferma subito (true).
      if ('standalone' in product && product.standalone === false) return true;

      // Verifica se un prodotto tra quelli scelti lo richiede come combinato

      // Conta il numero di prodotti richiesti di questo tipo
      const reqCount = this.allRequiredProducts.filter((rp) => rp.id === product.id).length;

      // Nessun prodotto richiesto di questo tipo
      if (reqCount === 0) return false;

      // È richiesto un prodotto di questo tipo per l'uso combinato

      // Riserva il primo prodotto di questo tipo per l'uso combinato

      // Calcola la posizione in elenco di questo prodotto
      const spList = this.selectedProducts.filter((rp) => rp.id === product.id);
      const spIndex = spList.findIndex((rp) => rp.sId === product.sId);

      // Ritorna true solo se è il primo
      return Boolean(spIndex === 0);
    },

    onUpdate(product) {
      console.log('Test.onUpdate() product =', product); // eslint-disable-line no-console

      this.$set(
        this.quotation.selectedProducts,
        this.quotation.selectedProducts.findIndex((p) => p.id === product.id),
        product,
      );
    },

    onComplete(value) {
      console.log('Test.onComplete()'); // eslint-disable-line no-console
      this.status.complete = value;
      this.$emit('complete', this.status);
    },
  },
};
</script>

<style>
.v-application--is-ltr .v-stepper__label {
  text-align: center !important;
}

.ck-editor__editable {
  min-height: 400px !important;
}

.component-teststage .v-stepper__label {
  text-align: left !important;
}
.component-teststages .v-stepper__label {
  text-align: center !important;
}
</style>
