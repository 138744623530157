<template>
  <div class="component-productimage">
    <v-card>
      <v-card-text>
        <v-img v-if="hasImage" :src="product.imageUrl" aspect-ratio="1">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center">
              <v-progress-circular indeterminate color="secondary"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-img v-else src="../assets/product-placeholder.png" aspect-ratio="1">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center">
              <v-progress-circular indeterminate color="secondary"></v-progress-circular>
            </v-row>
          </template>
        </v-img>

        <input type="file" id="fileInput" ref="fileInput"
          accept="image/*"
          @change="handleFileChange($event)">
      </v-card-text>

      <v-card-actions>
        <v-btn text color="primary"
          v-if="hasImage"
          :disabled="updating"
          :loading="updating"
          @click="remove()">
          Rimuovi
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn text color="primary"
          v-if="hasImage"
          :disabled="updating"
          :loading="updating"
          @click="upload()">
          Sostituisci
        </v-btn>
        <v-btn text color="primary"
          v-if="!hasImage"
          :disabled="updating"
          :loading="updating"
          @click="upload()">
          Carica foto
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- v-sheet class="product-image"
      color="secondary" -->
      <!-- v-skeleton-loader
        class="mx-auto"
        max-width="300"
        type="image"
      ></v-skeleton-loader -->
    <!-- /v-sheet -->
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'ProductImage',

  props: ['value'],

  data: () => ({
    updating: false,
    product: null,
  }),

  created() {
    this.product = this.value;
  },

  watch: {
    product(newValue) {
      console.log('watch -> product'); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
    hasImage() {
      return this.product.hasOwnProperty('imageUrl') && this.product.imageUrl !== null;
    },
  },

  methods: {
    async remove() {
      this.updating = true;

      // Se stiamo modificando un prodotto esistente...
      if (this.product.hasOwnProperty('id') && this.product.id !== null) {
        // ... elimina l'immagine anche da Firebase
        await this.$store.dispatch('products/removeImage', {
          productId: this.product.id,
        });
      }

      this.$set(this.product, 'imageUrl', null);
      this.$set(this.product, 'imageName', null);

      this.$refs.fileInput.value = '';

      this.updating = false;
    },

    upload() {
      this.$refs.fileInput.click();
    },

    async handleFileChange(evt) {
      // eslint-disable-next-line no-console
      console.log('evt.target.files =', evt.target.files);

      if (evt.target.files && evt.target.files.length === 1) {
        this.updating = true;

        const { imageUrl, imageName } = await this.$store.dispatch('products/updateImage', {
          productId: this.product.id,
          file: evt.target.files[0],
        });

        if (imageUrl && imageName) {
          this.$set(this.product, 'imageUrl', imageUrl);
          this.$set(this.product, 'imageName', imageName);
        }

        this.updating = false;
      }
    },
  },
};
</script>

<style scoped>
  .product-image {
    min-height: 300px;
  }

  #fileInput {
    display: none;
  }
</style>
