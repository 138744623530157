<template>
  <div class="view-nuovoprodotto">
    <PageHeader :breadcrumbs="breadcrumbs" icon="mdi-format-list-text" />

    <Product v-model="product"
      :updating="updating"
      @updated="updated = true" />

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-actions>
            <v-btn text color="primary"
              :disabled="updating"
              :loading="updating"
              @click="cancel()">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn text color="success"
              :disabled="updating || !updated"
              :loading="updating"
              @click="add()">
              Aggiungi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="leaveDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Vuoi abbandonare le modifiche?</v-card-title>

        <v-card-text>
          Non hai ancora inserito il prodotto. Sei certo di voler uscire senza prima memorizzarlo?
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text
            @click="leaveDialog = false">
            Rimani su questa pagina
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="warning" text
            @click="next()">
            Lascia questa pagina
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import Product from '@/components/Product';

export default {
  name: 'NuovoProdotto',

  components: {
    PageHeader,
    Product,
  },

  data: () => ({
    breadcrumbs: [
      {
        text: 'Prodotti',
        exact: true,
        to: { name: 'Prodotti' },
      },
      {
        text: 'Nuovo',
        disabled: true,
      },
    ],

    updating: false,

    product: {
      name: {
        it: '',
        en: '',
        de: '',
      },
      details: {
        it: '',
        en: '',
        de: '',
      },
      specs: {
        it: '',
        en: '',
        de: '',
      },

      rev: null,

      standalone: true,
      combined: false,
      consumptionType: 'fixed',
      consumption: null,
      um: 'kg',

      priceType: 'fixed',
      price: {},
      priceranges: [],

      phases: [],

      userTypes: [],
    },

    updated: false,
    leaveDialog: false,
    next: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
    selectedLanguage: null,
    languages: [ // TODO: Hardcoded da qualche parte comune
      { id: 'it', name: 'Italiano' },
      { id: 'en', name: 'Inglese' },
      { id: 'de', name: 'Tedesco' },
    ],
  }),

  methods: {
    cancel() {
      // Torna all'elenco dei prodotti
      this.$router.replace({ name: 'Prodotti' }, () => {});
    },

    async add() {
      this.updating = true;
      this.updated = false;

      // Prepara una map delle fasi selezionate
      const phases = {};
      this.product.phases.forEach((phase) => {
        phases[phase.id] = {
          name: phase.name,
        };
      });

      await this.$store.dispatch('products/add', {
        name: this.product.name,
        rev: this.product.rev,
        url: this.product.url,
        details: this.product.details,
        specs: this.product.specs,
        consumption: this.product.consumption,
        um: this.product.um,
        price: this.product.price,

        phases,

        userTypes: this.product.userTypes,

        ...(this.product.imageUrl !== undefined ? { imageUrl: this.product.imageUrl } : {}),
        ...(this.product.imageName !== undefined ? { imageName: this.product.imageName } : {}),
      });

      // Torna all'elenco dei prodotti
      this.$router.replace({ name: 'Prodotti' }, () => {});
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.updated) next();

    this.next = next;

    this.leaveDialog = true;

    next(false);
  },
};
</script>
