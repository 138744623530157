<template>
  <v-row>
    <v-col v-for="answer of answers"
      :key="answer.title"
      :cols="cols">
      <v-btn block x-large class="bigbutton" @click="$emit('onAnswer', answer)">
        {{ answer.title }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'QuestionButtons',

  props: ['value', 'answers'],

  data: () => ({
    status: null,
  }),

  created() {
    console.log('QuestionButtons: created()'); // eslint-disable-line no-console
    this.status = this.value;
  },

  watch: {
    value(newValue) {
      console.log('QuestionButtons: watch.value()'); // eslint-disable-line no-console
      this.status = newValue;
    },
  },

  computed: {
    cols() {
      const length = Array.isArray(this.answers)
        ? this.answers.length
        : 0;

      return length < 4
        ? 12 / length
        : 4;
    },
  },

  methods: {
  },
};
</script>

<style scoped>
.bigbutton {
  min-height: 200px;
  max-width: 100%;
  white-space: normal;
  letter-spacing: initial;
}

.bigbutton >>> .v-btn__content {
  max-width: 100%;
}
</style>
