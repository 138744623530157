<template>
  <v-stepper-content
    :step="index">
    <QuestionButtons
      v-if="question && question.type === 'buttons'"
      v-model="status"
      :answers="answers"
      @onAnswer="onAnswer" />
    <QuestionRadio
      v-if="question && question.type === 'radio'"
      v-model="status"
      :answers="answers"
      @onAnswer="onAnswer" />
    <QuestionCheckboxes
      v-if="question && question.type === 'checkboxes'"
      v-model="status"
      :answers="answers"
      @onAnswer="onAnswer" />
    <ColorSelector
      v-if="question && question.type === 'color'"
      v-model="status"
      @onAnswer="onColor" />
  </v-stepper-content>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import QuestionButtons from '@/components/QuestionButtons';
import QuestionRadio from '@/components/QuestionRadio';
import QuestionCheckboxes from '@/components/QuestionCheckboxes';
import ColorSelector from '@/components/ColorSelector';

export default {
  name: 'TestQuestionContent',

  props: ['value', 'stage', 'question', 'index'],

  components: {
    QuestionButtons,
    QuestionRadio,
    QuestionCheckboxes,
    ColorSelector,
  },

  data: () => ({
    status: null,
  }),

  created() {
    console.log('TestQuestionContent: created()'); // eslint-disable-line no-console
    this.status = this.value;
  },

  watch: {
    value(newValue) {
      console.log('TestQuestionContent: watch.value()'); // eslint-disable-line no-console
      this.status = newValue;
    },

    // status(newValue) {
    //   console.log('TestQuestionContent: watch.status()'); // eslint-disable-line no-console
    //   this.$emit('input', newValue);
    // },
  },

  computed: {
    answers() {
      return this.question && Array.isArray(this.question.answers)
        ? this.question.answers.filter((a) => {
          let visible = null;

          // Se non specificato visible = true
          if ('visible' in a === false) {
            visible = true;
          } else
          // Se è specificato ed è boolean...
          if (typeof a.visible === 'boolean') {
            // ... lo usa direttamente
            visible = a.visible;
          } else
          // Se è specificato ed è un array...
          if (Array.isArray(a.visible)) {
            // ... verifica se va mostrato per i tag
            const commonTags = a.visible.filter((tag) => this.status.tags.includes(tag));
            if (commonTags.length > 0) visible = true;
          }

          let hidden = null;

          // Se non specificato hidden = false
          if ('hidden' in a === false) {
            hidden = false;
          } else
          // Se è specificato ed è boolean...
          if (typeof a.hidden === 'boolean') {
            // ... lo usa direttamente
            hidden = a.hidden;
          } else
          // Se è specificato ed è un array...
          if (Array.isArray(a.hidden)) {
            // ... verifica se va mostrato per i tag
            const commonTags = a.hidden.filter((tag) => this.status.tags.includes(tag));
            if (commonTags.length > 0) hidden = true;
          }

          return visible && !hidden;
        })
        : [];
    },
  },

  methods: {
    onColor(colorInfo) {
      console.log('TestQuestionContent: onColor()'); // eslint-disable-line no-console

      const { colorlist, color } = colorInfo;

      this.status.colorlist = colorlist;
      this.status.color = color;

      this.$set(this.status.stages[this.stage.name].questions, this.question.name, {
        complete: true,
        answer: `${color.code} (${colorlist.name})`,
      });

      this.$emit('input', this.status);

      this.$nextTick(() => {
        this.status.currentStep += 1;

        if (this.question.last) {
          this.status.complete = true;
          this.status.stages[this.stage.name].complete = true;
        }

        this.$emit('input', this.status);
      });
    },

    onAnswer(answers) {
      console.log('TestQuestionContent: onAnswer()'); // eslint-disable-line no-console

      const answersArray = Array.isArray(answers) ? answers : [answers];

      let title = '';
      if (answersArray.length === 1) {
        title = answersArray[0].title;
      } else {
        answersArray.forEach((answer) => {
          title += `${answer.title}, `;
        });
        title = title.slice(0, -2);
      }

      this.$set(this.status.stages[this.stage.name].questions, this.question.name, {
        complete: true,
        answer: title,
      });

      answersArray.forEach((answer) => {
        if (typeof answer.tag === 'string') {
          // Inserisce il tag sia nell'elenco globale che in quello dello stage
          this.status.tags.push(answer.tag);
          this.status.stages[this.stage.name].tags.push(answer.tag);
        } else if (Array.isArray(answer.tag)) {
          // Inserisce i tag sia nell'elenco globale che in quello dello stage
          answer.tag.forEach((tag) => {
            this.status.tags.push(tag);
            this.status.stages[this.stage.name].tags.push(tag);
          });
        }
      });

      this.$emit('input', this.status);

      this.$nextTick(() => {
        this.status.currentStep += 1;

        if (this.question.last) {
          this.status.complete = true;
          this.status.stages[this.stage.name].complete = true;
        }

        this.$emit('input', this.status);
      });
    },
  },
};
</script>

<style>
</style>
