<template>
  <div
    class="component-productselectionchoice">
    <v-card outlined
      :color="cardColor">
      <!-- v-card-title>
        <v-row>
          <v-col>
            <span class="text-h5"
              :class="{'text--disabled': !selected && done}"
              v-text="`Scelta ${index + 1}`"></span>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-title -->

      <v-card-text class="px-0 pb-0">
        <template v-if="choice.withProcedure">
          <div class="body-1 mb-3 px-4"
            :class="{ 'text--disabled': done && !selected}"
            v-html="choice.procedure"></div>
          <v-divider></v-divider>
        </template>

        <v-list v-if="Array.isArray(choiceProducts) && choiceProducts.length > 0"
          subheader
          :color="cardColor">
          <!-- v-subheader>Prodotti inclusi in questa soluzione:</v-subheader -->
          <template v-for="product in choiceProducts">
            <v-list-item :key="`product-${product.id}`">
              <v-list-item-content>
                <v-list-item-title
                  :class="{'text--disabled': !selected && done}"
                  v-html="product.name[userLanguage]"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-row>
                  <v-btn class="ml-2" fab x-small depressed dark
                    :color="!selected && done ? 'secondary lighten-2' : 'secondary'"
                    @click="onDetails(product)">
                    <v-icon dark>mdi-eye</v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-action>
            </v-list-item>
            <!-- v-divider
              :key="`product-divider-${product.id}`"></v-divider -->
          </template>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-tooltip top v-if="isRecommended">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on"
              large color="amber accent-3">mdi-star</v-icon>
          </template>
          <span>Soluzione consigliata</span>
        </v-tooltip>

        <v-spacer></v-spacer>

        <v-btn color="success" :depressed="!done" :text="done"
          v-if="!selected && !solo"
          :disabled="updating"
          :loading="updating"
          @click="onSelect">
          Scegli
        </v-btn>
        <v-btn color="warning" text
          v-if="selected && !solo"
          :disabled="updating"
          :loading="updating"
          @click="onUnselect">
          Deseleziona
        </v-btn>
        <v-btn color="primary" text
          v-if="solo"
          :disabled="true"
          :loading="updating"
          @click="onUnselect">
          Selezionato automaticamente
        </v-btn>
      </v-card-actions>
    </v-card>

    <ProductDetailsDialog v-model="detailsDialog"
      :product="selectedPrdouct"
      @update="onUpdate" />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins, no-restricted-syntax, no-console */

import ProductDetailsDialog from '@/components/ProductDetailsDialog';

export default {
  name: 'ProductSelectionChoice',

  props: {
    choice: Object,
    status: Object,
    index: Number,
    done: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    selected: Boolean,
  },

  components: {
    ProductDetailsDialog,
  },

  data: () => ({
    detailsDialog: false,
    updating: false,

    selectedPrdouct: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  watch: {
  },

  computed: {
    products() {
      return this.$store.getters['products/docs'];
    },

    isRecommended() {
      switch (this.choice.recommended) {
        case 'always':
          return true;

        case 'conditions': {
          if (!this.choice.hasOwnProperty('recommendedConditions')) {
            console.log('ProductSelectionChoice: this.choice.recommendedConditions =', this.choice.recommendedConditions); // eslint-disable-line no-console
            return false;
          }

          let recommended = false;
          // Scorre ogni linea di condizioni
          for (const condition of Object.values(this.choice.recommendedConditions)) {
            const conditionTags = Object.values(condition);
            const commonTags = conditionTags.filter((tag) => this.tags.includes(tag));
            if (commonTags.length === conditionTags.length) {
              recommended = true;
              break;
            }
          }
          return recommended;
        }

        default:
          return false;
      }
    },

    // selected() {
    //   // eslint-disable-next-line no-prototype-builtins
    //   if (this.solo && (!this.choice.hasOwnProperty('selected') || !this.choice.selected)) {
    //     console.log('ProductSelectionChoice: watch.selected() $emit(select)');
    //     this.$emit('select');
    //   }
    //   return this.choice
    //     // eslint-disable-next-line no-prototype-builtins
    //     && this.choice.hasOwnProperty('selected') && this.choice.selected === true;
    // },

    cardColor() {
      if (this.selected) {
        return 'green lighten-4';
      }
      return this.isRecommended && !this.done ? 'amber lighten-5' : '';
    },

    tags() {
      return this.status && this.status.hasOwnProperty('tags') && Array.isArray(this.status.tags)
        ? this.status.tags
        : [];
    },

    choiceProducts() {
      const products = 'products' in this.choice
        && Array.isArray(this.choice.products)
        && this.choice.products.length > 0
        ? this.choice.products
        : [];

      // Invece di ritornare i prodotti memorizzati nella fase,
      // recupera il prodotto dall'elenco aggiornato.

      // Se il prodotto non è presente è possibile che non sia
      // accessibile alla tipologia di utente corrente.

      return this.products.filter((p) => products.findIndex((cp) => p.id === cp.id) > -1);
    },
  },

  methods: {
    async onDetails(product) {
      // eslint-disable-next-line no-console
      console.log('ProductSelectionChoice: onDetails() item =', product);

      this.selectedPrdouct = await this.$store.dispatch('products/getById', product.id);

      this.detailsDialog = true;
    },

    onUpdate(product) {
      // eslint-disable-next-line no-console
      console.log('ProductSelectionChoice: onUpdate() product =', product);

      this.$emit('update', product);
    },

    onSelect() {
      // eslint-disable-next-line no-console
      console.log('ProductSelectionChoice: onSelect()');

      this.updating = true;

      this.$emit('select');

      setTimeout(() => {
        this.updating = false;
      }, 500);
    },

    onUnselect() {
      // eslint-disable-next-line no-console
      console.log('ProductSelectionChoice: onUnselect()');

      this.updating = true;

      this.$emit('unselect');

      setTimeout(() => {
        this.updating = false;
      }, 500);
    },
  },
};
</script>

<style scoped>
</style>
