/* eslint-disable no-shadow */

import Vue from 'vue';
import firebase from 'firebase';
import {
  compareOrder, getOrder, getOrderMap, setOrderMap,
} from '@/utils/orderMaps';
import {
  INIT_COLORLISTS, UPDATE_COLORLISTS, SET_COLORLISTS, SET_CURRENT_COLORS,
  CLEAR_COLORLISTS, DESTROY_COLORLISTS, SET_COLORLISTS_LISTENER,
  SET_CURRENT_COLORS_LISTENER,
} from '@/store/actions/colorlists';

const state = {
  data: [],
  listener: null,

  currentColors: [],
  currentColorsListener: null,
};

const getters = {
  docs: (state) => Object.values(state.data),
  currentColors: (state) => Object.values(state.currentColors),
};

const actions = {
  [INIT_COLORLISTS]: ({ dispatch }) => {
    console.log('actions:INIT_COLORLISTS'); // eslint-disable-line no-console

    return dispatch(UPDATE_COLORLISTS);
  },

  [UPDATE_COLORLISTS]: async ({ commit, state }) => {
    console.log('actions:UPDATE_COLORLISTS'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Recuper la mappa con l'ordinamento
    const order = await getOrderMap('colorlists');
    if (order) {
      console.log('Esiste un ordinamento, lo utilizzo'); // eslint-disable-line no-console
      console.log(`order = ${JSON.stringify(order)}`); // eslint-disable-line no-console
    }

    // Recupera la collection con l'elenco
    const collectionRef = db.collection('colorlists');
    const listener = collectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (colorlists)'); // eslint-disable-line no-console

      commit(SET_COLORLISTS, {
        snapshot,
        order,
      });
    });
    commit(SET_COLORLISTS_LISTENER, listener);
  },

  [DESTROY_COLORLISTS]: ({ commit, state }) => {
    console.log('actions:DESTROY_COLORLISTS'); // eslint-disable-line no-console

    // Disattiva gli eventuali listener esistenti
    if (state.listener) state.listener();
    if (state.currentColorsListener) state.currentColorsListener();

    // Pulisce lo stato
    commit(CLEAR_COLORLISTS);
  },

  add: (context, payload) => {
    console.log('actions:add'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const collectionRef = db.collection('colorlists');

    return collectionRef.add({
      ...payload,
      order: (new Date()).getTime(),
    });
  },

  update: (context, payload) => {
    console.log('actions:update'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Separa la proprietà 'id' dal resto
    const { id, ...data } = payload;

    const docRef = db.doc(`/colorlists/${id}`);

    return docRef.update({
      ...data,
    });
  },

  delete: (context, payload) => {
    console.log('actions:delete'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const docRef = db.doc(`/colorlists/${payload}`);

    return docRef.delete();
  },

  reorder: async ({ commit }, payload) => {
    console.log('actions:reorder'); // eslint-disable-line no-console

    // Memorizza l'ordinamento attuale dell'elenco nel payload
    const order = {};
    payload.forEach((colorlist, index) => {
      order[colorlist.id] = index;
    });

    console.log(`order = ${JSON.stringify(order)}`); // eslint-disable-line no-console

    commit(SET_COLORLISTS, {
      list: payload,
      order,
    });

    return setOrderMap('colorlists', order);
  },

  getById: async (context, payload) => {
    console.log('actions:getById'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const colorlistRef = db.doc(`/colorlists/${payload}`);

    const colorlist = await colorlistRef.get();

    if (!colorlist.exists) return null;

    return colorlist.data();
  },

  addColor: (context, payload) => {
    console.log('actions:add'); // eslint-disable-line no-console

    const { colorlistId, ...color } = payload;

    const db = firebase.firestore();

    const colorlistRef = db.doc(`/colorlists/${colorlistId}`);
    const colorsCollectionRef = colorlistRef.collection('colors');

    return colorsCollectionRef.add(color);
  },

  updateColor: (context, payload) => {
    console.log('actions:add'); // eslint-disable-line no-console

    const { colorlistId, id, ...color } = payload;

    const db = firebase.firestore();

    const docRef = db.doc(`/colorlists/${colorlistId}/colors/${id}`);

    return docRef.update(color);
  },

  deleteColor: (context, payload) => {
    console.log('actions:add'); // eslint-disable-line no-console

    const { colorlistId, colorId } = payload;

    const db = firebase.firestore();

    const docRef = db.doc(`/colorlists/${colorlistId}/colors/${colorId}`);

    return docRef.delete();
  },

  selectColorlist: ({ commit, state }, payload) => new Promise((resolve) => {
    console.log('actions:selectColorlist'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Smette eventualmente di rimanere in ascolto per le modifiche
    // alla cartella colori selezionata precedentemente.
    if (state.currentColorsListener) state.currentColorsListener();

    // Recupera il documento associato alla cartella colori
    const colorlistRef = db.doc(`/colorlists/${payload.colorlistId}`);

    // Recupera il riferimento alla sotto-collezione 'colors'
    const colorsCollectionRef = colorlistRef.collection('colors').orderBy('code');

    const currentColorsListener = colorsCollectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (currentColorsListener)'); // eslint-disable-line no-console

      commit(SET_CURRENT_COLORS, {
        snapshot,
      });
    });
    commit(SET_CURRENT_COLORS_LISTENER, currentColorsListener);

    return resolve();
  }),

  unselectColorlist: ({ commit, state }) => new Promise((resolve) => {
    console.log('actions:unselectColorlist'); // eslint-disable-line no-console

    // Smette eventualmente di rimanere in ascolto per le modifiche
    // al menu selezionato precedentemente.
    if (state.currentColorsListener) state.currentColorsListener();

    // Nessuna menu selezionat, l'elenco delle sezioni è vuoto.
    commit(SET_CURRENT_COLORS, {
      snapshot: null,
    });

    return resolve();
  }),

  getColorlistColors: async (context, payload) => {
    console.log('actions:getColorlistColors'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Recupera il documento associato alla cartella colori
    const colorlistRef = db.doc(`/colorlists/${payload}`);

    // Recupera il riferimento alla sotto-collezione 'colors'
    const colorsCollectionRef = colorlistRef.collection('colors').orderBy('code');

    const snapshot = await colorsCollectionRef.get();

    const data = [];

    snapshot.forEach((doc) => {
      const docData = doc.data();

      data.push(docData);
    });

    return data;
  },

  publish: async () => {
    console.log('actions:publish'); // eslint-disable-line no-console

    const functions = firebase.functions();

    const publishColorlists = functions.httpsCallable('publishColorlists');

    return publishColorlists();
  },

  getAllAvailableColors: async () => {
    console.log('actions:getAllAvailableColors'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Recupera il documento che contiene le colorlists pubblicate
    const helperColorlistsDocRef = db.doc('/helpers/colorlists');
    const helperColorlistsDoc = await helperColorlistsDocRef.get();
    const helperColorlists = helperColorlistsDoc.data();

    const allAvailableColors = [
      {
        code: 'Bianco',
        r: 255,
        g: 255,
        b: 255,
        hbw: null,
        colorlist: null,
      },
    ];

    // helperColorlists.output.forEach((colorlist) => {
    helperColorlists.flatColorLists.forEach((colorlist) => {
      colorlist.colors.forEach((color) => {
        allAvailableColors.push({
          ...color,
          colorlist: colorlist.name,
        });
      });
    });

    return Promise.resolve(allAvailableColors);
  },

  getColorlistsColors: async () => {
    console.log('actions:getColorlistsColors'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Recupera il documento che contiene le colorlists pubblicate
    const helperColorlistsDocRef = db.doc('/helpers/colorlists');
    const helperColorlistsDoc = await helperColorlistsDocRef.get();
    const helperColorlists = helperColorlistsDoc.data();

    // return Promise.resolve(helperColorlists.output);
    return Promise.resolve(helperColorlists.flatColorLists);
  },
};

const mutations = {
  [SET_COLORLISTS_LISTENER]: (state, payload) => {
    console.log('mutations:SET_COLORLISTS_LISTENER'); // eslint-disable-line no-console

    state.listener = payload;
  },

  [SET_CURRENT_COLORS_LISTENER]: (state, payload) => {
    console.log('mutations:SET_CURRENT_COLORS_LISTENER'); // eslint-disable-line no-console

    state.currentColorsListener = payload;
  },

  [SET_COLORLISTS]: (state, payload) => {
    console.log('mutations:SET_COLORLISTS'); // eslint-disable-line no-console

    const data = [];

    if (payload.snapshot) {
      payload.snapshot.forEach((doc) => {
        const docData = doc.data();

        data.push({
          id: doc.id,
          ...docData,
          order: getOrder(payload.order, doc.id, docData.order),
        });
      });
    } else if (payload.list) {
      payload.list.forEach((doc) => {
        data.push({
          ...doc,
          order: getOrder(payload.order, doc.id, doc.order),
        });
      });
    }

    if (payload.snapshot || payload.list) {
      // Ordina
      data.sort(compareOrder);

      // eslint-disable-next-line no-console
      console.log('Ordinamento completato!');
    }

    Vue.set(state, 'data', data);

    // eslint-disable-next-line no-console
    console.log(`state.data.length = ${state.data.length}`);
    // eslint-disable-next-line no-console
    // console.log('state.data =', state.data);
  },

  [SET_CURRENT_COLORS]: (state, payload) => {
    console.log('mutations:SET_CURRENT_COLORS'); // eslint-disable-line no-console

    const colors = [];
    // let count = 0;

    if (payload.snapshot) {
      payload.snapshot.forEach((doc) => {
        const data = doc.data();

        colors.push({
          id: doc.id,
          ...data,
        });

        // count += 1;
      });
    } else if (payload.list) {
      payload.list.forEach((doc) => {
        colors.push(doc);

        // count += 1;
      });
    }

    Vue.set(state, 'currentColors', colors);

    // eslint-disable-next-line no-console
    console.log(`state.currentColors.length = ${state.currentColors.length}`);
    // eslint-disable-next-line no-console
    console.log('state.currentColors =', state.currentColors);
  },

  [CLEAR_COLORLISTS]: (state) => {
    console.log('mutations:CLEAR_COLORLISTS'); // eslint-disable-line no-console

    state.data = [];
    state.currentColors = [];
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
