<template>
  <div class="component-newtagdialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>

        <v-card>
          <v-card-title class="headline">Crea un tag</v-card-title>

          <v-card-text>
            <p>Che nome vuoi dare a questo tag?</p>

            <v-row>
              <v-col cols="12">
                <v-text-field type="text"
                  v-model="name"
                  :disabled="loading"
                  label="Nome esteso del tag"
                  single-line
                  autofocus
                  :rules="[v => !!v || 'Il nome esteso è richiesto']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field type="text"
                  v-model="slug"
                  :disabled="loading"
                  label="Nome breve del tag"
                  single-line
                  :rules="[v => !!v || 'Il nome breve è richiesto']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="cancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn type="submit"
              color="success" text
              :disabled="!valid || loading"
              :loading="loading"
              @click="newItem()">
              Crea
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'NewPricelistDialog',

  props: ['value'],

  data: () => ({
    dialog: false,
    valid: false,

    name: '',
    slug: '',

    loading: false,
  }),

  watch: {
    value(newValue) {
      const formRef = this.$refs.form;

      if (newValue === true) {
        if (formRef) {
          formRef.reset();
          formRef.resetValidation();
        }
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    async newItem() {
      const formRef = this.$refs.form;

      if (formRef.validate()) {
        this.loading = true;

        await this.$store.dispatch('tags/add', {
          name: this.name,
          slug: this.slug,
        });

        this.dialog = false;
        this.loading = false;
      }
    },
  },
};
</script>
