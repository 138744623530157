/* eslint-disable no-shadow */

import Vue from 'vue';
import firebase from 'firebase';
import {
  compareOrder, getOrder, getOrderMap, setOrderMap,
} from '@/utils/orderMaps';
import {
  INIT_PHASES, UPDATE_PHASES, SET_PHASES,
  CLEAR_PHASES, DESTROY_PHASES, SET_PHASES_LISTENER,
} from '@/store/actions/phases';

const state = {
  data: [],
  listener: null,
};

const getters = {
  docs: (state) => Object.values(state.data),
};

const actions = {
  [INIT_PHASES]: ({ dispatch }) => {
    console.log('actions:INIT_PHASES'); // eslint-disable-line no-console

    return dispatch(UPDATE_PHASES);
  },

  [UPDATE_PHASES]: async ({ commit, state }) => {
    console.log('actions:UPDATE_PHASES'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Recuper la mappa con l'ordinamento
    const order = await getOrderMap('phases');
    if (order) {
      console.log('Esiste un ordinamento, lo utilizzo'); // eslint-disable-line no-console
      console.log(`order = ${JSON.stringify(order)}`); // eslint-disable-line no-console
    }

    // Recupera la collection con l'elenco
    const collectionRef = db.collection('phases');
    const listener = collectionRef.onSnapshot((snapshot) => {
      console.log('onSnapshot (phases)'); // eslint-disable-line no-console

      commit(SET_PHASES, {
        snapshot,
        order,
      });
    });
    commit(SET_PHASES_LISTENER, listener);
  },

  [DESTROY_PHASES]: ({ state, commit }) => {
    console.log('actions:DESTROY_PHASES'); // eslint-disable-line no-console

    // Disattiva l'eventuale listener esistente
    if (state.listener) state.listener();

    // Pulisce lo state
    commit(CLEAR_PHASES);
  },

  add: (context, payload) => {
    console.log('actions:add'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const collectionRef = db.collection('phases');

    return collectionRef.add({
      ...payload,
      order: (new Date()).getTime(),
    });
  },

  update: (context, payload) => {
    console.log('actions:update'); // eslint-disable-line no-console

    const db = firebase.firestore();

    // Separa la proprietà 'id' dal resto
    const { id, ...data } = payload;

    const docRef = db.doc(`/phases/${id}`);

    return docRef.update({
      ...data,
    });
  },

  delete: (context, payload) => {
    console.log('actions:delete'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const docRef = db.doc(`/phases/${payload}`);

    return docRef.delete();
  },

  reorder: async ({ commit }, payload) => {
    console.log('actions:reorder'); // eslint-disable-line no-console

    // Memorizza l'ordinamento attuale dell'elenco nel payload
    const order = {};
    payload.forEach((phase, index) => {
      order[phase.id] = index;
    });

    console.log(`order = ${JSON.stringify(order)}`); // eslint-disable-line no-console

    commit(SET_PHASES, {
      list: payload,
      order,
    });

    return setOrderMap('phases', order);
  },

  getById: async (context, payload) => {
    console.log('actions:getById'); // eslint-disable-line no-console

    const db = firebase.firestore();

    const docRef = db.doc(`/phases/${payload}`);

    const doc = await docRef.get();

    if (!doc.exists) return null;

    return doc.data();
  },
};

const mutations = {
  [SET_PHASES_LISTENER]: (state, payload) => {
    console.log('mutations:SET_PHASES_LISTENER'); // eslint-disable-line no-console

    Vue.set(state, 'listener', payload);
  },

  [SET_PHASES]: (state, payload) => {
    console.log('mutations:SET_PHASES'); // eslint-disable-line no-console

    const data = [];

    if (payload.snapshot) {
      payload.snapshot.forEach((doc) => {
        const docData = doc.data();

        data.push({
          id: doc.id,
          ...docData,
          order: getOrder(payload.order, doc.id, docData.order),
        });
      });
    } else if (payload.list) {
      payload.list.forEach((doc) => {
        data.push({
          ...doc,
          order: getOrder(payload.order, doc.id, doc.order),
        });
      });
    }

    if (payload.snapshot || payload.list) {
      // Ordina
      data.sort(compareOrder);

      // eslint-disable-next-line no-console
      console.log('Ordinamento completato!');
    }

    Vue.set(state, 'data', data);

    // eslint-disable-next-line no-console
    console.log(`state.data.length = ${state.data.length}`);
    // eslint-disable-next-line no-console
    // console.log('state.data =', state.data);
  },

  [CLEAR_PHASES]: (state) => {
    console.log('mutations:CLEAR_PHASES'); // eslint-disable-line no-console

    Vue.set(state, 'data', []);
  },
};

export default {
  namespaced: true,

  state,
  getters,
  actions,
  mutations,
};
