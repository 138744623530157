<template>
  <div class="view-reset-ask">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Reimposta la password</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
            <v-card-text>
              <p>
                Inserisci l'indirizzo email associato al tuo account e premi <strong>Invia</strong>.
                Riceverai un'email con un collegamento che ti permetterà di reimpostare
                la password.
              </p>
              <v-alert
                :type="alertType" dismissible v-model="alert" class="mb-4"
                transition="scale-transition">
                {{ message }}
              </v-alert>
              <v-text-field autofocus
                v-model="email"
                label="Email"
                name="email"
                prepend-icon="mdi-email"
                type="email"
                :rules="[v => !!v || 'L\'indirizzo email è richiesto']"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn type="submit" color="primary"
                :disabled="!valid || submitting"
                :loading="submitting"
                @click="ask">Invia</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable max-len */

import firebase from 'firebase';

export default {
  name: 'Reset',

  data: () => ({
    email: '',

    submitting: false,
    valid: false,

    alert: false,
    alertType: 'error',
    message: '',
  }),

  created() {
    // Logout se l'utente è già loggato
    if (this.user.loggedIn) {
      firebase.auth().signOut().then(() => {
        console.log('firebase.auth().signOut() success'); // eslint-disable-line no-console
      });
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    ask() {
      if (this.$refs.form.validate()) {
        this.submitting = true;

        const route = this.$router.resolve({
          name: 'Login',
          query: {
            email: this.email,
          },
        });

        const continueUrl = `${window.location.origin}${route.href}`;
        console.log('ResetAsk: ask() continueUrl =', continueUrl); // eslint-disable-line no-console

        firebase.auth().sendPasswordResetEmail(this.email, {
          url: continueUrl,
        })
          .then(() => {
            console.log('firebase.auth().sendPasswordResetEmail() succes'); // eslint-disable-line no-console

            this.submitting = false;
            this.email = '';
            this.message = "L'email è stata inviata correttamente!";
            this.alertType = 'success';
            this.alert = true;

            // Email sent.
          }).catch((error) => {
            console.log('firebase.auth().sendPasswordResetEmail() error'); // eslint-disable-line no-console
            console.log(error); // eslint-disable-line no-console

            this.submitting = false;

            switch (error.code) {
              case 'auth/invalid-email':
                this.message = "L'indirizzo email non è nel formato corretto";
                break;

              case 'auth/user-not-found':
                this.message = 'Non esiste un utente con questo indirizzo email';
                break;

                // case 'auth/wrong-password':
                //   this.message = 'La password non è corretta';
                //   break;

              default:
                this.message = `Errore sconosciuto (${error.code})`;
                break;
            }

            this.alertType = 'error';
            this.alert = true;
          });

        // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        //   .then(() => {
        //     console.log('firebase.auth().setPersistence() success'); // eslint-disable-line no-console

        //     return firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        //       .then((user) => {
        //         console.log('firebase.auth().signInWithEmailAndPassword() success'); // eslint-disable-line no-console
        //         console.log(user); // eslint-disable-line no-console

        //         if (this.$route.query.redirect) {
        //           this.$router.replace(this.$route.query.redirect);
        //         } else {
        //           this.$router.replace('/');
        //         }
        //       })
        //       .catch((error) => {
        //         console.log('firebase.auth().signInWithEmailAndPassword() error'); // eslint-disable-line no-console
        //         console.log(error); // eslint-disable-line no-console

        //         this.submitting = false;

        //         switch (error.code) {
        //           case 'auth/invalid-email':
        //             this.message = 'L\'indirizzo email non è nel formato corretto';
        //             break;

        //           case 'auth/user-not-found':
        //             this.message = 'Non esiste un utente con questo indirizzo email';
        //             break;

        //           case 'auth/wrong-password':
        //             this.message = 'La password non è corretta';
        //             break;

        //           default:
        //             this.message = `Errore sconosciuto (${error.code})`;
        //             break;
        //         }

        //         this.alert = true;
        //       });
        //   })
        //   .catch((error) => {
        //     console.log('firebase.auth().setPersistence() error'); // eslint-disable-line no-console
        //     console.log(error); // eslint-disable-line no-console
        //   });
      }
    },
  },
};
</script>

<style>
.view-reset-ask {
  flex-grow: 1;
  padding-top: 2rem;
}
</style>
