<template>
  <div class="component-editcolordialog">
    <v-dialog v-model="dialog" max-width="400">
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>
        <v-card>
          <v-card-title class="headline">Modifica un colore</v-card-title>

          <v-card-text v-if="updatedColor">
            <v-row>
              <v-col cols="6">
                <v-text-field type="text"
                  v-model="updatedColor.code"
                  :disabled="loading"
                  label="Codice"
                  single-line
                  autofocus
                  :rules="[v => !!v || 'Il codice è richiesto']"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field type="text"
                  v-model="updatedColor.hbw"
                  :disabled="loading"
                  label="HBW"
                  single-line
                  :rules="[v => (!v || (v >= 0 && v <= 100))
                    || 'HBW (se presente) deve essere compreso tra 0 e 100']"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field type="text" class="centered-input"
                  v-model="updatedColor.r"
                  :disabled="loading"
                  label="R"
                  outlined
                  :rules="[v => (!!v && v >= 0 && v <= 255)
                    || 'R è richiesto e deve essere compreso tra 0 e 255']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field type="text" class="centered-input"
                  v-model="updatedColor.g"
                  :disabled="loading"
                  label="G"
                  outlined
                  :rules="[v => (!!v && v >= 0 && v <= 255)
                    || 'G è richiesto e deve essere compreso tra 0 e 255']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field type="text" class="centered-input"
                  v-model="updatedColor.b"
                  :disabled="loading"
                  label="B"
                  outlined
                  :rules="[v => (!!v && v >= 0 && v <= 255)
                    || 'B è richiesto e deve essere compreso tra 0 e 255']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="cancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn type="submit"
              color="success" text
              :disabled="!valid || loading"
              :loading="loading"
              @click="update()">
              Modifica
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'EditColorDialog',

  props: ['value', 'colorlist', 'color'],

  data: () => ({
    dialog: false,
    valid: false,
    loading: false,

    updatedColor: null,
  }),

  watch: {
    value(newValue) {
      const formRef = this.$refs.form;

      if (newValue === true) {
        this.dialog = true;

        this.updatedColor = {
          colorlistId: this.colorlist.id,
          id: this.color.id,

          code: this.color.code,
          hbw: this.color.hbw,
          r: this.color.r,
          g: this.color.g,
          b: this.color.b,
        };

        if (formRef) {
          // formRef.reset();
          formRef.resetValidation();
        }
      } else {
        this.dialog = false;
      }
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    async update() {
      const formRef = this.$refs.form;

      if (formRef.validate()) {
        this.loading = true;

        await this.$store.dispatch('colorlists/updateColor', this.updatedColor);

        this.dialog = false;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}
</style>
