<template>
  <div class="component-differentuseralert">
    <v-alert
      v-if="differentUser && userName && priceListName"
      text type="warning" class="mb-4">
      Attenzione, questo preventivo è stato creato da un altro utente
      (<strong>{{ userName }}</strong>, con listino <strong>{{ priceListName }}</strong>).
    </v-alert>

    <v-alert
      v-if="!quotationUserPriceList && !differentUser"
      type="warning" class="mb-4"
      transition="scale-transition">
      <strong>Non ti è stato assegnato un listino</strong><br>
      Questo preventivo non include i prezzi dei prodotti perché
      non hai un listino assegnato.
    </v-alert>

    <v-alert
      v-if="!quotationUserPriceList && differentUser"
      type="warning" class="mb-4"
      transition="scale-transition">
      <strong>Non è stato assegnato un listino all'utente che ha creato questo preventivo.</strong><br>
      Questo preventivo non include i prezzi dei prodotti perché
      l'utente che lo ha creato non ha un listino assegnato.
    </v-alert>
  </div>
</template>

<script>
export default {
  props: ['quotation'],

  name: 'DifferentUserAlert',

  data: () => ({
  }),

  computed: {
    user() {
      return this.$store.getters.user;
    },

    users() {
      return this.$store.getters['users/docs'];
    },

    pricelists() {
      return this.$store.getters['pricelists/docs'];
    },

    differentUser() {
      return !this.user || 'uid' in this.user === false
        || !this.quotation || 'uid' in this.quotation === false
        || this.user.uid !== this.quotation.uid;
    },

    quotationUser() {
      return Array.isArray(this.users) && this.quotation && 'uid' in this.quotation
        ? this.users.find((u) => u.uid === this.quotation.uid)
        : null;
    },

    quotationUserPriceList() {
      return Array.isArray(this.pricelists) && this.quotationUser && 'pricelist' in this.quotationUser
        ? this.pricelists.find((pl) => pl.id === this.quotationUser.pricelist)
        : null;
    },

    userName() {
      return this.quotationUser && 'firstName' in this.quotationUser && 'lastName' in this.quotationUser
        ? `${this.quotationUser.firstName} ${this.quotationUser.lastName}`
        : '';
    },

    priceListName() {
      return this.quotationUserPriceList && 'name' in this.quotationUserPriceList
        ? this.quotationUserPriceList.name
        : '';
    },
  },

  methods: {
  },
};
</script>
