<template>
  <div class="component-productarea">
    <template v-for="(rp, rpIndex) in requiringProducts">
      <v-tooltip :key="`area-p${product.id}-rp${rp.id}-${rpIndex}`" bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="rp-area"
            v-bind="attrs"
            v-on="on"
            v-text="`${rp.area} m²`"></span>
        </template>
        <span>{{ requiringProductTooltip(rp) }}</span>
      </v-tooltip>
    </template>

    <div v-if="(this.isCombined && this.isTheOne) || !this.isCombined"
      class="p-area-row">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span class="p-area"
            v-bind="attrs"
            v-on="on"
            v-text="`${area} m²`"
            @click="onEdit"></span>
        </template>
        <span>{{ productTooltip }}</span>
      </v-tooltip>

      <v-tooltip bottom max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="p-lock" x-small
            v-bind="attrs"
            v-on="on"
            @click="onToggleLockedArea">
            <v-icon :color="locked ? 'warning' : 'secondary'">{{ icon }}</v-icon>
          </v-btn>
        </template>
        <span v-html="toggleTooltip"></span>
      </v-tooltip>
    </div>

    <EditProductAreaDialog v-model="editDialog"
      :product="product"
      :quotation="quotation"
      @toggleLockedArea="onToggleLockedArea"
      @updateArea="onUpdateArea" />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import EditProductAreaDialog from '@/components/EditProductAreaDialog';

export default {
  name: 'ProductArea',

  props: ['product', 'quotation', 'userLanguage'],

  components: {
    EditProductAreaDialog,
  },

  data: () => ({
    editDialog: false,
  }),

  computed: {
    area() {
      const selectedProduct = this.selectedProducts
        .find((sp) => {
          if ('sId' in this.product && this.product.sId !== null && 'sId' in sp) {
            return this.product.sId === sp.sId;
          }
          return sp.id === this.product.id;
        });

      return selectedProduct.area;
    },

    locked() {
      return this.product
        && this.product.hasOwnProperty('lockedArea')
        && this.product.lockedArea === true;
    },

    icon() {
      return this.locked ? 'mdi-link' : 'mdi-link-off';
    },

    allRequiredProducts() {
      if (!Array.isArray(this.quotation.selectedProducts)) return [];

      const result = [];
      this.quotation.selectedProducts.forEach((product) => {
        if (product.consumptionType === 'variable'
          && Array.isArray(product.consumptionranges[product.consumptionRange || 0]
            .requiredProducts)
          && product.consumptionranges[product.consumptionRange || 0].requiredProducts.length > 0) {
          result.push(
            ...product.consumptionranges[product.consumptionRange || 0].requiredProducts
              .map((rp) => rp.product),
          );
        }
      });

      return result;
    },

    isCombined() {
      // Se il prodotto non può essere combinato si verma subito (false)
      if ('combined' in this.product === false || !this.product.combined) return false;

      // Il prodotto è combinabile

      // Se non può essere utilizzato da solo, di ferma subito (true).
      if ('standalone' in this.product && this.product.standalone === false) return true;

      // Verifica se un prodotto tra quelli scelti lo richiede come combinato

      // Conta il numero di prodotti richiesti di questo tipo
      const reqCount = this.allRequiredProducts.filter((rp) => rp.id === this.product.id).length;

      // Nessun prodotto richiesto di questo tipo
      if (reqCount === 0) return false;

      // È richiesto un prodotto di questo tipo per l'uso combinato

      // Riserva il primo prodotto di questo tipo per l'uso combinato

      // Calcola la posizione in elenco di questo prodotto
      const spList = this.selectedProducts.filter((rp) => rp.id === this.product.id);
      const spIndex = spList.findIndex((rp) => rp.sId === this.product.sId);

      // Ritorna true solo se è il primo
      return Boolean(spIndex === 0);
    },

    isTheOne() {
      const spList = this.selectedProducts.filter((rp) => rp.id === this.product.id);

      return spList.length === 1;
    },

    selectedProducts() {
      return this.quotation && Array.isArray(this.quotation.selectedProducts)
        ? this.quotation.selectedProducts
        : [];
    },

    requiringProducts() {
      // Se il prodotto non è per l'uso combinato,
      // non ritorna i prodotti che lo richiedono.
      if (!this.isCombined) return [];

      return this.selectedProducts.filter((sp) => {
        const requiring = sp.consumptionType === 'variable'
          && Array.isArray(sp.consumptionranges[sp.consumptionRange || 0].requiredProducts)
          && sp.consumptionranges[sp.consumptionRange || 0].requiredProducts
            .find((rp) => rp.product.id === this.product.id);
        return requiring;
      });
    },

    productTooltip() {
      return this.isCombined
        ? "Superficie per l'utilizzo autonomo (premere per modificare)"
        : 'Superficie (premere per modificare)';
    },

    toggleTooltip() {
      return this.locked
        ? '<strong>Superficie collegata</strong><br>Modificando questa superficie verrà cambiata automaticamente anche quella degli altri prodotti collegati (premere per scollegare e modificare autonomamente)'
        : '<strong>Superficie autonoma</strong><br>La superficie su cui verrà utilizzato questo prodotto è modificabile autonomamente (premere per collegare agli altri prodotti connessi)';
    },
  },

  methods: {
    requiringProductTooltip(product) {
      return product
        && product.hasOwnProperty('name')
        && product.name.hasOwnProperty(this.userLanguage)
        ? `Superficie per l'utilizzo combinato con ${product.name[this.userLanguage]}`
        : '';
    },

    onToggleLockedArea() {
      this.$emit('toggleLockedArea');
    },

    onUpdateArea(area) {
      this.$emit('updateArea', area);
    },

    onEdit() {
      this.editDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.component-productarea {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
}

.rp-area {
  margin-right: 2rem;
  color: rgba(0, 0, 0, 0.38);
  cursor: not-allowed;
  white-space: nowrap;
}

.p-area-row {
  display: flex;
  flex-flow: row nowrap;
}

.p-area {
  white-space: nowrap;
}

.p-lock {
  width: 2rem;
}

/* .component-productselectedpackaging {
  white-space: nowrap;
}

.packaging-button {
  padding: 0 0.3rem !important;
  margin-left: 0.2rem;
  text-transform: none;
  height: 16px !important;
}

.packaging-button >>> small {
  margin-bottom: -0.1rem;
  margin-left: 0.1rem;
  letter-spacing: 0;
} */
</style>
