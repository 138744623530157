<template>
  <div class="component-newcolordialog">
    <v-dialog v-model="dialog" max-width="400">
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>

        <v-card>
          <v-card-title class="headline">Aggiungi un colore</v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field type="text"
                  v-model="code"
                  :disabled="loading"
                  label="Codice"
                  single-line
                  autofocus
                  :rules="[v => !!v || 'Il codice è richiesto']"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field type="text"
                  v-model="hbw"
                  :disabled="loading"
                  label="HBW"
                  single-line
                  :rules="[v => (!v || (v >= 0 && v <= 100))
                    || 'HBW (se presente) deve essere compreso tra 0 e 100']"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-text-field type="text" class="centered-input"
                  v-model="r"
                  :disabled="loading"
                  label="R"
                  outlined
                  :rules="[v => (!!v && v >= 0 && v <= 255)
                    || 'R è richiesto e deve essere compreso tra 0 e 255']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field type="text" class="centered-input"
                  v-model="g"
                  :disabled="loading"
                  label="G"
                  outlined
                  :rules="[v => (!!v && v >= 0 && v <= 255)
                    || 'G è richiesto e deve essere compreso tra 0 e 255']"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field type="text" class="centered-input"
                  v-model="b"
                  :disabled="loading"
                  label="B"
                  outlined
                  :rules="[v => (!!v && v >= 0 && v <= 255)
                    || 'B è richiesto e deve essere compreso tra 0 e 255']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="cancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn type="submit"
              color="success" text
              :disabled="!valid || loading"
              :loading="loading"
              @click="newItem()">
              Crea
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'NewColorDialog',

  props: ['value', 'colorlist'],

  data: () => ({
    dialog: false,
    valid: false,

    code: '',
    hbw: '',
    r: '',
    g: '',
    b: '',

    loading: false,
  }),

  watch: {
    value(newValue) {
      const formRef = this.$refs.form;

      if (newValue === true) {
        if (formRef) {
          formRef.reset();
          formRef.resetValidation();
        }
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    async newItem() {
      const formRef = this.$refs.form;

      if (formRef.validate()) {
        this.loading = true;

        await this.$store.dispatch('colorlists/addColor', {
          colorlistId: this.colorlist.id,

          code: `${this.code}`,
          hbw: Number(this.hbw),
          r: Number(this.r),
          g: Number(this.g),
          b: Number(this.b),
        });

        this.dialog = false;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}
</style>
