<template>
  <v-stepper-step
    :step="index"
    :complete="isComplete"
    :color="color">
    <span v-html="title"></span>
  </v-stepper-step>
</template>

<script>
/* eslint-disable no-prototype-builtins */

export default {
  name: 'TestQuestionStep',

  props: ['value', 'stage', 'question', 'index'],

  components: {
  },

  data: () => ({
    status: null,
  }),

  created() {
    console.log('TestQuestionStep: created()'); // eslint-disable-line no-console
    this.status = this.value;
  },

  watch: {
    value(newValue) {
      console.log('TestQuestionStep: watch.value()'); // eslint-disable-line no-console
      this.status = newValue;
    },

    // status(newValue) {
    //   console.log('TestQuestionStep: watch.status()'); // eslint-disable-line no-console
    //   this.$emit('input', newValue);
    // },
  },

  computed: {
    isComplete() {
      return this.status && this.status.hasOwnProperty('stages')
        && this.status.stages.hasOwnProperty(this.stage.name)
        && this.status.stages[this.stage.name].hasOwnProperty('questions')
        && this.status.stages[this.stage.name].questions.hasOwnProperty(this.question.name)
        && this.status.stages[this.stage.name].questions[this.question.name].hasOwnProperty('complete')
        ? this.status.stages[this.stage.name].questions[this.question.name].complete
        : false;
    },

    color() {
      return this.isComplete ? 'success' : 'warning';
    },

    title() {
      const answer = this.status && this.status.hasOwnProperty('stages')
        && this.status.stages.hasOwnProperty(this.stage.name)
        && this.status.stages[this.stage.name].hasOwnProperty('questions')
        && this.status.stages[this.stage.name].questions.hasOwnProperty(this.question.name)
        && this.status.stages[this.stage.name].questions[this.question.name].hasOwnProperty('answer')
        ? this.status.stages[this.stage.name].questions[this.question.name].answer
        : null;

      return this.isComplete && answer
        ? `${this.question.title}&nbsp;<strong>${answer}</strong>`
        : `${this.question.title}`;
    },
  },

  methods: {
  },
};
</script>
