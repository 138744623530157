<template>
  <div class="component-productphases">
    <v-chip class="mr-1 my-1"
      v-for="phase in phases"
      :key="phase.id"
      label
      outlined
      small
    >
      {{ phase.name }}
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'ProductPhases',

  props: ['product'],

  data: () => ({
  }),

  computed: {
    phases() {
      // eslint-disable-next-line no-prototype-builtins
      return this.product && this.product.hasOwnProperty('phases') ? this.product.phases : {};
    },
  },
};
</script>

<style scoped>
</style>
