<template>
  <div class="component-quotation-user">
    <v-simple-table class="quotation-user" dense>
      <template v-slot:default>
        <tbody>
          <tr v-if="hasLogo && showLogo">
            <td class="logo">
              <img :src="user.imageUrl" />
            </td>
          </tr>
          <tr v-if="hasCompany">
            <td class="font-weight-bold">{{ company }}</td>
          </tr>
          <tr v-if="address">
            <td class="caption">{{ address }}</td>
          </tr>
          <tr v-if="fullName">
            <td>{{ fullName }}</td>
          </tr>
          <tr v-if="phone">
            <td class="caption">{{ phone }}</td>
          </tr>
          <tr v-if="phone1">
            <td class="caption">{{ phone1 }}</td>
          </tr>
          <tr v-if="email">
            <td class="caption">{{ email }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: ['quotation'],

  name: 'QuotationUser',

  data: () => ({
  }),

  computed: {
    user() {
      return this.quotation && 'user' in this.quotation ? this.quotation.user : null;
    },

    hasLogo() {
      return this.user && 'imageUrl' in this.user && this.user.imageUrl !== null;
    },

    showLogo() {
      return this.user && 'showLogo' in this.user && this.user.showLogo === true;
    },

    hasCompany() {
      return ['rivenditore', 'venditore-esterno', 'agente', 'imbianchino', 'progettista', 'keim']
        .includes(this.user.type || 'privato');
    },

    company() {
      return this.hasCompany ? this.user.company : '';
    },

    fullName() {
      return `${this.user?.firstName ?? ''} ${this.user?.lastName ?? ''}`;
    },

    address() {
      return this.user?.address ?? '';
    },

    phone() {
      return this.user?.phone ?? '';
    },

    phone1() {
      return this.user?.phone1 ?? '';
    },

    email() {
      return this.user?.email ?? '';
    },
  },
};
</script>

<style lang="scss" scoped>
// .v-data-table.quotation-user {
//   min-width: 400px;
// }

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  height: auto;
}

td.logo > img {
  max-height: 70px;
  max-width: 250px;
}
</style>
