<template>
  <div class="component-phasechoice">
    <v-card outlined>
      <v-card-title v-text="`Scelta ${count}`" class="subtitle-1">
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="choice.visibility"
              :items="[
                { text: 'Scelta sempre visibile', value: 'always' },
                { text: 'Scelta visibile in base ai tag', value: 'conditions' },
              ]"
              :hint="visibilityHint"
              persistent-hint
              prepend-icon="mdi-eye"
              @change="updated()"
            ></v-select>

            <Conditions
              v-if="choice.visibility === 'conditions'"
              v-model="choice.visibilityConditions"
              @change="updated()" />

            <v-select
              v-model="choice.recommended"
              :items="[
                { text: 'Scelta selezionabile ma non consigliata', value: 'never' },
                { text: 'Scelta consigliata', value: 'always' },
                { text: 'Scelta consigliata in base ai tag', value: 'conditions' },
              ]"
              :hint="recommendedHint"
              persistent-hint
              prepend-icon="mdi-star"
              @change="updated()"
            ></v-select>

            <Conditions
              v-if="choice.recommended === 'conditions'"
              v-model="choice.recommendedConditions"
              @change="updated()" />

            <PhasePriorities
              v-model="choice.priorities"
              @change="updated()" />
          </v-col>

          <v-col cols="12" md="6">
            <PhaseProducts
              v-model="choice.products"
              @change="updated()" />

            <v-row>
              <v-col cols="12" class="switch-continer">
                <v-switch color="info" class="mt-1"
                  v-model="choice.withProcedure"
                  :label="choice.withProcedure
                    ? 'La scelta include delle indicazioni'
                    : 'La scelta non include delle indicazioni'"
                  @change="updated()"></v-switch>
              </v-col>

              <v-col cols="12">
                <ckeditor
                  v-model="choice.procedure"
                  :disabled="!choice.withProcedure"
                  :editor="editor"
                  :config="editorConfig"
                  @input="updated()"></ckeditor>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="error" text
          :loading="updating"
          @click="deleteItem()">
          Elimina scelta
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unreachable */
/* eslint-disable max-len */

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import List from '@ckeditor/ckeditor5-list/src/list';
import Conditions from '@/components/Conditions';
import PhaseProducts from '@/components/PhaseProducts';
import PhasePriorities from '@/components/PhasePriorities';

export default {
  name: 'PhaseChoice',

  components: {
    Conditions,
    PhaseProducts,
    PhasePriorities,
  },

  props: ['value', 'count'],

  data: () => ({
    editor: ClassicEditor,
    editorConfig: {
      placeholder: 'Inserisci qui il testo',
      language: 'it',
      plugins: [
        EssentialsPlugin,
        BoldPlugin,
        ItalicPlugin,
        Underline,
        LinkPlugin,
        ParagraphPlugin,
        Alignment,
        Font,
        RemoveFormat,
        List,
      ],
      toolbar: {
        items: [
          'bold', 'italic', 'underline',
          '|',
          'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
          '|',
          'alignment:left', 'alignment:right', 'alignment:center',
          '|',
          'link', 'numberedList', 'bulletedList',
          '|',
          'undo', 'redo', 'removeFormat',
        ],
        shouldNotGroupWhenFull: true,
      },
    },

    updating: false,

    choice: null,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
    userLocale: 'it-IT', // TODO: Dovrà essere impostato nel profilo utente
  }),

  created() {
    console.log('PhaseChoice: created()'); // eslint-disable-line no-console
    this.updateChoice(this.value);
  },

  watch: {
    value(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;

      if (!newValue) {
        console.log('PhaseChoice: watch.value(null)'); // eslint-disable-line no-console
        return;
      }
      console.log('PhaseChoice: watch.value() newValue =', newValue); // eslint-disable-line no-console

      this.updateChoice(newValue);
    },

    choice(newValue) {
      console.log('PhaseChoice: watch.choice() newValue =', newValue); // eslint-disable-line no-console
      this.$emit('input', newValue);
    },
  },

  computed: {
    tags() {
      return this.$store.getters['tags/docs'];
    },

    visibilityHint() {
      switch (this.choice.visibility) {
        case 'always':
          return 'Questa scelta verrà mostrata quando la fase di lavorazione sarà richiesta';

        case 'conditions':
          return "Questa scelta sarà mostrata soltanto se tutti i tag (AND) di una delle seguenti linee (OR) saranno nell'elenco dei tag abilitati al termine del test.";

        default:
          return null;
      }
    },

    recommendedHint() {
      switch (this.choice.recommended) {
        case 'never':
          return 'Questa scelta verrà mostrata ma non apparirà come quella consigliata';

        case 'always':
          return 'Questa scelta apparirà sempre come quella consigliata';

        case 'conditions':
          return "Questa scelta apparirà come quella consigliata soltanto se tutti i tag (AND) di una delle seguenti linee (OR) saranno nell'elenco dei tag abilitati al termine del test.";

        default:
          return null;
      }
    },
  },

  methods: {
    updated() {
      console.log('PhaseChoice: updated()'); // eslint-disable-line no-console
      this.$emit('change');
    },

    updateChoice(newValue) {
      console.log('PhaseChoice: updateChoice() newValue =', newValue); // eslint-disable-line no-console

      // eslint-disable-next-line prefer-const
      let {
        products,
        visibility,
        visibilityConditions,
        recommended,
        recommendedConditions,
        priorities,
      } = newValue;

      // Se la visibilità non è impostata,
      // di default è 'Sempre'.
      if (!visibility) {
        visibility = 'always';
      }

      // Se la raccomandazione non è impostata,
      // di default è 'Mai'.
      if (!recommended) {
        recommended = 'never';
      }

      // Se l'elenco prodotti è assente,
      // ne crea uno vuoto.
      if (!products) products = [];

      // Se l'elenco prodotti è un oggetto,
      // lo converte in un elenco.
      if (products.constructor === Object) {
        products = Object.values(products);
      }

      // Se non ci sono condizioni di visibilità,
      // aggiunge la prima (vuota).
      if (!visibilityConditions) visibilityConditions = [];
      if (visibilityConditions.constructor === Object) {
        visibilityConditions = Object.values(visibilityConditions);
      }
      if (visibilityConditions.length === 0) {
        visibilityConditions.push(
          [],
        );
      }

      // Se non ci sono condizioni di raccomandazione,
      // aggiunge la prima (vuota).
      if (!recommendedConditions) recommendedConditions = [];
      if (recommendedConditions.constructor === Object) {
        recommendedConditions = Object.values(recommendedConditions);
      }
      if (recommendedConditions.length === 0) {
        recommendedConditions.push(
          [],
        );
      }

      // Se l'elenco delle priorità è assente,
      // ne crea uno vuoto.
      if (!priorities) priorities = [];

      // Se l'elenco delle priorità è un oggetto,
      // lo converte in un elenco.
      if (priorities.constructor === Object) {
        priorities = Object.values(priorities);
      }

      // // Inserisce la prima priorità
      // if (priorities.length === 0) {
      //   priorities.push(
      //     {
      //       value: 0,
      //       tags: [],
      //     },
      //   );
      // }

      this.choice = {
        products,
        visibility,
        visibilityConditions,
        recommended,
        recommendedConditions,
        priorities,
        withProcedure: newValue.withProcedure || false,
        procedure: newValue.procedure || '',
      };
    },

    // updated() {
    //   console.log('PhaseChoice: updated()'); // eslint-disable-line no-console
    //   this.$emit('updated');
    // },

    deleteItem() {
      console.log('PhaseChoice: deleteItem()'); // eslint-disable-line no-console
      this.$emit('delete');
    },
  },
};
</script>

<style scoped>
</style>
