<template>
  <div class="component-quotationpdf">
    <v-row v-if="!loading && quotation" class="component-quotation-content">
      <v-col cols="12" class="pa-0">
        <v-sheet class="keim-header" color="primary">
          <img src="@/assets/logo-app_bar-v3.png" class="keim-header-logo" />
        </v-sheet>

        <div class="quotation-header">
          <div class="quotation-header-left">
            <QuotationUser :quotation="quotation" />
          </div>
          <div class="quotation-header-right">
            <span class="font-weight-regular">Cantiere:</span>
            <span class="font-weight-bold">{{ quotation.name }}</span>
            <span class="caption">
            {{ quotation.location }} (<span class="text-uppercase">{{ quotation.province }}</span>)
            </span>
          </div>
        </div>

        <v-card flat>
          <v-card-text class="pa-0">
            <v-simple-table v-if="selectedProducts.length > 0">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Prodotto</th>
                    <th class="text-right">
                      <template v-if="type < 2">
                        Consumo<br>al m²
                      </template>
                    </th>
                    <th v-if="!!pricelist"
                      class="text-right">
                      <template v-if="type < 2">
                        Incidenza<br>al m²
                      </template>
                    </th>
                    <th class="text-right">
                      <template v-if="type < 2">
                        Superficie
                      </template>
                    </th>
                    <th class="text-right">
                      <template v-if="type < 2">
                        Quantità
                      </template>
                    </th>
                    <th v-if="!!pricelist && hasDiscount && type < 2" class="text-right">
                      Sconto
                    </th>
                    <th class="text-right">
                      <template v-if="!!pricelist && type < 2">
                        Costo
                      </template>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(product, pIndex) in selectedProducts">
                    <tr :key="`product-${product.id}-${pIndex}`">
                      <td class="subtitle-1">
                        <v-list-item-content>
                          <v-list-item-title class="">
                            <span class="product-name"
                              v-html="product.name[userLanguage]"></span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <small class="product-details"
                              v-if="Array.isArray(productDetails(product))">
                              {{ productDetails(product).join(', ') }}
                            </small>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </td>

                      <td class="text-right text-no-wrap body-2"
                        v-html="type < 2 ? consumptionText(product) : ''"></td>

                      <td v-if="!!pricelist"
                        class="text-right text-no-wrap body-2">
                        <template v-if="type < 2">
                          <span
                            v-for="(incidence, iIndex) in incidences(product)"
                            :key="`p${product.id}-i${iIndex}`"
                            :class="incidenceClass(incidence)"
                            v-html="incidence.text">
                          </span>
                        </template>
                      </td>

                      <td class="text-right body-2">
                        <template v-if="type < 2">
                          <span
                            v-for="(area, aIndex) in areas(product)"
                            :key="`p${product.id}-a${aIndex}`"
                            v-html="area.text">
                          </span>
                        </template>
                      </td>

                      <td class="text-right text-no-wrap">
                        <template v-if="type < 2">
                          <span class="body-2 font-weight-medium">
                            {{ quantityText(product) }}
                          </span><br>

                          <div class="packaging">
                            <span v-for="(pack, packIndex) in packages(product)"
                              :key="`p${product.id}-pack${packIndex}`"
                              class="caption text-no-wrap"
                              v-html="packageText(product, pack)">
                            </span>
                          </div>
                        </template>
                      </td>

                      <td v-if="!!pricelist && hasDiscount && type < 2"
                        class="text-right body-2 text-no-wrap">
                        <div class="discounts-container"
                          v-html="discountText(product)"></div>
                      </td>

                      <td v-if="!!pricelist"
                        class="text-right subtitle-1 font-weight-medium text-no-wrap">
                        <template v-if="type < 2">
                          {{ priceText(product) }}
                        </template>
                      </td>
                    </tr>

                    <!-- Mostra i prezzi di listino per ogni lattaggio selezionato -->
                    <template v-if="!!pricelist && product">
                      <tr class="prices-row"
                        v-for="(price, priceIndex) in product.priceranges[product.priceRange || 0].prices.filter((p) => p.quantity > 0)"
                        :key="`prices-${product.id}-${pIndex}-${priceIndex}`"
                        v-show="showPrices"
                      >
                        <td colspan="2" class="pricesContent"
                          v-html="productNameWithPriceRange(product)">
                        </td>
                        <td class="pricesContent"></td>
                        <td class="pricesContent"></td>
                        <td class="text-right pricesContent">{{ productPackaging(product, price) }}</td>
                        <td v-if="hasDiscount" class="pricesContent"></td>
                        <td class="text-right pricesContent">{{ productPrice(product, price) }}</td>
                      </tr>
                    </template>

                    <!-- Mostra le voci di capitolato -->
                    <template v-if="product && product.specs
                        && userLanguage in product.specs">
                      <tr :key="`specs-${product.id}`" v-show="showSpecs" class="specs-row">
                        <td colspan="7">
                          <v-card flat
                            v-show="showSpecs"
                          >
                            <v-card-text class="specsContent"
                              v-html="product.specs[userLanguage]"></v-card-text>
                          </v-card>
                        </td>
                      </tr>
                    </template>
                  </template>

                  <tr v-if="!!pricelist && type < 2">
                    <!-- td class="body-2 text--secondary">
                      <span class="font-weight-medium">Cantiere:</span> {{ place }}
                    </td -->

                    <td class="body-2 text--secondary text-right" colspan="3">
                      <template v-if="selectedIncidences.length > 0">
                        Incidenza totale:
                        <span class="font-weight-medium">{{ finalIncidence }}</span>
                      </template>
                    </td>

                    <td class="text-right pt-4" colspan="4">
                      <span class="title">{{ totalPrice() }}</span><br>
                      <span class="caption">
                        Tutti i prezzi indicati si intendono IVA esclusa
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="px-0">
        <span class="notes caption mb-2">
          I consumi indicati sono calcolati su supporti lisci e normalmente assorbenti.
          Il consumo è in ogni caso indicativo e quindi potrebbe variare in quanto è dipendente
          dalle caratteristiche del supporto e dalle modalità applicative.
        </span>

        <span class="notes caption">
          I contenuti della presente offerta vengono controllati costantemente e attentamente.
          Tuttavia non possiamo garantire la completezza e l’esattezza in quanto creati da terzi.
          KEIMFARBEN quindi non si assume alcuna responsabilità per la correttezza dei dati.
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import QuotationUser from '@/components/QuotationUser';

export default {
  name: 'QuotationPDF',

  props: {
    value: {
      type: Object,
    },
    updating: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    QuotationUser,
  },

  data: () => ({
    id: null,
    quotation: null,

    selected: null,

    areaUpdated: false,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  created() {
    this.quotation = this.value;
  },

  watch: {
    value(newValue) {
      console.log('QuotationPDF: watch.value()'); // eslint-disable-line no-console

      this.quotation = newValue;

      // Corregge i lockedArea per ogni prodotto selezionato
      // eslint-disable-next-line no-restricted-syntax
      for (const pIndex in this.quotation.selectedProducts) {
        if (this.quotation.selectedProducts.hasOwnProperty(pIndex)) {
          if (!this.quotation.selectedProducts[pIndex].hasOwnProperty('lockedArea')) {
            // Se il prodotto è combinato con qualcos'altro,
            // evita il blocco e imposta l'area iniziale a 0
            if (this.isCombined(this.quotation.selectedProducts[pIndex])) {
              this.$set(this.quotation.selectedProducts[pIndex], 'lockedArea', false);
              this.$set(this.quotation.selectedProducts[pIndex], 'area', 0);
            } else {
              this.$set(this.quotation.selectedProducts[pIndex], 'lockedArea', true);
            }
          }
        }
      }

      // Corregge l'area della superficie comune
      // eslint-disable-next-line no-prototype-builtins
      if (!newValue.hasOwnProperty('area')) this.$set(this.quotation, 'area', 100);

      // Calcola i lattaggi iniziali per ogni prodotto
      // eslint-disable-next-line no-restricted-syntax
      for (const product of this.quotation.selectedProducts) {
        this.updateInitialPackaging(product);
      }
    },

    quotation(newValue) {
      console.log('QuotationPDF: watch.quotation()'); // eslint-disable-line no-console
      this.$emit('input', newValue);

      if (this.$route.query.print === '1') {
        this.$nextTick(() => {
          setTimeout(() => { window.print(); }, 2000);
        });
      }
    },

    area(newValue) {
      console.log('QuotationPDF: watch.area() newValue =', newValue); // eslint-disable-line no-console
      // Aggiorna le aree dei prodotti con lockedArea === true
      // eslint-disable-next-line no-restricted-syntax
      for (const pIndex in this.selectedProducts) {
        if (this.selectedProducts.hasOwnProperty(pIndex)) {
          if (this.selectedProducts[pIndex].hasOwnProperty('lockedArea')
            && this.selectedProducts[pIndex].lockedArea === true) {
            this.$set(this.selectedProducts[pIndex], 'area', `${new Intl.NumberFormat('it-IT').format(newValue)}`);
          }
        }
      }
    },
  },

  computed: {
    hasDiscount() {
      return this.quotation
        && ('discount' in this.quotation
          || 'discountExtra' in this.quotation
          || 'discountAccounting' in this.quotation);
    },

    pricelist() {
      return 'pricelist' in this.$route.query ? this.$route.query.pricelist : null;
    },

    type() {
      return 'type' in this.$route.query ? Number(this.$route.query.type) : 0;
    },

    // Mostra i prezzi di listino
    showPrices() {
      if (this.type === 2) return false;

      return 'showPrices' in this.$route.query
        ? String(this.$route.query.showPrices).toLowerCase() === 'true'
        : false;
    },

    // Mostra le voci di capitolato
    showSpecs() {
      if (this.type === 2) return true;

      return 'showSpecs' in this.$route.query
        ? String(this.$route.query.showSpecs).toLowerCase() === 'true'
        : false;
    },

    area() {
      return this.quotation && this.quotation.hasOwnProperty('area') ? this.quotation.area : null;
    },

    selectedIncidences() {
      return this.quotation
        && this.quotation.hasOwnProperty('selectedIncidences')
        && Array.isArray(this.quotation.selectedIncidences)
        ? this.quotation.selectedIncidences
        : [];
    },

    finalIncidence() {
      if (this.selectedIncidences.length === 0) return '';

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      return formatter.format(
        this.selectedIncidences
          // Rimuove i prodotti non più selezionati
          // eslint-disable-next-line arrow-body-style
          .filter((incidence) => {
            // eslint-disable-next-line arrow-body-style
            return this.selectedProducts.findIndex((p) => {
              return 'sId' in p
                ? p.sId === incidence.id.substring(1, p.sId.length + 1)
                : p.id === incidence.id.substring(1, p.id.length + 1);
            }) > -1;
          })
          .reduce((accumulator, incidence) => accumulator + incidence.value, 0),
      );
    },

    allRequiredProducts() {
      if (!Array.isArray(this.selectedProducts)) return [];

      const result = [];
      this.selectedProducts.forEach((product) => {
        if (product.consumptionType === 'variable'
          && Array.isArray(product.consumptionranges[product.consumptionRange]
            .requiredProducts)
          && product.consumptionranges[product.consumptionRange].requiredProducts.length > 0) {
          result.push(
            ...product.consumptionranges[product.consumptionRange].requiredProducts
              .map((rp) => rp.product),
          );
        }
      });

      return result;
    },

    selectedProducts() {
      return this.quotation && 'selectedProducts' in this.quotation
        && Array.isArray(this.quotation.selectedProducts)
        ? this.quotation.selectedProducts.map((p) => {
          const product = p;

          if ('priceRange' in product === false || !product.priceRange) {
            product.priceRange = 0;
          }
          if ('consumptionRange' in product === false || !product.consumptionRange) {
            product.consumptionRange = 0;
          }
          if ('area' in product === false || product.area === null) {
            product.area = 100;
          }

          return product;
        })
        : [];
    },

    place() {
      return `${this.quotation.name} - ${this.quotation.location} (${this.quotation.province.toUpperCase()})`;
    },

    // agent() {
    //   return `${this.quotation.user.firstName} ${this.quotation.user.lastName}`;
    // },
  },

  methods: {
    productName(product, suffix = '') {
      return product && product.name && this.userLanguage in product.name
        ? `${product.name[this.userLanguage]}${suffix}`
        : '';
    },

    productNameWithPriceRange(product) {
      const suffix = product.priceranges.length > 1
        ? `<br><small>${product.priceranges[product.priceRange || 0].name}</small>`
        : '';

      return this.productName(product, suffix);
    },

    productPackaging(product, price) {
      if (product.um === 'pz') return '1 pz';

      return product && product.um && price && price.packaging
        ? `${price.packaging} ${product.um}`
        : '';
    },

    productPrice(product, priceData) {
      if (!product || !priceData) return '';

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      const price = `${formatter.format(priceData[this.pricelist])}`.replace('€', `€/${product.um}`);

      return price;
    },

    updateInitialPackaging(product, force = false) {
      // eslint-disable-next-line no-console
      console.log('Quotation.updateInitialPackaging() product =', product);

      const pIndex = this.quotation.selectedProducts
        .findIndex((p) => {
          if ('sId' in product && product.sId !== null && 'sId' in p) {
            return product.sId === p.sId;
          }
          // return false;
          return p.id === product.id;
        });

      // eslint-disable-next-line no-console
      console.log('Quotation.updateInitialPackaging() pIndex =', pIndex);

      // Verifica che il priceRange del prodotto sia corretto
      if (!product.priceranges.hasOwnProperty(product.priceRange)) {
        this.$set(
          this.quotation.selectedProducts[pIndex],
          'priceRange',
          0,
        );
      }

      // È stato selezionato un lattaggio manuale?
      const withoutPackaging = Boolean(
        product.priceranges[product.priceRange || 0].prices
          .find((p) => !p.hasOwnProperty('quantity')),
      );

      console.log(`Quotation.updateInitialPackaging() withoutPackaging = ${withoutPackaging}`); // eslint-disable-line no-console

      if (withoutPackaging || force) {
        console.log(`Quotation.updateInitialPackaging() Calcolo il lattaggio iniziale del prodotto ${product.name[this.userLanguage]}`); // eslint-disable-line no-console

        const quantity = this.estimatedQuantity(product);
        console.log(`Quotation.updateInitialPackaging() Quantità richiesta = ${quantity}`); // eslint-disable-line no-console

        // Ottiene il lattaggio massimo del prodotto
        const maxPackaging = this.convertToFloat(product.priceranges[product.priceRange || 0]
          .prices[0].packaging);
        console.log(`Quotation.updateInitialPackaging() maxPackaging = ${maxPackaging}`); // eslint-disable-line no-console

        if (product.um === 'pz') {
          // eslint-disable-next-line no-restricted-syntax
          for (const priceIndex in product.priceranges[product.priceRange || 0].prices) {
            if (product.priceranges[product.priceRange || 0].prices.hasOwnProperty(priceIndex)) {
              this.$set(
                this.quotation.selectedProducts[pIndex]
                  .priceranges[product.priceRange || 0]
                  .prices[priceIndex],
                'quantity',
                priceIndex > 0 ? 0 : 1,
              );
            }
          }
        } else {
          // Calcolo la quantità di latte con lattaggio massimo
          let maxPackagingQuantity = Math.trunc(quantity / maxPackaging);
          if (maxPackagingQuantity * maxPackaging < quantity) {
            maxPackagingQuantity += 1;
          }
          console.log(`Quotation.updateInitialPackaging() maxPackagingQuantity = ${maxPackagingQuantity}`); // eslint-disable-line no-console

          // eslint-disable-next-line no-restricted-syntax
          for (const priceIndex in product.priceranges[product.priceRange || 0].prices) {
            if (product.priceranges[product.priceRange || 0].prices.hasOwnProperty(priceIndex)) {
              this.$set(
                this.quotation.selectedProducts[pIndex]
                  .priceranges[product.priceRange || 0]
                  .prices[priceIndex],
                'quantity',
                priceIndex > 0 ? 0 : maxPackagingQuantity,
              );
            }
          }
        }
      }
    },

    isTheOne(product) {
      const spList = this.selectedProducts.filter((rp) => rp.id === product.id);

      return spList.length === 1;
    },

    convertToFloat(value) {
      return parseFloat(`${value}`.replace(/[^0-9,]+/g, '').replace(',', '.'));
    },

    productDetails(product) {
      const details = [];

      if (product.consumptionType === 'variable') {
        details.push(this.selectedConsumptionRange(product));
      }

      if (['colors', 'width'].includes(product.priceType)) {
        details.push(this.selectedPriceRange(product));
      }

      if (product.priceType === 'colors') {
        if (product.color) {
          details.push(`Colore ${product.color.code}`);
        } else if (!this.isWhite(product)) {
          details.push('Colore non scelto');
        }
      }

      return details;
    },

    isCombined(product) {
      // Se il prodotto non può essere combinato si ferma subito (false)
      if ('combined' in product === false || !product.combined) return false;

      // Il prodotto è combinabile

      // Se non può essere utilizzato da solo, si ferma subito (true).
      if ('standalone' in product && product.standalone === false) return true;

      // Verifica se un prodotto tra quelli scelti lo richiede come combinato

      // Conta il numero di prodotti richiesti di questo tipo
      const reqCount = this.allRequiredProducts.filter((rp) => rp.id === product.id).length;

      // Nessun prodotto richiesto di questo tipo
      if (reqCount === 0) return false;

      // È richiesto un prodotto di questo tipo per l'uso combinato

      // Riserva il primo prodotto di questo tipo per l'uso combinato

      // Calcola la posizione in elenco di questo prodotto
      const spList = this.selectedProducts.filter((rp) => rp.id === product.id);
      const spIndex = spList.findIndex((rp) => rp.sId === product.sId);

      // Ritorna true solo se è il primo
      return Boolean(spIndex === 0);
    },

    isWhite(product) {
      if (product.priceType !== 'colors') return false;

      const pricerange = product.priceranges[product.priceRange ?? 0];

      if (pricerange.colors.length !== 1) return false;

      return pricerange.colors[0].r === 255
        && pricerange.colors[0].g === 255
        && pricerange.colors[0].b === 255;
    },

    selectedConsumptionRange(product) {
      // eslint-disable-next-line no-prototype-builtins
      if (!product.hasOwnProperty('consumptionRange') || product.consumptionRange === null) {
        return product.consumptionranges[0].name;
      }
      return product.consumptionranges[product.consumptionRange ?? 0].name;
    },

    selectedPriceRange(product) {
      // eslint-disable-next-line no-prototype-builtins
      if (!product.hasOwnProperty('priceRange') || product.priceRange === null) {
        return product.priceranges[0].name;
      }
      return product.priceranges[product.priceRange ?? 0].name;
    },

    // La quantità totale calcolata in base al lattaggio selezionato
    quantity(product) {
      // eslint-disable-next-line no-console
      // console.log('Quotation.quantity()');

      if (!product) return null;

      let q = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const priceIndex in product.priceranges[product.priceRange ?? 0].prices) {
        if (product.priceranges[product.priceRange ?? 0].prices.hasOwnProperty(priceIndex)) {
          const price = product.priceranges[product.priceRange ?? 0].prices[priceIndex];

          // eslint-disable-next-line no-console
          // console.log('Quotation.quantity() price =', price);

          // eslint-disable-next-line no-console
          // console.log('Quotation.quantity() packaging =', price.packaging);
          // eslint-disable-next-line no-console
          // console.log('Quotation.quantity() quantity =', price.quantity);

          q += this.convertToFloat(price.packaging) * price.quantity;
        }
      }

      // eslint-disable-next-line no-console
      // console.log('Quotation.quantity() q =', q);

      return q;
    },

    quantityText(product) {
      if (!product) return '';

      const quantity = this.quantity(product);

      return quantity ? `${new Intl.NumberFormat('it-IT').format(quantity)} ${product.um}` : '0';
    },

    // La quantità totale stimata di prodotto
    estimatedQuantity(product) {
      if (!product) return null;

      if (this.isCombined(product)) {
        let q = 0;
        // Scorre tutti i prodotti per identificare quelli che richiedono
        // l'utilizzo di questo prodotto.
        this.selectedProducts.forEach((sp) => {
          // Verifica se richiede qualche prodotto combinato
          if (sp.consumptionType === 'variable'
            && Array.isArray(sp.consumptionranges[sp.consumptionRange ?? 0].requiredProducts)
            && sp.consumptionranges[sp.consumptionRange ?? 0].requiredProducts.length > 0) {
            // Verifica se richiede il prodotto combinato
            const required = sp.consumptionranges[sp.consumptionRange ?? 0].requiredProducts
              .find((rp) => rp.product.id === product.id);
            if (required) {
              q += sp.lockedArea
                ? required.consumption * (this.quotation.area ?? 100)
                : required.consumption * this.convertToFloat(sp.area ?? 100);
            }
          }
        });

        // Se è consentito anche l'utilizzo "standalone",
        // ed è l'unico prodotto di questo tipo in elenco...
        if (
          ('standalone' in product === false || product.standalone === true)
          && this.isTheOne(product)
        ) {
          q += product.lockedArea
            ? this.consumption(product) * (this.quotation.area ?? 100)
            : this.consumption(product) * this.convertToFloat(product.area ?? 100);
        }

        return q;
      }

      if (!this.quotation.area) return null;
      if (!this.consumption(product)) return null;

      return product.lockedArea
        ? this.consumption(product) * (this.quotation.area ?? 100)
        : this.consumption(product) * this.convertToFloat(product.area ?? 100);
    },

    consumption(product) {
      if (product.consumptionType === 'variable') {
        return product.consumptionranges[product.consumptionRange ?? 0].consumption;
      }

      return product.consumption;
    },

    consumptionText(product) {
      if (!product) return '';

      if (['pz', 'mq'].includes(product.um)) return '';

      // Per i prodotti combinati, mostra i consumi per ogni prodotto con cui è miscelato
      if (this.isCombined(product)) {
        let result = '';
        this.selectedProducts.forEach((sp) => {
          const required = sp.consumptionType === 'variable'
            && Array.isArray(sp.consumptionranges[sp.consumptionRange ?? 0].requiredProducts)
            && sp.consumptionranges[sp.consumptionRange ?? 0].requiredProducts
              .find((rp) => rp.product.id === product.id);
          if (required) {
            result += `<small class="text--disabled">+ ${sp.name[this.userLanguage]}: </small> `;
            result += `${new Intl.NumberFormat('it-IT').format(required.consumption)} ${product.um}`;
            result += '<br>';
          }
        });

        // Se è consentito anche l'utilizzo "standalone", aggiunge la sua riga di consumo
        if (('standalone' in product === false || product.standalone === true)
          && this.isTheOne(product)
          && (product.area ?? 100) > 0) {
          result += '<small class="text--disabled">Utilizzo autonomo: </small> ';
          result += `${new Intl.NumberFormat('it-IT').format(this.consumption(product))} ${product.um}`;
          result += '<br>';
        }

        return result;
      }

      return `${new Intl.NumberFormat('it-IT').format(this.consumption(product))} ${product.um}`;
    },

    incidence(product) {
      return product.area > 0 ? this.price(product) / this.convertToFloat(product.area) : null;
    },

    requiringProducts(product) {
      return this.selectedProducts.filter((sp) => {
        const requiring = sp.consumptionType === 'variable'
          && Array.isArray(sp.consumptionranges[sp.consumptionRange ?? 0].requiredProducts)
          && sp.consumptionranges[sp.consumptionRange ?? 0].requiredProducts
            .find((rp) => rp.product.id === product.id);
        return requiring;
      });
    },

    consumptionArray(product) {
      const cArray = [];
      if (product) {
        if (this.isCombined(product)) {
          // let result = '';
          this.selectedProducts.forEach((sp) => {
            const required = sp.consumptionType === 'variable'
              && Array.isArray(sp.consumptionranges[sp.consumptionRange ?? 0].requiredProducts)
              && sp.consumptionranges[sp.consumptionRange ?? 0].requiredProducts
                .find((rp) => rp.product.id === product.id);
            if (required) {
              cArray.push(required.consumption);
            }
          });

          // Se è consentito anche l'utilizzo "standalone",
          // ed è l'unico prodotto di questo tipo in elenco...
          if (
            ('standalone' in product === false || product.standalone === true)
            && this.isTheOne(product)
          ) {
            cArray.push(this.consumption(product));
          }

          return cArray;
        }

        return [this.consumption(product)];
      }

      return cArray;
    },

    areaArray(product) {
      const aArray = [];

      if (product) {
        if (this.isCombined(product)) {
          this.requiringProducts(product).forEach((rp) => {
            aArray.push(this.convertToFloat(rp.area ?? 100));
          });

          // Se è consentito anche l'utilizzo "standalone",
          // ed è l'unico prodotto di questo tipo in elenco...
          if (
            ('standalone' in product === false || product.standalone === true)
            && this.isTheOne(product)
          ) {
            // ... aggiunge la sua riga di area
            aArray.push(this.convertToFloat(product.area ?? 100));
          }

          return aArray;
        }

        return [this.convertToFloat(product.area ?? 100)];
      }

      return aArray;
    },

    incidences(product) {
      if (!product) return '';

      if (['pz'].includes(product.um)) return [];

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      const incidences = [];

      // Per i prodotti combinati,
      // mostra i consumi per ogni prodotto con cui è miscelato
      if (this.isCombined(product)) {
        const cArray = this.consumptionArray(product);
        // eslint-disable-next-line no-console
        console.log('FLK cArray =', cArray);

        const aArray = this.areaArray(product);
        // eslint-disable-next-line no-console
        console.log('FLK aArray =', aArray);

        let quantityFromConsumption = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const index in cArray) {
          if (cArray.hasOwnProperty(index)) {
            quantityFromConsumption += cArray[index] * aArray[index];
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const index in cArray) {
          if (cArray.hasOwnProperty(index)) {
            const incidence = aArray[index] > 0
              ? (cArray[index] * this.price(product)) / quantityFromConsumption
              : null;

            if (incidence) {
              incidences.push({
                id: `p${product.sId || product.id}-r${index}`,
                value: incidence || 0,
                text: `${formatter.format(incidence)}<br>`,
              });
            }
          }
        }
      } else {
        // Prodotto non combinato, procede con il calcolo normale dell'incidenza
        const incidence = this.incidence(product);

        incidences.push({
          id: `p${product.sId || product.id}`,
          value: incidence || 0,
          text: incidence ? `${formatter.format(incidence)}` : '-',
        });
      }

      return incidences;
    },

    areas(product) {
      if (!product) return '';

      if (product.um === 'pz') return [];

      const areas = [];

      // Per i prodotti combinati, mostra i consumi per ogni prodotto con cui è miscelato
      if (this.isCombined(product)) {
        const aArray = this.areaArray(product);

        // eslint-disable-next-line no-restricted-syntax
        for (const index in aArray) {
          if (aArray.hasOwnProperty(index)) {
            const area = this.convertToFloat(aArray[index]);
            if (area > 0) {
              areas.push({
                id: `p${product.id}-a${index}`,
                value: area,
                text: `${new Intl.NumberFormat('it-IT').format(area)} m²<br>`,
              });
            }
          }
        }

        return areas;
      }

      // Prodotto non combinato, procede con il calcolo normale dell'incidenza
      const area = this.convertToFloat(product.area ?? 100);
      areas.push({
        id: `p${product.id}`,
        value: area,
        text: `${new Intl.NumberFormat('it-IT').format(area)} m²`,
      });

      return areas;
    },

    packages(product) {
      if (!product) return [];

      return product.priceranges[product.priceRange ?? 0].prices
        .filter((p) => p.quantity > 0);
    },

    packageText(product, pack) {
      if (!product || !pack) return '';

      return `${pack.packaging} ${product.um} <small>x ${pack.quantity}</small>`;
    },

    isIncidenceSelected(incidence) {
      return Boolean(this.selectedIncidences.find((i) => i.id === incidence.id));
    },

    incidenceClass(incidence) {
      if (this.selectedIncidences.length === 0) return '';

      return this.isIncidenceSelected(incidence) ? 'font-weight-bold' : 'font-weight-light';
    },

    price(product) {
      if (!product || !this.pricelist) return null;

      // Identifica la facia di prezzo attualmente selezionata
      const priceRange = product.priceType === 'colors'
        ? product.priceRange ?? 0
        : 0;

      // Prepara l'elenco dei lattaggi attualmente selezionati
      const packages = product.priceranges[priceRange].prices
        .filter((p) => p.quantity > 0);

      let price = 0;
      packages.forEach((pack) => {
        const size = this.convertToFloat(pack.packaging);
        price += pack.quantity * size * pack[this.pricelist];
      });

      if (!price) return null;

      const discount = this.discount(product);
      const discountExtra = this.discountExtra(product);
      const discountAccounting = this.discountAccounting(product);

      let result = price;
      if (discount) result -= (result * discount);
      if (discountExtra) result -= (result * discountExtra);
      if (discountAccounting) result -= (result * discountAccounting);

      return result;
    },

    priceText(product) {
      const price = this.price(product);

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      if (!price) return `${formatter.format(0)}`;

      return `${formatter.format(price)}`;
    },

    totalPrice() {
      let result = 0;

      this.selectedProducts.forEach((product) => {
        result += this.price(product);
      });

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
      });

      return `${formatter.format(result)}`;
    },

    discount(product) {
      if (!product || !this.quotation) return 0;

      if ('discount' in product) {
        return product.discount;
      }

      return 'discount' in this.quotation && this.quotation.discount
        ? this.quotation.discount
        : 0;
    },

    discountExtra(product) {
      if (!product || 'discountExtra' in product === false) return 0;

      return product.discountExtra ?? 0;
    },

    discountAccounting(product) {
      if (!product || !this.quotation) return 0;

      if ('discountAccounting' in product) {
        return product.discountAccounting;
      }

      return 'discountAccounting' in this.quotation && this.quotation.discountAccounting
        ? this.quotation.discountAccounting
        : 0;
    },

    discountText(product) {
      const discount = this.discount(product);
      const discountExtra = this.discountExtra(product);
      const discountAccounting = this.discountAccounting(product);

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (!discount) return `${formatter.format(0)}`;

      let result = `<span class="font-weight-medium">${formatter.format(discount)}</span>`;
      if (discountExtra) result += `<span>${formatter.format(discountExtra)}</span>`;
      if (discountAccounting) result += `<span>${formatter.format(discountAccounting)}</span>`;
      return result;
    },

    updated() {
      this.$emit('updated');
    },

    onEdit() {
      this.$router.push({ name: 'ModificaPreventivo', params: { id: this.quotation.id } });
    },
  },
};
</script>

<style scoped lang="scss">
.v-list-item__title, .v-list-item__subtitle {
  white-space: normal;
}

.product-name {
  font-weight: bold;
}

.product-details {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.38);
}

.product-button {
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.v-btn.recalculate-button {
  height: 40px;
  width: 100%;
}

.productArea {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: flex-end;
}
.productArea .v-text-field {
  max-width: 150px;
  font-size: 0.875rem !important;
}
.productArea .v-text-field >>> .v-input__icon {
  font-size: 1rem !important;
  /* background-color: red; */
}
.productArea .v-text-field >>> .v-icon.v-icon {
  font-size: 1rem !important;
}
.productArea .v-text-field >>> .v-input__append-outer {
  margin-left: 0;
}

.packaging {
  color: rgba(0, 0, 0, 0.38) !important;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
}

.packaging span {
  line-height: 0.8rem;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding-left: 8px;
  padding-right: 8px;
}

#app .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 5px;
  padding-right: 5px;
}

.v-application.print .body-2 {
  font-size: 0.8rem !important;
}

.v-application.print .subtitle-1 {
  font-size: 0.9rem !important;
}
.v-application.print .caption {
  font-size: 0.60rem !important;
}
.v-application.print .v-btn:not(.v-btn--round).v-size--x-small {
  padding-left: 0;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th.area-header,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th.incidence-header {
  padding-right: 1rem;
  text-align: right;
}

.p-incidence-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.p-incidence {
  white-space: nowrap;
}

.p-incidence-checkbox {
  width: 2rem;
}

.switch-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: stretch;
  margin-right: 2rem;
}

.switch-container >>> .v-input__slot {
  margin-bottom: 0;
}

.specsContent,
.pricesContent {
  line-height: 1rem;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.38) !important;
  padding-right: 0;
  padding-left: 0;
}

.pricesContent {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  line-height: 0.8;
  height: 32px !important;
}

.specs-row {
  background: #fff !important;
}

.keim-header {
  -webkit-print-color-adjust: exact;
  margin-top: -40px;
  margin-bottom: 1rem;
  height: 70px;
  padding-top: 17px;

  @media (min-width: 960px) {
    height: 100px;
    padding-top: 30px;
    margin-top: -12px;
    margin-bottom: 3rem;
  }
}
.keim-header-logo {
  display: block;
  padding-top: 10px;
  width: 100px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 960px) {
    width: 150px;
  }
}

.discounts-container {
  display: flex;
  flex-flow: column nowrap;
  line-height: 1rem;
}

.notes {
  color: rgba(0, 0, 0, 0.38) !important;
  line-height: 0.8rem;
  display: block;
}

.quotation-header {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .quotation-header-left {
  }

  .quotation-header-right {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 150px;
  }
}
</style>
