<template>
  <v-row>
    <v-col cols="5">
      <v-hover v-slot:default="{ hover }">
        <v-sheet
          class="color-preview"
          tile
          height="60"
          :color="`rgba(${color.r}, ${color.g}, ${color.b}, 1)`">
              <v-btn x-small fab depressed color="secondary"
                v-if="hover"
                @click="$emit('edit', color)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
        </v-sheet>
      </v-hover>
    </v-col>

    <v-col cols="7">
      <v-row no-gutters>
        <v-col cols="6">
          <span class="font-weight-bold">{{ color.code }}</span>
        </v-col>
        <v-col cols="6">
          <span v-if="color.hbw">
            HBW: {{ color.hbw }}
          </span>
        </v-col>
        <v-col cols="12">
          RGB: {{ color.r }}, {{ color.g }}, {{ color.b }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['color'],

  name: 'Color',

  data: () => ({
  }),
};
</script>

<style lang="scss" scoped>
.color-preview {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
</style>
