<template>
  <div class="component-selectedproducts">
    <v-card outlined class="flex-grow-1">
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>Prodotti selezionati</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-chip
          v-if="selectedProducts.length > 0"
          class="ma-2"
          color="green"
          text-color="white"
        >
          {{ selectedProducts.length }}
        </v-chip>
      </v-toolbar>

      <v-card-text>
        <v-list v-if="selectedProducts.length > 0"
          two-line subheader>
          <template v-for="product in selectedProducts">

            <!-- template v-if="item.header">
              <v-subheader :key="index"
                v-text="item.header" />
            </template -->

            <!-- template v-else -->
              <v-list-item :key="'sId' in product
                ? `product-${product.id}-${product.sId}`
                : `product-${product.id}`" class="pl-0">
                <v-list-item-avatar tile class="mr-2">
                  <v-img v-if="hasImage(product)"
                    :src="product.imageUrl" aspect-ratio="1">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <v-img v-else src="../assets/product-placeholder.png" aspect-ratio="1">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center">
                        <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-html="product.name[userLanguage]"></v-list-item-title>
                  <v-list-item-subtitle>
                    <template v-if="product.consumptionType === 'variable'">
                      <v-btn class="mr-1 my-1"
                        color="secondary" outlined x-small
                        @click="onDetails(product)"
                        v-text="selectedConsumptionRange(product)"></v-btn>
                    </template>

                    <template v-if="isCombined(product)">
                      <v-btn class="mr-1 my-1"
                        color="warning" outlined x-small
                        @click="onDetails(product)">Combinato</v-btn>
                    </template>

                    <template v-if="product.priceType === 'colors'">
                      <v-btn class="mr-1 my-1"
                        color="secondary" outlined x-small
                        @click="onDetails(product)"
                        v-text="selectedPriceRange(product)"></v-btn>
                    </template>

                    <template v-if="product.priceType === 'colors' && product.color">
                      <v-btn class="mr-1 my-1"
                        color="secondary" outlined x-small
                        @click="onDetails(product)">
                        <v-icon
                      :color="`rgba(${product.color.r}, ${product.color.g}, ${product.color.b}, 1)`"
                          left>mdi-circle</v-icon> {{ product.color.code }}
                      </v-btn>
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action v-if="!noActions">
                  <v-row class="d-flex justify-end flex-nowrap">
                    <v-btn class="ml-1" fab x-small depressed color="grey" dark
                      v-if="unselect && !isCombined(product)"
                      @click="onUnselect(product)">
                      <v-icon dark>mdi-close</v-icon>
                    </v-btn>
                    <v-btn class="ml-1" fab x-small depressed color="secondary" dark
                      v-if="details"
                      @click="onDetails(product)">
                      <v-icon dark>mdi-eye</v-icon>
                    </v-btn>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-divider :key="'sId' in product
                ? `product-divider-${product.id}-${product.sId}`
                : `product-divider-${product.id}`"></v-divider>
            <!-- /template -->
          </template>
        </v-list>

        <v-alert v-else
          text type="warning" icon="mdi-format-list-text" border="left">
          Nessun prodotto selezionato
        </v-alert>
      </v-card-text>
    </v-card>

    <ProductDetailsDialog v-model="detailsDialog"
      :quotation="quotation"
      :product="selected"
      :select="false"
      @unselect="onUnselect"
      @update="onUpdate" />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import ProductDetailsDialog from '@/components/ProductDetailsDialog';

export default {
  name: 'SelectedProducts',

  props: {
    quotation: Object,
    unselect: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Boolean,
      default: false,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    // ProductPhases,
    ProductDetailsDialog,
  },

  data: () => ({
    loading: false,

    selected: null,
    detailsDialog: false,

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  computed: {
    selectedProducts() {
      // TODO: Ordinare in ordine alfabetico
      return this.quotation && Array.isArray(this.quotation.selectedProducts)
        ? this.quotation.selectedProducts
        : [];
    },

    allRequiredProducts() {
      if (!Array.isArray(this.quotation.selectedProducts)) return [];

      const result = [];
      this.quotation.selectedProducts.forEach((product) => {
        if (product.consumptionType === 'variable'
          && Array.isArray(product.consumptionranges[product.consumptionRange || 0]
            .requiredProducts)
          && product.consumptionranges[product.consumptionRange || 0].requiredProducts.length > 0) {
          result.push(
            ...product.consumptionranges[product.consumptionRange || 0].requiredProducts
              .map((rp) => rp.product),
          );
        }
      });

      return result;
    },
  },

  methods: {
    hasImage(product) {
      return product
        // eslint-disable-next-line no-prototype-builtins
        && product.hasOwnProperty('imageUrl') && product.imageUrl !== null;
    },

    isCombined(product) {
      // Se il prodotto non può essere combinato si ferma subito (false)
      if ('combined' in product === false || !product.combined) return false;

      // Il prodotto è combinabile

      // Se non può essere utilizzato da solo, si ferma subito (true).
      if ('standalone' in product && product.standalone === false) return true;

      // Verifica se un prodotto tra quelli scelti lo richiede come combinato

      // Conta il numero di prodotti richiesti di questo tipo
      const reqCount = this.allRequiredProducts.filter((rp) => rp.id === product.id).length;

      // Nessun prodotto richiesto di questo tipo
      if (reqCount === 0) return false;

      // È richiesto un prodotto di questo tipo per l'uso combinato

      // Riserva il primo prodotto di questo tipo per l'uso combinato

      // Calcola la posizione in elenco di questo prodotto
      const spList = this.selectedProducts.filter((rp) => rp.id === product.id);
      const spIndex = spList.findIndex((rp) => rp.sId === product.sId);

      // Ritorna true solo se è il primo
      return Boolean(spIndex === 0);
    },

    selectedConsumptionRange(product) {
      // eslint-disable-next-line no-prototype-builtins
      if (!product.hasOwnProperty('consumptionRange') || product.consumptionRange === null) {
        return product.consumptionranges[0].name;
      }
      return product.consumptionranges[product.consumptionRange].name;
    },

    selectedPriceRange(product) {
      // eslint-disable-next-line no-prototype-builtins
      if (!product.hasOwnProperty('priceRange') || product.priceRange === null) {
        return product.priceranges[0].name;
      }
      return product.priceranges[product.priceRange].name;
    },

    onUnselect(product = null) {
      this.loading = true;

      this.$emit('unselect', product || this.selected);

      this.loading = false;
    },

    onUpdate(product) {
      // eslint-disable-next-line no-console
      console.log('SelectedProducts.onUpdate() product =', product);

      this.$emit('update', product);
    },

    onDetails(product) {
      this.selected = product;
      this.detailsDialog = true;
    },
  },
};
</script>

<style scoped>
.v-list-item__title, .v-list-item__subtitle {
  white-space: normal;
}
</style>
