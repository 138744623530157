<template>
  <div class="component-new-notification-dialog">
    <v-dialog v-model="dialog" max-width="800" scrollable>
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>
        <v-card>
          <v-card-title class="headline">
            {{ selected && selected.id ? 'Modifica notifica' : 'Nuova notifica' }}
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-alert
              type="error" dismissible v-model="alert" class="mb-4"
              transition="scale-transition">
              {{ error }}
            </v-alert>

            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  v-model="notification.title"
                  :rules="[(v) => !!v || 'Il titolo è obbligatorio']"
                  label="Titolo"
                  required
                  autofocus
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="notification.body"
                  :rules="[(v) => !!v || 'Il testo è obbligatorio']"
                  label="Testo"
                  rows="1"
                  no-resize auto-grow
                  required
                ></v-textarea>
              </v-col>

              <v-col cols="12" class="mt-6">
                <v-autocomplete
                  v-model="notification.recipients"
                  :items="userTypes"
                  item-text="text"
                  item-value="value"
                  label="Destinatari"
                  outlined
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  clearable
                  :rules="[(v) => !!v.length || 'Seleziona almeno una tipologia di utente']"
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>

            <!-- Call to Action -->

            <v-row no-gutters>
              <v-col cols="6">
                <v-checkbox
                  v-model="notification.cta"
                  label="Aggiungi un pulsante di Call to Action"
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="notification.ctaText"
                  label="Testo pulsante"
                  hide-details
                  :disabled="!notification.cta"
                  :required="notification.cta"
                  :rules="[(v) => !notification.cta || !!v
                    || 'Il testo del pulsante è obbligatorio']"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="notification.ctaUrl"
                  label="URL Call to Action"
                  :disabled="!notification.cta"
                  :required="notification.cta"
                  :rules="[(v) => !notification.cta || !!v || 'L\'URL del pulsante è obbligatorio']"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Allegato -->
             <v-row no-gutters class="mb-6">
              <v-col cols="6">
                <v-checkbox
                  v-model="notification.attachment"
                  label="Aggiungi un pulsante per scaricare un allegato"
                ></v-checkbox>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="notification.attachmentText"
                  label="Testo pulsante"
                  hide-details
                  :disabled="!notification.attachment"
                  :required="notification.attachment"
                  :rules="[(v) => !notification.attachment || !!v
                    || 'Il testo del pulsante è obbligatorio']"
                ></v-text-field>

                <NotificationAttachment
                  v-model="notification" />
              </v-col>
            </v-row>

            <p>Premendo il pulsante <strong>Invia</strong> la notifica verrà inviata
              a tutti gli utenti che corrispondono alle tipologie selezionate.</p>
            <p>Selezionando <strong>Salva come bozza</strong> la notifica verrà
              salvata come bozza e potrà essere inviata in un secondo momento.</p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="submitting"
              @click="onCancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <!-- Salva come bozza -->
            <v-btn v-if="!selected || (selected && selected.status === 'draft')"
              color="primary" text
              :disabled="submitting || missingAttachment"
              @click="onAdd('draft')">
              Salva come bozza
            </v-btn>

            <!-- Invia subito la notifica -->
            <template v-if="selected && selected.id">
              <!-- Modifica una bozza -->
              <v-btn v-if="selected.status === 'draft'"
                type="submit"
                color="success"
                :loading="submitting"
                :disabled="!valid || submitting || missingAttachment"
                @click="onAdd('todo')">
                Invia ora
              </v-btn>

              <!-- Modifica una notifica già inviata -->
              <v-btn v-if="selected.status === 'sent'"
                type="submit"
                color="success"
                :loading="submitting"
                :disabled="!valid || submitting || missingAttachment"
                @click="onAdd('sent')">
                Modifica
              </v-btn>
            </template>
            <template v-else>
              <!-- Nuova notifica -->
              <v-btn type="submit"
                color="success"
                :loading="submitting"
                :disabled="!valid || submitting || missingAttachment"
                @click="onAdd('todo')">
                Invia ora
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { ADD_NOTIFICATION } from '@/store/actions/notifications';
import NotificationAttachment from '@/components/notifications/NotificationAttachment';

export default {
  name: 'EditNotificationDialog',

  props: ['value', 'selected'],

  components: {
    NotificationAttachment,
  },

  data: () => ({
    dialog: false,

    submitting: false,
    valid: true,
    alert: false,

    error: '',

    userTypes: [
      { value: 'privato', text: 'Privato' },
      { value: 'rivenditore', text: 'Rivenditore' },
      { value: 'venditore-esterno', text: 'Venditore esterno della rivendita' },
      { value: 'agente', text: 'Agente' },
      { value: 'imbianchino', text: 'Imbianchino' },
      { value: 'progettista', text: 'Progettista' },
      { value: 'keim', text: 'Funzionario KEIM' },
    ],

    notification: {
      title: '',
      body: '',
      recipients: [],

      cta: false,
      ctaText: '',
      ctaUrl: '',

      attachment: false,
      attachmentText: '',
      attachmentName: '',
      attachmentOriginalName: '',
      attachmentUrl: '',
    },
  }),

  watch: {
    value(newValue) {
      const formRef = this.$refs.form;

      // Reset del modulo quando il dialogo viene aperto
      if (newValue) {
        // Prepara la notifica da modificare.
        // Se non è stata selezionata una notifica esistente, prepara una nuova notifica.
        this.$set(this, 'notification', {
          title: this.selected && 'title' in this.selected ? this.selected.title : '',
          body: this.selected && 'body' in this.selected ? this.selected.body : '',

          cta: this.selected && 'cta' in this.selected ? this.selected.cta === true : false,
          ctaText: this.selected && 'ctaText' in this.selected ? this.selected.ctaText : 'Scopri di più',
          ctaUrl: this.selected && 'ctaUrl' in this.selected ? this.selected.ctaUrl : '',

          attachment: this.selected && 'attachment' in this.selected ? this.selected.attachment === true : false,
          attachmentText: this.selected && 'attachmentText' in this.selected ? this.selected.attachmentText : "Scarica l'allegato",
          attachmentName: this.selected && 'attachmentName' in this.selected ? this.selected.attachmentName : '',
          attachmentOriginalName: this.selected && 'attachmentOriginalName' in this.selected ? this.selected.attachmentOriginalName : '',
          attachmentUrl: this.selected && 'attachmentUrl' in this.selected ? this.selected.attachmentUrl : null,
        });

        // Se è stata selezionata una notifica esistente,
        // memorizza anche il suo ID.
        if (this.selected && 'id' in this.selected) {
          this.$set(this.notification, 'id', this.selected.id);
        }

        if (!this.selected || 'recipients' in this.selected === false || !Array.isArray(this.selected.recipients)) {
          // Seleziona automaticamente tutti i tipi di utente
          this.$set(this.notification, 'recipients', this.userTypes.map((userType) => userType.value));
        } else {
          // Altrimenti seleziona solo i tipi di utente già selezionati
          this.$set(this.notification, 'recipients', this.selected.recipients);
        }

        this.alert = false;
        this.error = '';

        if (formRef) {
          formRef.resetValidation();
        }
      }
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    missingAttachment() {
      return this.notification.attachment && !this.notification.attachmentUrl;
    },
  },

  methods: {
    onCancel() {
      this.dialog = false;
    },

    async onAdd(status = 'todo') {
      const formRef = this.$refs.form;

      if (!formRef.validate()) return;

      this.submitting = true;
      this.alert = false;
      this.error = '';

      const newNotification = {
        ...this.notification,
        status,
      };

      try {
        await this.$store.dispatch(`notifications/${ADD_NOTIFICATION}`, newNotification);
        this.submitting = false;
        this.dialog = false;
      } catch (error) {
        console.error(`${this.$options.name}: onAdd() error =`, error); // eslint-disable-line no-console

        this.submitting = false;

        this.error = `Errore sconosciuto: ${error}`;
        this.alert = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
