<template>
  <div class="component-newquotationdialog">
    <v-dialog v-model="dialog" max-width="600">
      <v-form ref="form"
        v-model="valid"
        @submit.prevent
        lazy-validation>
        <v-card>
          <v-card-title class="headline">Crea un preventivo</v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field type="text"
                  v-model="details.name"
                  :disabled="loading"
                  label="Nome preventivo"
                  autofocus
                  :rules="[v => !!v || 'Il nome del preventivo è richiesto']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field type="text"
                  v-model="details.location"
                  :disabled="loading"
                  label="Località cantiere"
                  :rules="[v => !!v || 'La località è richiesta']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="details.province"
                  :disabled="loading"
                  :items="provinces"
                  clearable
                  label="Provincia cantiere"
                  :rules="[v => !!v || 'La provincia è richiesta']"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <p>Premendo il pulsante <strong>Inizia il test</strong>, verrà avviata la procedura
              guidata che aiuterà nella scelta dei prodotti più adatti
              alle esigenze del cliente.</p>
            <p>Per scegliere direttamente i prodotti dall'elenco generale di quelli disponibili,
              premere <strong>Selezione manuale</strong>.</p>

            <v-row>
              <v-col cols="6">
                <v-btn type="submit"
                  block x-large color="secondary"
                  :disabled="!valid"
                  @click="onManual()">
                  Selezione manuale
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn type="submit"
                  block x-large color="success"
                  :disabled="!valid"
                  @click="onTest()">
                  Inizia il test
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { getProvince } from '@/utils/italy';

export default {
  name: 'NewQuotationDialog',

  props: ['value'],

  data: () => ({
    dialog: false,
    valid: false,
    loading: false,

    details: {
      name: 'Preventivo',
      location: '',
      province: '',
    },
  }),

  watch: {
    value(newValue) {
      if (newValue === true) {
        this.details.name = 'Preventivo';
        this.details.location = '';
        this.details.province = '';
      }
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    provinces: () => getProvince(),
  },

  methods: {
    onManual() {
      const formRef = this.$refs.form;

      if (formRef.validate()) {
        this.$router.push({
          name: 'NuovoPreventivo',
          params: {
            type: 'manual',
          },
          query: this.details,
        });
      }
    },

    onTest() {
      const formRef = this.$refs.form;

      if (formRef.validate()) {
        this.$router.push({
          name: 'NuovoPreventivo',
          params: {
            type: 'test',
          },
          query: this.details,
        });
      }
    },
  },
};
</script>
