/* eslint-disable no-console */

import Vue from 'vue';
import firebase from 'firebase';
import CKEditor from '@ckeditor/ckeditor5-vue';
import { FETCH_USER } from '@/store/actions/auth';
import { INIT_PHASES } from '@/store/actions/phases';
import { INIT_PRICELISTS } from '@/store/actions/pricelists';
import { INIT_COLORLISTS } from '@/store/actions/colorlists';
import { INIT_PRODUCTS } from '@/store/actions/products';
import { INIT_QUOTATIONS } from '@/store/actions/quotations';
import { INIT_TAGS } from '@/store/actions/tags';
import { INIT_USERS } from '@/store/actions/users';
import { INIT_NOTIFICATIONS } from '@/store/actions/notifications';
import App from '@/App';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(CKEditor);

let app = null;

let firebaseConfig = null;

switch (process.env.NODE_ENV) {
  case 'production':
    firebaseConfig = {
      apiKey: 'AIzaSyB6g3n30xjzt93E_9Ne5KI1ryC-HQZUG7A',
      authDomain: 'keim-easy.firebaseapp.com',
      projectId: 'keim-easy',
      storageBucket: 'keim-easy.appspot.com',
      messagingSenderId: '885738672559',
      appId: '1:885738672559:web:9fc7616d2c98aabb50df5a',
      // measurementId: 'G-VLS2PR6X2C',
    };
    Vue.prototype.$firebaseProjectId = 'keim-easy';
    Vue.prototype.$firebaseFunctionsURL = 'https://us-central1-keim-easy.cloudfunctions.net/';
    Vue.prototype.$firebaseHostingURL = 'https://keimeasy.com/';
    break;

  default:
    // Di default utilizza la configurazione per l'ambiente 'development'
    firebaseConfig = {
      apiKey: 'AIzaSyAyy9GPu5KNymmCH24_1ZX0OtxpFvTwbEE',
      authDomain: 'keim-quotations-dev.firebaseapp.com',
      databaseURL: 'https://keim-quotations-dev.firebaseio.com',
      projectId: 'keim-quotations-dev',
      storageBucket: 'keim-quotations-dev.appspot.com',
      messagingSenderId: '289017000999',
      appId: '1:289017000999:web:d0149644b90b5f78540a02',
      // measurementId: '',
    };
    Vue.prototype.$firebaseProjectId = 'keim-quotations-dev';
    Vue.prototype.$firebaseFunctionsURL = 'https://us-central1-keim-quotations-dev.cloudfunctions.net/';
    Vue.prototype.$firebaseHostingURL = 'https://keim.flocca.dev/';
    break;
}

firebase.initializeApp(firebaseConfig);

if (firebaseConfig && 'measurementId' in firebaseConfig) {
  Vue.prototype.$analytics = firebase.analytics();
}

firebase.auth().onAuthStateChanged(async (firebaseUser) => {
  console.log('firebase.auth().onAuthStateChanged()');
  console.log('firebaseUser =', firebaseUser);

  if (firebaseUser) {
    await store.dispatch(FETCH_USER, firebaseUser);

    const { currentUser } = store.getters;

    store.dispatch(`users/${INIT_USERS}`);
    store.dispatch(`quotations/${INIT_QUOTATIONS}`, firebaseUser);
    store.dispatch(`phases/${INIT_PHASES}`);
    store.dispatch(`pricelists/${INIT_PRICELISTS}`);
    store.dispatch(`colorlists/${INIT_COLORLISTS}`);
    store.dispatch(`products/${INIT_PRODUCTS}`);
    store.dispatch(`tags/${INIT_TAGS}`);

    if (currentUser && currentUser.superUser) {
      store.dispatch(`notifications/${INIT_NOTIFICATIONS}`);
    }
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
