<template>
  <div class="component-teststage">
    <v-card outlined>
      <v-card-text>
        <v-stepper vertical class="elevation-0"
          v-if="status && status.currentStep"
          v-model="status.currentStep">

          <template v-for="(question, index) of questions">
            <TestQuestionStep
              v-model="status"
              :key="`question-step-${index}`"
              :index="index+1"
              :stage="stage"
              :question="question" />

            <TestQuestionContent
              v-model="status"
              :key="`question-content-${index}`"
              :index="index+1"
              :stage="stage"
              :question="question" />
          </template>
        </v-stepper>

        <v-alert text prominent type="warning" border="left"
          v-if="colorAlertVisible">
          <v-row>
            <v-col>
              <strong>Attenzione!</strong> Contattare il funzionario KEIM
              per confermare la fattibilità del colore con la mazzetta selezionata.
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="orange"
          :disabled="isJustStarted"
          @click="onRestart()">
          Ricomincia
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn depressed color="success"
          :disabled="!isComplete"
          @click="onContinue()">Continua</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="restartDialog" max-width="600">
      <v-card>
        <v-card-title class="headline">Vuoi ricominciare?</v-card-title>

        <v-card-text>
          <p>Ricominciando dovrai rispondere nuovamente alle domande che
            hai completato fino ad ora. Sei sicuro di voler procedere?</p>
          <p v-if="status.currentStage > 1">
            Puoi scegliere di ricominciare da capo ripartendo dall'inizio del test,
            oppure tornare all'inizio della fase del test attuale.</p>
        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" text
            @click="restartDialog = false">
            Torna al test
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn color="warning" text
            v-if="status.currentStage > 1"
            @click="restartStage()">
            Ricomincia questa fase
          </v-btn>

          <v-btn color="warning" text
            @click="restart()">
            Ricomincia da capo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import TestQuestionStep from '@/components/TestQuestionStep';
import TestQuestionContent from '@/components/TestQuestionContent';

export default {
  name: 'TestStage',

  props: ['value', 'stage'],

  components: {
    TestQuestionStep,
    TestQuestionContent,
  },

  data: () => ({
    status: null,
    restartDialog: false,
  }),

  created() {
    this.status = this.value;
  },

  watch: {
    value(newValue) {
      console.log('TestStage: watch.value()'); // eslint-disable-line no-console
      this.status = newValue;
    },

    currentStage() {
      console.log('TestStage: watch.currentStage()'); // eslint-disable-line no-console
      if (this.isComplete && this.questions.length === 0) {
        if (`stage${this.currentStage}` === this.stageName) {
          this.onContinue();
        }
      }
    },
  },

  computed: {
    stageName() {
      return this.stage && 'name' in this.stage ? this.stage.name : null;
    },

    currentStep() {
      return this.status && 'currentStep' in this.status
        ? this.status.currentStep
        : 0;
    },

    currentStage() {
      return this.status
        && 'currentStage' in this.status
        ? this.status.currentStage
        : 0;
    },

    questions() {
      return this.stage && Array.isArray(this.stage.questions)
        // eslint-disable-next-line arrow-body-style
        ? this.stage.questions.filter((q) => {
          let visible = null;

          // Se non specificato visible = true
          if ('visible' in q === false) {
            visible = true;
          } else
          // Se è specificato ed è boolean...
          if (typeof q.visible === 'boolean') {
            // ... lo usa direttamente
            visible = q.visible;
          } else
          // Se è specificato ed è un array...
          if (Array.isArray(q.visible)) {
            // ... verifica se va mostrato per i tag
            const commonTags = q.visible.filter((tag) => this.status.tags.includes(tag));
            if (commonTags.length > 0) visible = true;
          }

          let hidden = null;

          // Se non specificato hidden = false
          if ('hidden' in q === false) {
            hidden = false;
          } else
          // Se è specificato ed è boolean...
          if (typeof q.hidden === 'boolean') {
            // ... lo usa direttamente
            hidden = q.hidden;
          } else
          // Se è specificato ed è un array...
          if (Array.isArray(q.hidden)) {
            // ... verifica se va mostrato per i tag
            const commonTags = q.hidden.filter((tag) => this.status.tags.includes(tag));
            if (commonTags.length > 0) hidden = true;
          }

          return visible && !hidden;
        })
        : [];
    },

    colorAlertVisible() {
      return this.status
        && Array.isArray(this.status.tags)
        && this.status.tags.includes('finitura-colore')
        && this.status.tags.includes('finitura-rivestimento');
      // && MAZZETTA DIVERSA DA EXCLUSIVE;
    },

    isComplete() {
      return this.status && 'currentStep' in this.status
        && this.status.currentStep > this.questions.length;
    },

    isJustStarted() {
      if (!this.status
        || !this.status.hasOwnProperty('currentStep')
        || !this.status.hasOwnProperty('stages')
        || !this.status.stages.hasOwnProperty(this.stage.name)
        || !this.status.stages[this.stage.name].hasOwnProperty('questions')) return true;

      return this.status.currentStep === 1 && this.status.currentStage === 1
        && Object.keys(this.status.stages[this.stage.name].questions).length === 0
        && this.status.stages[this.stage.name].questions.constructor === Object;
    },
  },

  methods: {
    onRestart() {
      this.restartDialog = true;
    },

    restart() {
      // Torna alla prima domanda dello stage iniziale
      this.status.currentStep = 1;
      this.status.currentStage = 1;
      this.status.complete = false;

      // Elimina tutti i tag
      this.$set(this.status, 'tags', []);

      // Elimina i tag e le risposte di ogni stage
      // eslint-disable-next-line no-restricted-syntax
      for (const stageName in this.status.stages) {
        if (this.status.stages.hasOwnProperty(stageName)) {
          this.$set(this.status.stages[stageName], 'tags', []);
          this.$set(this.status.stages[stageName], 'questions', {});
          this.status.stages[stageName].complete = false;
        }
      }

      this.$emit('input', this.status);
      this.restartDialog = false;
    },

    restartStage() {
      // Torna alla prima domanda dello stage attuale
      this.status.currentStep = 1;
      this.status.complete = false;

      // Lo stage attuale non è più completato
      this.status.stages[this.stage.name].complete = false;

      // Elimina le risposte dello stage attuale
      this.$set(this.status.stages[this.stage.name], 'questions', {});

      // Elimina i tag dello stage attuale dell'elenco globale
      this.status.stages[this.stage.name].tags.forEach((tag) => {
        this.$set(this.status, 'tags', this.status.tags.filter((t) => t !== tag));
      });

      // Elimina i tag dello stage attuale
      this.$set(this.status.stages[this.stage.name], 'tags', []);

      this.$emit('input', this.status);
      this.restartDialog = false;
    },

    onContinue() {
      this.status.stages[this.stage.name].complete = true;
      this.status.currentStep = 1;
      this.status.currentStage += 1;
      this.$emit('input', this.status);
    },
  },
};
</script>

<style scoped>
</style>
