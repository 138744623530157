<template>
  <div class="component-product-selected-packaging">
    <template v-for="(pack, index) in packages">
      <v-tooltip :key="index" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="packaging-button"
            color="secondary" x-small
            :outlined="!print"
            :text="print"
            v-html="packageText(pack)"
            @click="onEditPackaging"></v-btn>
        </template>
        <span >Confezioni selezionate (premere per modificare)</span>
      </v-tooltip>
    </template>

    <v-tooltip v-if="packages.length === 0" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="packaging-button"
          color="error" x-small
          :outlined="!print"
          :text="print"
          @click="onEditPackaging">NESSUNA</v-btn>
      </template>
      <span>Nessuna confezione selezionata (premere per modificare)</span>
    </v-tooltip>

    <v-tooltip bottom max-width="300"
      :color="tooltipColor">
      <template v-slot:activator="{ on, attrs }">
        <span class="estimated-quantity"
          v-bind="attrs"
          v-on="on"
          :class="{ 'orange--text': over, 'red--text': under }">
          {{ estimatedQuantityText }}
        </span>
      </template>
      <span v-if="under">Con le confezioni selezionate si ottiene una
        quantità <strong>inferiore</strong>
        a quella stimata in base al consumo e alla superficie</span>
      <span v-else-if="over">Con le confezioni selezionate si ottiene una
        quantità <strong>superiore</strong>
        a quella stimata in base al consumo e alla superficie</span>
      <span v-else>La quantità stimata in base al consumo e alla superficie
        coincide con quella ottenuta con le confezioni selezionate</span>
    </v-tooltip>


    <EditPackagingDialog v-model="editDialog"
      :product="product"
      :estimatedQuantity="estimatedQuantity"
      @increment="onIncrement"
      @decrement="onDecrement" />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import EditPackagingDialog from '@/components/EditPackagingDialog';

export default {
  name: 'ProductSelectedPackaging',

  props: ['product', 'estimatedQuantity', 'print', 'over', 'under'],

  components: {
    EditPackagingDialog,
  },

  data: () => ({
    editDialog: false,
  }),

  computed: {
    packages() {
      if (!this.product) return [];

      return this.product.priceranges[this.product.priceRange || 0].prices
        .filter((p) => p.quantity > 0);
    },

    // La versione testuale della quantità totale stimata
    estimatedQuantityText() {
      if (!this.product) return '';

      let result = '=';
      if (this.over) result = '>';
      if (this.under) result = '<';

      return this.estimatedQuantity
        ? `${result} ${new Intl.NumberFormat('it-IT').format(this.estimatedQuantity)} ${this.product.um}`
        : '';
    },

    tooltipColor() {
      let result = 'secondary';
      if (this.over) result = 'warning';
      if (this.under) result = 'error';
      return result;
    },
  },

  methods: {
    packageText(pack) {
      if (!this.product || !pack) return '';

      return `<strong>${pack.packaging} ${this.product.um}</strong> <small>x ${pack.quantity}</small>`;
    },

    onEditPackaging() {
      this.editDialog = true;
    },

    onIncrement(index) {
      this.$emit('increment', {
        id: this.product.id,
        sId: 'sId' in this.product ? this.product.sId : null,
        index,
      });
    },

    onDecrement(index) {
      this.$emit('decrement', {
        id: this.product.id,
        sId: 'sId' in this.product ? this.product.sId : null,
        index,
      });
    },
  },
};
</script>

<style scoped>
.component-product-selected-packaging {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-end;
}

.packaging-button {
  padding: 0 0.3rem !important;
  margin-left: 0.2rem;
  margin-bottom: 0.1rem;
  text-transform: none;
  height: 16px !important;
}

.packaging-button >>> small {
  margin-bottom: -0.1rem;
  margin-left: 0.1rem;
  letter-spacing: 0;
}

.estimated-quantity {
  display: block;
  font-size: 0.65rem;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.38);
  text-transform: lowercase !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
