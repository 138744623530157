<template>
  <div class="component-publishcolorlistsdialog">
    <v-dialog v-model="dialog" max-width="600">

        <v-card>
          <v-card-title class="headline">Pubblica le cartelle colori</v-card-title>

          <v-card-text>
            <p><strong>Vuoi pubblicare il cartelle colori?</strong>
              Continuando tutte le eventuali modifiche apportate verranno rese
              pubbliche.</p>
          </v-card-text>

          <v-card-actions>
            <v-btn color="grey" text
              :disabled="loading"
              @click="cancel">
              Annulla
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn color="success" text
              :loading="loading"
              @click="publish()">
              Pubblica
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['value'],

  name: 'PublishColorlistsDialog',

  data: () => ({
    dialog: false,
    loading: false,
  }),

  watch: {
    value(newValue) {
      this.dialog = newValue;
    },

    dialog(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    cancel() {
      this.dialog = false;
    },

    async publish() {
      this.loading = true;

      await this.$store.dispatch('colorlists/publish');

      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>
