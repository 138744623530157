<template>
  <v-dialog
    v-model="loading"
    persistent
    width="300">
    <v-card color="primary" dark>
      <v-card-text class="pt-5">
        <v-progress-linear indeterminate color="white" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['value'],

  name: 'Loading',

  data: () => ({
    loading: true,
  }),

  watch: {
    value(newValue) {
      if (newValue === true) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },

    loading(newValue) {
      if (this.value !== newValue) {
        this.$emit('input', newValue);
      }
    },
  },
};
</script>
