var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"component-productdiscounts"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-weight-medium",on:{"click":function($event){return _vm.onDiscountEdit()}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.discountText)+" ")])]}}])},[_c('span',[_vm._v("Sconto di codifica")])]),(_vm.discountExtra)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({on:{"click":function($event){return _vm.onDiscountEdit()}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.discountExtraText)+" ")])]}}],null,false,324920679)},[_c('span',[_vm._v("Sconto extra (premere per modificare)")])]):_vm._e(),(_vm.discountAccounting)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({on:{"click":function($event){return _vm.onDiscountEdit()}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.discountAccountingText)+" ")])]}}],null,false,3335442524)},[_c('span',[_vm._v("Sconto finanziario")])]):_vm._e(),_c('DiscountDialog',{attrs:{"quotation":_vm.quotation,"product":_vm.product},on:{"updateDiscount":function($event){return _vm.onUpdateDiscount($event)}},model:{value:(_vm.discountDialog),callback:function ($$v) {_vm.discountDialog=$$v},expression:"discountDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }