<template>
  <div class="view-login">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="8" lg="6">
        <v-card class="elevation-12">
          <v-row>
            <v-col cols="12" sm="8" class="py-0 signin">
              <v-card flat>
                <v-toolbar
                  color="primary"
                  tile
                  dark
                  flat>
                  <v-toolbar-title>Accedi</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
                  <v-card-text>
                    <v-alert
                      type="error" dismissible v-model="alert" class="mb-4"
                      transition="scale-transition">
                      {{ error }}
                    </v-alert>
                    <!-- L'email è stata confermata -->
                    <v-alert
                      type="success" v-model="emailConfirmed" class="mb-4"
                      transition="scale-transition">
                      L'indirizzo email è stato confermato! 🎉<br>
                      Ora puoi accedere utilizzando la tua email e la tua password.
                    </v-alert>
                    <v-text-field ref="email"
                      v-model="email"
                      label="Email"
                      name="email"
                      prepend-icon="mdi-email"
                      type="email"
                      :rules="[v => !!v || 'L\'indirizzo email è richiesto']"
                    />
                    <v-text-field ref="password"
                      v-model="password"
                      id="password"
                      label="Password"
                      name="password"
                      prepend-icon="mdi-lock"
                      type="password"
                      :rules="[v => !!v || 'La password è richiesta']"
                    />

                    <span class="caption text-center d-block">
                      Hai dimenticato la password?
                      <router-link :to="{ name: 'ResetAsk' }">Puoi reimpostarla</router-link>
                    </span>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn type="submit" color="primary"
                      :disabled="!valid || submitting"
                      :loading="submitting"
                      @click="onSignin">Accedi</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-col>

            <v-col cols="12" sm="4" class="py-0 signup">
              <v-card flat>
                <v-toolbar
                  color="secondary"
                  tile
                  dark
                  flat>
                  <v-toolbar-title>Registrati</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>

                <v-card-text>
                  <p><strong class="error--text">Non sei ancora registrato?</strong></p>
                  La registrazione su KEIM Easy è <strong>veloce e gratuita</strong>
                  e ti permetterà di accedere a tutte le funzionalità del software.
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="success"
                    :disabled="submitting"
                    :loading="submitting"
                    @click="onSignup">Registrati</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'Login',

  data: () => ({
    email: '',
    password: '',

    submitting: false,
    valid: false,

    alert: false,

    error: '',
  }),

  created() {
    // Logout se l'utente è già loggato
    if (this.user.loggedIn) {
      firebase.auth().signOut().then(() => {
        console.log('firebase.auth().signOut() success'); // eslint-disable-line no-console
      });
    }

    if (this.$route.query.email) {
      this.email = this.$route.query.email;
      this.$nextTick(() => this.$refs.password.focus());
    } else {
      this.$nextTick(() => this.$refs.email.focus());
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },

    emailConfirmed() {
      return this.$route.query.confirmEmail === 'true';
    },
  },

  methods: {
    onSignin() {
      if (this.$refs.form.validate()) {
        this.submitting = true;

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            console.log('firebase.auth().setPersistence() success!'); // eslint-disable-line no-console

            return firebase.auth().signInWithEmailAndPassword(this.email, this.password)
              .then((userCredential) => {
                console.log('firebase.auth().signInWithEmailAndPassword() success!'); // eslint-disable-line no-console
                console.log('firebase.auth().signInWithEmailAndPassword() userCredential =', userCredential); // eslint-disable-line no-console

                // Attende che l'utente sia loggato
                this.$store.dispatch('waitUntilUserIsLoggedIn')
                  .then(() => {
                    console.log('waitUntilUserIsLoggedIn() success'); // eslint-disable-line no-console

                    if (this.$route.query.redirect) {
                      this.$router.replace(this.$route.query.redirect);
                    } else {
                      this.$router.replace('/');
                    }
                  })
                  .catch((error) => {
                    console.log('waitUntilUserIsLoggedIn() error'); // eslint-disable-line no-console
                    console.log(error); // eslint-disable-line no-console
                  });
              })
              .catch((error) => {
                console.log('firebase.auth().signInWithEmailAndPassword() error =', error); // eslint-disable-line no-console

                this.submitting = false;

                switch (error.code) {
                  case 'auth/invalid-email':
                    this.error = 'L\'indirizzo email non è nel formato corretto';
                    break;

                  case 'auth/user-not-found':
                    this.error = 'Non esiste un utente con questo indirizzo email';
                    break;

                  case 'auth/wrong-password':
                    this.error = 'La password non è corretta';
                    break;

                  default:
                    this.error = `Errore sconosciuto (${error.code})`;
                    break;
                }

                this.alert = true;
              });
          })
          .catch((error) => {
            console.log('firebase.auth().setPersistence() error'); // eslint-disable-line no-console
            console.log(error); // eslint-disable-line no-console
          });
      }
    },

    onSignup() {
      this.$router.push({ name: 'Registrati' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';

.view-login {
  flex-grow: 1;
  padding-top: 2rem;
}

.signin {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding-right: 0;
  }
}

.signup {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding-left: 0;
  }

  .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: nowrap;
    background-color: #f5f5f5 !important;
  }

  header {
    flex-grow: 0;
  }

  .v-card__text {
    flex-grow: 1;
  }
}
</style>
