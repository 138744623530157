<template>
  <v-toolbar flat dense class="page-header">
    <v-toolbar-items>
      <v-icon left>{{ icon }}</v-icon>

      <v-breadcrumbs large class="pl-0 py-0" :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-toolbar-items>

    <slot name="right-items"></slot>

    <v-spacer></v-spacer>

    <slot name="left-items"></slot>
  </v-toolbar>
</template>

<script>
export default {
  name: 'PageHeader',

  props: ['breadcrumbs', 'icon'],

  data: () => ({
  }),

  computed: {
  },

  methods: {
  },
};
</script>

<style scoped>
.page-header >>> .v-toolbar__content {
  padding: 4px 0;
}
</style>>
