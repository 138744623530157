<template>
  <div class="component-quotations">
    <v-row no-gutters
      v-if="quotations.length > 0">
      <v-col>
        <v-card outlined>
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="filters.search"
                  outlined
                  placeholder="Cerca..."
                  append-icon="mdi-magnify"
                  clearable
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="px-1">
                <v-autocomplete
                  v-model="filters.province"
                  :items="provinces"
                  placeholder="Tutte le province"
                  outlined
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="archived-switch-continer">
                <v-switch color="orange" class="mt-1"
                  v-model="filters.archived" label="Mostra archiviati"></v-switch>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredQuotations"
            item-key="id"
            :items-per-page="20"
            sort-by="createdAt"
            :sort-desc="true"
            :footer-props="{
              itemsPerPageOptions: [10, 20, 30, 50, 100],
            }"
            class="elevation-0">

            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>

            <template v-slot:item.location="{ item }">
              {{ location(item) }}
            </template>

            <template v-slot:item.createdAt="{ item }">
              {{ createdAt(item.createdAt) }}
            </template>

            <template v-slot:item.user="{ item }">
              {{ item.userName }}
            </template>

            <template v-slot:item.action="{ item }">
              <div class="d-flex justify-end">
                <v-icon small color="orange" class="mr-2"
                  v-if="item.archived">
                  mdi-archive
                </v-icon>

                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- v-list-item
                      @click="onRename(item)">
                      <v-list-item-title>Rinomina</v-list-item-title>
                    </v-list-item -->
                    <v-list-item
                      @click="onDelete(item)">
                      <v-list-item-title>Elimina</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn class="ml-2" fab x-small depressed color="secondary" dark
                  @click="onEdit(item)">
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-alert text prominent type="success" border="left"
      v-else-if="quotations">
      <v-row align="center">
        <v-col class="grow">
          Al momento non è presente nessun preventivo.
        </v-col>
        <v-col class="shrink">
          <v-btn color="success" @click="newDialog = true">Crea un preventivo</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <NewQuotationDialog v-model="newDialog" />
    <DeleteQuotationDialog v-model="deleteDialog" :quotation="selected" />
  </div>
</template>

<script>
import firebase from 'firebase';
import moment from 'moment';
import NewQuotationDialog from '@/components/NewQuotationDialog';
import DeleteQuotationDialog from '@/components/DeleteQuotationDialog';
import { getProvince } from '@/utils/italy';

moment.locale('it');

export default {
  name: 'Quotations',

  components: {
    NewQuotationDialog,
    DeleteQuotationDialog,
  },

  data: () => ({
    newDialog: false,
    renameDialog: false,
    deleteDialog: false,

    selected: null,

    filters: {
      search: '',
      province: '',
      archived: false,
    },

    userLanguage: 'it', // TODO: Dovrà essere impostato nel profilo utente
  }),

  computed: {
    user() {
      return this.$store.getters.user;
    },

    headers() {
      // eslint-disable-next-line no-prototype-builtins
      return this.user && this.user.hasOwnProperty('superUser') && this.user.superUser === true
        ? [
          { text: 'Nome del preventivo', value: 'name', sortable: false },
          { text: 'Località e provincia', value: 'location', sortable: false },
          { text: 'Utente', value: 'user', sortable: false },
          { text: 'Creato il', value: 'createdAt', sortable: true },
          {
            text: '',
            value: 'action',
            sortable: false,
            align: 'end',
          },
        ]
        : [
          { text: 'Nome del preventivo', value: 'name', sortable: false },
          { text: 'Località e provincia', value: 'location', sortable: false },
          { text: 'Creato il', value: 'createdAt', sortable: false },
          {
            text: '',
            value: 'action',
            sortable: false,
            align: 'end',
          },
        ];
    },

    provinces: () => getProvince(),

    quotations() {
      return this.$store.getters['quotations/docs'];
    },

    filteredQuotations() {
      return this.quotations
        ? this.quotations.filter((q) => {
          // Filtro sugli archiviati
          if (!this.filters.archived && q.archived) return false;

          // Ricerca testuale generica su nome, utente e località
          const search = this.filters.search && this.filters.search.trim();
          const searchString = `${q.name} ${q.userName} ${q.location}`;
          const searchArray = searchString.split(' ');
          if (search && searchArray.length > 0) {
            const found = searchArray.filter((s) => s.toLowerCase().includes(search.toLowerCase()));
            if (found.length === 0) return false;
          }

          // Filtro per provincia
          if (this.filters.province
            && q.province !== this.filters.province) return false;

          return true;
        })
        : [];
    },
  },

  watch: {
    quotations(val) {
      if (Array.isArray(val)) {
        this.$emit('ready');
      }
    },
  },

  created() {
    if (Array.isArray(this.quotations)) {
      this.$emit('ready');
    }
  },

  methods: {
    createdAt(timestamp) {
      return timestamp instanceof firebase.firestore.Timestamp
        && moment(timestamp.toDate()).isValid()
        ? moment(timestamp.toDate()).format('LLL')
        : '';
    },

    username(user) {
      return user && user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : '';
    },

    location(quotation) {
      return `${quotation.location} (${quotation.province.toUpperCase()})`;
    },

    onRename(item) {
      this.selected = item;
      this.renameDialog = true;
    },

    onDelete(item) {
      this.selected = item;
      this.deleteDialog = true;
    },

    onEdit(item) {
      this.$router.push({ name: 'Preventivo', params: { id: item.id } });
    },
  },
};
</script>

<style scoped>
.archived-switch-continer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: stretch;
}
</style>
